import {
  WorkflowRequestStatus,
  WorkflowRequestsQueryQuery,
  WorkflowRequestsWorkflowRequestFragment,
} from 'gql/graphql';
import { UseQueryResult } from '@tanstack/react-query';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { isClient } from 'utilities/utils';
import { RequestsTable } from './Components/RequestsTable/RequestsTable';
import {
  AssignedToCell,
  DueDateCell,
  PercentCompleteCell,
  RequestTitleCell,
  UpdatedAtCell,
} from './Components/TableCells';

type ArchivedRequestsTableProps = {
  query: UseQueryResult<WorkflowRequestsQueryQuery>;
  onRowClick: (id: string) => void;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
};

const columns: GridColDef<WorkflowRequestsWorkflowRequestFragment>[] = [
  {
    field: 'title',
    headerName: 'Name',
    flex: 1.5,
    sortable: false,
    renderCell: (params) => <RequestTitleCell params={params} />,
  },
  {
    field: '_assignedContact',
    headerName: 'Assigned To',
    flex: 1,
    sortable: false,
    renderCell: (params) => <AssignedToCell params={params} />,
  },
  {
    field: '_assignedAccount',
    headerName: 'Account',
    flex: 1,
    sortable: false,
    valueGetter: (params) => `${params.row.assignedAccount?.name || ''}`,
  },
  {
    field: '_owner',
    headerName: 'Owner',
    flex: 1,
    sortable: false,
    valueGetter: (params) =>
      params.row?.owner?.employee &&
      `${params.row.owner?.employee.firstName} ${params.row.owner?.employee.lastName}`,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    sortable: true,
    renderCell: (params) => <DueDateCell params={params} />,
  },
  {
    field: 'percentComplete',
    headerName: 'Progress',
    flex: 1,
    sortable: false,
    renderCell: (params) => <PercentCompleteCell params={params} />,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Activity',
    flex: 1,
    sortable: true,
    renderCell: (params) => <UpdatedAtCell params={params} />,
  },
];

export const ArchivedRequestsTable = ({
  query,
  onRowClick,
  paginationModel,
  setPaginationModel,
}: ArchivedRequestsTableProps) => {
  const isUserClient = isClient();

  const columnVisibilityModel = {
    _owner: !isUserClient,
  };

  return (
    <RequestsTable
      query={query}
      onRowClick={onRowClick}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      tableName="Archived Requests"
      columns={columns}
      columnVisibilityModel={columnVisibilityModel}
      requestKey={WorkflowRequestStatus.Closed}
    />
  );
};
