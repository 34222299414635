import { graphql } from 'gql';

export const deleteWorkflowItemDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowItem($workflowItemId: Uuid!) {
    deleteWorkflowItem(workflowItemId: $workflowItemId) {
      errors {
        message
      }
    }
  }
`);

export const updateWorkflowItemDocument = graphql(/* GraphQL */ `
  mutation updateWorkflowItem(
    $prompt: String
    $type: String
    $workflowItemId: Uuid!
  ) {
    updateWorkflowItem(
      prompt: $prompt
      type: $type
      workflowItemId: $workflowItemId
    ) {
      errors {
        message
      }
      workflowItem {
        ...WorkflowItem
      }
    }
  }
`);

export const createWorkflowItemMutationDocument = graphql(/* GraphQL */ `
  mutation createWorkflowItem(
    $workflowSectionId: Uuid
    $workflowId: Uuid
    $workflowItemType: String!
    $prompt: String!
    $conditionalParentId: Uuid
  ) {
    createWorkflowItem(
      workflowSectionId: $workflowSectionId
      workflowId: $workflowId
      workflowItemType: $workflowItemType
      prompt: $prompt
      conditionalParentId: $conditionalParentId
    ) {
      errors {
        message
      }
      workflowItem {
        ...WorkflowItem
      }
    }
  }
`);

export const reorderWorkflowItemsMutationDocument = graphql(/* GraphQL */ `
  mutation reorderWorkflowItems(
    $workflowSectionId: Uuid!
    $workflowItemIds: [Uuid!]!
  ) {
    reorderWorkflowItems(
      workflowItemIds: $workflowItemIds
      workflowSectionId: $workflowSectionId
    ) {
      errors {
        message
      }
      workflowSection {
        ...WorkflowSection
      }
    }
  }
`);

export const reorderWorkflowSectionsMutationDocument = graphql(/* GraphQL */ `
  mutation reorderWorkflowSections(
    $workflowId: Uuid!
    $workflowSectionIds: [Uuid!]!
  ) {
    reorderWorkflowSections(
      workflowId: $workflowId
      workflowSectionIds: $workflowSectionIds
    ) {
      errors {
        message
      }
      workflowSections {
        ...WorkflowSection
      }
    }
  }
`);

export const createWorkflowItemConditionDocument = graphql(/* GraphQL */ `
  mutation createWorkflowItemCondition(
    $workflowItemId: Uuid!
    $conditionType: String!
    $fieldName: String!
    $matchValue: String
    $rangeLowerBound: String
    $rangeUpperBound: String
  ) {
    createWorkflowItemCondition(
      workflowItemId: $workflowItemId
      conditionType: $conditionType
      fieldName: $fieldName
      matchValue: $matchValue
      rangeLowerBound: $rangeLowerBound
      rangeUpperBound: $rangeUpperBound
    ) {
      errors {
        message
      }
      workflowItemCondition {
        id
        conditionType
        fieldName
        matchValue
        rangeLowerBound
        rangeUpperBound
      }
    }
  }
`);

export const updateWorkflowItemConditionDocument = graphql(/* GraphQL */ `
  mutation updateWorkflowItemCondition(
    $workflowItemConditionId: Uuid!
    $conditionType: String
    $fieldName: String
    $matchValue: String
    $rangeLowerBound: String
    $rangeUpperBound: String
  ) {
    updateWorkflowItemCondition(
      workflowItemConditionId: $workflowItemConditionId
      conditionType: $conditionType
      fieldName: $fieldName
      matchValue: $matchValue
      rangeLowerBound: $rangeLowerBound
      rangeUpperBound: $rangeUpperBound
    ) {
      errors {
        message
      }
      workflowItemCondition {
        id
        conditionType
        fieldName
        matchValue
        rangeLowerBound
        rangeUpperBound
      }
    }
  }
`);

export const deleteWorkflowItemConditionDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowItemCondition($workflowItemConditionId: Uuid!) {
    deleteWorkflowItemCondition(
      workflowItemConditionId: $workflowItemConditionId
    ) {
      errors {
        message
      }
    }
  }
`);
