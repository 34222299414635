import SendRequest from 'components/Requests/components/SendRequest/SendRequest';
import { TaxDeliverySummary } from 'components/Requests/components/TaxDelivery/components/TaxDeliverySummary/TaxDeliverySummary';
import { WorkflowViewInfoBar } from 'components/Requests/components/WorkflowView/components/WorkflowViewInfoBar/WorkflowViewInfoBar';
import { WorkflowViewTitlePresentation } from 'components/Requests/components/WorkflowView/components/WorkflowViewTitleWrapper/WorkflowViewTitleWrapper';
import { WorkflowViewWrapper } from 'components/Requests/components/WorkflowView/components/WorkflowViewWrapper/WorkflowViewWrapper';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';
import { DASHBOARD_ROUTE } from 'constants/constants';
import { WorkflowRequestFragment } from 'gql/graphql';
import { useNavigate, useLocation } from 'react-router-dom-latest';
import { Header } from 'ui/Header/Header';

type Props = {
  workflowRequest: Nullable<WorkflowRequestFragment>;
};

export const TaxDeliveryDispatchView = ({ workflowRequest }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  const handleClose = () => {
    navigate(REQUESTS_PAGE_ROUTES.requests);
  };

  return (
    <WorkflowViewWrapper
      slots={{
        Header: <Header onBack={handleGoBack} title="Review and Send" />,
        SideBar: (
          <SendRequest
            workflow={workflowRequest?.workflow}
            workflowRequest={workflowRequest}
            onClose={handleClose}
          />
        ),
      }}
    >
      <WorkflowViewTitlePresentation
        title={workflowRequest?.title || ''}
        description={workflowRequest?.workflow.description || ''}
        sourceDocuments={workflowRequest?.rpaDocuments || []}
        slots={{
          infoBar: <WorkflowViewInfoBar status={workflowRequest?.status} />,
        }}
      />
      <TaxDeliverySummary workflowRequest={workflowRequest} />
    </WorkflowViewWrapper>
  );
};
