import {
  WorkflowItemFragment,
  WorkflowQueryQuery,
  WorkflowSection,
} from 'gql/graphql';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { QueryClient } from '@tanstack/react-query';
import { workflowQueryDocument } from 'api/workflows';

export function getIndexInCache(
  index: SectionItemTuple,
  sections: WorkflowSection[],
) {
  const [sectionIndex, itemIndex] = index;

  if (sectionIndex === null || itemIndex === null) {
    return undefined;
  }
  let count = 0;

  const indexActual = sections?.[sectionIndex]?.items?.findIndex((item) => {
    // its a possible parent item if it doesn't have conditions
    if (!item.conditions?.length) {
      if (itemIndex === count) {
        return true;
      }
      count += 1;
    }
    return false;
  });

  return indexActual !== undefined && indexActual < 0 ? undefined : indexActual;
}

export function getParentItemNumberFromIndex(
  index: SectionItemTuple,
  sections: WorkflowSection[],
) {
  const [sectionIndex, itemIndex] = index;
  if (sectionIndex === null || itemIndex === null) {
    return undefined;
  }
  let count = 0;
  let found = false;

  sections?.some((section, sIdx) =>
    section.items?.some((item) => {
      if (sIdx === sectionIndex) {
        return true;
      }
      if (!item.conditions?.length) {
        count += 1;
      }
      return false;
    }),
  );
  sections[sectionIndex]?.items?.some((item, idx) => {
    if (item.conditions?.length) {
      return false;
    }
    if (idx === itemIndex) {
      found = true;
      return true;
    }
    count += 1;
    return false;
  });
  const number = count + 1;
  return !found || number < 0 ? undefined : number;
}

export function getWorkflowItemWithIndexFromCache(
  queryClient: QueryClient,
  activeIndex: SectionItemTuple,
  workflowId: string,
): [WorkflowItemFragment | null, Array<WorkflowItemFragment>] {
  const [sectionIndex, itemIndex] = activeIndex;
  if (sectionIndex === null || itemIndex === null) {
    return [null, []];
  }
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (!oldWorkflows) {
    return [null, []];
  }

  const parentItem =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items?.[itemIndex];

  if (parentItem === undefined) {
    return [null, []];
  }

  const conditionalItems =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items
      ?.slice(itemIndex + 1)
      .filter(
        (item) =>
          item.conditions?.length &&
          item.conditions?.[0]?.fieldName === parentItem?.id,
      ) || [];

  return [parentItem, conditionalItems];
}

export function getWorkflowItemWithIdFromCache(
  queryClient: QueryClient,
  activeId: string,
  workflowId: string,
): [WorkflowItemFragment | null, Array<WorkflowItemFragment>] {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (!oldWorkflows) {
    return [null, []];
  }

  let sectionIndex = -1;
  let itemIndex = -1;
  let parentItem: WorkflowItemFragment | undefined;
  oldWorkflows?.workflow?.sections?.find((section, sIdx) => {
    parentItem = section.items?.find((item, idx) => {
      if (item.id === activeId) {
        sectionIndex = sIdx;
        itemIndex = idx;
        return true;
      }
      return false;
    });
    return parentItem !== undefined;
  });

  if (!parentItem || sectionIndex === -1 || itemIndex === -1) {
    return [null, []];
  }

  const conditionalItems =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items?.filter(
      (item) =>
        item.conditions?.length && item.conditionalParentId === parentItem?.id,
    ) || [];
  return [parentItem as WorkflowItemFragment, conditionalItems];
}
