import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { BuilderSection } from 'components/Requests/requests.types';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { QuestionEditor } from 'components/Requests/components/RequestsBuilder/components/QuestionEditor/QuestionEditor';
import {
  useCreateWorkflowSection,
  useDeleteWorkflowSection,
  useUpdateWorkflowSection,
} from 'hooks/workflow-hooks';

export interface SectionEditorProps {
  sections: BuilderSection[];
  setSections: React.Dispatch<React.SetStateAction<BuilderSection[]>>;
}

export const SectionEditor: React.FC<SectionEditorProps> = ({
  sections,
  setSections,
}: SectionEditorProps) => {
  const { mutate: createWorkflowSectionMutation } = useCreateWorkflowSection();
  const { mutate: deleteWorkflowSectionMutation } = useDeleteWorkflowSection();
  const { mutate: updateWorkflowSectionMutation } = useUpdateWorkflowSection();

  const {
    activeQuestionIndex,
    setActiveQuestionIndex,
    workflowId,
    newItemInEdit,
    setNewItemInEdit,
  } = useRequestBuilderContext();
  const [sectionIndex, itemIndex] = activeQuestionIndex;

  const handleDeleteClicked = ([sectionIdx]: SectionItemTuple) => {
    if (!newItemInEdit && sectionIdx !== null) {
      const workflowSectionId = sections[sectionIdx].id;

      deleteWorkflowSectionMutation(
        {
          workflowSectionId,
        },
        {
          onSuccess: (response) => {
            if (response?.deleteWorkflowSection?.errors?.length) {
              return;
            }
            setNewItemInEdit(null);
            setSections((prevSections) => {
              const newSections = structuredClone(prevSections);
              newSections.splice(sectionIdx, 1);
              newSections.forEach((_, idx) => {
                newSections[idx].position = idx;
              });
              return newSections;
            });

            setActiveQuestionIndex([
              sectionIdx !== 0 ? sectionIdx - 1 : null,
              null,
            ]);
          },
        },
      );
    }
  };

  function createOrUpdateSection(itemText: string) {
    if (!workflowId || !itemText) {
      return;
    }

    if (!newItemInEdit && sectionIndex !== null) {
      if (sections[sectionIndex].title === itemText) {
        return;
      }
      updateWorkflowSectionMutation(
        {
          title: itemText,
          workflowSectionId: sections[sectionIndex].id,
        },
        {
          onSuccess: (response) => {
            if (response?.updateWorkflowSection?.errors?.length) {
              return;
            }

            setSections((prevSections) => {
              const newSections = structuredClone(prevSections);
              newSections[sectionIndex].id =
                response?.updateWorkflowSection?.workflowSection?.id || '';
              newSections[sectionIndex].title = itemText;
              return newSections;
            });
          },
        },
      );
      return;
    }

    if (!newItemInEdit) {
      return;
    }

    createWorkflowSectionMutation(
      {
        workflowId,
        title: itemText,
      },
      {
        onSuccess: (response) => {
          if (response?.createWorkflowSection?.errors?.length) {
            return;
          }

          setSections((prevSections) => {
            const newSections = structuredClone(prevSections);
            newSections.push({
              title: itemText,
              id: response?.createWorkflowSection?.workflowSection?.id || '',
              position: sections.length,
              items: [],
            });
            return newSections;
          });
          setNewItemInEdit(null);
          setActiveQuestionIndex([newItemInEdit.sectionIndex, null]);
        },
      },
    );
  }

  return (
    <QuestionEditor
      key={newItemInEdit ? 'NEW' : sections[sectionIndex!]?.id}
      defaultValue={!newItemInEdit ? sections[sectionIndex!]?.title || '' : ''}
      onInputChange={createOrUpdateSection}
      onDelete={handleDeleteClicked}
      deleteDisabled={sections.length < 1 && itemIndex === null}
      id={newItemInEdit ? 'NEW' : sections[sectionIndex!]?.id || ''}
    />
  );
};
