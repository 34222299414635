import { BuilderSection } from 'components/Requests/requests.types';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { DragHandle } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  List,
} from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';

export interface QuestionListItemsProps {
  section: BuilderSection;
  sectionIndex: number | null;
  itemIndex: number | null;
  onQuestionClicked: (index: SectionItemTuple) => void;
}

export const QuestionListItems = ({
  section,
  sectionIndex,
  itemIndex,
  onQuestionClicked,
}: QuestionListItemsProps) => (
  <Droppable droppableId={section.id} key={section.id} type="item">
    {(droppableProvided) => (
      <div
        ref={droppableProvided.innerRef}
        {...droppableProvided.droppableProps}
      >
        <List disablePadding>
          {section?.items?.map((question, qIdx) => {
            if (question.conditions?.length) {
              return null;
            }

            const isInEdit = question.id === 'NEW';
            const isSelectedItem =
              (section.position === sectionIndex && qIdx === itemIndex) ||
              isInEdit;

            const label = isInEdit ? 'New Question' : question.prompt || '';
            return (
              <Draggable
                key={question.id}
                draggableId={`${question.id}~~${section.position}`}
                index={qIdx}
                isDragDisabled={isInEdit}
              >
                {(draggableProvided) => (
                  <ListItemButton
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    onClick={() => onQuestionClicked([section.position, qIdx])}
                    selected={isSelectedItem}
                    aria-selected={isSelectedItem}
                    aria-label={label}
                    role="option"
                    key={question.id}
                    sx={{ alignItems: 'flex-start' }}
                  >
                    <ListItemIcon sx={{ minWidth: '36px', marginTop: '.2rem' }}>
                      {!isInEdit && <DragHandle />}
                    </ListItemIcon>
                    <ListItemText>
                      <Box display="flex" gap="10px">
                        <Typography
                          flexGrow={0}
                          sx={{ minWidth: '20px', paddingRight: '0.3rem' }}
                          fontWeight={isSelectedItem ? 700 : 400}
                        >
                          {question.position !== undefined &&
                            question.position !== null &&
                            question.position + 1}
                        </Typography>
                        <Typography
                          textAlign="start"
                          width="100%"
                          fontWeight={isSelectedItem ? 700 : 400}
                        >
                          {label}
                        </Typography>
                      </Box>
                    </ListItemText>
                  </ListItemButton>
                )}
              </Draggable>
            );
          })}
          {droppableProvided.placeholder}
        </List>
      </div>
    )}
  </Droppable>
);
