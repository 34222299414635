import { Box, useTheme } from '@mui/material';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { useReorderWorkflowSections } from 'hooks/workflow-hooks';
import { useReorderWorkflowItems } from 'hooks/workflow-item-hooks';
import {
  BuilderSection,
  WorkflowItemType,
} from 'components/Requests/requests.types';
import { WorkflowItem, WorkflowSection } from 'gql/graphql';
import { AlertMessage } from 'utilities/utils';
import { useQueryClient } from '@tanstack/react-query';
import { TOAST_FAILURE } from 'constants/constants';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { getWorkflowItemWithIndexFromCache } from 'components/Requests/components/RequestsBuilder/utils/item-utils';
import { SectionEditor } from './QuestionEditor/components/SectionEditor/SectionEditor';
import { WorkflowItemEditor } from './QuestionEditor/components/WorkflowItemEditor/WorkflowItemEditor';
import { QuestionList } from './QuestionList/QuestionList';

export type SectionItemTuple = [number | null, number | null];
export interface QuestionsBuilderProps {
  initialSections?: WorkflowSection[];
  footer: React.ReactNode;
}

export const QuestionsBuilder = ({
  initialSections,
  footer,
}: QuestionsBuilderProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { mutate: reorderWorkflowSectionMutation } =
    useReorderWorkflowSections();
  const { mutate: reorderWorkflowItemMutation } = useReorderWorkflowItems();

  const sortSectionItems = (items: WorkflowItem[]) => {
    const comparator = (a: WorkflowItem, b: WorkflowItem) => {
      const first = a.conditionalParentId !== null ? 1 : 0;
      const second = b.conditionalParentId !== null ? 1 : 0;
      return first - second;
    };

    return items.sort(comparator);
  };

  // kind of a hack to map the server sections to the builder sections until the server supports ordering
  const [sections, setSections] = useState<BuilderSection[]>(
    (initialSections && initialSections.length > 0
      ? (initialSections.map((section, idx) => ({
          ...section,
          position: idx,
          items: sortSectionItems(section.items || []),
        })) as BuilderSection[])
      : []) as BuilderSection[],
  );

  const {
    activeQuestionIndex,
    setActiveQuestionIndex,
    isSubmitting,
    workflowId,
    newItemInEdit,
    setNewItemInEdit,
    isDirty,
  } = useRequestBuilderContext();
  const [sectionIndex, itemIndex] = activeQuestionIndex;

  useEffect(() => {
    if (sectionIndex === null) {
      // if we have no sections we need to add one
      if (sections.length === 0) {
        if (newItemInEdit) {
          return;
        }

        setNewItemInEdit({
          sectionIndex: 0,
          prompt: '',
          type: null,
        });

        if (itemIndex === null) {
          return;
        }

        setActiveQuestionIndex([null, null]);
        return;
      }
      if (newItemInEdit) {
        return;
      }
      setActiveQuestionIndex([0, null]);
    }
  }, [
    newItemInEdit,
    itemIndex,
    sectionIndex,
    sections,
    setActiveQuestionIndex,
    setNewItemInEdit,
  ]);

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    // Use this to get the section information from the dragged item.
    const sectionPosition = Number(draggableId.split('~~')[1]);

    if (!destination || destination.index === source.index) {
      return;
    }

    if (type === 'section') {
      reorderSections(source.index, destination.index);
    } else {
      reorderItems(source.index, destination.index, sectionPosition);
    }
  };

  const reorderSections = (start: number, end: number) => {
    if (sectionIndex === null) {
      return;
    }

    const fallbackSections = [...sections];
    setSections((prevSections) => {
      const newOrder = [...prevSections];
      const removed = newOrder.splice(start, 1);
      newOrder?.splice(end, 0, ...removed);
      return newOrder.map((section, idx) => ({
        ...section,
        position: idx,
      }));
    });

    const mutationOrder = [...sections];
    const removed = mutationOrder.splice(start, 1);
    mutationOrder?.splice(end, 0, ...removed);

    reorderWorkflowSectionMutation(
      {
        workflowId,
        workflowSectionIds: mutationOrder.map((section) => section.id),
      },
      {
        onSuccess: (response) => {
          const rSections = response?.reorderWorkflowSections?.workflowSections;

          if (!rSections) {
            setSections(fallbackSections);
            return;
          }

          setSections(rSections as BuilderSection[]);
        },
      },
    );
  };

  const reorderItems = (
    start: number,
    end: number,
    sectionPosition: number,
  ) => {
    const [parentItem, conditionalItems] = getWorkflowItemWithIndexFromCache(
      queryClient,
      [sectionPosition, start],
      workflowId || '',
    );

    if (!parentItem?.section.id || !conditionalItems) {
      AlertMessage(TOAST_FAILURE, 'Failed to reorder question');
      return;
    }
    const fallbackSections = [...sections];

    //#region mutation ordering
    // This region is responible for properly ordganizing the various items
    // after a DnD.
    const sectionItems = [...(sections[sectionPosition].items || [])];
    const mutationOrder = sectionItems.filter(
      (item) => item.id !== parentItem.id,
    );

    mutationOrder?.splice(end, 0, parentItem);
    //#endregion

    setSections((prevQuestions) => {
      const newQuestions = structuredClone(prevQuestions);
      newQuestions[sectionPosition].items = mutationOrder;
      return newQuestions;
    });

    reorderWorkflowItemMutation(
      {
        workflowSectionId: parentItem.section.id,
        workflowItemIds: mutationOrder.map((item) => item.id),
      },
      {
        onSuccess: (response) => {
          const rSections = response?.reorderWorkflowItems?.workflowSection;
          if (!rSections) {
            setSections(fallbackSections);
          }
        },
      },
    );
  };

  const handleQuestionClicked = (index: SectionItemTuple) => {
    const [sectionIdx, itemIdx] = index;
    if (sectionIdx === null || sectionIdx >= sections.length) {
      return;
    }
    const itemCount = sections[sectionIdx].items?.length;
    if (itemCount && itemIdx && itemCount < itemIdx) {
      return;
    }

    setNewItemInEdit(null);
    setActiveQuestionIndex(index);
  };

  const handleCreateQuestionClicked = (
    sectionIdx: number,
    type: WorkflowItemType | null,
  ) => {
    if (!type) {
      setNewItemInEdit({
        sectionIndex: sections.length,
        type: null,
        prompt: '',
      });
      setActiveQuestionIndex([null, null]);
      return;
    }

    setNewItemInEdit({
      sectionIndex: sectionIdx,
      type,
      prompt: '',
    });

    setActiveQuestionIndex([null, null]);
  };

  return (
    <Box display="flex" flexGrow={1} overflow="hidden">
      <Box
        width="30%"
        borderRight={`1px solid ${theme.palette.common.secondary[200]}`}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <QuestionList
            sections={sections}
            onQuestionClicked={handleQuestionClicked}
            onAddQuestion={handleCreateQuestionClicked}
            addQuestionEnabled={
              !isDirty && !isSubmitting && newItemInEdit === null
            }
          />
        </DragDropContext>
      </Box>
      <Box display="flex" flexDirection="column" width="70%" height="100%">
        <Box
          width="100%"
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="start"
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          {(newItemInEdit && newItemInEdit.type) || itemIndex !== null ? (
            <WorkflowItemEditor sections={sections} setSections={setSections} />
          ) : (
            <SectionEditor sections={sections} setSections={setSections} />
          )}
        </Box>
        {footer}
      </Box>
    </Box>
  );
};
