import { Box, Button, ButtonGroup, Stack } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import { CustomPagination } from './CustomPagination';

export const ActionButtonFooter = () => {
  const selectedFileCount = 1;
  return (
    <Stack
      direction="row"
      justifyContent={selectedFileCount > 0 ? 'space-between' : 'flex-end'}
      className={`${gridClasses.footerContainer}`}
    >
      {selectedFileCount > 0 && (
        <Box p={1.5}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyItems="center"
          >
            <Box height="100%">selected files ({selectedFileCount}):</Box>
            <ButtonGroup variant="contained">
              <Button onClick={() => console.debug('clicked bulk send')}>
                Bulk Send
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      )}
      <CustomPagination />
    </Stack>
  );
};
