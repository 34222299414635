import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { OAUTH_ACCESS_TOKENS } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import './picker.css'
import { BoxFile, PickerProps } from './types';

export default function Picker ({ cancelWidget, startUpload }: PickerProps) {
  const [accessToken, setAccessToken] = useState('')

  const openPicker = () => {
    // @ts-ignore
    const filePicker = new Box.FilePicker()
    filePicker.addListener('cancel', cancelWidget)
    filePicker.addListener('choose', handleSelect)
    filePicker.show(
      '0',
      accessToken,
      {
        container: "#box-picker",
        logoUrl: 'box'
      }
    )
  }

  const fetchAccessToken = () => {
    const request = Axios.get(`${OAUTH_ACCESS_TOKENS}/box`);
    request.then((response) => {
      if (response.status === 200) {
        setAccessToken(response.data.access_token)
      }
    });
  }

  useEffect(() => {
    fetchAccessToken()
  }, []);

  useEffect(() => {
    if (accessToken) openPicker()
  }, [accessToken]);

  const handleSelect = (files: BoxFile) => {
    const file = {
      '@microsoft.graph.downloadUrl': files[0].shared_link.download_url,
      name: files[0].name
    }
    startUpload([file], 'box')
    cancelWidget()
    AlertMessage('success', 'File uploading', 3000)
  };

  return (
      <Modal isOpen className="customModal customModal--center customModal--alert customModal--nopadding">
      <div className="ModalHeader">
        <button type="button" className="close" aria-label="Close" onClick={cancelWidget}>
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalHeader>
        Select a File
      </ModalHeader>
      <ModalBody className="shiza">
        <div id="box-picker"/>
      </ModalBody>
    </Modal>
  )
}
