/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import Interweave from 'interweave';
import moment from 'moment-timezone';
import { Line } from 'rc-progress';
import { FileDrop } from 'react-file-drop';
import { GraphFileBrowser } from '@microsoft/file-browser';
import 'react-datepicker/dist/react-datepicker.css';
import GooglePicker from 'common/GooglePicker';
import SharePointPicker from 'common/SharePoint/Picker';
import BoxPicker from 'common/Box/Picker';
import SmartVaultWidget from 'common/SmartVault';
import {
  fileType,
  isUserEmployee,
  getCpaPreferences,
  truncateText,
} from 'utilities/utils';
import liscioLogo from 'images/liscio_logo.png';
import Attachment from './Attachment';
import Menu from 'common/Menu';
import createReactClass from 'create-react-class';
import { Modal, ModalBody, Label, Input } from 'reactstrap';
import HeaderTabs from './HeaderTabs_updated';
import Avatar from '@mui/material/Avatar';

const GRAVATAR_SIZE = 20;
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const onedriveListHeaders = [
  {
    value: 'name',
    label: 'Name',
    className: 'col-3 thCol',
  },
  {
    value: 'shared_by',
    label: 'Shared By',
    className: 'col-2 thCol',
  },
  {
    value: 'modified_by',
    label: 'Modified By',
    className: 'col-2 thCol',
  },
  {
    value: 'date_modified',
    label: 'Date Modified',
    className: 'col-2 thCol',
  },
  {
    value: 'size',
    label: 'Size',
    className: 'col-1 thCol',
  },
  {
    value: 'sharing',
    label: 'Sharing',
    className: 'col thCol',
  },
];
const oneDriveModalHeaders = [
  {
    label: 'My Files',
    value: 'my_files',
  },
  {
    label: 'Shared with me',
    value: 'shared_with_me',
  },
];

export const GravatarOption = ({ onSelect, option, className, children }) => {
  function handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  }

  return (
    <div className={className} onMouseDown={handleMouseDown}>
      <Avatar
        round
        size={GRAVATAR_SIZE}
        className="avatar"
        color="#0366d6"
        name={option.label}
        src={option.avatar}
      />
      {children}
    </div>
  );
};

export const GravatarValue = ({ value, children }) => (
  <div className="Select-value" title={value.title}>
    <span className="Select-value-label">
      <Avatar
        round
        name={value.label}
        size={GRAVATAR_SIZE}
        src={value.avatar}
      />
      {children}
    </span>
  </div>
);

export const DatePickerHTML = ({ onClick, value }) => {
  useEffect(() => {
    document.querySelector('.icon-calendar').addEventListener('click', () => {
      onClick();
    });
  }, []);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label style={{ cursor: 'pointer' }} className="date" onClick={onClick}>
      {value}
    </label>
  );
};

export const selectStylesEmail = {
  container: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    outlineStyle: 'none',
    '&:hover': {
      border: 'none',
      outlineStyle: 'none',
    },
    '&:focus': {
      border: 'none',
      outlineStyle: 'none',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    boxShadow: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: 0,
    outlineStyle: 'none',
    boxShadow: 'none',
    zIndex: 992,
    color: '#353C42',
  }),
};

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    outlineStyle: 'none',

    '&:hover': {
      border: 'none',
      outlineStyle: 'none',
    },
    '&:focus': {
      border: 'none',
      outlineStyle: 'none',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
    boxShadow: 'none',
    '&:hover': {
      border: state.isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
      outlineStyle: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
  }),
};

export const selectStylesEmailQuickCreate = {
  ...selectStyles,
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
    boxShadow: 'none',
    '&:hover': {
      border: state.isFocused ? '2px solid #919eab' : '2px solid #dfe3e8',
      outlineStyle: 'none',
    },
    fontWeight: 400,
    height: '40px',
    backgroundColor: '#f9fafb',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
    border: '2px solid #919eab',
    outlineStyle: 'none',
    boxShadow: 'none',
    zIndex: 992,
    fontWeight: 400,
  }),
};

export const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 2,
  colors: {
    ...theme.colors,
    primary25: '#dfe3e8',
    primary: '#919eab',
    primary50: 'dfe3e8',
    primary75: 'dfe3e8',
  },
  spacing: {
    baseUnit: 3,
    controlHeight: 34,
    menuGutter: 8,
  },
});

export const selectSeperator = {
  IndicatorSeparator: null,
};

export const SelectField = ({
  className,
  error,
  labelClass,
  name,
  label,
  options,
  handleChange,
  handleInputChange,
  value,
  clearable,
  disabled,
  onBlur,
  isLoading,
  noResultsText,
  onMenuScrollToBottom,
  onOpen,
  onBlurResetsInput,
  onCloseResetsInput,
  errorClass,
  from,
  menuPlacement,
  testid,
}) => {
  const stylesMap = {
    email: selectStylesEmail,
    emailNotes: selectStylesEmail,
    emailQuickCreate: selectStylesEmailQuickCreate,
  };

  const styles = stylesMap[from] || selectStyles;

  return (
    <div
      className={
        className
          ? className + (error ? ' form-error' : '')
          : `form-group form-inline-label align-items-stretch dropdown col-md-${
              from === 'email' ? 4 : 6
            } col-12${error ? ' form-error' : ''}`
      }
    >
      <label className={labelClass || ''} htmlFor={name}>
        {label}
      </label>
      <div className="labelValue" data-testid={testid}>
        <Select
          aria-label={name}
          id={name}
          name={name}
          options={options}
          onChange={handleChange}
          onInputChange={handleInputChange}
          className="select-custom-class"
          isClearable={clearable}
          isDisabled={disabled}
          inputProps={{
            autoComplete: 'off',
          }}
          value={value}
          onBlur={onBlur}
          isLoading={isLoading}
          noResultsText={noResultsText || 'No Data'}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onOpen={onOpen}
          onBlurResetsInput={onBlurResetsInput}
          onCloseResetsInput={onCloseResetsInput}
          styles={styles}
          components={
            (from === 'email' || from === 'emailNotes') && selectSeperator
          }
          theme={selectTheme}
          menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        />
        <span className={errorClass || 'error_label'}>{error}</span>
      </div>
    </div>
  );
};

export const OptionBoxSelectField = ({
  name,
  options,
  handleChange,
  value,
  clearable,
  disabled,
  placeholder,
  error,
}) => (
  <div className="dropdown-item">
    <Select
      name={name}
      options={options}
      onChange={handleChange}
      value={value}
      className="select-custom-class"
      clearable={clearable}
      disabled={disabled}
      placeholder={placeholder}
      styles={selectStyles}
      theme={selectTheme}
    />
    <span className="error_label">{error}</span>
  </div>
);

export const SelectFieldWithAvatar = ({
  className,
  error,
  labelClass,
  name,
  label,
  options,
  handleChange,
  handleInputChange,
  value,
  defaultValue,
  clearable,
  disabled,
  onBlur,
  from,
  menuPlacement,
  placeholder,
}) => (
  <div
    className={
      className
        ? className + (error ? ' form-error' : '')
        : `form-group form-inline-label align-items-stretch dropdown col-md-${
            from === 'email' ? 4 : 6
          } col-12${error ? ' form-error' : ''}`
    }
  >
    <label className={labelClass || ''} htmlFor={name}>
      {label}
    </label>
    <div className="labelValue">
      <Select
        name={name}
        placeholder={placeholder}
        options={options}
        menuPlacement={menuPlacement || 'auto'}
        onChange={handleChange}
        onInputChange={handleInputChange}
        formatOptionLabel={formatOptionLabel}
        className="select-custom-class"
        defaultValue={defaultValue}
        value={
          options
            ? options.filter(function (option) {
                return (
                  (from &&
                  (from === 'notes' || from === 'emailNotes') &&
                  option.id
                    ? option.id
                    : option.value) === value.value
                );
              })
            : null
        }
        isClearable={clearable}
        isDisabled={disabled}
        onBlur={onBlur}
        styles={
          from === 'email' || from === 'emailNotes'
            ? selectStylesEmail
            : selectStyles
        }
        theme={selectTheme}
        components={
          (from === 'email' || from === 'emailNotes') && selectSeperator
        }
      />
      <span className="error_label">{error}</span>
    </div>
  </div>
);

export const formatOptionLabel = ({ label, avatar, initials }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {initials && (
      <Avatar
        round
        size={GRAVATAR_SIZE}
        color="#0366d6"
        style={{ marginRight: '4px', width: '20px', height: '20px' }}
        name={label}
        src={avatar}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '10px',
          }}
        >
          {initials}
        </span>
      </Avatar>
    )}
    {label}
  </div>
);

// Email select styled Div
const StyledEmailSelect = styled.div`
  display: flex;
  align-items: center;
  text-transform: none;
  .Avatar__Size {
    margin-right: 4px;
    width: 40px;
    height: 40px;
    background-color: #0366d6;
  }
  .Avatar__Size__Selected {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    background-color: #0366d6;
    span {
      font-size: 8px;
    }
  }
  & > span {
    display: flex;
    align-items: center;
    font-size: 10px;
  }
  .Select__Label {
    display: flex;
    flex-direction: column;
  }
  .Email__Font {
    font-size: 12px;
  }
`;

// Display list of contacts in Email section in select
export const emailListOptions = (props) => (
  <components.Option {...props}>
    <StyledEmailSelect>
      {props?.data?.initials && (
        <Avatar
          round
          size={GRAVATAR_SIZE}
          className="Avatar__Size"
          name={props?.data?.label}
          src={props?.data?.avatar}
        >
          <span>{props?.data?.initials}</span>
        </Avatar>
      )}
      <div className="Select__Label">
        <span>{props?.data?.label}</span>
        <span className="Email__Font">{props?.data?.email}</span>
      </div>
    </StyledEmailSelect>
  </components.Option>
);

// Display Selected content in Select
export const formatOptionLabelEmail = (item) => (
  <StyledEmailSelect>
    {item.initials && (
      <Avatar
        round
        size={GRAVATAR_SIZE}
        className="Avatar__Size__Selected"
        name={item.label}
        src={item.avatar}
      >
        <span>{item.initials}</span>
      </Avatar>
    )}
    <div className="Select__Label">
      <span>{item.label}</span>
    </div>
  </StyledEmailSelect>
);

// Select for Email add to contacts contains Email,
export const SelectFieldEmail = ({
  className,
  error,
  labelClass,
  name,
  label,
  options,
  handleChange,
  handleInputChange,
  value,
  clearable,
  disabled,
  onBlur,
  isLoading,
  noResultsText,
  onMenuScrollToBottom,
  onOpen,
  onBlurResetsInput,
  onCloseResetsInput,
  errorClass,
  placeholder,
}) => (
  <div className={className && className}>
    <label className={labelClass || ''} htmlFor={name}>
      {label}
    </label>
    <div className="email-lower-case">
      <Select
        id={name}
        placeholder={placeholder && placeholder}
        name={name}
        formatOptionLabel={formatOptionLabelEmail}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        className="select-custom-class"
        isClearable={clearable}
        isDisabled={disabled}
        value={value}
        onBlur={onBlur}
        isLoading={isLoading}
        noResultsText={noResultsText || 'No Data'}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onOpen={onOpen}
        styles={selectStyles}
        components={{
          Option: emailListOptions,
        }}
        theme={selectTheme}
      />
      <span className={errorClass || 'error_label'}>{error}</span>
    </div>
  </div>
);

const formatGroupLabel = (data) => (
  <div
    style={{ display: 'flex', alignItems: 'center' }}
    className="SelectGroupLabel"
  >
    <span
      style={{
        fontSize: '12px',
        textTransform: 'uppercase',
        color: '#919EAB',
        backgroundColor: '#dfe3e8',
        letterSpacing: '1px',
        fontWeight: '600',
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        padding: '2px 7px',
        marginBottom: '6px',
      }}
    >
      {data.label}
    </span>
  </div>
);
export const OptionBoxSelectFieldWithAvatar = ({
  name,
  label,
  options,
  handleChange,
  handleInputChange,
  inputRenderer,
  onBlurResetsInput,
  onCloseResetsInput,
  onBlur,
  value,
  onSelectResetsInput,
  placeholder,
  isLoading,
  noResultsText,
  clearable,
  autofocus,
  disabled,
  error,
  inputValue,
}) => {
  let finalOptionValue;
  if (options.length === 2 && options[0].options) {
    const filterOptions = options.map((d) =>
      d.options.filter((e) => e.value === value.value),
    );
    const showValue = filterOptions.filter((d) => d.length);
    finalOptionValue = showValue[0];
  } else {
    finalOptionValue = options.filter(function (option) {
      return option.value === value.value;
    });
  }

  return (
    <div className="dropdown-item">
      <label htmlFor={name} className="label-medium">
        {label}
      </label>
      <span className="avatar avatar--w20 dropdown-item--value">
        <Select
          name={name}
          options={options}
          formatOptionLabel={formatOptionLabel}
          formatGroupLabel={formatGroupLabel}
          onChange={handleChange}
          onInputChange={handleInputChange}
          inputRenderer={inputRenderer}
          onBlurResetsInput={onBlurResetsInput}
          onCloseResetsInput={onCloseResetsInput}
          onSelectResetsInput={onSelectResetsInput}
          onBlur={onBlur}
          inputValue={inputValue}
          className="select-custom-class"
          value={options ? finalOptionValue : null}
          placeholder={isLoading ? 'Loading...' : placeholder}
          isLoading={isLoading}
          noResultsText={noResultsText}
          clearable={clearable}
          autoFocus={autofocus}
          disabled={disabled}
          styles={selectStyles}
          theme={selectTheme}
          isClearable={clearable}
        />
        {error && <span className="error_label d-inline">{error}</span>}
      </span>
    </div>
  );
};

export const DateField = ({
  startDate,
  labelClass,
  label,
  handleChange,
  minDate,
  maxDate,
  disabled,
  testid,
}) => (
  <div className="form-group form-inline-label align-items-stretch col-md-6 col-12">
    <label className={labelClass || ''} htmlFor="date-input">
      {label}
    </label>
    <div className="date-text date-calender pt-2 CustomFieldsDatePicker">
      <i
        className="icon-calendar vertical-middle text-dark"
        aria-hidden="true"
        data-testid={testid}
      />
      <DatePicker
        selected={moment(startDate).toDate()}
        popoverTargetAttachment="right center"
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        monthsShown={1}
        disabled={disabled}
        customInput={<DatePickerHTML />}
      />
    </div>
  </div>
);

export const RenderAttachments = (props) => {
  let documents = '';
  let m = [];
  if (props.newDocs) {
    m = props.data.newDocs;
  } else {
    m = props.data.documents;
  }
  if (m && m.length) {
    documents = m.map((doc) => {
      const logo = fileType(doc);
      const getSize = doc.file_size / 1024;
      const trimSize = getSize.toFixed(2);
      return (
        <div key={`para${doc.id}`} className="file-drop-upload">
          <div className="file-drop-type">
            <i className={logo} aria-hidden="true" />
          </div>
          <div className="file-drop-info">
            <div className="file-drop-detail">
              <h6 className="fileName" key={'document' + doc.id}>
                {(doc.is_active === undefined || doc.is_active === true) &&
                props.name !== 'Add to vault' ? (
                  <button
                    type="button"
                    className="fileName pr-0"
                    key={`doc${doc.id}`}
                    onClick={
                      props.data.popUp || !props.data.previewPopUp
                        ? () => props.openAttachment(doc)
                        : () => props.component.previewOpenAttachment(doc)
                    }
                    style={{ background: 'none', border: 'none' }}
                  >
                    {doc.doc_name}
                  </button>
                ) : (
                  <span className="text-dark pr-0">{doc.doc_name}</span>
                )}
              </h6>
            </div>
            {doc.is_active === undefined || doc.is_active === true ? (
              <div>
                {props.name === 'Add to vault' ? null : (
                  <button
                    type="button"
                    className="previewFile file-action"
                    key={`doc${doc.id}`}
                    onClick={
                      props.data.popUp || !props.data.previewPopUp
                        ? () => props.openAttachment(doc)
                        : () => props.component.previewOpenAttachment(doc)
                    }
                    style={{ background: 'none', border: 'none' }}
                  >
                    <i className="icon-open-eye" /> Preview
                  </button>
                )}
                {doc.file_size && (
                  <span className="file-action">{trimSize} KB</span>
                )}
              </div>
            ) : null}
            {doc.is_active === undefined || doc.is_active === true ? (
              <div>
                {props.name === 'taskDetail' || props.name === 'notesdetail' ? (
                  props.data.taskStatus !== 'Closed' &&
                  (!props.uploadPopUp ||
                    (isUserEmployee() && props.uploadPopUp)) &&
                  props.delAttachmentModal ? (
                    <button
                      type="button"
                      className="file-action"
                      key={`delete${doc.id}`}
                      onClick={() => props.delAttachmentModal(doc)}
                      style={{ background: 'none', border: 'none' }}
                    >
                      {!props.hideDeleteIcon && <i className="icon-close2" />}
                    </button>
                  ) : null
                ) : (props.name === 'task' && props.isEdit) ||
                  props.name === 'Add to vault' ? null : (
                  <button
                    type="button"
                    className="file-action"
                    key={`delete${doc.id}`}
                    onClick={() => props.showModal(doc.id, 'attachment')}
                    style={{ background: 'none', border: 'none' }}
                  >
                    {!props.hideDeleteIcon && <i className="icon-close2" />}
                  </button>
                )}
              </div>
            ) : (
              props.delAttachmentModal && (
                <span
                  className="file-action text-dark"
                  key={'doc' + doc.id}
                  href="javascript:void(0)"
                >
                  Deleted
                </span>
              )
            )}
          </div>
        </div>
      );
    });
  }
  return documents;
};

export const AttachementPortion = (props) => {
  const { folderSequence } = props.data;
  let progressbar;
  const apps = [];
  if (
    props.data.thirdPartyApps &&
    props.data.thirdPartyApps.length &&
    getCpaPreferences() &&
    getCpaPreferences().cloud_store &&
    getCpaPreferences().cloud_store.length
  ) {
    getCpaPreferences().cloud_store.map((each) => {
      props.data.thirdPartyApps.map((item) => {
        if (item.id === each.key && item.token_exits) {
          each.thumbnail = `${item.id}.png`;
          apps.push(each);
        }
        return null;
      });
      return null;
    });
  }
  if (
    !apps.length &&
    getCpaPreferences() &&
    getCpaPreferences().cloud_store &&
    getCpaPreferences().cloud_store.length
  ) {
    apps.push(getCpaPreferences().cloud_store[0]);
  }
  // const arrow = <i className="icon-rightarrow" />;
  const onedriveFolderHeader =
    folderSequence && folderSequence.length
      ? folderSequence.map((each, index) => {
          return (
            <span
              key={folderSequence.indexOf(each)}
              onClick={(e) => props.onedriveSharedFileHandle(each, 'folder')}
            >
              <span className="move-handle pr-1">
                {index === 0 ? each.name : ` > ${each.name}`}
              </span>
            </span>
          );
        })
      : null;
  const tabs = oneDriveModalHeaders;
  if (!(props.uploadFiles === undefined)) {
    progressbar = props.uploadFiles.map((item) => {
      const ext = item.split('.').pop();
      let logo = 'icon-filetype-pdf';
      if (
        ext === 'xls' ||
        ext === 'xlt' ||
        ext === 'xlm' ||
        ext === 'xlsx' ||
        ext === 'xlsm' ||
        ext === 'xltx' ||
        ext === 'xltm' ||
        ext === 'xlsb' ||
        ext === 'xla' ||
        ext === 'xlam' ||
        ext === 'xll' ||
        ext === 'xlw'
      ) {
        logo = 'icon-excel';
      } else if (
        ext === 'doc' ||
        ext === 'docx' ||
        ext === 'docm' ||
        ext === 'dotx' ||
        ext === 'dotm' ||
        ext === 'docb'
      ) {
        logo = 'icon-word';
      } else if (ext === 'zip') {
        logo = 'icon-zip';
      } else if (
        ext === 'ppt' ||
        ext === 'pot' ||
        ext === 'pps' ||
        ext === 'pptx' ||
        ext === 'pptm' ||
        ext === 'potx' ||
        ext === 'potm' ||
        ext === 'ppam' ||
        ext === 'ppsx' ||
        ext === 'ppsm' ||
        ext === 'sldx' ||
        ext === 'sldm'
      ) {
        logo = 'icon-powerpoint';
      } else if (
        ext === 'jpeg' ||
        ext === 'png' ||
        ext === 'gif' ||
        ext === 'jpg'
      ) {
        logo = 'icon-image-preview';
      } else if (ext === 'pdf') {
        logo = 'icon-filetype-pdf';
      } else {
        logo = 'icon-notes';
      }
      const returnValue = [];
      const percentCompleted = props.data['percentCompleted' + item]
        ? props.data['percentCompleted' + item]
        : 0;
      const getSize = props.data['fileSize' + item] / 1024;
      const trimSize = getSize.toFixed(2);
      const fileSize = props.data['fileSize' + item] ? trimSize + ' KB' : null;
      returnValue.push(
        <div key={item} className="file-drop-upload file-drop-inprogress">
          <div className="file-drop-type">
            <i className={logo} aria-hidden="true" />
          </div>
          <div className="file-drop-info">
            <div className="file-drop-detail">
              <span className="fileName">
                {item + ' (' + percentCompleted + '%)'}
              </span>
              <span className="fileweight">{fileSize}</span>
              <Line
                percent={percentCompleted}
                strokeWidth="4"
                strokeColor="#767676"
              />
            </div>
            {percentCompleted !== 100 ? (
              <a
                className="file-action"
                onClick={() => props.cancelUpload(item)}
                href="javascript:void(0)"
              >
                <i className="icon-close2" />
              </a>
            ) : null}
          </div>
        </div>,
      );
      return returnValue;
    });
  }
  return (
    <React.Fragment>
      {props.name === 'task' && props.isEdit ? null : (
        <FileDrop
          frame={window}
          onDrop={
            props.data.taskStatus !== 'Closed' ? props.handleFileDrop : null
          }
          className="dragBrowse"
          id="Attachments"
        >
          <div
            className={
              props.className
                ? 'DragDrop d-flex justify-content-center align-items-center ' +
                  props.className
                : 'DragDrop d-flex justify-content-center align-items-center'
            }
          >
            <div className="dropFile icon-upload" />
            <div className="dropFile pr-0">Drop files to attach</div>
            {props.openVault ? (
              <div className="dropFile pl-0 pr-0">
                <div className="col">
                  <span>, </span>
                  <a
                    className={`fileContainer text-capitalize ${
                      props.data.taskStatus === 'Closed'
                        ? `btn px-1 py-0 btn--onlyicon bg-transparent border-0 disabled`
                        : null
                    } `}
                    href="javascript:void(0);"
                    onMouseDown={() => props.openVault(true)}
                  >
                    <img
                      className="pr-1"
                      style={{ width: '20px' }}
                      src={liscioLogo}
                      alt="logo"
                    />{' '}
                    Liscio Vault
                  </a>
                </div>
              </div>
            ) : null}
            {props.data.googleDriveModal &&
            (props.name !== 'bulk' ||
              (props.name &&
                props.name === 'bulk' &&
                props.className &&
                props.className === 'DragDrop--mh100')) ? (
              <GooglePicker
                googleDrive={props.component.googleDrive}
                startUpload={props.component.uploadfile}
                googleDriveLoader={props.component.googleDriveLoader}
              />
            ) : null}
            {props.data.showSmartVaultWidget ? (
              <SmartVaultWidget
                token={props.component.smartVaultAccessToken()}
                startUpload={props.component.uploadfile}
                cancelWidget={props.component.toggleSmartVaultWidget}
              />
            ) : null}
            {props.data.openSharePoint ? (
              <SharePointPicker
                appKey={'sharepoint'}
                startUpload={props.component.uploadfile}
                cancelWidget={props.component.toggleSharePoint}
              />
            ) : null}
            {props.data.openBox ? (
              <BoxPicker
                startUpload={props.component.uploadfile}
                cancelWidget={props.component.toggleBox}
              />
            ) : null}
            {props.data.openOnedrive ? (
              <Modal
                isOpen
                className="customModal customModal--alert customModal--w990 customModal--nopadding"
              >
                <div className="ModalHeader">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => props.handleOnedriveFiles('close')}
                  >
                    <i className="icon-close2" aria-hidden="true" />
                  </button>
                </div>
                <ModalBody className="modalContent">
                  {props.data.loading && <div id="loading" />}
                  <div className=" odHeader p-0">
                    <HeaderTabs
                      data={tabs}
                      activeTab={props.data.onedriveFilesActiveTab}
                      handleClick={props.sharedFolder}
                    />
                  </div>
                  {props.data.sharedFilesList &&
                  props.data.sharedFilesList.length > 0 &&
                  props.data.onedriveFilesActiveTab === 1 &&
                  !props.data.noSharedFiles ? (
                    <div
                      className="center-wrapper"
                      style={{ maxHeight: '500px', overflowY: 'auto' }}
                    >
                      <div className="tableWrap">
                        <div className="tableHead">
                          <div className="tableFilter d-flex justify-content-end">
                            <span className="align-self-center mr-2" />
                            <div className="select-custom-class" />
                          </div>
                        </div>
                        <div className="d-flex pb-3">
                          {onedriveFolderHeader}
                        </div>
                        <div className="tRow tRow--head">
                          <div className="row">
                            <div
                              className="col-auto thCol pr-0 flex-grow-0"
                              style={{ width: '55px' }}
                            >
                              <div className="d-flex">
                                <span
                                  className="ml-auto"
                                  style={{ paddingRight: '6px' }}
                                >
                                  <i
                                    className="icon-Files"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </div>
                            {onedriveListHeaders.map((item) => (
                              <div key={item.value} className={item.className}>
                                <span className="posRel">{item.label}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="tRow tRow--body">
                          {props.data.sharedFilesList.map((item, key) => {
                            let commentTime = item
                              ? item.lastModifiedDateTime
                              : '';
                            const parsedTime = item ? item.createdDateTime : '';
                            if (
                              commentTime &&
                              commentTime.indexOf('ago') === -1
                            ) {
                              const zonedTime = moment.tz(parsedTime, timeZone);
                              commentTime =
                                zonedTime.format('MMM DD, YYYY') +
                                ' at ' +
                                zonedTime.format('hh:mm a');
                            }
                            const getSize = item.size ? item.size / 1024 : '';
                            const trimSize = getSize ? getSize.toFixed(2) : '';
                            return (
                              <div className="row" key={item.id}>
                                <div className="col-auto tdCol pr-0 pl-1 flex-grow-0">
                                  <div className="d-flex ml-auto">
                                    <div style={{ width: '32px' }}>
                                      {((item.folder &&
                                        item.folder !== undefined) ||
                                        (!item.folder &&
                                          item.folder === undefined)) &&
                                      !item.file ? null : (
                                        <div className="posRel checkbox checkbox-primary check-container pl-0 pr-3">
                                          <Label htmlFor={item.id}>
                                            <Input
                                              key={item.id}
                                              type="checkbox"
                                              id={item.id}
                                              checked={
                                                item.checked
                                                  ? item.checked
                                                  : false
                                              }
                                              className={`chkbx ${item.id}`}
                                              name="isChecked"
                                              onChange={() =>
                                                props.onedriveSharedFileHandle(
                                                  item,
                                                )
                                              }
                                            />
                                            <i className="checkmark" />
                                          </Label>
                                        </div>
                                      )}
                                    </div>
                                    <span
                                      onClick={() =>
                                        props.onedriveSharedFileHandle(item)
                                      }
                                    >
                                      <i
                                        className={
                                          !item.folder &&
                                          item.folder === undefined &&
                                          item.file
                                            ? fileType({
                                                content_type:
                                                  item.file.mimeType,
                                              })
                                            : 'icon-File'
                                        }
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="col-3 tdCol"
                                  onClick={() =>
                                    props.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <span>{item.name ? item.name : '-'}</span>
                                </div>
                                <div
                                  className="col-2 tdCol"
                                  onClick={() =>
                                    props.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <span>
                                    {item.shared
                                      ? item.shared.owner
                                        ? item.shared.owner.user
                                          ? item.shared.owner.user.displayName
                                            ? item.shared.owner.user.displayName
                                            : '-'
                                          : '-'
                                        : '-'
                                      : '-'}
                                  </span>
                                </div>
                                <div
                                  className="col-2 tdCol"
                                  onClick={() =>
                                    props.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <span>
                                    {item.lastModifiedBy
                                      ? item.lastModifiedBy.user
                                        ? item.lastModifiedBy.user.displayName
                                        : '-'
                                      : '-'}
                                  </span>
                                </div>
                                <div
                                  className="col-2 tdCol"
                                  onClick={() =>
                                    props.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <span>{commentTime || '-'}</span>
                                </div>
                                <div
                                  className="col-1 tdCol"
                                  onClick={() =>
                                    props.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <span>
                                    {trimSize ? trimSize + 'KB' : '-'}
                                  </span>
                                </div>
                                <div
                                  className="col tdCol"
                                  onClick={() =>
                                    props.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <span>
                                    {item.shared ? 'Sharing' : 'Private'}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <button
                          type="button"
                          onClick={props.handleOnedriveSelectedFiles}
                          className="btn btn-primary"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  ) : props.data.noSharedFiles ? (
                    <div id="content">
                      <div className="center-wrapper">
                        <PageNotFound
                          name="message"
                          message="There are no records to show you right now."
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="modalContent--inner"
                      style={{ maxHeight: '500px', overflowY: 'auto' }}
                    >
                      <GraphFileBrowser
                        getAuthenticationToken={props.getAuthenticationToken}
                        onSuccess={props.onSuccess}
                        onCancel={props.onCancel}
                        itemMode="files"
                      />
                    </div>
                  )}
                </ModalBody>
              </Modal>
            ) : null}
            <div className="dropFile pl-0 pr-0">
              <Attachment
                name={props.name}
                data={props.data}
                setAttachmentNull={() => props.setAttachmentNull()}
                uploadDocument={() => props.uploadDocument()}
                uploadPopUp={props.uploadPopUp}
                handleFileDrop={props.handleFileDrop}
                uploadFiles={props.uploadFiles}
                documents={props.documents}
                cancelUpload={props.cancelUpload}
                openAttachment={props.openAttachment}
                delAttachmentModal={props.delAttachmentModal}
                showModal={props.showModal}
                component={props.component}
                dataProps={props.dataProps}
                notedetail={props.notedetail}
                from={props.from ? props.from : ''}
              />
            </div>
            {!props.uploadPopUp && apps.length && isUserEmployee() ? (
              <div className="dropdown dropdown--small">
                <span className="text-dark p-1"> or </span>
                <Menu
                  menuItems={apps}
                  menuComponent={
                    <span className="btn btn-link btn--onlyicon dropdown-toggle font-weight-bold label-large p-0 cstLinks">
                      Document Management System (DMS)
                      <i className="icon-chevron-down2" aria-hidden="true" />
                    </span>
                  }
                  handleMenuItemClick={(e) => {
                    if (e.key === 'onedrive') {
                      props.handleOnedriveFiles('open', e);
                    } else if (e.key === 'googledrive') {
                      props.component.googleDrive();
                    } else if (e.key === 'sharepoint') {
                      props.component.toggleSharePoint();
                    } else if (e.key === 'box') {
                      props.component.toggleBox();
                    } else {
                      props.component.toggleSmartVaultWidget();
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        </FileDrop>
      )}
      {props.error ? <span className="error_label">{props.error}</span> : null}
      {!props.uploadPopUp ? progressbar : null}
      <div className="attached-file-list">
        <RenderAttachments
          name={props.name}
          isEdit={props.isEdit}
          data={props.data}
          openAttachment={props.openAttachment}
          uploadPopUp={props.uploadPopUp}
          newDocs={props.newDocs}
          showModal={props.showModal}
          delAttachmentModal={props.delAttachmentModal}
          component={props.component}
        />
      </div>
    </React.Fragment>
  );
};

export const TextAreaField = ({
  className,
  name,
  label,
  type,
  placeholder,
  onChange,
  onBlur,
  rows,
  value,
  disabled,
  error,
}) => (
  <div className={className || 'col'}>
    <label htmlFor={name} className="col-form-label">
      {label}
    </label>
    <textarea
      type={type || 'text'}
      className="form-control"
      id={name}
      placeholder={placeholder || ''}
      onChange={(input) => onChange(input.target.value, name)}
      onBlur={(input) => (onBlur ? onBlur(input.target.value, name) : null)}
      value={value || ''}
      disabled={disabled}
      rows={rows || 2}
    />
    <span className="error_label">{error}</span>
  </div>
);

export const CheckboxField = ({
  className,
  name,
  value,
  onChange,
  disabled,
  labelclassName,
  label,
  error,
  testid,
}) => (
  <div
    className={
      className
        ? `checkbox checkbox-primary check-container ${className}`
        : 'checkbox checkbox-primary check-container'
    }
  >
    <label htmlFor={name}>
      <input
        name={name}
        id={name}
        type="checkbox"
        value={value !== undefined && value !== null && value}
        checked={value !== undefined && value !== null && value}
        onChange={(input) => onChange(input, name)}
        disabled={disabled}
        data-testid={testid}
      />
      <i className="checkmark" />
      <span className={`pl-2 ${labelclassName || ''} `}>{label}</span>
    </label>
    <span
      className="error_label pl-2"
      style={{
        fontSize: '0.9rem',
      }}
    >
      {error}
    </span>
  </div>
);

// this is component is used to display data in account overview and contactoverview page
export class DisplayData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.propertyValues.sensitive ? 'password' : 'text',
    };
  }

  changeInputType = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  render() {
    const { data, propertyValues, isEmployee } = this.props;
    const { type } = this.state;
    let datatoDisplay =
      data[propertyValues.value] !== null
        ? propertyValues.formatValue
          ? propertyValues.formatValue(data[propertyValues.value])
          : data[propertyValues.value]
        : '-';
    if (datatoDisplay !== undefined && datatoDisplay.length === 0) {
      datatoDisplay = '-';
    }
    if (propertyValues.onlyForEmployee && !isEmployee) {
      return null;
    }
    return (
      <div
        className={
          propertyValues.customClassName
            ? propertyValues.customClassName
            : 'col-sm-12 col-md-6 col-lg-4'
        }
      >
        <div
          className={`form-group ${
            propertyValues.notInline ? '' : 'form-inline-label'
          }`}
        >
          {propertyValues.value === 'is_restricted_account' && isEmployee ? (
            <span className="text-body mr-3">Restricted Account</span>
          ) : propertyValues.value === 'is_restricted_account' &&
            !isEmployee ? null : (
            <label className="align-self-start mr-2">
              {propertyValues.label ? `${propertyValues.label}: ` : null}
            </label>
          )}
          <div
            className={
              propertyValues.sensitive
                ? 'inputGroup inputGroup--value'
                : 'labelValue'
            }
          >
            {type === 'password' &&
            datatoDisplay &&
            datatoDisplay.length &&
            datatoDisplay !== '-' ? (
              <span>{'*****************'.slice(0, datatoDisplay.length)}</span>
            ) : (
              <span className="wordBreak">
                {propertyValues.value === 'is_restricted_account' &&
                isEmployee ? (
                  <CheckboxField
                    id="restrictaccount"
                    name="restrictaccount"
                    value={datatoDisplay}
                    disabled
                  />
                ) : propertyValues.value === 'is_restricted_account' &&
                  !isEmployee ? null : (
                  datatoDisplay
                )}
              </span>
            )}
            {propertyValues.sensitive &&
            datatoDisplay &&
            datatoDisplay.length &&
            datatoDisplay !== '-' ? (
              <span className="inputHelpIcon" onClick={this.changeInputType}>
                <i
                  className={
                    type === 'password' ? 'icon-close-eye' : 'icon-open-eye'
                  }
                  aria-hidden="true"
                />
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export class HideSensitiveInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'password',
    };
  }

  changeInputType = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  render() {
    const { type } = this.state;
    const { data: datatoDisplay } = this.props;
    return (
      <div className="inputGroup inputGroup--value">
        {type === 'password' &&
        datatoDisplay &&
        datatoDisplay.length &&
        datatoDisplay !== '-' ? (
          <span>{'*****************'.slice(0, datatoDisplay.length)}</span>
        ) : (
          <span>{datatoDisplay}</span>
        )}
        <span className="inputHelpIcon" onClick={this.changeInputType}>
          <i
            className={type === 'password' ? 'icon-close-eye' : 'icon-open-eye'}
            aria-hidden="true"
          />
        </span>
      </div>
    );
  }
}

export const InputFieldToTrickAutoFill = ({
  className,
  label,
  name,
  error,
  passwordShow,
  showHide,
  type,
  placeholder,
  onChange,
  onBlur,
  value,
  disabled,
  autoComplete,
}) => (
  <div className={className ? className + (error ? ' form-error' : '') : 'col'}>
    <div>
      <label htmlFor="random">{label}</label>
      <div className={passwordShow ? 'labelValue inputGroup' : 'labelValue'}>
        <input type="text" className="hidden" id="random" />
      </div>
    </div>
    <div>
      <label htmlFor={name} className="hidden">
        Hello
      </label>
      <div className={passwordShow ? 'labelValue inputGroup' : 'labelValue'}>
        <input
          type={type || 'text'}
          className="form-control"
          id={name}
          placeholder={placeholder || ''}
          onChange={(input) => onChange(input.target.value, name)}
          onBlur={(input) => (onBlur ? onBlur(input.target.value, name) : '')}
          value={value || ''}
          disabled={disabled}
          autoComplete={autoComplete || 'off'}
        />
        {passwordShow ? (
          <span className="inputHelpIcon" onClick={showHide}>
            <i
              className={
                passwordShow === 'password' ? 'icon-close-eye' : 'icon-open-eye'
              }
              aria-hidden="true"
            />
          </span>
        ) : (
          ''
        )}
        <span className="error_label">{error}</span>
      </div>
    </div>
  </div>
);

export const RenderAttachmentsInProgress = (props) => {
  const { uploadFiles, data } = props;
  const progressbar = uploadFiles.map((item) => {
    const ext = item.split('.').pop();
    let logo = 'icon-filetype-pdf';
    if (
      ext === 'xls' ||
      ext === 'xlt' ||
      ext === 'xlm' ||
      ext === 'xlsx' ||
      ext === 'xlsm' ||
      ext === 'xltx' ||
      ext === 'xltm' ||
      ext === 'xlsb' ||
      ext === 'xla' ||
      ext === 'xlam' ||
      ext === 'xll' ||
      ext === 'xlw'
    ) {
      logo = 'icon-excel';
    } else if (
      ext === 'doc' ||
      ext === 'docx' ||
      ext === 'docm' ||
      ext === 'dotx' ||
      ext === 'dotm' ||
      ext === 'docb'
    ) {
      logo = 'icon-word';
    } else if (ext === 'zip') {
      logo = 'icon-zip';
    } else if (
      ext === 'ppt' ||
      ext === 'pot' ||
      ext === 'pps' ||
      ext === 'pptx' ||
      ext === 'pptm' ||
      ext === 'potx' ||
      ext === 'potm' ||
      ext === 'ppam' ||
      ext === 'ppsx' ||
      ext === 'ppsm' ||
      ext === 'sldx' ||
      ext === 'sldm'
    ) {
      logo = 'icon-powerpoint';
    } else if (
      ext === 'jpeg' ||
      ext === 'png' ||
      ext === 'gif' ||
      ext === 'jpg'
    ) {
      logo = 'icon-image-preview';
    } else if (ext === 'pdf') {
      logo = 'icon-filetype-pdf';
    } else {
      logo = 'icon-notes';
    }
    const returnValue = [];
    const percentCompleted = data[`percentCompleted${item}`]
      ? data[`percentCompleted${item}`]
      : 0;
    const getSize = data[`fileSize${item}`] / 1024;
    const trimSize = getSize.toFixed(2);
    const fileSize = data[`fileSize${item}`] ? `${trimSize} KB` : null;
    returnValue.push(
      <div key={item} className="file-drop-upload file-drop-inprogress">
        <div className="file-drop-type">
          <i className={logo} aria-hidden="true" />
        </div>
        <div className="file-drop-info">
          <div className="file-drop-detail">
            <span className="fileName">{`${item} (${percentCompleted}%)`}</span>
            <span className="fileweight">{fileSize}</span>
            <Line
              percent={percentCompleted}
              strokeWidth="4"
              strokeColor="#767676"
            />
          </div>
          {percentCompleted !== 100 ? (
            <a className="file-action" onClick={() => props.cancelUpload(item)}>
              <i className="icon-close2" />
            </a>
          ) : null}
        </div>
      </div>,
    );
    return returnValue;
  });
  return progressbar;
};

export const SelectTemplateFieldOption = createReactClass({
  render() {
    const contnt = this.props.data.content.replace(/&nbsp;/g, ' ');
    const data = truncateText(contnt, 107);
    return (
      <components.Option {...this.props}>
        <div className={this.props.getClassNames()}>
          <span>{this.props.children}</span>
          <span className="emCt">
            <Interweave allowAttributes content={data} />
          </span>
        </div>
      </components.Option>
    );
  },
});
