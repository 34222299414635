/* eslint-disable global-require */
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import { FormAlert } from 'components/Login/LoginV5/components/FormAlert/FormAlert';
import { ResetPasswordFormInterface } from 'components/Login/LoginV5/LoginV5ResetPassword';

export const ResetPasswordForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
  } = useFormContext<ResetPasswordFormInterface>();

  const passwordError = errors.password?.message;
  const confirmPasswordError = errors.password_confirmation?.message;

  const onCancel = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <>
      <h1>Password Reset</h1>
      <FormAlert />
      <div>
        <div className="form-group">
          <div className={`form-group ${passwordError && 'form-error'}`}>
            <div className="labelValue inputGroup">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Password"
                className="form-control"
                autoComplete="off"
                data-testid="password"
                {...register('password')}
              />
              <span
                onClick={() => {
                  setIsPasswordVisible((currentState) => !currentState);
                }}
                className="inputHelpIcon"
              >
                <i
                  className={
                    isPasswordVisible ? 'icon-close-eye' : 'icon-open-eye'
                  }
                  aria-hidden="true"
                />
              </span>
            </div>
            {!passwordError && (
              <span className="text-dark label-submedium" id="confirm_password">
                Password must contain at least 12 characters, including 1 upper
                case letter, 1 lower case letter, 1 numeric, and 1 special
                character.
              </span>
            )}
            <div className="text-danger error_label" id="password">
              {passwordError}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className={`form-group ${confirmPasswordError && 'form-error'}`}>
            <div className="labelValue inputGroup">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Confirm Password"
                className="form-control"
                autoComplete="off"
                data-testid="password"
                {...register('password_confirmation')}
              />
              <span
                onClick={() => {
                  setIsConfirmPasswordVisible((currentState) => !currentState);
                }}
                className="inputHelpIcon"
              >
                <i
                  className={
                    isConfirmPasswordVisible
                      ? 'icon-close-eye'
                      : 'icon-open-eye'
                  }
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="text-danger error_label" id="password">
              {confirmPasswordError}
            </div>
          </div>
        </div>
        <div className="form-group">
          <button
            type="submit"
            id="loginSubmit"
            className="btn btn-primary w-100 mb-3"
            data-testid="sign_in"
          >
            Submit
          </button>
          <button
            type="button"
            className="btn send-magic-link btn-outline-light w-100"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
        <div className="clearfix" />
      </div>
    </>
  );
};

export default ResetPasswordForm;
