import { ChevronLeft, Close, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  ButtonProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';

interface HeaderItem {
  label: string;
  tooltip?: string;
  'data-print-id'?: string;
}

export interface HeaderMenuItem extends HeaderItem {
  icon: React.ReactNode;
  props?: MenuItemProps;
}

interface HeaderButtonItem extends HeaderItem {
  props?: ButtonProps;
}
interface HeaderProps {
  children?: React.ReactNode;
  title?: string;
  menuItems?: Array<HeaderMenuItem>;
  buttonItems?: Array<HeaderButtonItem>;
  onClose?: () => void;
  onBack?: () => void;
}

export function Header({
  buttonItems,
  children,
  title,
  menuItems,
  onClose,
  onBack,
}: HeaderProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleOptionsClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleOptionsClosed = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Box
      component="header"
      display="flex"
      alignItems="center"
      padding="20px 20px"
      minHeight="72px"
      borderTop={`1px solid ${theme.palette.grey[200]}`}
    >
      {onBack && (
        <ButtonBase onClick={onBack} sx={{ marginRight: '32px' }}>
          <Typography variant="body2" textAlign="center" fontWeight="700">
            <ChevronLeft fontSize="inherit" />
            BACK
          </Typography>
        </ButtonBase>
      )}
      {title && (
        <Typography variant="h5" component="h1" fontWeight="700" mr={4}>
          {title}
        </Typography>
      )}
      {children}
      <Box marginLeft="auto">
        {buttonItems?.map(({ label, props, tooltip }) =>
          tooltip ? (
            <TooltipStyled key={label} title={tooltip}>
              <Box>
                <Button key={label} variant="contained" {...props}>
                  {label}
                </Button>
              </Box>
            </TooltipStyled>
          ) : (
            <Button variant="contained" key={label} {...props}>
              {label}
            </Button>
          ),
        )}
      </Box>
      {menuItems && menuItems.length > 0 && (
        <>
          <IconButton
            onClick={handleOptionsClicked}
            sx={{ padding: 0 }}
            aria-label="Editor options"
          >
            <MoreVert />
          </IconButton>
          <Menu open={open} anchorEl={anchorEl} onClose={handleOptionsClosed}>
            {menuItems.map(({ icon, label, props, tooltip }) =>
              tooltip ? (
                <TooltipStyled title={tooltip}>
                  <Box>
                    <MenuItem
                      key={label}
                      {...props}
                      onClick={(event) => {
                        if (props?.onClick) props.onClick(event);
                        handleOptionsClosed();
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{label}</ListItemText>
                    </MenuItem>
                  </Box>
                </TooltipStyled>
              ) : (
                <MenuItem
                  key={label}
                  {...props}
                  onClick={(event) => {
                    if (props?.onClick) props.onClick(event);
                    handleOptionsClosed();
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              ),
            )}
          </Menu>
        </>
      )}
      {onClose && (
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
}
