import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-latest';

import { useSignInWithMagicLink } from 'hooks/login-v5-hooks';
import useEffectOnce from 'hooks/useEffectOnce';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenticatedRedirect } from 'components/AuthenticatedRedirect/AuthenticatedRedirect';
import { DEFAULT_ERROR_MESSAGE } from 'constants/constants';
import { Box } from '@mui/material';
import { CpaSelector, LoginPageLayout, MFAForm, FormAlert } from './components';
import { INVALID_MFA_ERROR_TYPE } from './constants';

import { formSchema } from './helpers/magicLinkFormValidationSchema';

export type ProcessState = 'initialVerification' | 'cpaSelector' | 'mfaCode';

export interface LoginMagicLinkFormInterface {
  two_factor_code: string;
}

export const LoginV5MagicLink = () => {
  const { id: token } = useParams<{ id: string }>();
  const [processState, setProcessState] = useState<ProcessState>(
    'initialVerification',
  );

  const formCtx = useForm<LoginMagicLinkFormInterface>({
    resolver: yupResolver(formSchema),
    context: { processState },
  });
  const { handleSubmit, setError, getValues } = formCtx;

  const {
    mutateAsync: signInWithMagicLink,
    data: signInResponse,
    isLoading,
  } = useSignInWithMagicLink({
    onSuccess: () => {
      setProcessState('cpaSelector');
    },
    onError: (error) => {
      const statusCode = error?.response?.status;
      const message = error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;

      const { two_factor_code } = getValues();
      // We need to establish a better contract with the backend :/
      if (message === INVALID_MFA_ERROR_TYPE && !two_factor_code) {
        setProcessState('mfaCode');
      } else {
        setError('root.serverError', {
          type: String(statusCode),
          message,
        });
      }
    },
  });

  useEffectOnce(() => {
    if (token) {
      signInWithMagicLink({ token });
    }
  });

  const onSubmit: SubmitHandler<LoginMagicLinkFormInterface> = useCallback(
    (data) => {
      if (token) {
        signInWithMagicLink({ token, twoFactorCode: data.two_factor_code });
      }
    },
    [signInWithMagicLink, token],
  );

  return (
    <AuthenticatedRedirect>
      <FormProvider {...formCtx}>
        <div id="page-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading && <div id="loading" />}
            <LoginPageLayout>
              <>
                {processState === 'initialVerification' && (
                  <Box mt={4}>
                    <FormAlert />
                  </Box>
                )}
                {processState === 'mfaCode' && <MFAForm />}
                {processState === 'cpaSelector' && signInResponse && (
                  <CpaSelector
                    temporaryAuthToken={signInResponse?.auth_token}
                    availableCpas={signInResponse?.cpa_data}
                    loginType="magic_link"
                  />
                )}
              </>
            </LoginPageLayout>
          </form>
        </div>
      </FormProvider>
    </AuthenticatedRedirect>
  );
};

export default LoginV5MagicLink;
