import { useMutation } from '@tanstack/react-query';
import {
  signIn,
  signInSSO,
  cpaSwitcher,
  generateMagicLink,
  signInWithMagicLink,
  CpaSelectorResponse,
  generateResetPasswordLink,
  resetPassword,
  ResetPasswordResponse,
  changePassword,
} from 'api/login-v5';
import { AxiosError } from 'axios';

export function useSignIn(props?: { onSuccess?: () => void }) {
  return useMutation({
    mutationFn: signIn,
    onSuccess: () => {
      if (props?.onSuccess) {
        props.onSuccess();
      }
    },
    onError: (err: AxiosError) => err,
  });
}

export function useSignInSSO(props?: { onSuccess?: () => void }) {
  return useMutation({
    mutationFn: signInSSO,
    onSuccess: () => {
      if (props?.onSuccess) {
        props.onSuccess();
      }
    },
  });
}

export function useCpaSwitcher(props?: {
  onSuccess?: (mutationResult: CpaSelectorResponse) => void;
}) {
  return useMutation({
    mutationFn: cpaSwitcher,
    onSuccess: (mutationResult) => {
      if (props?.onSuccess) {
        props.onSuccess(mutationResult);
      }
    },
  });
}

export function useGenerateMagicLink(props?: { onSuccess?: () => void }) {
  return useMutation({
    mutationFn: generateMagicLink,
    onSuccess: () => {
      if (props?.onSuccess) {
        props.onSuccess();
      }
    },
  });
}

export function useSignInWithMagicLink(props?: {
  onSuccess?: () => void;
  onError?: (error?: AxiosError) => void;
}) {
  return useMutation({
    mutationFn: signInWithMagicLink,
    onSuccess: () => {
      if (props?.onSuccess) {
        props.onSuccess();
      }
    },
    onError: (error) => {
      if (props?.onError) {
        props.onError(error as AxiosError);
      }
    },
  });
}

export function useGenerateResetPasswordLink(props?: {
  onSuccess?: () => void;
}) {
  return useMutation({
    mutationFn: generateResetPasswordLink,
    onSuccess: () => {
      if (props?.onSuccess) {
        props.onSuccess();
      }
    },
  });
}

export function useResetPassword(props?: {
  onSuccess?: (mutationResult?: ResetPasswordResponse) => void;
  onError?: (error?: AxiosError) => void;
}) {
  return useMutation({
    mutationFn: resetPassword,
    onSuccess: (mutationResult) => {
      if (props?.onSuccess) {
        props.onSuccess(mutationResult);
      }
    },
    onError: (error) => {
      if (props?.onError) {
        props.onError(error as AxiosError);
      }
    },
  });
}

export const useChangePassword = () =>
  useMutation({
    mutationFn: changePassword,
  });
