/* eslint-disable */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import Select, { components } from 'react-select';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { EditorState, convertFromRaw } from 'draft-js';
import ConfirmationModal from 'common/ConfirmationModal';
import { NUMBER } from 'utilities/regularexpressions';
import { getCpaPreferences, AlertMessage, getMLData } from 'utilities/utils';
import * as TaskApi from 'api/TaskApi';
import {
  AttachementPortion,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import UploadFiles from 'api/UploadFiles';
import { autoTagApi } from 'api/AutoTag';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import {
  OAUTH_ACCESS_TOKENS,
  THIRD_PARTY_APPS,
  GET_TEMPLATES_FOR_CREATION,
  CREATE_DOC_FROM_TEMPLATE_IDS,
} from 'constants/constants';
import { generateUniqueHash, removeBreakElement } from 'common/NotesUtilities';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import LoadingOverlay from 'common/LoadingOverlay';

const isIE = /* @cc_on!@ */ false || !!document.documentMode;
let ML_APP_DATA = {};
let accessToken = '';
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class MessageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      taskTitle: '',
      comment: '',
      uploadFiles: [],
      documents: [],
      newDocs: [],
      attachmentId: undefined,
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      isDocumentUpload: false,
      showAttachmentModal: false,
      showModal: false,
      isFileModalSubmit: false,
      popUp: false,
      // previewPopUp: true,
      // previewScreen: false,
      // previewDocument: null,
      yearId: '',
      yearValue: '',
      selectedTags: [],
      selectedDocuments: [],
      monthId: '',
      monthValue: '',
      type: 'bulk',
      openOnedrive: false,
      thirdPartyApps: [],
      loading: false,
      accessToken: '',
      sharedFilesList: [],
      folderSequence: [],
      onedriveFilesActiveTab: 0,
      noSharedFiles: false,
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      showSmartVaultWidget: false,
      // imageUrls: [],
      // noOfImages: 0,
      disableDraftBotton: false,
    };
    this.templateDropdown = this.templateDropdown.bind(this);
    this.updateAutoTagData = this.updateAutoTagData.bind(this);
    this.autoTagsUpdated = false;
    this.selectInputRef = React.createRef();
  }

  componentDidMount() {
    // this.setState({
    //   loading: true,
    // });
    ML_APP_DATA = getMLData();
    const request = Axios.get(THIRD_PARTY_APPS);
    request
      .then((response) => {
        if (response.data.success === 200) {
          this.setState({
            thirdPartyApps: response.data.data.length ? response.data.data : [],
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log('err', err);
        this.setState({ loading: false });
      });
    this.getTemplates();
  }

  onSuccess = (keys) => {
    const selectedRecords = keys.map((selectedItem) => {
      const item = selectedItem.driveItem_203[2];
      const head = { headers: { Authorization: `Bearer ${accessToken}` } };
      Axios.get(
        `https://graph.microsoft.com/v1.0/me/drive/items/${item}`,
        head,
      ).then((res) => {
        const selectedfile = [];
        selectedfile.push(res.data);
        this.startUpload(selectedfile, 'onedrive');
      });
      return selectedItem;
    });
    this.handleOnedriveFiles('close');
  };

  onCancel = () => {
    this.handleOnedriveFiles('close');
  };

  onedriveSharedFileHandle = (item, itemType = '') => {
    console.log('item', item, itemType);
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    if (
      ((item.folder && item.folder !== undefined) ||
        (!item.folder && item.folder === undefined)) &&
      !item.file
    ) {
      this.setState({ loading: true });
      if (item.id === 'shared') {
        this.sharedFolder(1);
      } else {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}/children?expand=thumbnails`,
          head,
        ).then((response) => {
          if (response.status === 200) {
            let sequence = this.state.folderSequence;
            const index = sequence.findIndex((x) => x.active === true);
            if (index > -1) {
              sequence[index].active = false;
              this.setState({ folderSequence: sequence });
            }
            if (itemType === 'folder') {
              const indx = sequence.findIndex((x) => x.id === item.id);
              if (indx > -1) {
                sequence[indx].active = true;
                sequence = sequence.slice(0, indx + 1);
                this.setState({ folderSequence: sequence });
              }
            } else {
              const seq = {
                name: item.name,
                id: item.id,
                parentReference: { driveId: item.parentReference.driveId },
                active: true,
                folder: true,
              };
              sequence.push(seq);
            }
            this.setState({
              sharedFilesList: response.data.value,
              folderSequence: sequence,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        });
      }
    } else {
      const checkboxState = this.state.sharedFilesList;
      const index = checkboxState.findIndex((x) => x.id === item.id);
      if (index > -1) {
        checkboxState[index].checked = !checkboxState[index].checked;
        this.setState({ sharedFilesList: checkboxState }, () => {
          // console.log('pali', this.state.sharedFilesList);
        });
      }
    }
  };

  setAttachmentNull() {
    const uploadFile = document.getElementById('upload_doc');
    uploadFile.value = null;
  }

  getAuthenticationToken = () => accessToken;

  getTemplates() {
    this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=message_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({
                documents: response.data.documents,
                templateDocuments: [],
              });
              // let x;
              // for (x = 0; x < documents.length; x += 1) {
              //   const documnt = { ...documents[x] };
              //   documnt.id = response.data.doc_ids[x];
              //   documents[x] = documnt;
              //   this.setState({ documents });
              //   if (x + 1 === documents.length) {
              //     this.setState({ templateDocuments: [] });
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch((errors) => {
          this.setState({ loading: false });
        });
    }
  };

  async updateAutoTagData() {
    try {
      const token = await autoTagApi('login', ML_APP_DATA);
      if (
        !token ||
        (typeof token === 'object' && Object.keys(token).length === 0)
      ) {
        const response = await autoTagApi('login', ML_APP_DATA);
      }
      const filesResponse = await Promise.all(
        this.state.documents.map((doc) =>
          autoTagApi('results', { file: doc.doc_name }, true),
        ),
      );
      if (
        filesResponse &&
        filesResponse[0] &&
        filesResponse[0].status &&
        filesResponse[0].status === 401
      ) {
        return this.updateAutoTagData();
      }
      const autoTagData = filesResponse.reduce(
        (acc, cv) => ({
          tags: acc.tags.concat(
            cv.Suggested_tags ? cv.Suggested_tags[0][0] : [],
          ),
          // months: acc.months.concat(cv.month || []),
          // years: acc.years.concat(cv.year+"" || []),
          yearsAndMonths: acc.yearsAndMonths.concat(
            [
              {
                month: cv.month || '',
                year: `${cv.year}` || '',
              },
            ] || [],
          ),
        }),
        { yearsAndMonths: [], tags: [] },
      );
      const selectedTags = getCpaPreferences().upload_tags.filter((tag) =>
        // return autoTagData.tags.find(autoTag=>autoTag.match(new RegExp(tag.label,'gi')))
        autoTagData.tags.find(
          (autoTag) => autoTag.toLowerCase() === tag.label.toLowerCase(),
        ),
      );
      // console.log({selectedTags,pref:this.props.preferences})
      const filteredYearsAndMonths = autoTagData.yearsAndMonths.map((YMO) => ({
        year: getCpaPreferences().upload_file_year.find(
          (item) => item.label === YMO.year,
        ),
        month: getCpaPreferences().upload_file_month.find(
          (item) => item.label === YMO.month,
        ),
      }));
      // this.setState({ yearId: parseInt(filteredYearsAndMonths[0].year.key, 10), yearValue: filteredYearsAndMonths[0].year.label });
      // this.setState({ monthId: filteredYearsAndMonths[0].month.key, monthValue: filteredYearsAndMonths[0].month.label });
      // this.setState({ selectedTags });
    } catch (error) {
      console.error(error);
      // if(error.message.match(/axios is not defined/gi)) this.updateAutoTagData();
    }
  }

  handleOnedriveSelectedFiles = () => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    const checkboxState = this.state.sharedFilesList;
    checkboxState.map((item) => {
      if (item.checked && item.checked !== undefined) {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}`,
          head,
        ).then((res) => {
          const selectedfile = [];
          selectedfile.push(res.data);
          this.startUpload(selectedfile, 'onedrive');
        });
      }
      this.handleOnedriveFiles('close');
      return null;
    });
  };

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open') {
      const request = Axios.get(`${OAUTH_ACCESS_TOKENS}/${item.key}`);

      request
        .then((response) => {
          if (response.status === 200) {
            accessToken = response.data.access_token;

            this.setState({
              openOnedrive: !this.state.openOnedrive,
              accessToken
            });
          }
          else {
            AlertMessage(
              'error',
              `Please set up ${item.value} to use the cloud store.`,
              3000,
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (toggle === 'close') {
      this.setState({
        openOnedrive: !this.state.openOnedrive,
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
      });
    }
  };

  smartVaultAccessToken = () => {
    const { access_token = null } =
      this.state.thirdPartyApps.find(
        (thirdPartyApp) => thirdPartyApp.id === 'smartvault',
      ) || {};
    return access_token;
  };

  toggleSmartVaultWidget = () => {
    this.setState((ps) => ({ showSmartVaultWidget: !ps.showSmartVaultWidget }));
  };

  attachmentModalClose() {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  }

  inputRenderer = (inputProps) => (
    <components.Input
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { taskTitle, comment, documents, warningMsgLost } = this.state;
    let commentExist = true;
    if (!comment) {
      commentExist = false;
    } else if (typeof comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        commentExist = false;
      }
    }
    if (
      (taskTitle || commentExist || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !taskTitle))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      const coment = removeBreakElement(content);
      this.setState({
        selectedOption,
        taskTitle: value,
        titleChange: '',
        comment: coment,
        uniqueId: generateUniqueHash(5),
        warningMsgLost: false,
        selectedTemplate: null,
        templateDocuments:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
        documents:
          selectedOption.documents && selectedOption.documents.length
            ? selectedOption.documents
            : [],
      });
    } else {
      this.setState(
        {
          selectedOption: null,
          taskTitle: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
        },
      );
    }
  }

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState({ taskTitle: val, titleChange: val });
    }
  };

  handleChange = (e) => {
    this.setState({ errors: {}, taskTitle: e.target.value, isChanged: true });
  };

  handleCommentChange = (value) => {
    // this.setState({ comment: value });
    this.setState({ comment: value, errors: {} });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    }
  };

  handleFileDrop = (files, e) => {
    if (e) {
      const uploadFile = files;
      this.startUpload(uploadFile);
      this.setState({ popUp: true });
      return (
        <AttachementPortion
          name="bulk"
          handleFileDrop={this.handleFileDrop}
          data={this.state}
          setAttachmentNull={this.setAttachmentNull}
          uploadDocument={this.uploadDocument}
          uploadPopUp
          uploadFiles={this.state.uploadFiles}
          documents={this.state.documents}
          cancelUpload={this.cancelUpload}
          openAttachment={this.openAttachment}
          showModal={this.showModal}
          component={this}
          sharedFolder={this.sharedFolder}
          onedriveSharedFileHandle={this.onedriveSharedFileHandle}
          handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
          getAuthenticationToken={this.getAuthenticationToken}
          onSuccess={this.onSuccess}
          onCancel={this.onCancel}
        />
      );
    }
  };

  uploadDocument = () => {
    const uploadFile = document.getElementById('upload_doc').files;
    this.startUpload(uploadFile);
  };

  cancelUpload = (filename) => {
    this.state[`${filename}sourceToken`].cancel();
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleDeleteAttachment = () => {
    TaskApi.deleteTaskAttachment(this.state.attachmentId, this);
    this.setState({ showModal: false });
  };

  googleDrive = (stat = '') => {
    if (stat === 'false') {
      this.setState({ googleDriveModal: false });
    } else {
      this.setState((prevState) => ({
        googleDriveModal: !prevState.googleDriveModal,
      }));
    }
  };

  googleDriveLoader = (stat = '') => {
    if (stat === 'false') {
      this.setState({ loading: false });
    } else if (stat === 'true') {
      this.setState({ loading: true });
    } else {
      this.setState((prevState) => ({ loading: !prevState.loading }));
    }
  };

  uploadfile = (uploadFile, fro = '') => {
    this.startUpload(uploadFile, fro);
  };

  startUpload(uploadFile, fro = '') {
    let i;
    const component = this;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      getCpaPreferences() &&
      getCpaPreferences().attachment &&
      getCpaPreferences().attachment[0].label &&
      NUMBER.test(getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes = getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    for (i = 0; i < uploadFile.length; i += 1) {
      const data = new FormData();
      if (fro === 'onedrive' || fro === 'sharepoint') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i]['@microsoft.graph.downloadUrl']);
      } else if (fro === 'google_drive' || fro === 'smart_vault') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i].file);
      } else {
        data.append('aws_url', uploadFile[i], uploadFile[i].name);
      }
      if (uploadFile[i].size > maxsizeInBytes) {
        alert(`File exceeds limit of ${maxsizeInMb}MB`);
        return false;
      }
      const { CancelToken } = Axios;
      const source = CancelToken.source();
      const key = `${uploadFile[i].name}sourceToken`;
      this.setState({ [key]: source });
      this.state.uploadFiles.push(uploadFile[i].name);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(uploadFile[i].name),
      };
      TaskApi.addDocument(
        data,
        component,
        config,
        uploadFile[i].name,
        'bulkMessage',
        fro,
      );
      if (component.state.errors.documents) {
        const err = {};
        err.documents = '';
        component.setState({ errors: err });
      }
    }
    return true;
  }

  showModal = (nid, type) => {
    if (type === 'attachment') {
      this.setState({ attachmentId: nid, showModal: true });
    }
  };

  showSelectedData = () => {
    if (this.isFormValid()) {
      const { documents, taskTitle, comment } = this.state;
      const documentsIds = [];
      documents.map((each) => {
        documentsIds.push(each.id);
        return each;
      });
      const data = {
        subject: taskTitle,
        doc_ids: documentsIds,
        comment,
      };
      this.props.showSelectedData(true, data);
      // alert('next');
    }
  };

  isFormValid() {
    const { taskTitle, comment } = this.state;
    const err = {};
    let formIsValid = true;
    if (taskTitle === '' || !taskTitle) {
      formIsValid = false;
      err.taskTitle_error = 'Enter task title';
    }
    if (!comment) {
      formIsValid = false;
      err.comment_error = 'Enter Description';
    } else if (typeof comment === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(comment))
          .getCurrentContent()
          .getPlainText().length
      ) {
        formIsValid = false;
        err.comment_error = 'Enter Description';
      }
    }
    this.setState({ errors: err });
    return formIsValid;
  }

  openAttachment = (c) => {
    UploadFiles.showOpenAttachment(c, this);
  };

  errorDoc = () => {
    const err = {};
    err.documents = 'Please select document';
    this.setState({ errors: err });
  };

  openPopUp = () => {
    this.setState({ popUp: !this.state.popUp });
  };

  cancelAllUploads() {
    const component = this;
    if (component.state.uploadFiles.length > 0) {
      const tmpHash = {};
      tmpHash.uploadFiles = [];
      const { uploadFiles } = component.state;
      uploadFiles.map((file) => {
        component.state[`${file}sourceToken`].cancel();
        return null;
      });
      component.setState(tmpHash);
    }
    if (component.state.newDocs.length) {
      const docIds = [];
      let x;
      let count = 0;
      for (x = 0; x < component.state.newDocs.length; x += 1) {
        const { id } = component.state.newDocs[x];
        const indx = component.state.documents.findIndex((y) => y.id === id);
        if (indx !== -1) {
          docIds.push(component.state.documents[x].id);
          count += 1;
          if (count === component.state.newDocs.length) {
            component.setState({ newDocs: [] });
          }
        }
      }
      const data = { doc_ids: docIds };
      data.doc_ids.map((item) => {
        const index = component.state.documents.findIndex((y) => y.id === item);
        const uploadedFiles = component.state.documents;
        uploadedFiles.splice(index, 1);
        component.setState({ documents: uploadedFiles });
        return null;
      });
      TaskApi.cancelDocuments(component, data, 'tasks');
    }
  }

  stateUpdate = (value, changetype = '', data = '') => {
    const stateUpdatedValues = { ...value };
    this.setState({ stateUpdatedValues });
    if (changetype === 'isFileModalTaskDetail' && data) {
      this.setState({
        newDocs: [],
        errors: {},
        yearId: value.yearId,
        yearValue: value.yearValue,
        monthId: value.monthId,
        monthValue: value.monthValue,
        selectedTags: data.tags,
        selectedDocuments: data.doc_ids,
        isFileModalSubmit: true,
      });
    }
    if (changetype === 'isFileModalSubmit' && data) {
      this.setState({
        yearId: value.yearId,
        yearValue: value.yearValue,
        monthId: value.monthId,
        monthValue: value.monthValue,
        selectedTags: data.tags,
        selectedDocuments: data.doc_ids,
        isFileModalSubmit: true,
      });
    }
  };

  sharedFolder = (key) => {
    if (key === 0) {
      this.setState({
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
      });
    } else if (key === 1) {
      const head = {
        headers: { Authorization: `Bearer ${this.state.accessToken}` },
      };
      this.setState({ loading: true, sharedFilesList: [], folderSequence: [] });
      Axios.get(
        'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe',
        head,
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.value.length === 0) {
            this.setState({ loading: false, noSharedFiles: true });
          } else {
            const seq = this.state.folderSequence;
            seq.push({
              name: 'Shared',
              id: 'shared',
              parentReference: { driveId: 'shared' },
              active: true,
              folder: true,
            });
            this.setState({ folderSequence: seq });
            response.data.value.map((item) => {
              const data = item.remoteItem;
              const sharedFiles = this.state.sharedFilesList;
              sharedFiles.push(data);
              this.setState({
                sharedFilesList: sharedFiles,
                onedriveFilesActiveTab: 1,
              });
              return null;
            });
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }
      });
    }
  };

  render() {
    const {
      errors,
      taskTitle,
      uploadFiles,
      documents,
      showModal,
      selectedOption,
      templateOptions,
      selectedTemplate,
    } = this.state;

    return (
      <div
        id="content"
        data-testid="messageSection"
        className={this.props.showThis ? '' : 'hidden'}
      >
        {this.state.loading ? <LoadingOverlay /> : null}
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto with-disabled-inputs">
          <div className="row">
            <div
              className={`form-group col-12${
                errors.taskTitle_error
                  ? ' form-error cstmClr msgCustmClr'
                  : ' cstmClr msgCustmClr'
              }`}
            >
              <label htmlFor="taskTitle">Message Title</label>
              <div className="labelValue customEditor boxResize flWdth">
                {selectedOption && selectedOption !== null ? (
                  <input
                    autoComplete="off"
                    name="taskTitle"
                    className="form-control"
                    id="taskTitle"
                    type="text"
                    value={taskTitle}
                    maxLength="200"
                    onChange={this.handleChange}
                  />
                ) : (
                  <Select
                    id="template-dropdown"
                    name="taskTitle"
                    placeholder=""
                    ref={this.selectInputRef}
                    className="select-custom-class slctTemp"
                    value={selectedOption}
                    onChange={this.templateDropdown}
                    options={templateOptions}
                    inputValue={this.state.titleChange}
                    // optionComponent={SelectTemplateFieldOption}
                    onInputChange={this.templateChange}
                    // inputRenderer={input => this.inputRenderer(input)}
                    tabSelectsValue={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onSelectResetsInput={this.state.titleChange} // false shows
                    noResultsText=""
                    styles={selectStyles}
                    theme={selectTheme}
                    classNamePrefix="custom-react-select"
                    components={{
                      Option: SelectTemplateFieldOption,
                      Input: this.inputRenderer,
                    }}
                  />
                )}
              </div>
              {taskTitle ? (
                <Button
                  className="btn btn-sm btn-primary"
                  data-testid="clearBtn"
                  onClick={() => this.templateDropdown(null)}
                >
                  Clear
                </Button>
              ) : null}
              <span className="error_label">{errors.taskTitle_error}</span>
            </div>
          </div>
          <div className="row">
            <div
              className={
                errors.comment_error
                  ? 'form-group col-lg-12 form-error'
                  : 'form-group col-lg-12'
              }
            >
              <div className="has-float-label customEditor boxResize">
                <label htmlFor="taskDescription">Description</label>
                <TinymceEditor
                  initialValue=""
                  value={this.state.comment}
                  handleChange={this.handleCommentChange}
                />
                <span className="error_label">{errors.comment_error}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group dragWrap col-lg-12">
              <label htmlFor="attachments">Attachments</label>
              <AttachementPortion
                name="bulk"
                handleFileDrop={this.handleFileDrop}
                data={this.state}
                setAttachmentNull={this.setAttachmentNull}
                uploadDocument={this.uploadDocument}
                uploadFiles={uploadFiles}
                documents={documents}
                cancelUpload={this.cancelUpload}
                openAttachment={this.openAttachment}
                showModal={this.showModal}
                uploadPopUp
                component={this}
                sharedFolder={this.sharedFolder}
                onedriveSharedFileHandle={this.onedriveSharedFileHandle}
                handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
                getAuthenticationToken={this.getAuthenticationToken}
                onSuccess={this.onSuccess}
                onCancel={this.onCancel}
              />
            </div>
          </div>
          <div className="formbtn justify-content-end">
            <div className="btn-wrap btn--leftspace">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => this.props.showDocumentSectionFn(false)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.showSelectedData}
                disabled={this.state.disableDraftBotton}
              >
                Draft Message
              </button>
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={showModal}
          headerText="You are about to delete the attached file."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={this.hideModal}
          yesBtnClick={this.handleDeleteAttachment}
          data-testid="yesBtn"
          yesBtnText="Yes"
          noBtnText="No"
        />
        <ConfirmationModal
          isOpen={this.state.warningMsgLost}
          className="customModal customModal--w500 customModal--nopadding"
          messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
          noBtnClick={() => this.setState({ warningMsgLost: false })}
          yesBtnClick={() => this.templateDropdown(selectedTemplate)}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <Modal
          isOpen={this.state.showAttachmentModal}
          className="customModal customModal--w990 customModal--nopadding"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={() => this.attachmentModalClose()}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <div
                className={
                  isIE ? 'left-inner-content' : 'sample-letter-preview'
                }
              >
                <div className="letter-logo" />
                {isIE ? (
                  <PdfViewer file={this.state.selectedAttachment} />
                ) : (
                  <iframe
                    width="100%"
                    height="600"
                    src={this.state.selectedAttachment}
                    title="selected attachment"
                  />
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default MessageSection;
