import { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import Axios from 'axios';
import {
  AlertMessage
} from 'utilities/utils';
import { TRUSTED_DOMAINS } from 'constants/constants';

type ModalProps = {
  closeModal: () => void
  domainData: {
    id: string
    domain: string
    created_at: string
  }[]
}

export default function AddTrustedDomainModal ({ closeModal, domainData }: ModalProps) {
  const [newDomain, setnewDomain] = useState('');

  const saveDomain = () => {
    const request = Axios.post(TRUSTED_DOMAINS, { domain: newDomain })
    request
      .then(response => {
        domainData.push(response.data.data)
        AlertMessage('success', response, 3000);
        closeModal()
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
      })
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setnewDomain(e.target.value)
  };

  return (
    <Modal
      isOpen
      className='customModal customModal--center customModal--alert customModal--w500 customModal--nopadding'
    >
      <div className="ModalHeader">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeModal}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody className="modalContent">
        <div className="modalContent--inner">
          <div className="modalContent--header text-large">
            <h5 className="modalContent--header__heading mb-3">
              Add Domain
            </h5>
          </div>
          <div>
            <input
              onChange={handleInput}
              type="text"
              placeholder='my-company.com'
              className="form-control"
            />
          </div>
          <div className="row">
            <div className="col-12 justify-content-end formbtn justify-content-end">
              <div className="btn-wrap btn--leftspace">
                <Button className="btn btn-outline-light" onClick={closeModal}>
                  Cancel
                </Button>
                <Button className="btn btn-primary" onClick={saveDomain}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
