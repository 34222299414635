import { useCallback, useRef } from 'react';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';

interface workflowItemHookProps {
  onSubmit: (newValue: string) => void;
}

export const useWorkflowItemInput = ({ onSubmit }: workflowItemHookProps) => {
  const { newItemInEdit, setNewItemInEdit, setDirty } =
    useRequestBuilderContext();
  const ref = useRef<HTMLInputElement>(null);

  const submitInput = useCallback(
    (prompt: string) => {
      const trimmedPrompt = prompt.trim();
      if (!trimmedPrompt) {
        return;
      }
      if (newItemInEdit) {
        setNewItemInEdit({
          ...newItemInEdit,
          prompt: trimmedPrompt,
        });
      }
      onSubmit(trimmedPrompt);
    },
    [newItemInEdit, onSubmit, setNewItemInEdit],
  );

  const handleOnBlur = () => {
    submitInput(currentValue());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setDirty(true);
    if (event.key === 'Enter') {
      submitInput(currentValue());
    }
  };

  const currentValue = () => ref.current?.value || '';

  return {
    ref,
    onBlur: handleOnBlur,
    onKeyDown: handleKeyDown,
  };
};
