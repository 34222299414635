import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import Axios from 'axios';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import TruncateMarkup from 'react-truncate-markup';
import moment from 'moment-timezone';
import JSZip from 'jszip';
import mime from 'mime';
import FileSaver from 'file-saver';
import FilePopup from 'components/Files/FilePopup';
import { AlertMessage, formatText } from 'utilities/utils';
import {
  LINK_EMAILS,
  USER_EMAILS,
  ZIP_ATTACHMENT_DATA,
} from 'constants/constants';
// import ComposeEmailFromThread from './ComposeEmailFromThread';
import useWhichSection from 'components/Email/components/useWhichSection';
import {
  /* getInitialState, */ download,
  formatEmail,
} from 'components/Emails/EmailUtilities';
import LiscioLogo from 'images/liscio_logo.png';
import EmailActionsDropdown from './EmailActionsDropdown';
import DisplayEmailAttachments from './DisplayEmailAttachments';

const Message = ({
  data,
  connectedApp,
  // connectedAppAccessToken,
  refreshPage,
  composeEmailAction,
  // composeEmailFor,
  composeEmailData,
  expandMail,
  refreshForVault,
  showUnreadOption,
  unreadEmail,
}) => {
  const email = data;
  const [content, setContent] = useState(
    DOMPurify.sanitize(data.content, { WHOLE_DOCUMENT: true }),
  );
  const [documents, setDocuments] = useState(data.documents);
  const [showMore, setShowMore] = useState(expandMail);
  const [showModal, setShowModal] = useState(false);
  const [addToVault, setAddToVault] = useState();
  const [fetchAttachments, setFetchAttachments] = useState(false);
  const [showToRecipients, setShowToRecipients] = useState(false);
  const [showCcRecipients, setShowCcRecipients] = useState(false);
  const [showBccRecipients, setShowBccRecipients] = useState(false);
  const { inLinkedSection } = useWhichSection();

  useEffect(() => {
    const frame = document.getElementById(`iframe${data.src_email_id}`);
    // Adjusting the iframe height onload event
    if (frame) {
      frame.onload = () => {
        frame.contentWindow.document.body.style.fontFamily =
          'Source Sans Pro, sans-serif';
        frame.contentWindow.document.body.style.fontSize = '15px';
        // to open links in new tab
        const links = frame.contentWindow.document.querySelectorAll('a');
        links.forEach((link) => {
          link.setAttribute('target', '_blank');
        });
        const images = frame.contentWindow.document.querySelectorAll('img');
        images.forEach((image) => {
          image.setAttribute('style', 'max-width: 100%');
        });
        // set the height of the iframe as
        // the height of the iframe content
        frame.style.height = `${
          frame.contentWindow.document.body.scrollHeight + 35
        }px`;
        // set the width of the iframe as the
        // width of the iframe content
        // frame.style.width =
        //   frame.contentWindow.document.body.scrollWidth + 'px';
      };
    }
  }, [showMore]);

  useEffect(() => {
    if (
      (composeEmailData && composeEmailData.email_id) ||
      (data.draft_data && data.draft_data.draft_id !== null) ||
      (data && data.draft_id !== null && data.draft_id !== undefined)
    ) {
      const myElement = document.getElementById(data.email_id);
      if (myElement && myElement !== null) {
        setTimeout(() => {
          myElement.scrollIntoView();
        }, 100);
      }
    }
  }, [composeEmailData === data]);

  const getInlineImages = async () => {
    try {
      let emailBody = content;
      const response = await Axios.get(
        `${inLinkedSection ? LINK_EMAILS : USER_EMAILS}/${
          data.email_id
        }/attachments?content_disposition=inline`,
      ).then((res) => res.data.data);
      response.forEach((doc) => {
        if (doc.content_type.includes('image') && doc.attachment_body) {
          emailBody = emailBody.replace(
            `cid:${doc.content_id.replace('<', '').replace('>', '')}`,
            `data:${doc.content_type};base64,${doc.attachment_body}`,
          );
        }
      });
      setContent(emailBody);
    } catch (error) {
      console.log(error);
    }
  };

  // we get inline images only when email is expended
  useEffect(() => {
    if (
      email.documents &&
      email.documents.length &&
      !fetchAttachments &&
      showMore
    ) {
      getInlineImages();
      setFetchAttachments(true);
    }
  }, [showMore]);

  const downloadAttachments = async (docs) => {
    const params = {
      id: docs.map((file) => file.id),
      email_id: docs[0].email_id,
    };
    try {
      const files = await Axios.post(ZIP_ATTACHMENT_DATA, params).then(
        (res) => res.data.data,
      );
      if (files && files.length) {
        const zip = new JSZip();
        let count = 0;
        const file_names = {};
        files.map((item) => {
          const binry = item.attachment_body;
          let fileName = item.file_name;
          const temp_doc_name = fileName.split('.');
          if (file_names[item.file_name]) {
            temp_doc_name[0] = `${temp_doc_name[0]}(${
              file_names[item.file_name]
            })`;
            file_names[item.file_name] += 1;
          } else {
            file_names[item.file_name] = 1;
          }
          if (temp_doc_name.length === 1 && item.content_type) {
            temp_doc_name[1] = mime.extension(item.content_type);
          }
          fileName = temp_doc_name.join('.');
          // eslint-disable-next-line no-unused-vars
          const convertTime = moment()
            .add(moment().utcOffset(), 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
          zip.file(fileName, binry, {
            base64: true,
            createFolders: false,
            date: new Date(),
          });
          count += 1;
          if (count === files.length) {
            zip.generateAsync({ type: 'blob' }).then((con) => {
              FileSaver.saveAs(con, 'all_docs.zip');
            });
          }
          return true;
        });
      }
    } catch (error) {
      AlertMessage('error', 'Error', 'Something went wrong');
    }
  };

  const handleCheckbox = (item, action = '') => {
    const newArr = documents.map((each) => {
      if (action === 'add' && each.id === item.id) {
        return { ...each, isChecked: !each.isChecked };
      }
      if (action === 'uncheck_all') {
        return { ...each, isChecked: false };
      }
      return { ...each };
    });
    setDocuments([...newArr]);
  };

  const openModal = (doc = [], downloadocs = []) => {
    if (doc && doc.length) {
      setAddToVault({ email_id: data.email_id, documents: [...doc] });
      setShowModal(true);
    }
    if (doc.length !== downloadocs.length) {
      AlertMessage(
        'error',
        'Some files are not attaching that are already in vault.',
        2000,
      );
    }
  };

  const closeModal = (act = '') => {
    setAddToVault();
    handleCheckbox({}, 'uncheck_all');
    setShowModal(!showModal);
    if (act === 'addedToVault') {
      refreshForVault();
    }
  };

  const showRecipients = (showRecipientsFor = '') => {
    if (showRecipientsFor === 'To') {
      setShowToRecipients(true);
    } else if (showRecipientsFor === 'Cc') {
      setShowCcRecipients(true);
    } else if (showRecipientsFor === 'Bcc') {
      setShowBccRecipients(true);
    }
  };

  const recipients = (dataFor, labelFor, recipientOf) => {
    if (showMore && dataFor && dataFor !== null) {
      return (
        <span className="d-block my-1">
          <span>{labelFor}: &nbsp;</span>
          {formatEmail(dataFor).length > 1 && !recipientOf ? (
            <span className="d-inline">
              {formatEmail(dataFor)[0]}
              ...
              <span
                className="text-body font-weight-bold pl-2"
                onClick={(e) => {
                  e.stopPropagation();
                  showRecipients(labelFor);
                }}
              >
                +{formatEmail(dataFor).length - 1} other
              </span>
            </span>
          ) : (
            <span className="d-inline">
              {dataFor.replace(/[\\[\]']+/g, '')}
            </span>
          )}
        </span>
      );
    }
    return null;
  };

  const buttons = (fro = '', attachment = {}, url = '') => {
    let docsForVault = [];
    let docsForDownload = [];
    if (fro === 'forlist') {
      docsForVault = documents.filter(
        (e) => e.isChecked === true && !e.document_id,
      );
      docsForDownload = documents.filter((e) => e.isChecked === true);
    } else if (fro === 'forSingleAttachment') {
      if (!attachment.document_id) {
        docsForVault.push(attachment);
      }
      docsForDownload.push(attachment);
    }
    if (!url) {
      return (
        <ButtonGroup className="btn-group-transparent">
          <Button
            className="btn-sm btn-transparent btn--onlyicont h-auto py-1 text-secondary"
            onClick={() =>
              docsForDownload && docsForDownload.length
                ? openModal(docsForVault, docsForDownload)
                : AlertMessage('error', 'Select file for upload', 2000)
            }
            data-tip
            data-for="download_icon"
          >
            <img
              className="mr-2"
              src={LiscioLogo}
              alt="Liscio"
              width="20"
              style={{ verticalAlign: 'middle' }}
            />
            Add to vault
          </Button>
          <Button
            className="btn-sm btn-transparent btn--onlyicon h-auto py-1"
            onClick={() =>
              docsForDownload && docsForDownload.length
                ? downloadAttachments(docsForDownload)
                : AlertMessage('error', 'Select file for download', 2000)
            }
            data-tip
            data-for="download_icon"
          >
            <i className="icon-download2" />
          </Button>
        </ButtonGroup>
      );
    }
    return (
      <div className="btn-wrap btn--leftspace">
        <Button
          className="btn btn-outline-light"
          onClick={() => openModal(docsForVault, docsForDownload)}
        >
          Add to vault
        </Button>
        <Button
          className="btn-link btn--onlyicon btn btn-secondary"
          onClick={() =>
            docsForDownload && docsForDownload.length
              ? download(attachment, url)
              : null
          }
          data-tip
          data-for="download_icon"
        >
          <i className="icon-download2" aria-hidden="true" />
        </Button>
      </div>
    );
  };

  const initials = data.from_email
    ? (data.from_email.split('@')[0]
        ? data.from_email.split('@')[0].charAt(0)
        : '') +
      (data.from_email.split('@')[1]
        ? data.from_email.split('@')[1].charAt(0)
        : '')
    : '';

  return (
    <div className="em_right_blo">
      <div className="em_right_block p-4">
        <div className="d-flex">
          <div
            onClick={() => setShowMore(!showMore)}
            className="avatar avatar--w40 avatar--border avatar--borderPrimary"
          >
            {data.avatar ? (
              <img src={data.avatar} alt="" className="rounded-circle" />
            ) : (
              <span className="uName">{initials}</span>
            )}
          </div>
          <div className="messageDetails flex-grow-1">
            <div className="row w-100 no-gutters">
              <div onClick={() => setShowMore(!showMore)} className="col-sm-9">
                <div className="messageBlock">
                  {data.inbound_status === 'draft' ? (
                    <div>
                      <h6 className="font-size font-weight-bold mb-0">
                        <span className="error_label">
                          <strong>[Draft]</strong>
                        </span>
                        {data.from_email}
                      </h6>
                      <p className="messageDesc noAnchor error_label mb-1">
                        {`This message hasn't been sent.`}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h6 className="font-size font-weight-bold">
                        {data.from_email}
                      </h6>
                      {recipients(data.to_email, 'To', showToRecipients)}
                      {recipients(data.cc_email, 'Cc', showCcRecipients)}
                      {recipients(data.bcc_email, 'Bcc', showBccRecipients)}
                      {!showMore ? (
                        <p className="messageDesc noAnchor mb-1">
                          <TruncateMarkup lines={1} ellipsis="...">
                            <span>{formatText(data.snippet)}</span>
                          </TruncateMarkup>
                        </p>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-3 pr-0 text-right pt-0">
                <div className="text-right">
                  {data.draft_id !== null &&
                  data.draft_id !== undefined ? null : (
                    <span className="pl-2 text-primary">
                      <EmailActionsDropdown
                        IconClass="text-primary"
                        emailDetails={Object.assign(data, {
                          contentImg: content,
                        })}
                        composeEmailAction={composeEmailAction}
                        connectedApp={connectedApp || ''}
                        refreshPage={refreshPage || null}
                        refreshForVault={refreshForVault}
                        viewContextFrom="eachThread"
                        showUnreadOption={showUnreadOption}
                        unreadEmail={unreadEmail}
                      />
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="messageAction">
                    {data.documents && data.documents.length ? (
                      <i
                        aria-hidden="true"
                        className="icon-paper-clicp text-dark btn py-0 pl-0 pr-2 cursor-default"
                      />
                    ) : null}
                    <span className="text-dark messageDate subHeadline">
                      {dayjs(data.email_date).format('MM/DD/YY, h:mm a')}
                    </span>
                  </div>
                </div>
              </div>
              {showMore ? (
                <div className="col-12">
                  <div className="messageExpand flex-column bg-white">
                    <div
                      className="message__wrap px-0 py-1 bg-white"
                      style={{ display: data.content ? 'block' : 'none' }}
                    >
                      <div
                        className="messageDesc"
                        style={{ marginLeft: '-8px', marginRight: '8px' }}
                      >
                        {data.content && data.content !== 'null' ? (
                          <iframe
                            id={`iframe${data.src_email_id}`}
                            srcDoc={content}
                            title="email"
                            scrolling="yes"
                            style={{
                              width: '100%',
                              border: 'none',
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    {documents &&
                    documents.length &&
                    documents.filter(
                      (e) =>
                        e.content_disposition !== null &&
                        !e.content_disposition.includes('inline'),
                    ).length ? (
                      <div className="docsInfo">
                        <div className="dragWrap">
                          <div className="d-flex align-items-center justify-content-between">
                            <label
                              htmlFor="attachments"
                              className="text-dark mb-0"
                            >
                              Attachments:
                            </label>
                            <div>
                              <span className="align-self-center text-dark mr-3">
                                Selected (
                                {
                                  documents.filter((e) => e.isChecked === true)
                                    .length
                                }
                                )
                              </span>
                              {buttons('forlist')}
                            </div>
                          </div>
                          <div className="attached-file-list">
                            {documents
                              .filter(
                                (e) =>
                                  e.content_disposition !== null &&
                                  !e.content_disposition.includes('inline'),
                              )
                              .map((e) => (
                                <DisplayEmailAttachments
                                  btnCstClass="w-100 m-0"
                                  attachment={e}
                                  buttons={buttons}
                                  handleCheckbox={handleCheckbox}
                                  emailId={data.email_id}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* {(composeEmailFor && composeEmailData === data) ||
      (data.draft_data && data.draft_data.draft_id !== null) ||
      (data && data.draft_id !== null && data.draft_id !== undefined) ? (
        <div className="em_right_block px-4 pt-4 pb-2 mt-4">
          <ComposeEmailFromThread
            viewContext={
              composeEmailFor ||
              (data.draft_data && data.draft_data.draft_id !== null
                ? 'draft'
                : '') ||
              (data && data.draft_id !== null && data.draft_id !== undefined
                ? 'draft'
                : '')
            }
            viewContextFrom="thread"
            msgClass="m-0 p-0 border-0"
            msgInrClass="px-0 mb-0"
            emailDetails={data}
            initialState={getInitialState(
              data.draft_data || {
                ...data,
                date: `${dayjs(data.email_date).format(
                  'ddd, MMM D, YYYY',
                )} at ${dayjs(data.email_date).format('h:mm a')}`,
              },
              composeEmailFor,
            )}
            composeEmailAction={composeEmailAction}
            connectedApp={connectedApp || ''}
            connectedAppAccessToken={connectedAppAccessToken}
          />
        </div>
      ) : null} */}
      {showModal ? (
        <FilePopup
          addToVault="Add to vault"
          addToVaultFileDetails={addToVault}
          closeModal={closeModal}
        />
      ) : null}
    </div>
  );
};

export default React.memo(Message);
