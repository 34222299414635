// The id of the condition for a parent question
// requestItems[x].responseItem.workflowItem.id

import { ConditionalHashMap } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';
import {
  WorkflowItemFragment,
  WorkflowRequestItemFragment,
  WorkflowRequestQueryQuery,
} from 'gql/graphql';

// The child question's associated condition id
// requestItems[y].responseItem.workflowItem.conditionalParentId
// workflow.sections[x].items[y].conditionalParentId

// The current value of the parent question that a condition is based on
// requestItems[x].responseItem.value.booleanValue

export const createConditionalHashMap = (
  workflowRequest: WorkflowRequestQueryQuery['workflowRequest'],
): ConditionalHashMap => {
  const conditions: ConditionalHashMap = {};

  // Create a hashmap of conditions with the parent id as the key
  workflowRequest?.workflow?.sections?.forEach((section, sidx) => {
    section?.items?.forEach((item, idx) => {
      item?.conditions?.forEach((condition) => {
        const requestItem =
          workflowRequest?.requestSections?.[sidx]?.requestItems?.[idx];
        if (!requestItem || !item.conditionalParentId) {
          return;
        }

        conditions[item.conditionalParentId] = {
          ...condition,
          currentValue: null,
          requestItems: !conditions[item.conditionalParentId]
            ? [requestItem]
            : [
                ...(conditions[item.conditionalParentId].requestItems || []),
                requestItem,
              ],
        };
      });
    });
  });

  // Update the hashmap with the current value of the parent id from the requestItems
  // only works for boolean values ATM
  workflowRequest?.requestSections?.forEach((sections) => {
    sections.requestItems?.forEach((item) => {
      const id = item?.responseItem?.workflowItem?.id;
      if (conditions[id]) {
        conditions[id].currentValue = (
          item?.responseItem?.value as any
        )?.booleanValue;
      }
    });
  });

  return conditions;
};

export const getConditionalQuestions = (
  workflowItemId: string,
  conditionalHashMap: ConditionalHashMap,
): WorkflowRequestItemFragment[] =>
  conditionalHashMap[workflowItemId]?.requestItems || [];

// enum ConditionType {
//   field_set = 'field_set',
//   file_uploaded = 'file_uploaded',
//   equals = 'equals',
//   not_equals = 'not_equals',
//   greater_than = 'greater_than',
//   less_than = 'less_than',
//   in_range = 'in_range',
// }

export const matchValues = (
  conditionType: any,
  matchValue: any,
  currentValue: any,
) => {
  if (conditionType === 'equals') {
    if (matchValue === 'true') {
      return currentValue === true;
    }

    if (matchValue === 'false') {
      return currentValue === false;
    }
  }
  return false;
};

export const isConditionalQuestion = (workflowItem: WorkflowItemFragment) => {
  if (workflowItem?.conditions?.length) {
    return true;
  }
  return false;
};

export const showConditionalQuestion = (
  conditionalHashMap: ConditionalHashMap,
  workflowItem: WorkflowItemFragment,
) => {
  const parentId = workflowItem?.conditionalParentId;

  const parentCondition =
    conditionalHashMap && conditionalHashMap[parentId as string];

  const showQuestion =
    parentCondition &&
    matchValues(
      parentCondition?.conditionType,
      parentCondition?.matchValue,
      parentCondition?.currentValue,
    );

  return showQuestion;
};
