import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ThemeProvider,
  Typography,
  Dialog,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Stack,
  IconButton,
  TableContainer,
  styled,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { PaymentEstimateObject } from 'api/tax-delivery';
import { MinusIcon, PlusIcon } from 'common/Icons';
import {
  useDeletePaymentEstimates as useDeletePaymentEstimate,
  useGetPaymentEstimates,
} from 'hooks/payment-hooks';
import { SyntheticEvent, useState } from 'react';
import liscioLightTheme from 'theme/liscioLight';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Add, Delete, Edit } from '@mui/icons-material';
import { numericFormatter } from 'react-number-format';
import { parseISO, format } from 'date-fns';
import TaxDetailsDialog from './components/TaxDetailsDialog/TaxDetailsDialog';

interface Props {
  accountId: string;
  isExpanded: (panel: string) => boolean;
  handleChange: (
    panel: string,
  ) => (event: SyntheticEvent, expanded: boolean) => void;
}

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  [`& .${tableRowClasses.root}`]: {},
  [`& .${tableCellClasses.root}`]: {
    [`&.${tableCellClasses.head}`]: {
      ...theme.typography.body2,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontWeight: 400,
      ...theme.typography.body1,
      letterSpacing: 'inherit',
      textTransform: 'none',
      p: {
        maxWidth: '15rem',
      },
    },
  },
}));

export default function TaxDetailsAccordion({
  isExpanded,
  handleChange,
  accountId,
}: Props) {
  const { taxDetails } = useFlags();
  const {
    data: dataArr,
    refetch,
    isInitialLoading,
  } = useGetPaymentEstimates(accountId);
  const { mutate: deletePaymentMutation } = useDeletePaymentEstimate();
  const [DialogOpen, setDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<PaymentEstimateObject>();

  const handleAdd = () => {
    setSelectedData(undefined);
    setDialogOpen(true);
  };

  const handleSuccess = () => {
    refetch();
    setDialogOpen(false);
  };

  const handleDelete = (id?: string) => {
    if (!id) {
      return;
    }
    deletePaymentMutation(id, {
      onSettled: () => {
        refetch();
      },
    });
  };

  if (!taxDetails) {
    return null;
  }

  if (isInitialLoading) {
    return <Typography>Loading...</Typography>;
  }

  const formatCurrency = (value?: number | null) =>
    value
      ? numericFormatter(`$${value}`, {
        thousandSeparator: true,
        valueIsNumericString: true,
        decimalScale: 2,
      })
      : null;

  const formatDueDate = (date?: string | null) => {
    if (date) {
      const isoDate = parseISO(date);
      return format(isoDate, 'MM/dd/yyyy');
    }

    return null;
  };

  return (
    <Accordion
      disableGutters
      square
      elevation={0}
      expanded={isExpanded('tax_details')}
      onChange={handleChange('tax_details')}
    >
      <AccordionSummary
        id="tax_details-header"
        expandIcon={isExpanded('tax_details') ? <MinusIcon /> : <PlusIcon />}
        aria-controls="tax_details-content"
      >
        <Typography fontWeight="bolder" variant="h6">
          Tax Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ThemeProvider theme={liscioLightTheme}>
          <Stack>
            <Box marginLeft="auto">
              <Button
                startIcon={<Add />}
                onClick={handleAdd}
                variant="contained"
              >
                Tax Jurisdication
              </Button>
            </Box>
            <TableContainerStyled>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Jurisdiction</TableCell>
                    <TableCell>Q1 Date</TableCell>
                    <TableCell>Q1 $</TableCell>
                    <TableCell>Q2 Date</TableCell>
                    <TableCell>Q2 $</TableCell>
                    <TableCell>Q3 Date</TableCell>
                    <TableCell>Q3 $</TableCell>
                    <TableCell>Q4 Date</TableCell>
                    <TableCell>Q4 $</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataArr?.map((data) => (
                    <TableRow key={data.uuid}>
                      <TableCell>
                        <Typography>{data.tax_year}</Typography>
                      </TableCell>
                      <TableCell>{data.jurisdiction}</TableCell>
                      <TableCell>{formatDueDate(data.q1_due_date)}</TableCell>
                      <TableCell>
                        {formatCurrency(data.q1_payment_estimate)}
                      </TableCell>
                      <TableCell>{formatDueDate(data.q2_due_date)}</TableCell>
                      <TableCell>
                        {formatCurrency(data.q2_payment_estimate)}
                      </TableCell>
                      <TableCell>{formatDueDate(data.q3_due_date)}</TableCell>
                      <TableCell>
                        {formatCurrency(data.q3_payment_estimate)}
                      </TableCell>
                      <TableCell>{formatDueDate(data.q4_due_date)}</TableCell>
                      <TableCell>
                        {formatCurrency(data.q4_payment_estimate)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          type="button"
                          onClick={() => {
                            setSelectedData(data);
                            setDialogOpen(true);
                          }}
                          aria-label={`Edit ${data.tax_year} ${data.jurisdiction}`}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          type="button"
                          onClick={() => handleDelete(data.uuid)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainerStyled>
          </Stack>
          <Dialog open={DialogOpen} maxWidth="lg">
            <Box>
              <TaxDetailsDialog
                accountId={accountId}
                estimate={selectedData}
                onSuccess={handleSuccess}
                onCancel={() => setDialogOpen(false)}
              />
            </Box>
          </Dialog>
        </ThemeProvider>
      </AccordionDetails>
    </Accordion>
  );
}
