import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Axios from 'axios';
import { Line } from 'rc-progress';
import DocumentShow from 'components/UploadedFiles/DocumentShow';
import { fileType, getStorageData } from 'utilities/utils';
import {
  USER_EMAILS,
  EMAIL_ATTACHMENTS,
  REPLACE_DOCUMENT_URL,
} from 'constants/constants';
import ImagePreview from 'common/ImagePreview';
import styled from '@emotion/styled';
import Checkbox from 'common/Checkbox';
import {
  emailFileSize,
  emailGetSize,
  emailTrimSize,
} from 'components/Email/EmailUtilities';
import { useLinkedEmailsUrl } from 'hooks/use-linked-emails-url';
import useWhichSection from './useWhichSection';

const StyedWrap = styled.div`
  background-color: #f9fafb;
  padding: 0 10px 0 0;
  margin-top: 10px;
  .EmailsAttachments__File {
    padding: 5px 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border: 0;
      height: auto;
      background-color: transparent;
    }
    .EmailsAttachments__PreviewFile {
      padding: 0px 1.5rem;
      font-size: 15px;
      color: #212b36;
      .icon-open-eye {
        padding-right: 10px;
        color: #919eab;
        font-size: 0.7rem;
      }
      &:hover {
        color: #00aecc !important;
        .icon-open-eye {
          color: #00aecc !important;
        }
      }

      &:nth-last-child(1) {
        padding: 0 0 0 8px;
      }
    }
    .EmailsAttachments__Detail {
      font-weight: 400;
      font-size: 15px;
      color: #212b36;
      flex-grow: 1;

      i {
        margin: 0 14px 0 6px;
        vertical-align: middle;
        color: #919eab;
      }
    }

    .EmailsAttachments__PreviewClose {
      display: flex;
      align-items: center;
      .icon-close2 {
        font-size: 10px;
        color: #919eab;
        font-weight: bold;
      }
      .EmailsAttachments__FileSize {
        color: #919eab;
        font-weight: 400;
      }
    }
    .EmailsAttachments__Checkbox {
      align-items: center;
      display: flex;
      .check-container {
        span {
          padding: 10px 9px 9px;
        }
      }
      .icon-circle-checkmark {
        color: #20d195;
        margin: 0 0.5rem;
      }
    }
  }

  .FileAttached {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    &__Type {
      padding-left: 0.5rem;
    }
    &__Info {
      padding-left: 1rem;
      flex-grow: 1;
    }
  }
`;

const DisplayEmailAttachments = ({
  attachment,
  emailId,
  deleteAttachment,
  handleCheckbox,
  buttons,
  state,
}) => {
  const linkedEmailsUrl = useLinkedEmailsUrl();

  const [showPreview, setShowPreview] = useState(false);
  const [attachBody, setAttachBody] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachmentBody, setAttachmentBody] = useState(
    attachment.attachment_body,
  );
  const [url, setUrl] = useState();
  const { inLinkedSection } = useWhichSection();
  // TODO: __HOMEDATA-AUDIT
  const storage = getStorageData();

  const homeDataObj = storage.pref_type_options;
  const upload_file_year = homeDataObj.upload_file_year
    ? homeDataObj.upload_file_year
    : [];
  const upload_file_month = homeDataObj.upload_file_month
    ? homeDataObj.upload_file_month
    : [];
  const upload_tags = homeDataObj.upload_tags ? homeDataObj.upload_tags : [];

  const percentCompleted =
    state && attachment && state[`percentCompleted${attachment.file_name}`]
      ? state[`percentCompleted${attachment.file_name}`] ||
        attachment[`percentCompleted${attachment.file_name}`]
      : null;
  const getSize = emailGetSize(attachment);
  const trimSize = emailTrimSize(getSize);
  const fileSize = emailFileSize(trimSize);
  const isPdfFile = attachment.content_type.includes('pdf');
  const isImageFile = attachment.content_type.includes('image');

  function download(textInput, csv) {
    let fileurl = '';
    if (csv) {
      const blob = new Blob([attachment.file_data], {
        type: 'text/csv;charset=utf-8;',
      });
      fileurl = URL.createObjectURL(blob);
    } else {
      fileurl = `data:${fileType(
        attachment.content_type
          ? attachment.content_type
          : attachment.attachment_content_type,
      )};base64,${textInput}`;
    }
    const element = document.createElement('a');
    element.setAttribute('href', fileurl);
    element.setAttribute('download', attachment.file_name);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const handleVaultData = async () => {
    const response = await Axios.get(
      `${REPLACE_DOCUMENT_URL}/${attachment.id}`,
    ).then((res) => res.data);
    setAttachmentBody(response.url);
    return response.url;
  };

  const handlePreviewFromBody = async () => {
    const response = await Axios.get(
      `${
        inLinkedSection ? linkedEmailsUrl : USER_EMAILS
        // TODO: are attachments covered in new endpoints?
      }/${emailId}/attachments/${attachment.id}`,
    ).then((res) => res.data.data);
    setAttachmentBody(
      `data: ${attachment.content_type}; base64, ${response[0].attachment_body}`,
    );
    return response[0].attachment_body;
  };

  const handlePreviewUrl = async () => {
    const response = await Axios.get(
      `${EMAIL_ATTACHMENTS}/${attachment.id}`,
    ).then((res) => res.data);
    setAttachmentBody(response.url);
    return response.url;
  };

  const handlePreviewBasedOnFiles = (attachmentRawData, attachmentUrl) => {
    if (handleCheckbox) {
      setShowPreview(true);
      setUrl(attachmentUrl);
    } else if (
      !handleCheckbox &&
      attachment.content_type &&
      (isImageFile || isPdfFile)
    ) {
      setAttachBody(true);
      setShowPreview(true);
    } else if (
      !handleCheckbox &&
      attachment.content_type &&
      attachment.attachment_content_type &&
      (attachment.content_type.includes('text/csv') ||
        attachment.content_type.includes('sheet')) &&
      attachment.attachment_content_type.includes('text/plain') &&
      attachment.file_data
    ) {
      download(attachment.file_data, 'csvfile');
    } else if (
      !handleCheckbox &&
      attachment.content_type &&
      attachmentRawData
    ) {
      download(attachmentRawData);
    } else {
      window.location.href = attachmentUrl;
    }
  };

  const handlePreview = async () => {
    if (attachmentBody && attachBody) {
      setShowPreview(true);
    } else {
      setLoading(true);
      try {
        let attachmentUrl;
        let attachmentRawData;
        // this is for liscio vault files
        if (attachment.fromlisciovault) {
          attachmentUrl = await handleVaultData();
        } else if (
          attachment.attachment_id &&
          attachment.attachment_id !== null
        ) {
          const previewBody = await handlePreviewFromBody();
          attachmentUrl = previewBody;
          attachmentRawData = previewBody;
        } else {
          attachmentUrl = await handlePreviewUrl();
        }
        handlePreviewBasedOnFiles(attachmentRawData, attachmentUrl);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  return (
    <StyedWrap className="EmailsAttachments" key={`attachment${attachment.id}`}>
      {loading ? <div id="loading" /> : null}
      <div className="EmailsAttachments__File">
        {handleCheckbox ? (
          <div className="EmailsAttachments__Checkbox">
            <Checkbox
              checked={attachment.isChecked}
              onChange={() => handleCheckbox(attachment, 'add')}
              color="info"
            />
            {attachment.document_id && <i className="icon-circle-checkmark" />}
          </div>
        ) : null}
        {attachment.id ? (
          <>
            <div className="EmailsAttachments__Detail">
              <span className="EmailsAttachments__FileType">
                <i className={fileType(attachment)} aria-hidden="true" />
              </span>
              <span>{attachment.file_name}</span>
            </div>
            <div className="EmailsAttachments__PreviewClose">
              <button
                type="button"
                className="EmailsAttachments__PreviewFile"
                key={`doc${attachment.id}`}
                onClick={() => handlePreview()}
              >
                <i className="icon-open-eye" />
                Preview
              </button>
              {attachment.size && (
                <span className="EmailsAttachments__FileSize">{fileSize}</span>
              )}
              {deleteAttachment && (
                <button
                  type="button"
                  className="EmailsAttachments__PreviewFile"
                  onClick={() => deleteAttachment(attachment)}
                >
                  <i className="icon-close2" />
                </button>
              )}
            </div>
          </>
        ) : (
          <div key={attachment.id} className="FileAttached">
            <div className="FileAttached__Type">
              <i className={`${fileType(attachment)}`} aria-hidden="true" />
            </div>
            <div className="FileAttached__Info">
              <div className="FileAttached__Detail">
                <span className="FileNmae">{attachment.file_name}</span>
                <span className="FileWeight">{fileSize}</span>
                <Line
                  percent={percentCompleted}
                  strokeWidth="2"
                  strokeColor="#00AECC"
                />
              </div>
            </div>
          </div>
        )}
        {/* user can delete attachments of new and draft email */}
      </div>
      {handleCheckbox && showPreview && !attachment.document_id ? (
        <Modal isOpen={showPreview} className="Md-full-width modal-FullH">
          <div className="eng-letter">
            <header>
              <div className="headerWrapper row">
                <div className="left-side col-md-8 col-12">
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={() => closePreview()}
                  >
                    <i className="icon-back-arrow" aria-hidden="true" />
                  </Button>
                  <div className="head-inner d-block">
                    <h4 className="mt-2 mb-0">{attachment.file_name}</h4>
                  </div>
                </div>
                <div className="right-side col-md-4 col-12">
                  <div className="text-right pr-5">
                    {buttons
                      ? buttons('forSingleAttachment', attachment, url)
                      : null}
                  </div>
                </div>
              </div>
            </header>
            <div className="main-sec">
              <div className="left-side-inner-sec w-100">
                {isPdfFile && attachmentBody ? (
                  <div className="modalContent--inner">
                    <iframe
                      width="100%"
                      height="100vh"
                      name="myfilename"
                      src={attachmentBody}
                      title="selected attachment"
                      style={{
                        width: '100%',
                        height: '80vh',
                        border: 'none',
                      }}
                    />
                  </div>
                ) : null}
                {isImageFile && attachmentBody ? (
                  <ImagePreview
                    items={[{ ...attachment, src: attachmentBody }]}
                    toggle={closePreview}
                    selectedImageId={attachment.id}
                  />
                ) : null}
                {!isPdfFile && !isImageFile && (
                  <div className="left-inner-content">
                    <div
                      className="emptyBox text-center"
                      onClick={() => download(url)}
                    >
                      <h3 className="emptyBox-icon text-large">
                        <i className="icon-download2" /> Please download file to
                        view the details.
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {handleCheckbox && showPreview && attachment.document_id ? (
        <Modal isOpen={showPreview} className="Md-full-width modal-FullH">
          <DocumentShow
            years={upload_file_year}
            months={upload_file_month}
            tags={upload_tags}
            onButtonClose={closePreview}
            selectedItem={{ id: attachment.document_id }}
          />
        </Modal>
      ) : null}
      {!handleCheckbox && showPreview && isPdfFile && attachmentBody ? (
        <Modal
          isOpen
          className="customModal customModal--w990 customModal--nopadding"
          toggle={closePreview}
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={closePreview}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <iframe
                width="100%"
                height="100vh"
                name="myfilename"
                src={attachmentBody}
                title="selected attachment"
                style={{
                  width: '100%',
                  height: '80vh',
                  border: 'none',
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      ) : null}
      {!handleCheckbox && showPreview && isImageFile && attachmentBody ? (
        <ImagePreview
          items={[{ ...attachment, src: attachmentBody }]}
          toggle={closePreview}
          selectedImageId={attachment.id}
        />
      ) : null}
    </StyedWrap>
  );
};

export default DisplayEmailAttachments;
