import React from 'react';

const RadioButton = ({
  checked,
  onChange,
  buttonFor,
  buttonValue,
  buttonField,
}) => (
  <div>
    <label htmlFor={buttonFor}>
      <input
        type="radio"
        name={buttonFor}
        id={buttonFor}
        value={buttonValue}
        checked={checked}
        onClick={() => onChange(buttonValue, buttonField)}
      />
      <i className="checkmark" />
      <span style={{ fontSize: '0.9rem' }}>{buttonValue}</span>
    </label>
  </div>
);

export default RadioButton;
