/* eslint-disable no-nested-ternary */
import React from 'react';
import CustomImage from 'common/CustomImage';

const EachApp = ({
  appDetails,
  handleConnect,
  handleDisconnect,
  handleConnectBtnLabel,
  handleDisconnectBtnLabel,
  emailButton,
}) => (
  <div className="col-lg-4 col-sm-12 col-md-6">
    <div className="card light-outline mb-4">
      <div className="card-body">
        <h5 className="card-title">
          <CustomImage
            src={`${appDetails.id}.png`}
            style={{
              maxHeight: '40px',
              maxWidth: '100%',
              paddingRight: '12px'
            }}
          />
          <span style={{ paddingRight: '10px' }}>
            {appDetails.name}
          </span>
          {appDetails.token_exits && (
            <span className="green-badge">Connected</span>
          )}
        </h5>
        <div className="form-group form-inline-label">
          <label className="align-self-start" style={{ minWidth: '0' }}>
            {appDetails.email ? 'Email:' : ''}
          </label>
          <div className="labelValue">
            <span>{appDetails.email ? appDetails.email : ''}</span>
          </div>
        </div>
        {appDetails.token_exits ? (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-light col mt-2 card-button"
              onClick={() => handleDisconnect(appDetails)}
            >
              {handleDisconnectBtnLabel || 'Disconnect'}
            </button>
          </div>
        ) : emailButton ? (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary col mt-2 card-button"
              onClick={() => handleConnect(appDetails)}
            >
              {handleConnectBtnLabel || 'Connect'}
            </button>
          </div>
        ) : (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary col mt-2 card-button"
              onClick={() => handleConnect(appDetails)}
            >
              {handleConnectBtnLabel || 'Connect'}
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default EachApp;
