import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { UseQueryResult } from '@tanstack/react-query';
import { RpaDocumentsQuery } from 'gql/graphql';
import { useEffect, useMemo, useState } from 'react';
import { TabsKey } from 'components/Requests/state/request-state';
import { CustomPagination } from 'components/Requests/components/Tables/Components/CustomPagination';
import { LoadingOverlay } from 'components/Requests/components/Tables/Components/LoadingOverlay';
import { NoResultsOverlay } from 'components/Requests/components/Tables/Components/NoResultsOverlay';
import { useGetWorkflowCounts } from 'components/Requests/hooks/get-workflow-counts';
import { RequestsDataGridStyled } from 'components/Requests/components/Styles/Styles';
import { isClient } from 'utilities/utils';

export type RequestsTableProps = {
  query: UseQueryResult<RpaDocumentsQuery>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  tableName: string;
  columns: GridColDef[];
  requestKey: TabsKey;
};

export const RpaDocumentsTable = ({
  query,
  paginationModel,
  setPaginationModel,
  tableName,
  columns,
  requestKey,
}: RequestsTableProps) => {
  const isUserClient = isClient();
  const { requestsCounts, isLoadingOrError } =
    useGetWorkflowCounts(isUserClient);

  const rows = useMemo(
    () => query?.data && query?.data?.rpaDocuments?.collection,

    [query?.data],
  );
  const [totalCount, setTotalCount] = useState(-1);
  const requestCount = requestsCounts[requestKey];

  useEffect(() => {
    if (isLoadingOrError || requestCount === undefined) {
      return;
    }
    setTotalCount(requestCount);
  }, [isLoadingOrError, requestKey, requestCount]);

  if (totalCount === -1) {
    return <LoadingOverlay />;
  }

  return (
    <RequestsDataGridStyled
      initialState={{
        pagination: {
          paginationModel,
        },
      }}
      rows={rows || []}
      columns={columns}
      sortingMode="client"
      loading={query.isLoading}
      slots={{
        pagination: CustomPagination,
        loadingOverlay: LoadingOverlay,
        noResultsOverlay: () =>
          NoResultsOverlay({
            message: `No ${tableName}`,
            refetch: query.refetch,
          }),
        noRowsOverlay: () =>
          NoResultsOverlay({
            message: `No ${tableName}`,
            refetch: query.refetch,
          }),
      }}
      disableColumnMenu
      pageSizeOptions={[25, 50]}
      rowHeight={72}
      columnHeaderHeight={36}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={totalCount}
    />
  );
};
