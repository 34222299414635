import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Axios from 'axios';
import { Line } from 'rc-progress';
import DocumentShow from 'components/UploadedFiles/DocumentShow';
import { fileType, getStorageData } from 'utilities/utils';
import {
  USER_EMAILS,
  EMAIL_ATTACHMENTS,
  REPLACE_DOCUMENT_URL,
} from 'constants/constants';
import ImagePreview from 'common/ImagePreview';

const DisplayEmailAttachments = ({
  attachment,
  emailId,
  deleteAttachment,
  handleCheckbox,
  buttons,
  state,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [attachBody, setAttachBody] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachmentBody, setAttachmentBody] = useState(
    attachment.attachment_body,
  );
  const [url, setUrl] = useState();
  // TODO: __HOMEDATA-AUDIT
  const storage = getStorageData();

  const homeDataObj = storage.pref_type_options;
  const upload_file_year = homeDataObj.upload_file_year
    ? homeDataObj.upload_file_year
    : [];
  const upload_file_month = homeDataObj.upload_file_month
    ? homeDataObj.upload_file_month
    : [];
  const upload_tags = homeDataObj.upload_tags ? homeDataObj.upload_tags : [];

  function download(textInput, csv) {
    let fileurl = '';
    if (csv) {
      const blob = new Blob([attachment.file_data], {
        type: 'text/csv;charset=utf-8;',
      });
      fileurl = URL.createObjectURL(blob);
    } else {
      fileurl = `data:${fileType(
        attachment.content_type
          ? attachment.content_type
          : attachment.attachment_content_type,
      )};base64,${textInput}`;
    }
    const element = document.createElement('a');
    element.setAttribute('href', fileurl);
    element.setAttribute('download', attachment.file_name);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const handlePreview = async () => {
    if (attachmentBody && attachBody) {
      setShowPreview(true);
    } else {
      setLoading(true);
      try {
        let response;
        let attachmentUrl;
        let attachmentRawData;
        // this is for liscio vault files
        if (attachment.fromlisciovault) {
          response = await Axios.get(
            `${REPLACE_DOCUMENT_URL}/${attachment.id}`,
          ).then((res) => res.data);
          setAttachmentBody(response.url);
          attachmentUrl = response.url;
        } else if (
          attachment.attachment_id &&
          attachment.attachment_id !== null
        ) {
          response = await Axios.get(
            `${USER_EMAILS}/${emailId}/attachments/${attachment.id}`,
          ).then((res) => res.data.data);
          setAttachmentBody(
            `data: ${attachment.content_type}; base64, ${response[0].attachment_body}`,
          );
          attachmentUrl = response[0].attachment_body;
          attachmentRawData = response[0].attachment_body;
        } else {
          response = await Axios.get(
            `${EMAIL_ATTACHMENTS}/${attachment.id}`,
          ).then((res) => res.data);
          setAttachmentBody(response.url);
          attachmentUrl = response.url;
        }
        if (handleCheckbox) {
          setShowPreview(true);
          setUrl(attachmentUrl);
        } else if (
          !handleCheckbox &&
          attachment.content_type &&
          (attachment.content_type.includes('image') ||
            attachment.content_type.includes('pdf'))
        ) {
          setAttachBody(true);
          setShowPreview(true);
        } else if (
          !handleCheckbox &&
          attachment.content_type &&
          attachment.attachment_content_type &&
          (attachment.content_type.includes('text/csv') ||
            attachment.content_type.includes('sheet')) &&
          attachment.attachment_content_type.includes('text/plain') &&
          attachment.file_data
        ) {
          download(attachment.file_data, 'csvfile');
        } else if (
          !handleCheckbox &&
          attachment.content_type &&
          attachmentRawData
        ) {
          download(attachmentRawData);
        } else {
          window.location.href = attachmentUrl;
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  // TODO: find better way to handle this
  const percentCompleted =
    state && attachment && state[`percentCompleted${attachment.file_name}`]
      ? state[`percentCompleted${attachment.file_name}`] ||
        attachment[`percentCompleted${attachment.file_name}`]
      : null;
  const getSize = attachment.file_size ? attachment.file_size / 1024 : null;
  const trimSize = getSize ? getSize.toFixed(2) : null;
  const fileSize = trimSize ? `${trimSize} KB` : null;
  return (
    <div key={`attachment${attachment.id}`}>
      {loading ? <div id="loading" /> : null}
      <div className="file-drop-upload  d-flex align-items-center">
        {handleCheckbox ? (
          <div className="file-drop-type d-flex align-items-center">
            <div className="checkbox checkbox-primary check-container">
              <Label htmlFor={attachment.id}>
                <Input
                  key={attachment.id}
                  type="checkbox"
                  id={attachment.id}
                  checked={attachment.isChecked}
                  value={attachment.id}
                  className={`chkbx ${attachment.id}`}
                  name="isChecked"
                  onChange={() => handleCheckbox(attachment, 'add')}
                />
                <i className="checkmark" />
              </Label>
            </div>
            {attachment.document_id ? (
              <i className="icon-circle-checkmark ml-2 clrGreen" />
            ) : null}
          </div>
        ) : null}
        {attachment.id ? (
          <div className="file-drop-info d-flex align-items-center pr-0">
            <div className="file-drop-detail col pl-0 pt-0">
              <h6 className="fileName">
                <button
                  type="button"
                  className="btn btn-link fileName pr-0 d-flex align-items-center"
                >
                  <div className="file-drop-type">
                    <i
                      className={`${fileType(attachment)} pr-0`}
                      aria-hidden="true"
                    />
                  </div>
                  <div>{attachment.file_name}</div>
                </button>
              </h6>
            </div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-link previewFile"
                key={`doc${attachment.id}`}
                onClick={() => handlePreview()}
              >
                <i className="icon-open-eye" />
                Preview
              </button>
              {attachment.size && (
                <span className="file-action">{fileSize}</span>
              )}
              {deleteAttachment ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-link p-0 ml-2 previewFile file-action"
                    onClick={() => deleteAttachment(attachment)}
                  >
                    <i className="icon-close2 mr-0 pr-0" />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div
            key={attachment.id}
            className="file-drop-info d-flex align-items-center pr-0"
          >
            <div className="file-drop-type">
              <i className={`${fileType(attachment)}`} aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <span className="fileName">{attachment.file_name}</span>
                <span className="fileweight">{fileSize}</span>
                <Line
                  percent={percentCompleted}
                  strokeWidth="4"
                  strokeColor="#767676"
                />
              </div>
            </div>
          </div>
        )}
        {/* user can delete attachments of new and draft email */}
      </div>
      {handleCheckbox && showPreview && !attachment.document_id ? (
        <Modal isOpen={showPreview} className="Md-full-width modal-FullH">
          <div className="eng-letter">
            <header>
              <div className="headerWrapper row">
                <div className="left-side col-md-8 col-12">
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={() => closePreview()}
                  >
                    <i className="icon-back-arrow" aria-hidden="true" />
                  </Button>
                  <div className="head-inner d-block">
                    <h4 className="mt-2 mb-0">{attachment.file_name}</h4>
                  </div>
                </div>
                <div className="right-side col-md-4 col-12">
                  <div className="text-right pr-5">
                    {buttons
                      ? buttons('forSingleAttachment', attachment, url)
                      : null}
                  </div>
                </div>
              </div>
            </header>
            <div className="main-sec">
              <div className="left-side-inner-sec w-100">
                {attachment.content_type.includes('pdf') && attachmentBody ? (
                  <div className="modalContent--inner">
                    <iframe
                      width="100%"
                      height="100vh"
                      name="myfilename"
                      src={attachmentBody}
                      title="selected attachment"
                      style={{
                        width: '100%',
                        height: '80vh',
                        border: 'none',
                      }}
                    />
                  </div>
                ) : null}
                {attachment.content_type.includes('image') && attachmentBody ? (
                  <ImagePreview
                    items={[{ ...attachment, src: attachmentBody }]}
                    toggle={closePreview}
                    selectedImageId={attachment.id}
                  />
                ) : (
                  <div className="left-inner-content">
                    <div
                      className="emptyBox text-center"
                      onClick={() => download(url)}
                    >
                      <h3 className="emptyBox-icon text-large">
                        <i className="icon-download2" /> Please download file to
                        view the details.
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {handleCheckbox && showPreview && attachment.document_id ? (
        <Modal isOpen={showPreview} className="Md-full-width modal-FullH">
          <DocumentShow
            years={upload_file_year}
            months={upload_file_month}
            tags={upload_tags}
            onButtonClose={closePreview}
            selectedItem={{ id: attachment.document_id }}
          />
        </Modal>
      ) : null}
      {!handleCheckbox &&
      showPreview &&
      attachment.content_type.includes('pdf') &&
      attachmentBody ? (
        <Modal
          isOpen
          className="customModal customModal--w990 customModal--nopadding"
          toggle={closePreview}
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={closePreview}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <iframe
                width="100%"
                height="100vh"
                name="myfilename"
                src={attachmentBody}
                title="selected attachment"
                style={{
                  width: '100%',
                  height: '80vh',
                  border: 'none',
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      ) : null}
      {!handleCheckbox &&
        showPreview &&
        attachment.content_type.includes('image') &&
        attachmentBody && (
          <ImagePreview
            items={[{ ...attachment, src: attachmentBody }]}
            toggle={closePreview}
            selectedImageId={attachment.id}
          />
        )}
    </div>
  );
};

export default DisplayEmailAttachments;
