import { graphql } from 'gql';

export const WorkflowSectionFragment = graphql(/* GraphQL */ `
  fragment WorkflowSection on WorkflowSection {
    createdAt
    id
    position
    items {
      ...WorkflowItem
      ...WorkflowSignableDocumentItem
    }
    title
    updatedAt
  }
`);

export const WorkflowWithoutSectionsFragment = graphql(/* GraphQL */ `
  fragment WorkflowWithoutSections on Workflow {
    id
    title
    description
    createdAt
    status
    type
  }
`);

export const WorkflowWithSectionsFragment = graphql(/* GraphQL */ `
  fragment WorkflowWithSections on Workflow {
    ...WorkflowWithoutSections
    sections {
      ...WorkflowSection
    }
  }
`);

export const workflowsQueryDocument = graphql(/* GraphQL */ `
  query workflows(
    $status: WorkflowStatus
    $statuses: [WorkflowStatus!]
    $page: Int
    $perPage: Int
    $sortField: WorkflowSortOption
    $sortDirection: String
  ) {
    workflows(
      status: $status
      statuses: $statuses
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalCount
      collection {
        ...WorkflowWithoutSections
      }
    }
  }
`);

export const workflowsCountQueryDocument = graphql(/* GraphQL */ `
  query workflowsCount(
    $status: WorkflowStatus
    $statuses: [WorkflowStatus!]
    $page: Int
    $perPage: Int
  ) {
    workflows(
      status: $status
      statuses: $statuses
      page: $page
      perPage: $perPage
    ) {
      totalCount
    }
  }
`);

export const workflowQueryDocument = graphql(/* GraphQL */ `
  query workflowQuery($id: Uuid!) {
    workflow(id: $id) {
      ...WorkflowWithSections
    }
  }
`);

export const createWorkflowMutationDocument = graphql(/* GraphQL */ `
  mutation createWorkflow($title: String!, $description: String) {
    createWorkflow(title: $title, description: $description) {
      errors {
        message
      }
      workflow {
        id
        title
        description
      }
    }
  }
`);

export const updateWorkflowMutationDocument = graphql(/* GraphQL */ `
  mutation updateWorkflow($status: String!, $workflowId: Uuid!) {
    updateWorkflow(status: $status, workflowId: $workflowId) {
      errors {
        message
      }
      workflow {
        id
        status
      }
    }
  }
`);

export const createWorkflowSectionDocument = graphql(/* GraphQL */ `
  mutation createWorkflowSection($workflowId: Uuid!, $title: String!) {
    createWorkflowSection(workflowId: $workflowId, title: $title) {
      errors {
        message
      }
      workflowSection {
        id
      }
    }
  }
`);

export const deleteWorkflowSectionDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowSection($workflowSectionId: Uuid!) {
    deleteWorkflowSection(workflowSectionId: $workflowSectionId) {
      errors {
        message
      }
    }
  }
`);

export const updateWorkflowSectionDocument = graphql(/* GraphQL */ `
  mutation updateWorkflowSection($title: String!, $workflowSectionId: Uuid!) {
    updateWorkflowSection(
      title: $title
      workflowSectionId: $workflowSectionId
    ) {
      errors {
        message
      }
      workflowSection {
        id
      }
    }
  }
`);

export const WorkflowItemConditionFragment = graphql(/* GraphQL */ `
  fragment WorkflowItemCondition on WorkflowItemCondition {
    id
    conditionType
    fieldName
    matchValue
    rangeLowerBound
    rangeUpperBound
  }
`);

export const WorkflowItemInterfaceFragment = graphql(/* GraphQL */ `
  fragment WorkflowItemInterface on WorkflowItemInterface {
    id
    prompt
    type
    conditionalParentId
    updatedAt
    position
    section {
      id
    }
    conditions {
      ...WorkflowItemCondition
    }
  }
`);

export const WorkflowItemFragment = graphql(/* GraphQL */ `
  fragment WorkflowItem on WorkflowItemInterface {
    ...DefaultWorkflowItem
    ...WorkflowSignableDocumentItem
  }
`);

export const DefaultWorkflowItemFragment = graphql(/* GraphQL */ `
  fragment DefaultWorkflowItem on WorkflowItem {
    ... on WorkflowItem {
      ...WorkflowItemInterface
    }
  }
`);

export const WorkflowSignableDocumentItemFragment = graphql(/* GraphQL */ `
  fragment WorkflowSignableDocumentItem on WorkflowSignableDocumentItem {
    ... on WorkflowSignableDocumentItem {
      ...WorkflowItemInterface
      esignAgreement {
        documentToSign {
          id
          uuid
          legacyId
          awsUrlFileName
        }
        signatories {
          ...Signers
        }
        signingAvailableUrl
        startSigningUrl
      }
    }
  }
`);

export const signersFragment = graphql(/* GraphQL */ `
  fragment Signers on AgreementSignatory {
    id
    label
    contact {
      id
    }
    kbaRequired
    signOnSameDevice
    esignSignatureBlocks {
      pageNumber
      top
      left
      width
      height
    }
  }
`);

export const workflowTemplatesDocument = graphql(/* GraphQL */ `
  query workflowTemplates($statuses: [WorkflowTemplateStatus!]) {
    workflowTemplates(statuses: $statuses) {
      ...WorkflowTemplate
    }
  }
`);

export const createWorkflowTemplateFromWorkflowDocument =
  graphql(/* GraphQL */ `
    mutation createWorkflowTemplateFromWorkflow($id: Uuid!) {
      createWorkflowTemplateFromWorkflow(workflowId: $id) {
        errors {
          message
        }
        workflowTemplate {
          ...WorkflowTemplate
        }
      }
    }
  `);

export const createWorkflowsFromWorkflowTemplateDocument =
  graphql(/* GraphQL */ `
    mutation createWorkflowsFromWorkflowTemplateDocument($id: Uuid!) {
      createWorkflowsFromWorkflowTemplate(workflowTemplateId: $id) {
        errors {
          message
        }
        workflows {
          id
        }
      }
    }
  `);

export const updateWorkflowTemplateFromWorkflowDocument =
  graphql(/* GraphQL */ `
    mutation updateWorkflowTemplateFromWorkflow(
      $workflowId: Uuid!
      $workflowTemplateId: Uuid!
    ) {
      updateWorkflowTemplateFromWorkflow(
        workflowId: $workflowId
        workflowTemplateId: $workflowTemplateId
      ) {
        errors {
          message
        }
        workflowTemplate {
          ...WorkflowTemplate
        }
      }
    }
  `);

export const deleteWorkflowTemplateDocument = graphql(/* GraphQl */ `
  mutation deleteWorkflowTemplate($workflowTemplateId: Uuid!) {
    deleteWorkflowTemplate(workflowTemplateId: $workflowTemplateId) {
      errors {
        message
      }
      workflowTemplate {
        ...WorkflowTemplate
      }
    }
  }
`);

export const workflowTemplateFragment = graphql(/* GraphQL */ `
  fragment WorkflowTemplate on WorkflowTemplate {
    id
    createdAt
    updatedAt
    displayName
    status
    firm {
      id
    }
  }
`);

export const saveWorkflowMutationDocument = graphql(/* GraphQL */ `
  mutation saveWorkflow($id: Uuid!, $title: String, $description: String) {
    saveWorkflow(id: $id, title: $title, description: $description) {
      errors {
        message
      }
      workflow {
        id
      }
    }
  }
`);

export const readyToSendMutationDocument = graphql(/* GraphQL */ `
  mutation readyToSend($workflowId: Uuid!) {
    readyToSend(workflowId: $workflowId) {
      errors {
        message
      }
      workflowRequest {
        id
      }
    }
  }
`);
