import { useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom-latest';

import { useResetPassword } from 'hooks/login-v5-hooks';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_ERROR_MESSAGE } from 'constants/constants';
import {
  LoginPageLayout,
  MFAForm,
  NextStepsMessage,
  ResetPasswordForm,
} from './components';
import { INVALID_MFA_ERROR_TYPE } from './constants';

import { formSchema } from './helpers/resetPasswordFormValidationSchema';

export type ProcessState = 'newPasswordForm' | 'mfaCode' | 'confirmation';

export interface ResetPasswordFormInterface {
  password: string;
  password_confirmation: string;
  two_factor_code: string;
}

export const LoginV5ResetPassword = () => {
  const navigate = useNavigate();
  const { id: token } = useParams<{ id: string }>();
  const [processState, setProcessState] =
    useState<ProcessState>('newPasswordForm');

  const formCtx = useForm<ResetPasswordFormInterface>({
    resolver: yupResolver(formSchema),
    context: { processState },
  });
  const { handleSubmit, setError, getValues } = formCtx;

  const {
    mutateAsync: resetPassword,
    data: resetPasswordResponse,
    isLoading,
  } = useResetPassword({
    onSuccess: () => {
      setProcessState('confirmation');
    },
    onError: (error) => {
      const statusCode = error?.response?.status;
      const message = error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;

      const { two_factor_code } = getValues();
      // We need to establish a better contract with the backend :/
      if (message === INVALID_MFA_ERROR_TYPE && !two_factor_code) {
        setProcessState('mfaCode');
      } else {
        setError('root.serverError', {
          type: String(statusCode),
          message,
        });
      }
    },
  });

  const onSubmit: SubmitHandler<ResetPasswordFormInterface> = useCallback(
    (data) => {
      if (token) {
        resetPassword({
          token,
          twoFactorCode: data.two_factor_code,
          password: data.password,
          passwordConfirmation: data.password_confirmation,
        });
      }
    },
    [resetPassword, token],
  );

  return (
    <FormProvider {...formCtx}>
      <div id="page-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          {isLoading && <div id="loading" />}
          <LoginPageLayout>
            <>
              {processState === 'newPasswordForm' && <ResetPasswordForm />}
              {processState === 'mfaCode' && <MFAForm />}
              {processState === 'confirmation' && (
                <NextStepsMessage
                  rawMessage={resetPasswordResponse?.message}
                  backToLogin={() => {
                    navigate('/');
                  }}
                />
              )}
            </>
          </LoginPageLayout>
        </form>
      </div>
    </FormProvider>
  );
};

export default LoginV5ResetPassword;
