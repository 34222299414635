/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unused-state */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
// TODO: check react-s-alert
import React, { Component } from 'react';
import Select from 'react-select';
// import Alert from 'react-s-alert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Axios from 'axios';
import PageNotFound from 'common/PageNotFound';
import { getCurrentPath, getFirmType, getStorageData } from 'utilities/utils';
import * as actions from 'actions/TaskActions';
import {
  MIXPANEL_TASK_EVENT,
  THIRD_PARTY_APPS,
  USER_EMAILS,
} from 'constants/constants';
import { selectStyles, selectTheme } from 'common/CustomFields';
import { withRouter } from 'utilities/withRouterWrapper';
import { withStorageData } from 'utilities/withStorageData';
import { trackMixpanelEvent } from 'utilities/mixpanelfn';
import RequestInformation from './task_type/RequestInformation';
import GetSignature from './task_type/GetSignature';
import ManageToGoItems from './task_type/ManageToGoItems_updated';
import ToDo from './task_type/ToDo';
import EDoc from './task_type/EDoc';
import VirtualMeeting from './task_type/VirtualMeeting_updated';
import Invoice from './task_type/Invoice';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

class NewTask extends Component {
  constructor(props) {
    super(props);
    const url = window.location.href.split('type=');
    const taskType = url[1] ? url[1] : 'request_info';
    this.state = {
      taskTypeKey: taskType,
      taskTypeValue: '',
      allContacts: [],
      allAccounts: [],
      contactDetails: '',
      accountDetails: '',
      colleagues: [],
      owners: [],
      loading: false,
      isEdit: false,
      selectedOption: '',
      homeData: {},
      taskTypes: [],
      unauthorisedAccess: false,
      changedOnce: false,
      thirdPartyApps: [],
      on_schedule: false,
      viewContext: this.props.viewContext,
      externalDocs: props.taskAttachments || [],
      gettingEmailAttachments: false,
      emailId:
        this.props.viewContext && this.props.emailDetails
          ? this.props.emailDetails.email_id
          : undefined,
    };
    this.goBack = this.goBack.bind(this);
    this.newPage = this.newPage.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.title = 'Create Task';
    if (getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
    const { location } = this.props;
    const path = getCurrentPath(location);
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (homeDataObj && !homeDataObj.is_employee) {
      this.setState({ unauthorisedAccess: true });
    }
    if (path === '/task/edit/:id') {
      this.setState({ isEdit: true });
    }
  }

  componentDidMount() {
    const { location, params } = this.props;
    const path = getCurrentPath(location);
    if (this.state.unauthorisedAccess) {
      return false;
    }
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    this.setState(
      {
        homeData: homeDataObj,
        taskTypes: homeDataObj.pref_type_options.cpa_task_type,
      },
      function () {
        // For New Task
        // const results = new RegExp('[?&]type=([^&#]*)').exec(this.props.location.search);
        const results = window.location.href.split('type=');
        const taskType = results[1]
          ? results[1]
          : this.props.defaultType
          ? this.props.defaultType
          : 'request_info'; // ?type=to_do
        // For edit task

        if (path === '/task/edit/:id') {
          const { id } = params;
          this.props.actions.fetchtaskDetail(id, this, true, 'newtask');
        } else if (this.state.viewContext && path !== '/task/detail/:id') {
          this.props.actions.fetchDetail({
            subject: this.props.taskTitle || this.props.emailDetails?.subject,
            description:
              this.props.taskDescription || this.props.emailDetails?.content,
          });
          this.changeTaskType(taskType);
        } else if (this.state.viewContext && path === '/task/detail/:id') {
          this.props.actions.fetchDetail({
            ...this.props.taskDetail,
            emailSubject:
              this.props.taskTitle || this.props.emailDetails?.subject,
            emailDescription:
              this.props.taskDescription || this.props.emailDetails?.content,
          });
          this.changeTaskType(taskType);
        } else {
          this.changeTaskType(taskType); // on type change
          this.props.actions.fetchDetail([]);
        }
      },
    );
    Axios.get(THIRD_PARTY_APPS).then((res) => {
      if (res.data && res.data.data && res.data.data.length) {
        this.setState({ thirdPartyApps: res.data.data });
      }
    });
    if (window.CKEDITOR === undefined) {
      const script = document.createElement('script');
      script.src = 'https://turmericp.liscio.me/ckeditor/ckeditor.js';
      script.id = 'ckeditor';
      document.body.appendChild(script);
      script.onload = () => {
        if (window.CKEDITOR && !window.CKEDITOR.disableAutoInline) {
          window.CKEDITOR.disableAutoInline = true;
        }
      };
    }
    this.getEmailAttachments();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const path = getCurrentPath(location);

    if (this.props.location.search !== nextProps.location.search) {
      const results = nextProps.location.search.split('type=');
      const taskType = results[1]; // ?type=to_do
      if (path !== '/task/edit/:id') {
        this.changeTaskType(taskType); // on type change from left panel
      }
    }
  }

  componentWillUnmount() {
    const { location } = this.props;
    const path = getCurrentPath(location);

    if (this.state.viewContext && path === '/task/detail/:id') {
      this.props.actions.fetchDetail({
        ...this.props.taskDetail,
        emailSubject: '',
        emailDescription: '',
        emailDocuments: [],
      });
    } else {
      this.props.actions.fetchDetail({});
    }
  }

  //  this when task is created from email
  getEmailAttachments = async (fromaction = '') => {
    const { location } = this.props;
    const path = getCurrentPath(location);

    const { viewContext } = this.state;
    if (
      this.props.emailDetails &&
      this.props.emailDetails.documents &&
      this.props.emailDetails.documents.length
    ) {
      this.setState({
        gettingEmailAttachments:
          (this.props.emailDetails.documents &&
            this.props.emailDetails.documents.length) ||
          false,
      });
      try {
        const url = `${USER_EMAILS}/${this.props.emailDetails.email_id}/attachments?for_task_creation=true`;
        const inlineImgUrl = `${USER_EMAILS}/${this.props.emailDetails.email_id}/attachments?content_disposition=inline`;
        const apis =
          fromaction && fromaction === 'changetask'
            ? [Axios.get(inlineImgUrl)]
            : [Axios.get(inlineImgUrl), Axios.get(url)];
        await Axios.all(apis).then(
          Axios.spread((...responses) => {
            let response = [];
            let responseInline = [];
            responseInline = responses[0] ? responses[0].data.data : [];
            response = responses[1] ? responses[1].data.data : [];
            if (response && response.length) {
              response.map((e) => {
                e.isAttachmentFromEmailOrMessage = true;
                return e;
              });
              this.setState({ externalDocs: response });
            }
            if (responseInline && responseInline.length) {
              let emailBody = this.props.emailDetails.content;
              responseInline.forEach((doc) => {
                if (doc.content_type.includes('image') && doc.attachment_body) {
                  emailBody = emailBody.replace(
                    `cid:${doc.content_id.replace('<', '').replace('>', '')}`,
                    `data:${doc.content_type};base64,${doc.attachment_body}`,
                  );
                }
              });
              if (viewContext && path !== '/task/detail/:id') {
                this.props.actions.fetchDetail({
                  ...this.props.taskDetail,
                  description: emailBody,
                });
              } else if (viewContext && path === '/task/detail/:id') {
                this.props.actions.fetchDetail({
                  ...this.props.taskDetail,
                  emailDescription: emailBody,
                });
              }
            }
            this.setState({ gettingEmailAttachments: false });
          }),
        );
      } catch (error) {
        this.setState({ gettingEmailAttachments: false });
        console.log(error);
      }
    }
  };

  deleteEmailAttachment = (input) => {
    const { externalDocs } = this.state;
    // const { externalexternalDocs } = this.state;
    this.setState({
      externalDocs: externalDocs.filter((e) => e.id !== input),
    });
  };

  handleTaskTypeChange = (val) => {
    const { location } = this.props;
    const path = getCurrentPath(location);
    const { viewContext } = this.state;
    if (
      path === '/contactdetails/task/:id' ||
      path === '/accountdetails/task/:id' ||
      viewContext
    ) {
      this.changeTaskType(val.value);
    } else {
      this.props.navigate(`/task/new?type=${val.key}`, {
        search: `?type=${val.key}`,
        state: { from: location.state.from, page: location.state.page },
        replace: true,
      });
    }
  };

  changeTaskType(taskType) {
    const { taskTypes, viewContext } = this.state;
    const path = getCurrentPath(this.props.location);
    const selectedOption = taskTypes.find((item) => item.value === taskType);
    if (taskType) {
      this.setState({
        taskTypeKey: taskType,
        taskTypeValue: selectedOption.label,
        selectedOption: selectedOption,
      });
      if (viewContext) {
        if (path !== '/task/detail/:id') {
          this.props.actions.fetchDetail({
            subject: this.props.taskTitle || this.props.emailDetails?.subject,
            description:
              this.props.taskDescription || this.props.emailDetails?.content,
            documents: [],
          });
        } else if (path === '/task/detail/:id') {
          this.props.actions.fetchDetail({
            ...this.props.taskDetail,
            emailSubject:
              this.props.taskTitle || this.props.emailDetails.subject,
            emailDescription:
              this.props.taskDescription || this.props.emailDetails.content,
            emailDocuments: [],
          });
        }
        this.getEmailAttachments('changetask');
      }
    }
  }

  removeparticularTaskType = (value) => {
    const filter = this.state.taskTypes.filter((item) => item.value !== value);
    this.setState({ taskTypes: filter });
  };

  goBack(createdNewTask) {
    const { state } = this.props.location;
    const { viewContext, taskTypeKey } = this.state;
    if (!createdNewTask) {
      const from_email = viewContext === 'email';
      trackMixpanelEvent(MIXPANEL_TASK_EVENT, {
        state: 'cancelled',
        taskType: taskTypeKey,
        from_email,
      });
    }
    if (viewContext) {
      // we will refresh the email list page when task is created from email
      if (createdNewTask && this.props?.refreshEmailListPage) {
        this.props.refreshEmailListPage();
      }
      this.props.closeModalInEmailPage();
    } else if (state && state.page === 'account_tasks') {
      this.props.navigate(`/accountdetails/${state.id}/tasks`);
    } else if (state && state.page === 'contact_tasks') {
      this.props.navigate(`/contactdetails/${state.id}#tasks`);
    } else if (state && state.page === 'inFocus') {
      this.props.navigate(`/accountdetails/${state.id}/inFocus`);
    } else if (state && state.page === 'dashboard') {
      this.props.navigate('/');
    } else {
      this.props.navigate(-1);
    }
  }

  newPage() {
    this.props.navigate('/');
  }

  render() {
    const { thirdPartyApps } = this.state;
    if (this.state.unauthorisedAccess) {
      return <PageNotFound name="message" message="(Unauthorized Access)" />;
    }
    const { taskDetail, account_data, viewContext } = this.props;
    if (account_data) {
      taskDetail.account_data = account_data;
    }
    return (
      <div className="NewTask" data-testid="newTask">
        <div
          id="loading"
          style={this.state.loading ? blockStyle : noneStyle}
          data-testid="loading"
        />
        <header>
          <h1 className="pageHeading">
            {this.state.isEdit ? 'Edit Task' : 'Create Task'}
          </h1>
        </header>
        {/* <Alert html /> */}
        <div className="center-wrapper col-lg-10 col-sm-12 col-md-12 m-auto with-disabled-inputs">
          {/* we will show back button when we show new task in modal */}
          {!viewContext && (
            <div
              className="pb-3 col-container section-title"
              data-testid="backButtonContainer"
            >
              <h6 role="presentation" className="stepBacklink">
                <a
                  href="javascript:;"
                  onClick={this.goBack}
                  data-testid="backButton"
                >
                  <i className="icon-left-arrow" /> Back
                </a>
              </h6>
            </div>
          )}
          <div className="form-group form-inline-label customformLabel">
            <label htmlFor="selectTaskType" data-testid="taskTypeLabel">
              Task:
            </label>
            <Select
              name="taskType"
              isClearable={false}
              options={this.state.taskTypes}
              value={this.state.selectedOption}
              onChange={this.handleTaskTypeChange}
              backspaceRemoves={false}
              deleteRemoves={false}
              isSearchable={false}
              isDisabled={this.state.isEdit}
              className="select-custom-class task-type-select"
              styles={selectStyles}
              theme={selectTheme}
            />
          </div>
          {(() => {
            switch (this.state.taskTypeKey) {
              case 'request_info':
                return (
                  <RequestInformation
                    removeparticularTaskType={this.removeparticularTaskType}
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
              case 'get_signature':
                return (
                  <GetSignature
                    removeparticularTaskType={this.removeparticularTaskType}
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
              case 'manage_to_go_items':
                return (
                  <ManageToGoItems
                    removeparticularTaskType={this.removeparticularTaskType}
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
              case 'to_do':
                return (
                  <ToDo
                    removeparticularTaskType={this.removeparticularTaskType}
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
              case 'edoc':
                return (
                  <EDoc
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
              case 'virtual_meeting':
                return (
                  <VirtualMeeting
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    thirdPartyApps={thirdPartyApps}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );

              case 'invoice':
                return (
                  <Invoice
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
              default:
                return (
                  <RequestInformation
                    removeparticularTaskType={this.removeparticularTaskType}
                    taskDetail={taskDetail}
                    data={this.state}
                    goBack={this.goBack}
                    newPage={this.newPage}
                    on_schedule={this.state.on_schedule}
                    deleteEmailAttachment={this.deleteEmailAttachment}
                  />
                );
            }
          })()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taskDetail: state.taskReducer.taskDetail,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(NewTask)),
);
