const url = process.env.REACT_APP_API_URI || '';

/* BASE API */
export const API_V3_URL = `${url}/api/v3`;
export const API_V5_URL = `${url}/api/v5`;

/* GQL API */
export const GQL_URL = `${process.env.REACT_APP_GQL_URI || url}/graphql`;

/* OAUTH REDIRECT URLS */
/* See OauthRedirect and  App_updated routes */
export const redirect_base_url =
  process.env.REACT_APP_REDIRECT_URL_BASE || 'https://app.liscio.me';
export const ADOBE_REDIRECT_URL = `${redirect_base_url}/adobe`;
export const GOOGLE_OAUTH_REDIRECT_URL = `${redirect_base_url}/google`;
export const OUTLOOK_OAUTH_REDIRECT_URL = `${redirect_base_url}/outlook`;
export const QB_REDIRECT_URL = `${redirect_base_url}/qb`;
export const ZOOM_REDIRECT_URL = `${redirect_base_url}/zoom`;
// We use the outlook callback as the microsoft one.
export const MICROSOFT_OAUTH_REDIRECT_URL = OUTLOOK_OAUTH_REDIRECT_URL;

/* OTHER API'S */
export const SERVER_URL = url;
export const HOSTNAME = SERVER_URL.replace(/(^\w+:|^)\/\//, '');
export const SIGN_IN_API = `${API_V3_URL}/sign_in`;
export const FORGOT_PASSWORD_API_PATH = `${API_V3_URL}/forgot_password`;
export const SEND_MAGIC_LINK = `${API_V3_URL}/send_magic_link`;
export const HOME_API_PATH = `${API_V3_URL}/home`;
export const TASK_API_PATH = `${API_V3_URL}/tasks`;
export const MENU_ITEMS_API_PATH = `${API_V3_URL}/get_menu_items`;
export const { REACT_APP_INCREMENT_VECTOR } = process.env;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_FILE_PICKER_API_KEY =
  process.env.REACT_APP_GOOGLE_FILE_PICKER_API_KEY;
export const GOOGLE_FILE_PICKER_APP_ID =
  process.env.REACT_APP_GOOGLE_FILE_PICKER_APP_ID;
export const ML_API_URI = process.env.REACT_APP_ML_API_URI;
export const ML_USERNAME = process.env.REACT_APP_ML_USERNAME;
export const ML_PWD = process.env.REACT_APP_ML_PWD;
export const GET_CONTACTS_API_PATH = `${API_V3_URL}/get_current_cpa_contacts`;
export const GET_ACCOUNTS_API_PATH = `${API_V3_URL}/get_current_cpa_accounts`;
export const GET_EMPLOYEES_API_PATH = `${API_V3_URL}/employees`;
export const DOCUMENT_API_PATH = `${API_V3_URL}/documents`;
export const NOTE_API_PATH = `${API_V3_URL}/notes`;
export const ARCHIVE_NOTE = `${API_V3_URL}/archive_note`;
export const GET_ACCOUNTS = `${API_V3_URL}/accounts?page=`;
export const GET_CONTACTS = `${API_V3_URL}/contacts?page=`;
export const CONTACT = `${API_V3_URL}/contacts`;
export const SEARCH_TASK_CONTACTS = `${API_V3_URL}/get_task_contact_search`;
export const SEARCH_TASK_ACCOUNTS = `${API_V3_URL}/get_task_account_search`;
export const GET_COLLEAGUE = `${API_V3_URL}/get_staf_person`;
export const CHECK_PRIMARY_EMAIL = `${API_V3_URL}/check_primary_email`;
export const DEACTIVATE_CONTACT = `${API_V3_URL}/deactivate_contact`;
export const REACTIVATE_CONTACT = `${API_V3_URL}/reactivate_contact`;
export const GET_NOTES_OF_CONTACT = `${API_V3_URL}/get_notes_of_contact`;
export const GET_NOTES_OF_ACCOUNT = `${API_V3_URL}/get_notes_of_account`;
export const CONTACT_RELATIONSHIPS = `${API_V3_URL}/get_relationship_of_contact`;
export const SAVE_RELATIONSHIP = `${API_V3_URL}/relationships`;
export const GET_ACCOUNTS_NOT_IN_RELATION_WITH_CONTACT = `${API_V3_URL}/get_accounts_not_in_relation_with_contact`;
export const GET_ACCOUNTS_FOR_LOGINED_CONTACT = `${API_V3_URL}/get_accounts_of_contact_on_the_basis_of_user_id`;
export const ADD_COMMENT_API_PATH = `${API_V3_URL}/add_comment`;
export const ACCOUNT = `${API_V3_URL}/accounts`;
export const UPDATE_TASK_API_PATH = `${API_V3_URL}/update_task`;
export const UPDATE_TASK_FIELD_API_PATH = `${API_V3_URL}/update_task_field`;
export const CHANGE_OWNER_API_PATH = `${API_V3_URL}/task_reassignment`;
export const MESSAGES_API_PATH = `${API_V3_URL}/messages`;
export const UPLOAD_FILES = `${API_V3_URL}/upload_files`;
export const UPDATE_NEW_DOCUMENT = `${API_V3_URL}/update_new_document`;
export const CANCEL_UPLOAD = `${API_V3_URL}/cancel_upload`;
export const CHANGE_FILE_NAME = `${API_V3_URL}/change_file_name`;
export const UPDATE_TASK_STATUS_API_PATH = `${API_V3_URL}/update_task_status`;
export const SEARCH_ACCOUNTS = `${API_V3_URL}/account_search`;
export const SEARCH_ACCOUNTS_WITH_FILTERS = `${API_V3_URL}/account_search_with_filter`;
export const SEARCH_CONTACTS = `${API_V3_URL}/contact_search`;
export const GET_THREAD_MESSAGES = `${API_V3_URL}/get_thread_messages`;
export const DOCUMENTS_LIST = `${API_V3_URL}/documents_list`;
export const DOCUMENTS_LIST_CONTACT = `${API_V3_URL}/documents_list_contact`;
export const DELETE_DOCUMENTS = `${API_V3_URL}/delete_documents`;
export const UPDATE_DOCUMENTS = `${API_V3_URL}/update_documents`;
export const UPDATE_DOCUMENT = `${API_V3_URL}/update_document`;
export const DISCARD_DRAFT = `${API_V3_URL}/discard_draft`;
export const ARCHIVE_DRAFT = `${API_V3_URL}/archive_thread`;
export const INVITE_TO_LISCIO = `${API_V3_URL}/invite_to_liscio`;
export const DEACTIVATE_ACCOUNT = `${API_V3_URL}/deactivate_account`;
export const GET_TASKS_OF_CONTACT = `${API_V3_URL}/get_tasks_of_contact`;
export const ADD_DEPENDENT = `${API_V3_URL}/dependants`;
export const CHANGE_PASSWORD_API_PATH = `${API_V3_URL}/change_password`;
export const GET_DATA_API_PATH = `${API_V3_URL}/get_data`;
export const UPDATE_CONTACT_DATA_API_PATH = `${API_V3_URL}/update_contact`;
export const UPDATE_CONTACT_ADDRESS_API_PATH = `${API_V3_URL}/update_contact_address`;
export const ACCOUNTS_LOGINS = `${API_V3_URL}/account_logins`;
export const UPDATE_CONTACT_AND_PHONE_API_PATH = `${API_V3_URL}/update_contact_and_phone`;
export const AGREED_TO_TERMS = `${API_V3_URL}/agreed_to_terms`;
export const GET_TASKS_OF_ACCOUNT = `${API_V3_URL}/get_tasks_of_account`;
export const SECURITY_QUESTIONS = `${API_V3_URL}/security_questions`;
export const GET_PROFILE_DATA_API_PATH = `${API_V3_URL}/profile`;
export const UPDATE_PROFILE_API_PATH = `${API_V3_URL}/update_profile`;
export const UPDATE_PROFILE_AVATAR = `${API_V3_URL}/update_avatar`;
export const REMOVE_PROFILE_PIC = `${API_V3_URL}/remove_profile_pic`;
export const CHECK_CURRENT_PASSWORD_API_PATH = `${API_V3_URL}/check_current_password`;
export const PROFILE_CHANGE_PASSWORD_API_PATH = `${API_V3_URL}/profile_change_password`;
export const SHOW_DOCUMENT_API_PATH = `${API_V3_URL}/get_link`;
export const LOGIN_WITH_MAGIC_LINK_API_PATH = `${API_V3_URL}/login_with_magic_link`;
export const CONTACT_CARD_DETAILS = `${API_V3_URL}/get_client_card`;
export const REACTIVATE_ACCOUNT = `${API_V3_URL}/reactivate_account`;
export const GET_TEAM_MEMBERS_OF_ACCCOUNT = `${API_V3_URL}/get_team_members_of_account`;
export const ADD_OR_DELETE_TEAM_MEMBERS = `${API_V3_URL}/team_members`;
export const GET_ALL_EMPLOYEES = `${API_V3_URL}/employees`;
export const SEND_INVITE_LINK = `${API_V3_URL}/invite_to_liscio`;
export const SEND_FORGOT_LINK = `${API_V3_URL}/invite_or_forgot_email`;
export const DELETE_CONTACT_RELATED_DATA = `${API_V3_URL}/delete_contact_related_data`;
export const GET_ALL_CPAUSERS = `${API_V3_URL}/user_list`;
export const CHECK_LINK_VALIDITY = `${API_V3_URL}/check_link_validation`;
export const GET_CPA_LOGO_API_PATH = `${API_V3_URL}/get_cpa_logo_from_name_before_login`;
export const EMPLOYEE = `${API_V3_URL}/employees`;
export const REPLACE_DOCUMENT_URL = `${API_V3_URL}/get_document_url`;
export const GET_DOWNLOAD_URL = `${API_V3_URL}/get_download_url`;
export const REPLACE_AGREEMENT_URL = `${API_V3_URL}/get_agreement_url`;
export const GET_TRANSACTION_HISTORY = `${API_V3_URL}/get_transaction_history/`;
export const UPDATE_ALERT_STATUS = `${API_V3_URL}/update_alert`;
export const REOPEN_TASK_API_PATH = `${API_V3_URL}/reopen_task`;
export const DEACTIVATE_EMPLOYEE = `${API_V3_URL}/deactivate_employee`;
export const REACTIVATE_EMPLOYEE = `${API_V3_URL}/reactivate_employee`;
export const GET_STAFF_PERSON_API_PATH = `${API_V3_URL}/get_staf_person`;
export const GET_ENGAGEMENT_LETTER_API_PATH = `${API_V3_URL}/engagements/new.json`;
export const GET_NOTIFICATIONS = `${API_V3_URL}/get_notifications`;
export const SERVICE_WORKER = `${SERVER_URL}/api/v1/update_notification_credentials`;
export const GET_TEMPLATE_AND_REPLACE_VARS_API_PATH = `${API_V3_URL}/get_template_content_and_replace_var`;
export const SIGN_DOC_API_PATH = `${API_V3_URL}/sign_document_and_generate_pdf`;
export const SEARCH_CONTACTS_FOR_BULKINVITE = `${API_V3_URL}/search_for_bulk_invitation`;
export const SEND_BULKINVITES = `${API_V3_URL}/bulk_invite`;
export const SEARCH_FOR_BULKENGAGEMENTLETTER = `${API_V3_URL}/search_for_bulk_engagement_letter`;
export const CANCEL_TASKS_DOCUMENTS = `${API_V3_URL}/cancel_task`;
export const CANCEL_NOTES_DOCUMENTS = `${API_V3_URL}/cancel_note`;
export const CREATE_SESSION_AGREEMENTS_FOR_BULK_SIGNATURE = `${API_V3_URL}/create_session_agreements`;
export const GET_SESSION_AGREEMENT = `${API_V3_URL}/get_session_agreement`;
export const UPDATE_SESSION_AGREEMENT = `${API_V3_URL}/update_session_agreement`;
export const REMOVE_SESSION_AGREEMENT = `${API_V3_URL}/remove_session_agreement`;
export const SEND_BULK_ENG_LETTER = `${API_V3_URL}/send_bulk_engagement_letters`;
export const GET_BULK_TEMPLATE_AND_REPLACE_VARS_API_PATH = `${API_V3_URL}/get_template_content_bulk`;
export const SEARCH_CONTACTS_FOR_BULKMESSAGE = `${API_V3_URL}/search_for_bulk_new_message`;
export const GET_DEFAULT_MESSAGE_RECIPIENT = `${API_V3_URL}/get_default_recipient`;
export const ACCOUNT_BANK_ACCOUNTS = `${API_V3_URL}/bank_accounts`;
export const SEND_BULKMESSAGE = `${API_V3_URL}/new_bulk_message`;
export const GET_TO_DO_TASK_DATA = `${API_V3_URL}/get_to_do_task_data`;
export const GET_TO_DO_CONTACTS_OF_ACCOUNT_API_PATH = `${API_V3_URL}/get_to_do_contacts_of_accounts`;
export const SEARCH_TO_DO_CONTACTS = `${API_V3_URL}/get_to_do_contact_search`;
export const SEARCH_TO_DO_ACCOUNTS = `${API_V3_URL}/get_to_do_account_search`;
export const UPDATE_TODO_TASK = `${API_V3_URL}/update_task_to_do`;
export const CHANGE_ASSIGNEE = `${API_V3_URL}/change_assignee`;
export const GENERATE_ONBOARD_UPDATE_MESSAGE = `${API_V3_URL}/auto_generate_onboard_message`;
export const GET_MESSAGES_OF_ACCOUNT = `${API_V3_URL}/get_messages_of_account`;
export const GET_MESSAGES_OF_CONTACT = `${API_V3_URL}/get_messages_of_contact`;
export const GET_CONT_EMP_OF_ACCOUNT_API_PATH = `${API_V3_URL}/get_emp_and_contact_of_account`;
export const MARK_ALL_READ = `${API_V3_URL}/mark_all_read`;
export const BULK_REASSIGN_OWNER = `${API_V3_URL}/bulk_reassignment`;
export const GET_INVITE_EMAIL = `${API_V3_URL}/get_email`;
export const DELETE_MESSAGE = `${API_V3_URL}/delete_message`;
export const LOGGED_IN_AS = `${API_V3_URL}/transition_out`;
export const SWITCH_BACK = `${API_V3_URL}/switch_back`;
export const LOGOUT = `${API_V3_URL}/logout`;
export const GET_TERM_CONDITIONS = `${API_V3_URL}/get_terms_and_conditions`;
export const UPDATE_TERMS = `${API_V3_URL}/update_agreed_to_terms`;
export const TEXT_LOGS = `${API_V3_URL}/get_text_logs`;
export const MESSAGE_MASS_ARCHIVE = `${API_V3_URL}/mass_archive`;
export const TASK_MASS_ARCHIVE = `${API_V3_URL}/mass_archive_tasks`;
export const TWO_FACTOR = `${API_V3_URL}/two_factor`;
export const TWO_FACTOR_BACKUP = `${API_V3_URL}/two_factor_backup`;
export const SIGN_IN_MFA = `${API_V3_URL}/sign_in_mfa`;
export const BACKUP_CODE = `${API_V3_URL}/backup_code`;
export const LITE_FILE_LISTING = `${API_V3_URL}/lite_file_listing`;
export const CREATE_RECORDS = `${API_V3_URL}/create_records`;
export const LITE_UPLOAD_FILES = `${API_V3_URL}/lite_upload_files`;
export const LISCIO_MAGIC_LINK = `${API_V3_URL}/liscio_magic_link`;
export const CHECK_ACCOUNT = `${API_V3_URL}/check_account`;
export const CHECK_IF_VALID_URL = `${API_V3_URL}/check_if_valid_url`;
export const GET_RECURRING_EXPECTED_DUE_DATE = `${API_V3_URL}/get_task_expected_due_date`;
export const SEARCH_FOR_BULK_DOWNLOAD_INVITATION = `${API_V3_URL}/search_for_bulk_download_invitation`;
export const BULK_DOWNLOAD_INVITE = `${API_V3_URL}/bulk_download_invite`;
export const ARCHIVE_FILES = `${API_V3_URL}/arhive_files`;
export const VIEW_NOTIFICATION = `${API_V3_URL}/view_notification`;
export const MULTIPLE_FILES_UPLOAD_FOR_SHARING = `${API_V3_URL}/multiple_files_upload_for_sharing`;
export const SHARE_PRIVATE_FILES = `${API_V3_URL}/share_private_files`;
export const GET_FILE_SOURCES_DATA = `${API_V3_URL}/get_file_sources_data`;
export const DELETE_RECIPIENTS = `${API_V3_URL}/delete_recipients`;
export const ADD_MORE_RECIPIENT = `${API_V3_URL}/add_more_recipient`;
export const DELETE_FILE_AND_ASSOC = `${API_V3_URL}/delete_file_and_assoc`;
export const ADD_NEW_FILE_TO_TASK = `${API_V3_URL}/add_existing_document_to_task`;
export const CREATE_NEW_EDOC_REQUEST = `${API_V3_URL}/edoc_requests`;
export const WEBHOOK_RESPONSE = `${API_V3_URL}/webhook_response`;
export const GET_TEMPLATES = `${API_V3_URL}/esign_templates`;
export const GET_AGREEMENT_PREVIEW = `${API_V3_URL}/get_agreement_preview`;
export const GET_AUDIT_TRAIL = `${API_V3_URL}/get_audit_trail`;
export const SEND_ADOBE_CODE = `${API_V3_URL}/adobe_code`;
export const ADOBE_INTEGRATION_STATUS = `${API_V3_URL}/adobe_integration_status`;
export const GET_TEMPLATE_PREVIEW = `${API_V3_URL}/get_template_preview`;
export const GET_AGREEMENT_FROM_ADOBE = `${API_V3_URL}/get_agreement_from_adobe`;
export const CREATE_ADOBE_ACCOUNT = `${API_V3_URL}/create_adobe_admin_account`;
export const CREATE_ADOBE_TEMPLATE = `${API_V3_URL}/create_adobe_template`;
export const DELETE_ADOBE_TEMPLATE = `${API_V3_URL}/delete_adobe_template`;
export const DISCONNECT_ADOBE = `${API_V3_URL}/disconnect_adobe`;
export const ADOBE_CLIENT_ID = process.env.REACT_APP_ADOBE_CLIENT_ID;
export const CREATE_BULK_EDOC_REQUESTS = `${API_V3_URL}/create_bulk_edoc_requests`;
export const ADOBE_WEB_ACCESS_URL = `${API_V3_URL}/adobe_web_access_url`;
export const SEARCH_CONTACTS_FOR_BULKEDOC = `${API_V3_URL}/search_for_bulk_edoc_request`;
export const PIN_UNPIN_THREAD_MESSAGE = `${API_V3_URL}/pin_unpin_thread_message`;
export const PIN_UNPIN_MESSAGE = `${API_V3_URL}/pin_unpin_message`;
export const REPLACE_TAGS = `${API_V3_URL}/replace_tags`;
export const LOGOUT_FROM_DEVICE = `${API_V3_URL}/logout_from_device`;
export const ZOOM_CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID;
export const ZOOM_INTEGRATION_STATUS = `${API_V3_URL}/zoom_integration_status`;
export const SEND_ZOOM_CODE = `${API_V3_URL}/zoom_code`;
export const ZOOM_MEETING = `${API_V3_URL}/zoom_meeting`;
export const GET_DOCUMENT_DETAILS = `${API_V3_URL}/get_document_details`;
export const LISCIO_VAULT_FILES = `${API_V3_URL}/liscio_vault_files`;
export const DISCONNECT_ZOOM = `${API_V3_URL}/remove_zoom_user`;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
export const LOGIN_WITH_CODE = `${API_V3_URL}/login_with_code`;
export const GET_NEW_CONTACTS = `${API_V3_URL}/get_new_contacts`;
export const INVITE_RELATED_CONTACT_TO_LISCIO = `${API_V3_URL}/invite_related_contact_to_liscio`;
export const CREATE_CONTACT_AND_RELATION = `${API_V3_URL}/create_contact_and_relation`;
export const GET_EDOC_REQUESTS = `${API_V3_URL}/get_edoc_requests`;
export const GET_REL_CONTACTS_AND_CST_OF_ACCOUNTS = `${API_V3_URL}/get_rel_contacts_and_cst_of_accounts`;
export const GET_ASSIGNED_ACCOUNTS_OF_EMPLOYEE = `${API_V3_URL}/get_assigned_accounts_of_employee`;
export const GET_UNASSIGNED_ACCOUNTS_OF_EMPLOYEE = `${API_V3_URL}/get_unassigned_accounts_of_employee`;
export const DELETE_CST_MEMBER_USING_ACCOUNT_AND_EMPLOYEE = `${API_V3_URL}/delete_team_members`;
export const CPA_TAGS = `${API_V3_URL}/tags`;
export const UPDATE_TAGS_SORT_ORDER = `${API_V3_URL}/update_tags_sort_order`;
export const GET_CPA_PREFERENCES = `${API_V3_URL}/get_cpa_preference_type`;
export const DESKTOP_APPS = `${API_V3_URL}/desktopapp_download`;
export const THIRD_PARTY_APPS = `${API_V3_URL}/third_party_apps`;
export const OAUTH_ACCESS_TOKENS = `${API_V5_URL}/firms/app_connections/oauth/access_tokens`;
export const SWITCH_CPA = `${API_V3_URL}/switch_cpa`;
export const DESKTOP_APP_VERSION = `${API_V3_URL}/desktopapp_updates`;
export const CUSTOM_TEMPLATES = `${API_V3_URL}/custom_templates`;
export const UPDATE_TEMPLATES_SORT_ORDER = `${API_V3_URL}/update_custom_templates_sort_order`;
export const IMAGES = `${API_V3_URL}/images`;
export const IMAGES_URLS = `${API_V3_URL}/images_urls`;
export const GET_TEMPLATES_FOR_CREATION = `${API_V3_URL}/get_templates_for_creation`;
export const CREATE_DOC_FROM_TEMPLATE_IDS = `${API_V3_URL}/create_doc_from_template_ids`;
export const REMOVE_ALL_SESSION_AGREEMENTS = `${API_V3_URL}/remove_all_session_agreements`;
export const EDOC_TEMPLATE_FIELDS = `${API_V3_URL}/get_template_fields_info`;
export const STRIPE_ACCOUNT = `${API_V3_URL}/cpa_stripe_accounts`;
export const PAYMENT_TERMS = `${API_V3_URL}/payment_terms`;
export const PAYMENTS = `${API_V3_URL}/payments`;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const CUSTOMER = `${API_V3_URL}/customers`;
export const KARBON_INTEGRATION_STATUS = `${API_V3_URL}/karbon_integration_status`;
export const KARBON_CONNECTION = `${API_V3_URL}/connection`;
export const KARBON_IMPORT_DATA = `${API_V3_URL}/import_data`;
export const DISCONNECT_KARBON = `${API_V3_URL}/disconnect_karbon`;
export const LISCIO_KARBON_ACCOUNTS = `${API_V3_URL}/liscio_karbon_accounts`;
export const PAYMENT_DETAILS = `${API_V3_URL}/payment_details`;
export const PAYMENT_ESTIMATES = (accountId) =>
  `${API_V5_URL}/accounts/${accountId}/payment_estimates`;
export const CREATE_PAYMENT_ESTIMATES = `${API_V5_URL}/firms/payment_estimates`;

export const SEARCH_IN_KARBON = `${API_V3_URL}/search_in_karbon`;
export const SEARCH_IN_LISCIO = `${API_V3_URL}/search_in_liscio`;
export const DELINK = `${API_V3_URL}/delink`;
export const LINK_ACCOUNT = `${API_V3_URL}/link_account`;
export const CREATE_IN_KARBON = `${API_V3_URL}/create_in_karbon`;
export const CREATE_IN_LISCIO = `${API_V3_URL}/create_in_liscio`;
export const LISCIO_KARBON_CONTACTS = `${API_V3_URL}/liscio_karbon_contacts`;
export const CONTACT_SEARCH_IN_KARBON = `${API_V3_URL}/contact_search_in_karbon`;
export const CONTACT_SEARCH_IN_LISCIO = `${API_V3_URL}/contact_search_in_liscio`;
export const LINK_CONTACT = `${API_V3_URL}/link_contact`;
export const DELINK_CONTACT = `${API_V3_URL}/delink_contact`;
export const ABANDON_KARBON = `${API_V3_URL}/abdon_karbon`;
export const CREATE_IN_KARBON_CONTACT = `${API_V3_URL}/create_in_karbon_contact`;
export const CREATE_IN_LISCIO_CONTACT = `${API_V3_URL}/create_in_liscio_contact`;
export const ACCOUNT_REPORTS = `${API_V3_URL}/account_reports`;
export const PIN_BASED_AUTH_SMART_VAULT = `${API_V5_URL}/firms/app_connections/smart_vault/access_tokens`;
export const DMS_FILE_EXPORT = `${API_V5_URL}/firms/app_connections/dms/file_exports`;
export const USER_VIEWS = `${API_V3_URL}/user_views`;
export const GET_ALL_MESSAGES_VIEW = `${API_V3_URL}/get_all_messages_view`;
export const CALENDLY = `${API_V3_URL}/calendly`;
export const QB_CLIENT_ID = process.env.REACT_APP_QB_CLIENT_ID;
export const QB = `${API_V3_URL}/quickbooks`;
export const QB_SYNC = `${API_V3_URL}/qb_sync`;
export const QB_ACCOUNTS = `${API_V3_URL}/qb_accounts`;
export const QB_LINKED_ACCOUNTS = `${API_V3_URL}/qb_linked_accounts`;
export const QB_INVOICE_ITEMS = `${API_V3_URL}/qb_invoice_items`;
export const QB_INVOICE = `${API_V3_URL}/qb_invoice`;
export const QB_INVOICE_PDF = `${API_V3_URL}/qb_invoice_pdf`;
export const GENERATE_QB_ACCOUNTS_CSV_REPORT = `${API_V3_URL}/generate_qb_accounts_csv_report`;
export const SYNC_EMAILS = `${API_V3_URL}/sync_emails`;
export const USER_EMAILS = `${API_V3_URL}/user_emails`;
export const USER_EMAILS_RESYNC = `${API_V3_URL}/user_emails/resync`;
export const LINK_EMAILS = `${API_V3_URL}/link_emails`;

/* V5 Linked Emails */
export const ENTITY_EMAIL_THREADS = `${API_V5_URL}/firms/emails/entity_email_threads`;
export const ENTITY_EMAIL_LINKS = `${API_V5_URL}/firms/emails/entity_email_links`;

export const STRIPE_PAYOUTS = `${API_V3_URL}/payouts`;
export const STRIPE_PAYOUTS_CSV = `${API_V3_URL}/stripe_payouts_csv`;
export const FORWARD_OUTLOOK_MAIL = `${API_V3_URL}/forward_outlook_mail`;
export const ACCOUNTS_ARCHIVE = `${API_V3_URL}/bulk_archive`;
export const ACCOUNTS_UNARCHIVE = `${API_V3_URL}/bulk_unarchive`;
export const SEND_OUTLOOK_NEW_OR_DRAFT_MAIL = `${API_V3_URL}/send_outlook_new_or_draft_mail`;
export const UPDATE_EMAILS_STATUS = `${API_V3_URL}/update_emails_status`;
export const EMAIL_ATTACHMENTS = `${API_V3_URL}/email_attachments`;
export const USER_EMAIL_SETTINGS = `${API_V3_URL}/user_email_settings`;
export const AWAY_FOR_OUT_OF_OFFICE = `${API_V3_URL}/away_for_out_of_office`;
export const UNREAD_EMAIL = `${API_V3_URL}/unread_email`;
export const ZIP_ATTACHMENT_DATA = `${API_V3_URL}/zip_attachment_data`;
export const UNREAD_MESSAGE = `${API_V3_URL}/update_message_read_status`;
export const ACCOUNTS_INFOCUS_DATA = `${API_V3_URL}/account_infocus_detail`;
export const ACTIVITY_DETAIL = `${API_V3_URL}/activity_detail`;
export const WORKFLOW_REQUEST_PDF = `${API_V5_URL}/workflow`;
export const ENCRYPTED_FIELD = `${API_V5_URL}/encrypted_fields`;
export const TRUSTED_DOMAINS = `${API_V5_URL}/firms/admin/trusted_domains`;
export const SIGN_IN = `${API_V5_URL}/auth/sign_in`;
export const SIGN_IN_SSO = `${API_V5_URL}/auth/sign_in_sso`;
export const CPA_AUTHORIZATION = `${API_V5_URL}/auth/cpa_authorization`;
export const MAGIC_LINKS = `${API_V5_URL}/auth/magic_links`;
export const RESET_PASSWORD = `${API_V5_URL}/auth/password_resets`;
export const CHANGE_PASSWORD_V5 = `${API_V5_URL}/auth/passwords`;

/* EMPLOYEE CONTACTS */
export const GET_EMP_AND_CONTACT_API_PATH = `${API_V3_URL}/get_emp_and_contact`;
export const REQUEST_FILTERS_EMPLOYEES_AND_CONTACTS = `${API_V5_URL}/workflow/requests/filtering/employees_and_contacts`;
export const GET_CONTACTS_OF_ACCOUNT_API_PATH = `${API_V3_URL}/get_contacts_of_accounts`;

/* EMPLOYEE ACCOUNTS */
export const GET_ACCOUNTS_OF_CONTACT_API_PATH = `${API_V3_URL}/get_accounts_of_contact`;

/* TASKS */
export const GET_TASK_ACCOUNTS = `${API_V3_URL}/get_initial_task_data`;

/* MIXPANEL EVENTS */
export const MIXPANEL_LOGIN_EVENT = 'Login';
export const MIXPANEL_MESSAGE_EVENT = 'Message';
export const MIXPANEL_URL_EVENT = 'Url';
export const MIXPANEL_FILE_UPLOAD_EVENT = 'FileUpload';
export const MIXPANEL_TASK_EVENT = 'Task';
export const MIXPANEL_COMMENT_EVENT = 'Comment';
export const MIXPANEL_ACCOUNT_PAGE_EVENT = 'AccountPage';
export const MIXPANEL_EDOC_EVENT = 'Edoc';
export const MIXPANEL_INVOICE_CREATE_EVENT = 'Billing/invoice/create';
export const MIXPANEL_INVOICE_EDIT_EVENT = 'Billing/invoice/edit';
export const MIXPANEL_INVOICE_VOID_EVENT = 'Billing/invoice/void';
export const MIXPANEL_INVOICE_PAID_EVENT = 'Billing/invoice/paid';
export const MIXPANEL_SIGNUP_EVENT = 'Signup';
export const MIXPANEL_INVITE_EVENT = 'Invite';
export const MIXPANEL_EMAIL_EVENT = 'Email';
export const MIXPANEL_EMAIL_READ_EVENT = 'Email/read';
export const MIXPANEL_EMAIL_ACTION_EVENT = 'Email/action';
export const MIXPANEL_EMAIL_ADD_TO_ACCOUNT_EVENT = 'Email/account';
export const MIXPANEL_EMAIL_ADD_TO_TASK_EVENT = 'Email/task/add';
export const MIXPANEL_EMAIL_ADD_TO_CONTACT_EVENT = 'Email/contact';
export const MIXPANEL_CREATE_CONTACT_EVENT = 'Contact/create';
export const MIXPANEL_ARCHIVE_CONTACT_EVENT = 'Contact/archive';
export const MIXPANEL_EDIT_CONTACT_EVENT = 'Contact/edit';
export const MIXPANEL_CREATE_ACCOUNT_EVENT = 'Account/create';
export const MIXPANEL_EDIT_ACCOUNT_EVENT = 'Account/edit';

/* SMS ENPOINTS */
export const SMS_THREADS = `${url}/text-messaging/threads`;
export const SMS_MESSAGES = `${url}/text-messaging/messages`;
export const SMS_STATUS = `${url}/text-messaging/status`;
export const SMS_ORDER = `${url}/text-messaging/order_phone_number`;
export const SMS_PHONE_LINES = `${url}/text-messaging/phone_lines`;
export const SMS_ADD_TO_VAULT = `${url}/text-messaging/documents`;
export const SMS_ARCHIVE_THREAD = `${url}/text-messaging/archive`;
export const SMS_PROVISION_STATUS = `${API_V5_URL}/text_messaging/text_messages/status`;
export const SMS_BRAND_REGISTRATION = `${API_V5_URL}/text_messaging/brand_registrations`;

/* ACTIVE STORAGE FILE UPLOADS */
export const ACTIVESTORAGE_URL = `${API_V3_URL}/direct_uploads`;

/* WORKFLOWS */
export const WORKFLOWS = `${url}/workflows/workflows`;
export const WORKFLOWS_CLIENT = `${url}/workflows/client_workflows`;
export const WORKFLOWS_ACTION_ITEMS = `${url}/workflows/workflow_action_items`;
export const WORKFLOWS_REQUESTS = `${url}/workflows/workflow_requests`;
export const WORKFLOWS_RPA_REQUESTS = `${url}/workflows/rpa_requests`;

export const WORKFLOW_ZIP_PACKAGE = (id) =>
  `${API_V5_URL}/workflow/${id}/zip_package`;

/* LEDGER SETUP */
export const QB_GET_BANK_DATA = `${API_V3_URL}/qb_banks`;

/* OWNERS */
export const GET_OWNERS = `${API_V3_URL}/get_would_be_owner`;

/* INVOICE */
export const GET_INVOICE_ITEMS = `${API_V3_URL}/invoice_items`;
export const QB_INVOICE_TERMS = `${API_V3_URL}/invoice_terms`;
export const INVOICE_RETRIEVAL_AND_CREATION = `${API_V3_URL}/invoices`;
export const GET_CPA_INVOICES_SETTINGS = `${API_V3_URL}/cpa_invoices_settings`;
export const GET_ACCOUNTS_FOR_INVOICE = `${API_V3_URL}/get_accounts_for_invoice`;
export const GET_CONTACTS_FOR_INVOICE = `${API_V3_URL}/get_contacts_for_invoice`;
export const GET_COUNT_INVOICES_THAT_NEED_ATTENTION = `${API_V3_URL}/get_invoices_count_that_need_attention`;
export const GET_INVOVICE_TERMS_AND_ITEMS = `${API_V3_URL}/get_invoice_terms_and_items`;

/* TAX DELIVERY */
export const FIRMS_SIGNERS = `${API_V5_URL}/firms/signers`;
export const KBA_REQUIREMENTS = `${API_V5_URL}/firms/kba_requirements`;
export const BATCH_ASSIGN_CONTACT = `${API_V5_URL}/firms/batch_assign_contact`;
/* dashboard route */
export const DASHBOARD_ROUTE = '/dashboard';

/* INVOICE MODAL MODES */
export const EDIT = 'EDIT';
export const DEFAULT = 'DEFAULT';
export const RECURRING = 'RECURRING';
export const EDIT_RECURRING = 'EDIT RECURRING';

/* RECURRING INVOICE END DATE */
export const NEVER = 'Never';
export const END_DATE = 'End Date';

/* BILLING TABS */
export const INVOICES_TAB = 'Invoices';
export const RECURRING_TAB = 'Recurring';
export const INVOICE_HISTORY_TAB = 'Invoice_history';
export const MONTHLY = 'Monthly';
export const MONTHS = ' Months';

/** ALERT TYPES */
export const RED = 'red';
export const BLUE = 'blue';

/** ALERT MESSAGES */
export const YOUR_PAYMENT_HAS_FAILED = 'Your payment has failed. ';
export const YOUR_ACCOUNT_IS_OVERDUE = 'Your account is overdue. ';
export const AUTHORIZE_PAYMENT_FOR_NEW_RECURRING_INVOICES =
  'Authorize payment for new recurring invoices';
export const PAYMENT_FOR_THIS_ACCOUNT_HAS_FAILED =
  'Payment for this account has failed. ';
export const THIS_ACCOUNT_IS_OVERDUE = 'This account is overdue! ';
export const UPDATE_PAYMENT_NOW = 'Update payment now.';
export const MAKE_PAYMENT_NOW = 'Make payment now.';
export const CONTACT_CLIENT = 'Contact client.';

/** BILLING FILTERS */
export const STATUS = 'STATUS';
export const INVOICE_TYPE = 'INVOICE TYPE';
export const DATE_RANGE = 'DATE';
export const TEXT_SEARCH = 'TEXT SEARCH';
export const ACCOUNT_LABEL = 'ACCOUNT';
export const OWNER = 'OWNER';

/** INVOICE FORM KEYS */
export const ACCOUNT_KEY = 'account';
export const CONTACT_KEY = 'contact';
export const INVOICE_NUMBER_KEY = 'invoiceNumber';
export const OWNER_KEY = 'owner';
export const ITEM_KEY = 'item';
export const INVOICE_DATE_KEY = 'invoiceDate';
export const DUE_DATE_KEY = 'dueDate';
export const AMOUNT_KEY = 'amount';
export const DESCRIPTION_KEY = 'customTemplateInput';
export const DESCRIPTION_INPUT_KEY = 'descriptionInput';
export const PAYMENT_METHODS_KEY = 'paymentMethods';

/** COMMON */
export const DUE_DATE = 'Due Date';
export const SELECT = 'Select';
export const CANCEL = 'Cancel';
export const FILES = 'Files';
export const NO_FILES = 'No files to show';
export const CREATE_NOTE = 'Create Note';
export const CREATE_TASK = 'Create Task';
export const MARK_UNREAD = 'Mark as unread';
export const MARK_UNREAD_HERE = 'Mark as unread from here';
export const UPDATE_SUCCESS = 'Updated successfully.';

/** SUCCESS/ERROR NOTIFICATIONS */
export const TOAST_SUCCESS = 'success';
export const TOAST_FAILURE = 'error';
export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong. We apologize for the inconvenience.';
export const DEFAULT_SUCCESS_MESSAGE = 'Success!';
export const toastDefaultMessageMapper = {
  [TOAST_SUCCESS]: DEFAULT_SUCCESS_MESSAGE,
  [TOAST_FAILURE]: DEFAULT_ERROR_MESSAGE,
};

/** SIDEBAR RELATED CONSTANTS */
export const FIRM_ADMIN = 'firm_admin';
export const LOGGED_IN_TYPE = 'loggedInType';
export const SWITCHED_USER = 'switchedUser';
export const ACT_AS_KEY = 'act_as';
export const BULK_ACTIONS_SUB_MENU = '/bulkinvite';
export const ADD_NEW_SUB_MENU = '/addnew';
export const PROFILE_SETTINGS_MENU = '/profile_settings';
export const ACCOUNTS_URL = '/accounts';
export const TASK_DETAIL = '/task/detail';
export const ACCOUNT_DETAILS = '/accountdetails';
export const CONTACT_DETAILS = '/contactdetails';
export const ALL_TASKS = '/all_tasks';
export const CONTACTS_URL = '/contacts';
export const STAR_SUB_MENU = '/star';
export const INBOX_URL = '/inbox';
export const ALERT_URL = '/alert';
export const SENT_URL = '/sent';

/** INVOICE STATUSES */
export const OVERDUE = 'overdue';
export const UNPAID = 'unpaid';
export const FAILED = 'failed';
export const PAID = 'paid';
export const VOID = 'void';
export const EDIT_INVOICE = 'edit';
export const TASK_CLOSED = 'Closed';
export const MANAGE_RECURRING_OPTION = 'Manage Recurring Invoice';

/** INFOCUS TABS */
export const INFOCUS_CLIENT_TASK = 'clientTask';
export const INFOCUS_FIRM_TASK = 'firmTask';
export const INFOCUS_FILES = 'files';
export const INFOCUS_INVOICES = 'invoices';

/** TASK TYPES */
export const INVOICE = 'Invoice';
export const TASK = 'Task';

/** INVOICE SUB MENU */
export const NEW_INVOICE_SUB_MENU = 'New Invoice';
export const RECURRING_CHARGE_SUB_MENU = 'Recurring Charge';

/** BILLING COLUMNS */
export const ACCOUNT_COL = 'ACCOUNT';
export const OWNER_COL = 'OWNER';
export const STATUS_COL = 'STATUS';
export const PAYMENT_METHOD_COL = 'PAYMENT METHOD';
export const INVOICE_TYPE_COL = 'INVOICE TYPE';
export const DUE_DATE_COL = 'DUE DATE';
export const AMOUNT_COL = 'AMOUNT';
export const LAST_ACTIVITY_COL = 'LAST ACTIVITY';
export const START_DATE_COL = 'START DATE';
export const END_DATE_COL = 'END DATE';
export const FREQUENCY_COL = 'FREQUENCY';
export const NEXT_CHARGE_COL = 'NEXT CHARGE';

/** SORT ORDER */
export const DESC = 'desc';
export const ASC = 'asc';

/** APP URLS */
export const CLIENT_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=me.liscio.lisciopro';
export const CLIENT_IOS_URL =
  'https://apps.apple.com/us/app/liscio-pro/id1411993078';
export const FIRM_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=me.liscio.firmdash';
export const FIRM_IOS_URL =
  'https://apps.apple.com/us/app/firmdash/id1454472980';

/* INVOICES FOR DASHBOARD */
export const GET_INVOICES_FOR_DASHBOARD = `${API_V3_URL}/get_unpaid_invoices_and_unauthorized_parents`;

/** ADD NEW MENU */
export const FILE = 'File';

/** ICON LOCATION IN BUTTON */
export const BUTTON_ICON_LOCATION_FRONT = 'FRONT';
export const BUTTON_ICON_LOCATION_BACK = 'BACK';

/** ICON LOCATION IN BUTTON */
export const listMode = 'list';
export const splitMode = 'split';
export const detailedMode = 'detailed';

/** STRIPE ACCOUNT STATUS */
export const ACTIVE_STRIPE_ACCOUNT = 'active';
export const NO_STRIPE_ACCOUNT = 'no_account';

/** SMS CONSTANTS */
export const GET_SMS_CONTACT_PHONES = `${API_V3_URL}/get_contact_phones`;
export const SMS_NO_MESSAGES =
  'You have no active SMS messages. New sent or received SMS messages will appear here.';

/** SENTRY CONSTANTS */
export const REACT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

/** cookies */
export const TRUSTED_LISCIO_TLDS = [
  'liscio.me',
  'lisciostaging.com',
  'devliscio.com',
];

// Subdomains signifying app sites
export const APP_SUBDOMAINS = [
  'app',
  // NOTE: The below seem to be deprecated
  'appdev',
  'appuat',
];
export const FIRM_SUBDOMAINS = ['firm'];

/* Deployed Development Builds */
export const IS_DEPLOYED_REVIEW_APP =
  process.env.REACT_APP_DEPLOYED_REVIEW_APP ||
  process.env.REACT_APP_IS_NETLIFY_PREVIEW ||
  false;

export const REACT_APP_FAKE_HOSTNAME =
  process.env.REACT_APP_FAKE_HOSTNAME || '';
export const REACT_APP_FAKE_COMPANY =
  process.env.REACT_APP_FAKE_COMPANY || 'uatolympus.lisciostaging.com';

export const MOBILE_URL = process.env.REACT_APP_MOBILE_URI;
export const FIRM_SITE_URL =
  process.env.REACT_APP_FIRM_SITE_URI || 'https://firm.lisciostaging.com';

/** Rudderstack Categories */
export const ACTIVITY_TIMELINE_EXPRIMENT = 'ActivityTimelineExperiment';
