import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom-latest";
import { useFlags } from "launchdarkly-react-client-sdk";
import { rudderPageView } from "./tracking";

export function useRudderStack() {
  const location = useLocation();
  const { trackPageViews } = useFlags();
  const previousLocationRef = useRef('INITIAL_LOAD');

  useEffect(() => {
    if (trackPageViews) {
      rudderPageView({ ...location, previousLocation: previousLocationRef.current });
      
      previousLocationRef.current = location.pathname;
    }
  }, [location, trackPageViews])
}