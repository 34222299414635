import { Stack, Typography } from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';

import { BackupOutlined } from '@mui/icons-material';
import yn from 'images/icons/yn.svg';
import squiggly from 'images/icons/squiggle.svg';
import {
  WorkflowItemFragment,
  WorkflowRequestFragment,
  WorkflowRequestItemFragment,
} from 'gql/graphql';
import { TaxDeliveryItem } from 'components/Requests/components/TaxDelivery/components/TaxDeliveryItem/TaxDeliveryItem';

type WorkflowSectionItemProps = {
  workflowRequest: WorkflowRequestFragment;
  workflowItem: WorkflowItemFragment;
  workflowRequestItem: WorkflowRequestItemFragment;
  position: number;
  subPosition: number;
};

export const WorkflowPreviewListItem = ({
  workflowItem,
  workflowRequestItem,
  workflowRequest,
  position,
  subPosition,
}: WorkflowSectionItemProps) => {
  const itemTypeMap = {
    [WorkflowItemType.Boolean]: (
      <>
        <img alt="" src={yn} style={{ marginRight: '8px' }} />
        <Typography variant="body1">Y/N</Typography>
      </>
    ),
    [WorkflowItemType.Text]: (
      <>
        <img alt="" src={squiggly} style={{ marginRight: '8px' }} />

        <Typography variant="body1">Short Answer</Typography>
      </>
    ),
    [WorkflowItemType.Upload]: (
      <>
        <BackupOutlined style={{ marginRight: '8px' }} />

        <Typography variant="body1">File Upload</Typography>
      </>
    ),
    [WorkflowItemType.Reviewable]: (
      <TaxDeliveryItem
        workflowRequestItem={workflowRequestItem}
        assignees={workflowRequest.assignedContacts}
      />
    ),
    [WorkflowItemType.Signable]: (
      <TaxDeliveryItem
        workflowRequestItem={workflowRequestItem}
        assignees={workflowRequest.assignedContacts}
      />
    ),
    [WorkflowItemType.Payable]: (
      <TaxDeliveryItem
        workflowRequestItem={workflowRequestItem}
        assignees={workflowRequest.assignedContacts}
      />
    ),
  };

  const isConditional = subPosition > 0;

  const conditionalSX = {
    paddingLeft: '20px',
  };

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={2}
      mb={3}
    >
      <Typography
        variant="h4"
        display="block"
        maxWidth="500px"
        sx={isConditional ? conditionalSX : {}}
      >
        <Typography variant="h4" component="span" fontWeight="bold" pr={1}>
          {`${position}.`}
          {isConditional && `${subPosition}`}
        </Typography>
        {workflowItem.prompt}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="start"
        width="150px"
      >
        {itemTypeMap[workflowItem.type as WorkflowItemType]}
      </Stack>
    </Stack>
  );
};
