import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import 'babel-polyfill';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useMatch,
  useNavigationType,
  useParams,
} from 'react-router-dom-latest';
import 'bootstrap/dist/js/bootstrap.min';
import Invite from 'components/Invite/Invite';
import Login from 'components/Login/Login';
import LiscioLogin from 'components/Login/Lisciologin';
import LoginV5 from 'components/Login/LoginV5/LoginV5';
import InvalidUrl from 'components/InvalidUrl/InvalidUrl';
import CreateContact from 'components/Contacts/ContactCreation/CreateContact';
import FilePopUp from 'components/Files/FilePopup';
import MagicLinkFlagged from 'components/MagicLink/MagicLinkFlagged';
import NoteDetails from 'components/Notes/NoteDetail_updated';
// import ChangePassword from 'components/ForgotPassword/ForgotPassword';
import RemoteLogin from 'components/RemoteLogin/RemoteLogin';
import PageNotFound from 'common/PageNotFound';
import OauthRedirect from 'components/Integrations/OauthRedirect';
import EmailPaymentView from 'components/Invoice/EmailPaymentView';
import Maintanance from 'common/Maintanance';
import Dashboard from 'components/Dashboard/Dashboard';
import InboxList from 'components/Messages/Inbox/InboxList';
import NewMessageModal from 'components/Messages/NewMessageModal';
import NewMessage from 'components/Messages/NewMessage';
import CreateNotes from 'components/Notes/CreateNotes';
import NewEsign from 'components/Esign/NewEsign';
import NewTask from 'components/Tasks/Create/NewTask';
import NewAccount from 'components/Accounts/AccountCreation/NewAccount';
import AccountPage from 'components/Accounts/AccountPage';
import BulkRequestDownload from 'components/BulkActions/BulkRequestDownload_updated';
import BulkEsign from 'components/BulkActions/BulkEsign';
import BulkSignature from 'components/BulkActions/BulkSignature';
import BulkMessage from 'components/BulkActions/BulkMessage';
import BulkInvite from 'components/BulkActions/BulkInvite';
import BulkReassign from 'components/BulkActions/BulkReassign_updated';
import NotificationList from 'components/Notification/NotificationList';
import ProfileSettings from 'components/Profile/ProfileSettings';
import TaskDetail from 'components/Tasks/Edit/TaskDetail';
import AccountList from 'components/Accounts/AccountList_old';
import AccountListUpdated from 'components/Accounts/AccountList';
import AllContacts from 'components/Contacts/AllContacts';
import ContactPage from 'components/Contacts/ContactPage';
import FilesList from 'components/Files/FilesList';
import ESignList from 'components/Esign/EsignList';
import AdminList from 'components/Admin/AdminList';
import Preferences from 'components/Admin/Preferences';
import Templates from 'components/Admin/Templates';
// import TaskType from 'components/Tasks/elements/TaskType';
import TaskList from 'components/Tasks/TaskList';
import RedirectTaskDetail from 'components/Tasks/RedirectTaskDetail';
import { useRudderStack } from 'analytics-utils/useRudderStack';
import * as Sentry from '@sentry/react';
import { getAuthSession } from 'auth/session';
import { APP_SUBDOMAINS, FIRM_SUBDOMAINS } from 'constants/constants';
import { getSiteCompanyName } from 'utilities/utils';
import PrivateRoute from './PrivateRoute_updated';
import Emails from './Email';
import Billing from './Billing';
import InvoiceTaskDetails from './Billing/elements/InvoiceDetails/InvoiceTaskDetails';
import UserInfo from './Admin/UserInfo';
import CreateUser from './Admin/CreateUser';
import NewTemplate from './Admin/NewTemplate_hooks';
import NewEmail from './Emails/components/NewEmail';
import EsignTemplates from './Esign/EsignTemplates';
import NewEsignTemplate from './Esign/NewEsignTemplate';
import InviteContact from './Invite/InviteContact';
import DeviceDetail from './Profile/DeviceDetail';
import ShowDocument from './UploadedFiles/ShowDocument';
// import ForgotPassword from './ForgotPassword/ForgotPassword';
import ForgotPasswordFlagged from './ForgotPassword/ForgotPasswordFlagged';
import EditAccount from './Accounts/AccountCreation/EditAccount';
import LiscioDispatchOauth from './Integrations/LiscioDispatchOauth';
import MobileRedirect from './DeepLink/MobileRedirect';
import SMSSettingsForm from './SMS/components/SMSSettingsForm/SMSSettingsForm';
import SMSAdminPage from './SMS/SMSAdminPage';
import AdminBillingPage from './Admin/Billing/AdminBillingPage';
import { IntegrationsRoutes } from './Integrations/IntegrationsRoutes';
import { RequestsRoutes } from './Requests/requests.routes';

const SMSPage = lazy(() => import('./SMS/SMSPage'));

const App = () => {
  const { requestsPageFeature, smsLineManagementFeature, v5Login } = useFlags();
  const subdomain = getSiteCompanyName();
  const LoginComponent = useMemo(() => {
    if (v5Login) {
      return LoginV5;
    }
    // Return GLobal Login for app/firm
    if ([...APP_SUBDOMAINS, ...FIRM_SUBDOMAINS].includes(subdomain)) {
      return LiscioLogin;
    }
    return Login;
  }, [subdomain, v5Login]);

  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch(location.pathname);
  const action = useNavigationType();
  const params = useParams();

  useEffect(() => {
    const { userpilot } = window;
    if (userpilot) {
      if (!getAuthSession()) {
        // this is for showing user pilot message when user is not logged
        userpilot.anonymous();
      }
      userpilot.reload();
    }
  }, [location]);

  useRudderStack();
  return (
    <Sentry.ErrorBoundary fallback={<InvalidUrl navigate={navigate} />}>
      <Suspense fallback={<div id="loading" />}>
        <Routes>
          <Route exact path="/i/*" element={<Invite />} />
          <Route exact path="/invite/*" element={<Invite />} />
          <Route
            exact
            path="/magicLink/:id"
            element={<MagicLinkFlagged params={params} />}
          />
          <Route exact path="/ml/:id" element={<MagicLinkFlagged />} />
          <Route
            exact
            path="/lisciopro/:page/:id"
            element={<MobileRedirect />}
          />
          <Route exact path="/lisciopro/:page" element={<MobileRedirect />} />
          <Route exact path="/lisciopro" element={<MobileRedirect />} />
          <Route
            exact
            path="/firmdash/:page/:id"
            element={<MobileRedirect />}
          />
          <Route exact path="/firmdash/:page" element={<MobileRedirect />} />
          <Route exact path="/firmdash" element={<MobileRedirect />} />
          {/* <Route exact path="/cp/*" element={<ChangePassword />} /> */}
          {/* <Route exact path="/changepassword/*" element={<ChangePassword />} /> */}
          <Route exact path="/remotelogin/:id" element={<RemoteLogin />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route
            path="/login"
            element={<LoginComponent location={location} navigate={navigate} />}
          />
          <Route path="/adobe" element={<OauthRedirect />} />
          <Route path="/zoom" element={<OauthRedirect />} />
          <Route path="/google" element={<OauthRedirect />} />
          <Route path="/outlook" element={<OauthRedirect />} />
          <Route path="/onedrive" element={<OauthRedirect />} />
          <Route path="/box" element={<OauthRedirect />} />
          <Route path="/calendly" element={<OauthRedirect />} />
          <Route path="/sharepoint" element={<OauthRedirect />} />
          <Route path="/qb" element={<OauthRedirect />} />
          <Route path="/payment" element={<EmailPaymentView />} />
          <Route path="/switchcpa" element={<LoginComponent />} />
          <Route path="/maintanance" element={<Maintanance />} />
          <Route path="/glitch" element={<InvalidUrl navigate={navigate} />} />
          <Route path="/cp/:id" element={<ForgotPasswordFlagged />} />
          <Route path="/opendispatch" exact element={<LiscioDispatchOauth />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route
              index
              element={<Dashboard location={location} navigate={navigate} />}
            />
            <Route
              path="/dashboard"
              element={<Dashboard location={location} navigate={navigate} />}
            />
            {/* INBOX ROUTES */}
            {/* <Route path="all_messages" element={<InboxList />}>
          <Route path="inbox" element={<MessageType />} />
          <Route path="alert" element={<MessageType />} />
          <Route path="sent" element={<MessageType />} />
          <Route path="draft" element={<MessageType />} />
          <Route path="archived" element={<MessageType />} />
          <Route path="pinned" element={<MessageType />} />
          <Route path="all" element={<MessageType />} />
          <Route path=":id" element={<SingleMessage />} />
          <Route path="inbox/:id" element={<SingleMessage />} />
          <Route path="alert/:id" element={<SingleMessage />} />
          <Route path="draft/:id" element={<SingleMessage />} />
          <Route path="all/:id" element={<SingleMessage />} />
        </Route> */}
            <Route
              exact
              path="/all_messages"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/inbox/:id"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/alert/:id"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/sent/:id"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/draft/:id"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/archive/:id"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/pinned/:id"
              element={<InboxList params={params} />}
            />
            <Route
              exact
              path="/all_messages/:id"
              element={<InboxList params={params} />}
            />
            <Route
              path="/contact/new"
              element={<CreateContact navigate={navigate} params={params} />}
            />
            <Route exact path="/contactdetails/:id" element={<ContactPage />} />
            <Route
              path="/fileupload/new"
              element={
                <FilePopUp
                  params={params}
                  location={location}
                  match={match}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/message/new"
              element={
                <NewMessage
                  navigate={navigate}
                  location={location}
                  match={match}
                />
              }
            />
            <Route
              path="/note/new"
              element={
                <CreateNotes
                  navigate={navigate}
                  location={location}
                  match={match}
                />
              }
            />
            <Route path="/notedetails/:id" element={<NoteDetails />} />
            <Route
              path="/note/edit/:id"
              element={
                <CreateNotes
                  navigate={navigate}
                  location={location}
                  match={match}
                />
              }
            />
            {/* ACCOUNT ROUTES */}
            <Route
              exact
              path="/accounts"
              element={<AccountList navigate={navigate} location={location} />}
            />
            <Route
              exact
              path="/accounts_beta"
              element={
                <AccountListUpdated navigate={navigate} location={location} />
              }
            />
            <Route
              exact
              path="/accountdetails/message/:id"
              element={
                <NewMessageModal
                  location={location}
                  match={match}
                  navigate={navigate}
                />
              }
            />
            <Route exact path="/account/new" element={<NewAccount />} />
            <Route exact path="/account/edit/:id" element={<EditAccount />} />
            <Route exact path="/accountdetails/:id" element={<AccountPage />} />
            <Route
              exact
              path="/accountdetails/:id/details"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/relationships"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/tasks"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/messages"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/notes"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/payroll"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/activity"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/logins"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/emails"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/files"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/billing/invoices"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/inFocus"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/billing/recurring"
              element={<AccountPage />}
            />
            <Route
              exact
              path="/accountdetails/:id/emails/:email_id"
              element={<AccountPage />}
            />
            {/* CONTACT ROUTES */}
            <Route exact path="/contact/new" element={<CreateContact />} />
            <Route exact path="/contacts" element={<AllContacts />} />
            <Route exact path="/contact/edit/:id" element={<CreateContact />} />
            <Route
              exact
              path="/contactdetails/:id"
              element={<ContactPage location={location} />}
            />
            <Route
              exact
              path="/contactdetails/message/:id"
              element={
                <NewMessageModal
                  location={location}
                  match={match}
                  navigate={navigate}
                />
              }
            />
            <Route
              exact
              path="/contactdetails/:id/overview"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/relationships"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/tasks"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/messages"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/notes"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/activity"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/files"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/emails"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/contactdetails/:id/billing"
              element={<Billing />}
            />
            <Route
              exact
              path="/contactdetails/:id/emails/:email_id"
              element={<ContactPage />}
            />
            <Route
              path="/bulkdownload"
              element={<BulkRequestDownload navigate={navigate} />}
            />
            <Route
              path="/bulkesign"
              element={<BulkEsign navigate={navigate} />}
            />
            <Route
              path="/bulksignature"
              element={<BulkSignature navigate={navigate} />}
            />
            <Route path="/bulkmessage" element={<BulkMessage />} />
            <Route path="/bulkinvite" element={<BulkInvite />} />
            <Route
              path="/bulkreassign"
              element={<BulkReassign navigate={navigate} />}
            />
            <Route
              path="/notifications"
              element={
                <NotificationList
                  navigate={navigate}
                  action={action}
                  match={match}
                />
              }
            />
            {/* PROFILE SETTINGS ROUTES */}
            <Route
              path="/profile_settings"
              element={<ProfileSettings navigate={navigate} />}
            />
            <Route
              exact
              path="/profile_settings/devicedetail/:id"
              element={<DeviceDetail />}
            />
            <Route
              path="/contacts"
              element={<AllContacts location={location} navigate={navigate} />}
            />
            <Route
              exact
              path="/fileupload/:id"
              element={
                <FilePopUp
                  params={params}
                  location={location}
                  match={match}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/files"
              element={
                <FilesList
                  action={action}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            {/* for client side */}
            <Route
              path="/files/new"
              element={
                <FilePopUp
                  params={params}
                  location={location}
                  match={match}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/document"
              element={
                <ShowDocument
                  params={params}
                  location={location}
                  match={match}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/esign_list"
              element={
                <ESignList
                  match={match}
                  action={action}
                  location={location}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/admin"
              element={
                <AdminList
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/preferences"
              element={<Preferences location={location} navigate={navigate} />}
            />
            <Route path="*" element={<IntegrationsRoutes />} />
            <Route
              path="/templates"
              element={<Templates navigate={navigate} />}
            />
            <Route path="/admin-billing" element={<AdminBillingPage />} />
            {/* EMAIL ROUTES */}
            <Route
              path="/email/new"
              element={<NewEmail location={location} navigate={navigate} />}
            />
            <Route
              path="/emails"
              element={<Emails location={location} navigate={navigate} />}
            />
            <Route
              path="/emails/:id"
              element={
                <Emails
                  params={params}
                  location={location}
                  navigate={navigate}
                />
              }
            />
            {/* SMS ROUTES */}
            <Route exact path="/sms/*" element={<SMSPage />} />
            {smsLineManagementFeature && (
              <Route exact path="/sms-admin/*" element={<SMSAdminPage />} />
            )}

            <Route
              exact
              path="/sms-settings-form"
              element={<SMSSettingsForm />}
            />

            {/* TASK ROUTES */}
            {/*
        <Route path="all_tasks" element={<TaskList />}>
          <Route path="pending_review" element={<TaskType />} />
          <Route path="archived" element={<TaskType />} />
          <Route path="draft" element={<TaskType />} />
        </Route> */}
            <Route exact path="/all_tasks" element={<TaskList />} />
            <Route exact path="/tasks/:id" element={<RedirectTaskDetail />} />
            <Route
              exact
              path="/task/new"
              element={<NewTask params={params} key="new" />}
            />
            <Route
              exact
              path="/task/edit/:id"
              element={<NewTask params={params} key="edit" />}
            />
            <Route path="/task/detail/:id" element={<TaskDetail />} />
            <Route
              exact
              path="/accountdetails/task/:id"
              element={<NewTask params={params} />}
            />
            <Route
              exact
              path="/contactdetails/task/:id"
              element={<NewTask params={params} />}
            />
            {/*  REQUESTS ROUTES */}
            {/*  ./Requests/requests.routes.tsx */}
            {requestsPageFeature && RequestsRoutes}
            <Route
              path="/message/new/modal"
              element={
                <NewMessageModal
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route path="/billing/invoices" element={<Billing />} />
            <Route path="/billing/recurring" element={<Billing />} />
            <Route
              path="/billing/invoices/:id"
              element={<InvoiceTaskDetails />}
            />
            <Route
              path="/billing/recurring/:id"
              element={<InvoiceTaskDetails />}
            />
            {/* ADMIN ROUTES */}
            <Route
              path="/userdetails/:id"
              element={
                <UserInfo
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/userdetails/:id"
              element={
                <UserInfo
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/user/new"
              element={
                <CreateUser
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/user/edit/:id"
              element={
                <CreateUser
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route path="/employee/:id/accounts" element={<AdminList />} />
            <Route
              exact
              path="/templates/:id"
              element={
                <NewTemplate
                  params={params}
                  match={match}
                  location={location}
                  navigate={navigate}
                />
              }
            />
            {/* EDOC ROUTES */}
            <Route
              path="/esign_templates"
              exact
              element={
                <EsignTemplates
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/esign_templates/new"
              exact
              element={
                <NewEsignTemplate
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/esign_templates/new"
              exact
              element={
                <NewEsign
                  params={params}
                  location={location}
                  navigate={navigate}
                  match={match}
                />
              }
            />
            <Route
              path="/new_esign"
              exact
              element={
                <NewEsign params={params} match={match} navigate={navigate} />
              }
            />
            <Route
              path="/esign/edit/:id"
              exact
              element={
                <NewEsign params={params} match={match} navigate={navigate} />
              }
            />
            <Route
              path="/invite_contact"
              exact
              element={
                <InviteContact
                  params={params}
                  match={match}
                  navigate={navigate}
                />
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};
export default App;
