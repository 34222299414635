import {
  assignEmployeeWorkflowRequestMutationDocument,
  bulkSendRequests,
  createWorkflowRequestMutationDocument,
  dispatchWorkflowRequestDocument,
  getZipPackage,
  postWorkflowRPARequest,
  updateWorkflowRequestMutationDocument,
  workflowRequestQueryDocument,
  workflowRequestsCountQueryDocument,
  workflowRequestsQueryDocument,
} from 'api/workflow-requests';
import {
  DeleteWorkflowRequestDocument,
  WorkflowRequestsQueryQueryVariables,
} from 'gql/graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { invalidateWorkflowRequestsQuery } from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { useGraphQLWithOptions, useMutationGraphQL } from './useGraphQL';

export function useWorkflowRequestsQuery(
  variables: WorkflowRequestsQueryQueryVariables,
  options?: { enabled: boolean },
) {
  return useGraphQLWithOptions(
    workflowRequestsQueryDocument,
    { retry: false, ...options },
    variables,
  );
}

export function useWorkflowRequestsCountQuery(
  variables?: WorkflowRequestsQueryQueryVariables,
  options?: { enabled?: boolean; staleTime?: number },
) {
  return useGraphQLWithOptions(
    workflowRequestsCountQueryDocument,
    options,
    variables || {},
  );
}

export function useWorkflowRequestQuery(id: string, options?: any) {
  return useGraphQLWithOptions(workflowRequestQueryDocument, options, { id });
}

export function useCreateWorkflowRequest() {
  return useMutationGraphQL(createWorkflowRequestMutationDocument);
}

export function useUpdateWorklowRequest() {
  const queryClient = useQueryClient();
  return useMutationGraphQL(updateWorkflowRequestMutationDocument, {
    onSuccess: (response) => {
      if (!response?.updateWorkflowRequest?.workflowRequest?.id) {
        return;
      }
      invalidateWorkflowRequestsQuery(
        response?.updateWorkflowRequest?.workflowRequest?.id,
        queryClient,
      );
    },
  });
}

export function useAssignEmployeeWorkflowRequest() {
  return useMutationGraphQL(assignEmployeeWorkflowRequestMutationDocument);
}

export function useDispatchWorkflowRequest() {
  return useMutationGraphQL(dispatchWorkflowRequestDocument);
}

export function usePostWorkflowRPARequest() {
  return useMutation({
    mutationKey: ['workflow-rpa-request'],
    mutationFn: postWorkflowRPARequest,
  });
}

export function useDeleteWorkflowRequest() {
  return useMutationGraphQL(DeleteWorkflowRequestDocument);
}

export function useBulkSendRequests() {
  return useMutationGraphQL(bulkSendRequests);
}

export function useGetZipPackage(id: string) {
  return useMutation({
    mutationFn: () => getZipPackage(id),
  });
}
