import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useWorkflowItemInput } from 'components/Requests/components/RequestsBuilder/hooks/workflow-item-input-hook';
import { invalidateWorkflowRequestsQuery } from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';
import { TOAST_FAILURE } from 'constants/constants';
import { graphql } from 'gql';
import {
  ResponseTextValueFragment,
  WorkflowItemFragment,
  WorkflowResponseItemFragment,
} from 'gql/graphql';
import useEffectOnce from 'hooks/useEffectOnce';
import { useMutationGraphQL } from 'hooks/useGraphQL';
import { ChangeEvent, useContext, useState } from 'react';
import { AlertMessage } from 'utilities/utils';

interface TextItemProps {
  workflowItem: WorkflowItemFragment;
  workflowResponseItem?: WorkflowResponseItemFragment | null;
  disabledEdit?: boolean;
}

export const updateResponseItemTextDocument = graphql(/* GraphQL */ `
  mutation updateResponseItemText(
    $notApplicable: Boolean
    $textValue: String
    $workflowResponseItemId: Uuid!
  ) {
    updateResponseItemText(
      notApplicable: $notApplicable
      textValue: $textValue
      workflowResponseItemId: $workflowResponseItemId
    ) {
      errors {
        message
      }
      workflowResponseItem {
        ...WorkflowResponseItem
      }
    }
  }
`);

export const responseTextValueFragment = graphql(/* GraphQL */ `
  fragment responseTextValue on StringValue {
    value
  }
`);

export function TextItem({
  workflowItem,
  workflowResponseItem,
  disabledEdit,
}: TextItemProps) {
  const { mutate: updateResponseItemText, isLoading: isMutating } =
    useMutationGraphQL(updateResponseItemTextDocument);
  const [notApplicable, setNotApplicable] = useState(
    workflowResponseItem?.notApplicable || false,
  );

  const queryClient = useQueryClient();

  const { checkValid, setDirtyField } = useContext(WorkflowViewContext);

  useEffectOnce(() => {
    checkValid(workflowResponseItem?.value);
  });

  const onSubmit = (newValue: string) => {
    updateResponseItemText(
      {
        textValue: newValue,
        workflowResponseItemId: workflowResponseItem?.id || '',
      },
      {
        onSettled: () => {
          checkValid(newValue);

          if (!workflowResponseItem?.id) {
            return;
          }
          setDirtyField(workflowResponseItem.id, false);
          invalidateWorkflowRequestsQuery(
            workflowResponseItem?.id,
            queryClient,
          );
        },
      },
    );
  };

  const inputProps = useWorkflowItemInput({
    onSubmit,
  });

  const handleOnChange = () => {
    setDirtyField(workflowResponseItem?.id || '', true);
  };

  const handleNotApplicable = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setNotApplicable(checked);
    updateResponseItemText(
      {
        notApplicable: checked,
        workflowResponseItemId: workflowResponseItem?.id || '',
      },
      {
        onSuccess: () => {
          checkValid(checked);
          invalidateWorkflowRequestsQuery(
            workflowResponseItem?.id,
            queryClient,
          );
        },
        onError: () => {
          AlertMessage(TOAST_FAILURE, 'Failed to update response item');
          setNotApplicable(!checked);
        },
      },
    );
  };

  const value = notApplicable
    ? ''
    : (workflowResponseItem?.value as ResponseTextValueFragment)?.value;
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Typography variant="h4" paddingBottom="4px">
        {workflowItem.prompt}
      </Typography>
      <Box width="100%">
        <TextField
          multiline
          hiddenLabel
          disabled={disabledEdit || notApplicable}
          variant="outlined"
          inputProps={{
            'aria-label': `${workflowItem.prompt} input`,
            ...inputProps,
            onChange: handleOnChange,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            sx: {
              backgroundColor: 'common.white',
              padding: '4px 0px 5px',
              '& .MuiInputBase-inputMultiline': { border: 'none' },
            },
          }}
          fullWidth
          defaultValue={value}
        />
      </Box>
      <FormGroup>
        <FormControlLabel
          label="This item does not apply to me"
          control={
            <Switch
              disabled={disabledEdit || isMutating}
              onChange={handleNotApplicable}
              checked={notApplicable}
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
