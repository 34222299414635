import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import SmallLoaderGif from 'images/small-loader.gif';

const Indicator = styled('div')((props) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: 1999,
  width: '100%',
  height: '100%',
  overflow: 'visible',
  backgroundImage: `url(${SmallLoaderGif})`,
  backgroundSize: '100px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(223,227,232, 0.6)', // #dfe3e8, 0.6
  visibility: props.loading === 1 ? 'visible' : 'hidden',
}));

function LoadingIndicator({ loading }) {
  return <Indicator loading={loading ? 1 : 0} />;
}

LoadingIndicator.propTypes = { loading: PropTypes.bool.isRequired };

export default LoadingIndicator;
