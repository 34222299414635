/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import Select, { components } from 'react-select';
import { withRouter } from 'utilities/withRouterWrapper';
import { Modal, ModalBody, Input, Button, ModalHeader } from 'reactstrap';
import * as TaskApi from 'api/TaskApi';
import { autoTagApi } from 'api/AutoTag';
import { EditorState, convertFromRaw } from 'draft-js';
import * as Config from 'constants/constants';
import {
  SelectField,
  AttachementPortion,
  RenderAttachments,
  selectStyles,
  selectTheme,
  CheckboxField,
  SelectTemplateFieldOption,
} from 'common/CustomFields';
import ConfirmationModal from 'common/ConfirmationModal';
import UploadFiles from 'api/UploadFiles';
import * as Utilities from 'utilities/utils';
import { NUMBER } from 'utilities/regularexpressions';
import { generateUniqueHash, removeBreakElement } from 'common/NotesUtilities';
import { AlertMessage } from 'utilities/utils';
import TinymceEditor from 'components/Emails/components/TinymceEditior';

const isIE = /* @cc_on!@ */ false || !!document.documentMode;
let accessToken = '';
let ML_APP_DATA = {};
class FilePopupBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownToggle: false,
      selectedTags: [],
      data: [],
      uploadFiles: [],
      documents: [],
      showModal: false,
      showAttachmentModal: false,
      isDocumentUpload: false,
      attachmentId: undefined,
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      selectedAttachment: '',
      previewScreen: false,
      previewDocument: null,
      errors: {},
      changedOnce: false,
      openOnedrive: false,
      openSharePoint: false,
      openBox: false,
      thirdPartyApps: [],
      loading: false,
      accessToken: '',
      sharedFilesList: [],
      folderSequence: [],
      onedriveFilesActiveTab: 0,
      noSharedFiles: false,
      googleDriveModal: false,
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      subject: '',
      showSmartVaultWidget: false,
      showingToggle: false,
    };
    this.scroll = this.scroll.bind(this);
    this.updateAutoTagData = this.updateAutoTagData.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.templateDropdown = this.templateDropdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.autoTagsUpdated = false;
    this.selectInputRef = React.createRef();
    this.handleToggleIsOpen = this.handleToggleIsOpen.bind(this);
  }

  componentDidMount() {
    document.title = 'Upload File';
    window.addEventListener('click', this.scroll);
    ML_APP_DATA = Utilities.getMLData();
    const { fileDropped } = this.props;
    if (fileDropped) {
      this.startUpload(fileDropped);
    }
    if (Utilities.isUserEmployee()) {
      this.setState({ loading: true });
      this.getTemplates();
      const request = axios.get(Config.THIRD_PARTY_APPS);
      request
        .then((response) => {
          if (response.data.success === 200) {
            this.setState({
              thirdPartyApps: response.data.data.length
                ? response.data.data
                : [],
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.setState({ loading: false });
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { formdata } = this.props;
    if (Object.keys(formdata).length) {
      if (formdata.tags && formdata.tags.length && !this.state.changedOnce) {
        this.setState({
          selectedTags: formdata.tags,
          changedOnce: true,
        });
      }
      if (
        formdata.makePrivate !== undefined &&
        nextProps.formdata.makePrivate !== undefined &&
        formdata.makePrivate !== nextProps.formdata.makePrivate &&
        nextProps.formdata.makePrivate === true
      ) {
        // this.templateDropdown(null);
        this.setState({ warningMsgLost: true }, () => {
          this.templateDropdown(null);
        });
      }
    }
  }

  componentWillUnmount() {
    const { formdata, uploadType } = this.props;
    if (formdata.documents && formdata.documents.length) {
      const docIds = [];
      formdata.documents.map((item) => {
        docIds.push(item.id);
        return docIds;
      });
      const data = { doc_ids: docIds };
      if (uploadType === 'task') {
        TaskApi.cancelDocuments(this, data, 'tasks');
      } else if (uploadType === 'manual') {
        UploadFiles.deleteUploadedFile(data, this);
      }
    }
    window.removeEventListener('click', this.scroll);
  }

  onSuccess = (keys) => {
    keys.map((selectedItem) => {
      const item = selectedItem.driveItem_203[2];
      const head = { headers: { Authorization: `Bearer ${accessToken}` } };
      axios
        .get(`https://graph.microsoft.com/v1.0/me/drive/items/${item}`, head)
        .then((res) => {
          const selectedfile = [];
          selectedfile.push(res.data);
          this.startUpload(selectedfile, 'onedrive');
        });
      return selectedItem;
    });
    this.handleOnedriveFiles('close');
  };

  onCancel = (err) => {
    console.log('onCancel', err.message);
    this.handleOnedriveFiles('close');
  };

  onedriveSharedFileHandle = (item, itemType = '') => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    if (
      ((item.folder && item.folder !== undefined) ||
        (!item.folder && item.folder === undefined)) &&
      !item.file
    ) {
      this.setState({ loading: true });
      if (item.id === 'shared') {
        this.sharedFolder(1);
      } else {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}/children?expand=thumbnails`,
            head,
          )
          .then((response) => {
            if (response.status === 200) {
              let { folderSequence: sequence } = this.state;
              const index = sequence.findIndex((x) => x.active === true);
              if (index > -1) {
                sequence[index].active = false;
                this.setState({ folderSequence: sequence });
              }
              if (itemType === 'folder') {
                const indx = sequence.findIndex((x) => x.id === item.id);
                if (indx > -1) {
                  sequence[indx].active = true;
                  sequence = sequence.slice(0, indx + 1);
                  this.setState({ folderSequence: sequence });
                }
              } else {
                const seq = {
                  name: item.name,
                  id: item.id,
                  parentReference: { driveId: item.parentReference.driveId },
                  active: true,
                  folder: true,
                };
                sequence.push(seq);
              }
              this.setState({
                sharedFilesList: response.data.value,
                folderSequence: sequence,
                loading: false,
              });
            } else {
              this.setState({ loading: false });
            }
          });
      }
    } else {
      const checkboxState = this.state.sharedFilesList;
      const index = checkboxState.findIndex((x) => x.id === item.id);
      if (index > -1) {
        checkboxState[index].checked = !checkboxState[index].checked;
        this.setState({ sharedFilesList: checkboxState }, () => {
          // console.log('pali', this.state.sharedFilesList);
        });
      }
    }
  };

  getTemplates() {
    this.setState({ loading: true });
    axios
      .get(
        `${Config.GET_TEMPLATES_FOR_CREATION}?template_type=message_template`,
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true });
      const request = axios.post(
        `${Config.CREATE_DOC_FROM_TEMPLATE_IDS}?doc_type=Manual`,
        mydata,
      );
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            if (documents.length) {
              this.setState({ documents: response.data.documents }, () => {
                let x;
                for (x = 0; x < response.data.doc_ids.length; x += 1) {
                  const location =
                    documents.length - response.data.doc_ids.length + x;
                  const documnt = { ...documents[location] };
                  documnt.from_template_document = true;
                  documents[location] = documnt;
                  this.setState({ documents });
                  if (x + 1 === response.data.doc_ids.length) {
                    this.props.updateForm('documents', documents);
                    this.setState({ templateDocuments: [] });
                    this.updateAutoTagData();
                  }
                }
              });
              // let x;
              // const len = documents.length - response.data.doc_ids.length;
              // for (x = 0; x < response.data.doc_ids.length; x += 1) {
              //   const location = (documents.length - response.data.doc_ids.length) + x;
              //   const documnt = { ...documents[location] };
              //   documnt.id = response.data.doc_ids[x];
              //   documnt.from_template_document = true;
              //   documents[location] = documnt;
              //   this.setState({ documents });
              //   if (x + 1 === response.data.doc_ids.length) {
              //     this.props.updateForm('documents', documents);
              //     this.setState({ templateDocuments: [] });
              //     this.updateAutoTagData();
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  setAttachmentNull = () => {
    const uploadFile = document.getElementById('upload_doc');
    uploadFile.value = null;
  };

  getAuthenticationToken = () => Promise.resolve(accessToken);

  inputRenderer = (inputProps) => {
    const { formdata } = this.props;
    // delete inputProps.value;
    return (
      <components.Input
        {...inputProps}
        value={this.state.titleChange}
        className="Select-input"
        disabled={formdata.checkbox !== undefined && formdata.checkbox === true}
      />
    );
  };

  templateDropdown(selectedOption) {
    const { formdata } = this.props;
    const { subject, documents, warningMsgLost } = this.state;
    let commentExist = true;
    if (!formdata.message) {
      commentExist = false;
    } else if (typeof formdata.message === 'object') {
      if (
        !EditorState.createWithContent(convertFromRaw(formdata.message))
          .getCurrentContent()
          .getPlainText().length
      ) {
        commentExist = false;
      }
    }
    let templateDocExist = false;
    if (_.find(documents, { from_template_document: true })) {
      templateDocExist = true;
    }
    if (
      (subject || commentExist || templateDocExist) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !subject))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      const { content, value } = selectedOption;
      // let coment = '';
      const coment = removeBreakElement(content);
      // if (content_json && content_json !== null) {
      //   coment = JSON.parse(content_json);
      // } else {
      //   coment = removeBreakElement(content);
      // }
      const docmnts = [...documents];
      if (
        docmnts.length &&
        selectedOption.documents &&
        selectedOption.documents.length
      ) {
        let x;
        for (x = 0; x < selectedOption.documents.length; x += 1) {
          docmnts.push(selectedOption.documents[x]);
          // const documnt = { ...selectedOption.documents[x] };
          // docmnts[x] = documnt;
          this.setState({ documents: docmnts });
          if (x + 1 === selectedOption.documents.length) {
            // this.setState({ templateDocuments: [] });
          }
        }
      } else if (docmnts.length && !selectedOption.documents.length) {
        this.setState({ documents: docmnts });
      } else if (!docmnts.length && !selectedOption.documents.length) {
        this.setState({ documents: [] });
      } else if (
        !docmnts.length &&
        selectedOption.documents &&
        selectedOption.documents.length
      ) {
        this.setState({ documents: selectedOption.documents });
      }
      // const docs = documents.filter((el) => { return !templateDocuments.includes(el); });
      this.setState(
        {
          selectedOption,
          subject: value,
          titleChange: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          templateDocuments:
            selectedOption.documents && selectedOption.documents.length
              ? selectedOption.documents
              : [],
        },
        () => {
          this.props.updateForm('subject', value);
        },
      );
      this.props.updateForm('message', coment);
    } else {
      if (documents.length && templateDocExist) {
        const filteredDocuments = documents.filter(
          (item) => !item.from_template_document,
        );
        this.setState({ documents: filteredDocuments }, () => {
          console.log('documents', this.state.documents);
        });
      } else if (documents.length && !templateDocExist) {
        this.setState({ documents });
      } else if (
        (!documents.length && !templateDocExist) ||
        (!documents.length && templateDocExist)
      ) {
        this.setState({ documents: [] });
      }
      this.setState(
        {
          selectedOption: null,
          subject: '',
          taskTitle: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          // documents: (docs && docs.length) ? docs : [],
          templateDocuments: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
          this.props.clearForm(this.state.documents);
          // this.props.updateForm('subject', '');
        },
      );
      // this.props.updateForm('message', '');
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.props.updateForm('subject', this.state.subject);
    });
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      this.setState(
        {
          subject: val,
          titleChange: val,
        },
        () => {
          if (val) {
            this.props.updateForm('subject', val);
          }
        },
      );
    }
  };

  handleCommentChange = (value) => {
    this.props.updateForm('message', value);
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    }
  };

  // eslint-disable-next-line consistent-return
  async updateAutoTagData() {
    try {
      const token = await autoTagApi('login', ML_APP_DATA);
      if (
        !token ||
        (typeof token === 'object' && Object.keys(token).length === 0)
      ) {
        // console.log('errorApi',token)
        await autoTagApi('login', ML_APP_DATA);
        // console.log('res', response);
        // console.log(response)
        // await setAutotagAccessToken(response.token)
        // console.log(await getAccessToken())
      }
      const filesResponse = await Promise.all(
        this.props.formdata.documents.map((doc) =>
          autoTagApi('results', { file: doc.doc_name }, true),
        ),
      );
      if (
        filesResponse &&
        filesResponse[0] &&
        filesResponse[0].status &&
        filesResponse[0].status === 401
      ) {
        return this.updateAutoTagData();
      }
      const autoTagData = filesResponse.reduce(
        (acc, cv) => ({
          tags: acc.tags.concat(
            cv.Suggested_tags ? cv.Suggested_tags[0][0] : [],
          ),
          // months: acc.months.concat(cv.month || []),
          // years: acc.years.concat(cv.year+"" || []),
          yearsAndMonths: acc.yearsAndMonths.concat(
            [
              {
                month: cv.month || '',
                year: `${cv.year}` || '',
              },
            ] || [],
          ),
        }),
        { yearsAndMonths: [], tags: [] },
      );
      this.props.preferences.upload_tags.filter((tag) =>
        // return autoTagData.tags.find(autoTag=>autoTag.match(new RegExp(tag.label,'gi')))
        autoTagData.tags.find(
          (autoTag) => autoTag.toLowerCase() === tag.label.toLowerCase(),
        ),
      );
      // console.log({selectedTags,pref:this.props.preferences})
      autoTagData.yearsAndMonths.map((YMO) => ({
        year: this.props.preferences.upload_file_year.find(
          (item) => item.label === YMO.year,
        ),
        month: this.props.preferences.upload_file_month.find(
          (item) => item.label === YMO.month,
        ),
      }));
      // this.props.updateForm('year', filteredYearsAndMonths[0].year);
      // this.props.updateForm('month', filteredYearsAndMonths[0].month);
      // this.setState({ selectedTags }, () => {
      //   this.props.updateForm('tags', this.state.selectedTags);
      // });
    } catch (error) {
      console.error(error);
      // if(error.message.match(/axios is not defined/gi)) this.updateAutoTagData();
    }
  }

  handleOnedriveSelectedFiles = () => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    const checkboxState = this.state.sharedFilesList;
    checkboxState.map((item) => {
      if (item.checked && item.checked !== undefined) {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}`,
            head,
          )
          .then((res) => {
            const selectedfile = [];
            selectedfile.push(res.data);
            this.startUpload(selectedfile, 'onedrive');
          });
      }
      this.handleOnedriveFiles('close');
      return null;
    });
  };

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open') {
      const request = axios.get(`${Config.OAUTH_ACCESS_TOKENS}/${item.key}`);

      request
        .then((response) => {
          if (response.status === 200) {
            accessToken = response.data.access_token;
            const { openOnedrive } = this.state;

            this.setState({
              openOnedrive: !openOnedrive,
              accessToken
            });
          }
          else {
            Utilities.AlertMessage(
              'error',
              `Please set up ${item.value} to use the cloud store.`,
              3000,
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (toggle === 'close') {
      const { openOnedrive } = this.state;
      this.setState({
        openOnedrive: !openOnedrive,
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
      });
    }
  };

  googleDrive = (stat = '') => {
    if (stat === 'false') {
      this.setState({ googleDriveModal: false });
    } else {
      this.setState((prevState) => ({
        googleDriveModal: !prevState.googleDriveModal,
      }));
    }
  };

  googleDriveLoader = (stat = '') => {
    if (stat === 'false') {
      this.setState({ loading: false });
    } else if (stat === 'true') {
      this.setState({ loading: true });
    } else {
      this.setState((prevState) => ({ loading: !prevState.loading }));
    }
  };

  smartVaultAccessToken = () => {
    const { access_token = null } =
      this.state.thirdPartyApps.find(
        (thirdPartyApp) => thirdPartyApp.id === 'smartvault',
      ) || {};
    return access_token;
  };

  toggleSmartVaultWidget = () => {
    this.setState({ showSmartVaultWidget: !this.state.showSmartVaultWidget });
  };

  toggleSharePoint = () => {
    this.setState({ openSharePoint: !this.state.openSharePoint });
  };

  toggleBox = () => {
    this.setState({ openBox: !this.state.openBox });
  };

  scroll(e) {
    if (e && e.target && e.target.id !== 'tagId') {
      this.toggleDropdown(false);
    }
  }

  attachmentModalClose = () => {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  };

  toggleDropdown = (toggle = undefined) => {
    const { dropdownToggle } = this.state;
    this.setState({
      dropdownToggle: toggle !== undefined ? toggle : !dropdownToggle,
      tagInput: '',
    });
  };

  filterTags = (e) => {
    const { value } = e.target;
    const div = document.getElementById('tagDropDown');
    const a = div.getElementsByTagName('a');
    let count = 0;
    for (let i = 0; i < a.length; i++) {
      if (value) {
        if (a[i].innerHTML.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          a[i].style.display = 'block';
        } else {
          count += 1;
          a[i].style.display = 'none';
          if (count === a.length) {
            document.getElementById('noRecords').style.display = 'block';
          }
        }
      } else {
        a[i].style.display = 'block';
      }
    }
    this.setState({ [e.target.name]: value });
  };

  handleTagChange(action, value) {
    const { selectedTags } = this.state;
    let array = this.state.selectedTags;
    const index = selectedTags.findIndex((each) => each.key === value.key);
    if (action === 'select' && index === -1) {
      selectedTags.push(value);
    }
    if (action === 'remove') {
      array = selectedTags.filter((each) => each.key !== value.key);
      this.setState({ dropdownToggle: false });
    }
    this.setState(
      { selectedTags: action === 'select' ? selectedTags : array },
      () => {
        this.props.updateForm('tags', this.state.selectedTags);
        this.toggleDropdown(false);
      },
    );
  }

  handleFileDrop = (files) => {
    const uploadFile = files;
    this.startUpload(uploadFile);
  };

  uploadDocument = () => {
    let uploadFile = null;
    const nodes = document.querySelectorAll('#upload_doc');
    nodes.forEach((element) => {
      if (element.files.length > 0) uploadFile = element.files;
    });
    this.startUpload(uploadFile);
  };

  cancelUpload = (filename) => {
    this.state[`${filename}sourceToken`].cancel();
  };

  openAttachment = (c) => {
    UploadFiles.showOpenAttachment(c, this);
  };

  showModal = (nid, type) => {
    if (type === 'attachment') {
      this.setState({ attachmentId: nid, showModal: true });
    }
  };

  uploadfile = (uploadFile, fro = '') => {
    this.startUpload(uploadFile, fro);
  };

  startUpload(uploadFile, fro = '') {
    let i;
    const component = this;
    const { uploadType } = this.props;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      Utilities.getCpaPreferences() &&
      Utilities.getCpaPreferences().attachment &&
      Utilities.getCpaPreferences().attachment[0].label &&
      NUMBER.test(Utilities.getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes =
        Utilities.getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = Utilities.getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    for (i = 0; i < uploadFile.length; i += 1) {
      const data = new FormData();
      if (fro === 'onedrive' || fro === 'sharepoint' || fro === 'box') {
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i]['@microsoft.graph.downloadUrl']);
      } else if (fro === 'google_drive' || fro === 'smart_vault') {
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i].file);
      } else {
        data.append('aws_url', uploadFile[i], uploadFile[i].name);
      }
      // data.append('isDraft', true);
      if (uploadType === 'message') {
        data.append('doc_type', 'Message');
      }
      if (uploadType === 'message' || uploadType === 'task') {
        data.append('is_draft', true);
      }
      if (uploadFile[i].size > maxsizeInBytes) {
        alert(`File exceeds limit of ${maxsizeInMb}MB`);
        return false;
      }
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const key = `${uploadFile[i].name}sourceToken`;
      this.setState({ [key]: source });
      this.state.uploadFiles.push(uploadFile[i].name);
      const fileSize = uploadFile[i].size;
      const fileSizeKey = `fileSize${uploadFile[i].name}`;
      this.setState({ [fileSizeKey]: fileSize });
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(uploadFile[i].name),
      };
      UploadFiles.addUploadDocument(
        data,
        component,
        '',
        config,
        uploadFile[i].name,
        uploadType,
        fro,
      );
      if (component.state.errors.documents) {
        const err = component.state.errors;
        err.documents = '';
        component.setState({ errors: err });
      }
    }
    return true;
  }

  previewOpenAttachmenr = (doc) => {
    this.setState({ previewScreen: true, previewDocument: doc });
  };

  previewCloseAttachment = (data) => {
    if (data.editedFileName) {
      if (this.state.documents.length) {
        let x;
        for (x = 0; x < this.state.documents.length; x += 1) {
          if (this.state.documents[x].id === data.selectedItem.id) {
            const { documents } = this.state;
            const doc = documents;
            const filename = data.editedFileName;
            doc[x].doc_name = filename;
            this.setState({ documents: doc });
          }
        }
      }
    }
    this.setState({ previewScreen: false, previewDocument: null });
  };

  deleteNote(type) {
    if (this.props.shareFile) {
      this.props.delDocumentstoShare(this.state.attachmentId);
    } else if (type === 'attachment') {
      UploadFiles.deleteAttachment(this.state.attachmentId, this);
    }
    this.setState({ showModal: false });
  }

  sharedFolder = (key) => {
    if (key === 0) {
      this.setState({
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
      });
    } else if (key === 1) {
      const head = {
        headers: { Authorization: `Bearer ${this.state.accessToken}` },
      };
      this.setState({ loading: true, sharedFilesList: [], folderSequence: [] });
      axios
        .get('https://graph.microsoft.com/v1.0/me/drive/sharedWithMe', head)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.value.length === 0) {
              this.setState({ loading: false, noSharedFiles: true });
              // AlertMessage('error', 'No items found in shared list', 1000);
            } else {
              const { folderSequence, sharedFilesList } = this.state;
              const seq = folderSequence;
              seq.push({
                name: 'Shared',
                id: 'shared',
                parentReference: { driveId: 'shared' },
                active: true,
                folder: true,
              });
              this.setState({ folderSequence: seq });
              response.data.value.map((item) => {
                const data = item.remoteItem;
                const sharedFiles = sharedFilesList;
                sharedFiles.push(data);
                this.setState({
                  sharedFilesList: sharedFiles,
                  onedriveFilesActiveTab: 1,
                });
                return null;
              });
              this.setState({ loading: false });
            }
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  doNotSendMsg = () => {
    const { formdata } = this.props;
    const com = this;
    com.setState({ warningMsgLost: true }, async () => {
      await com.templateDropdown(null);
      com.props.updateForm('checkbox', !formdata.checkbox);
    });
  };

  handleToggleIsOpen() {
    const { showingToggle } = this.state;
    this.setState({ showingToggle: !showingToggle });
  }

  renderValue = (isAccount) => {
    const { formdata } = this.props;
    if (Utilities.isUserEmployee() && isAccount) {
      return '';
    }
    if (formdata && formdata.message) {
      return formdata.message;
    }

    return '';
  };

  render() {
    const { preferences, formdata, data, showMessageInput, hideCheckbox } =
      this.props;
    const { selectedOption, templateOptions, selectedTemplate, showingToggle } =
      this.state;

    const recipientDropdown =
      document.getElementById('showRecipientDropdown') || false;
    let accountOnly = false;
    if (data.uniqueRecords.length) {
      data.uniqueRecords.some((item) => {
        if (!item.contact) {
          accountOnly = true;
        }
        return accountOnly;
      });
    }
    return (
      <div className="col-12">
        {this.state.loading && <div id="loading" />}
        <div className="row">
          {(Utilities.isUserEmployee() || showingToggle) && (
            <>
              {!this.props.shareFile && (
                <SelectField
                  name="year"
                  label="Year"
                  className="form-group dropdown col-6"
                  value={formdata.year || { value: '', label: '' }}
                  handleChange={(input) => this.props.updateForm('year', input)}
                  options={preferences.upload_file_year || []}
                />
              )}
              {!this.props.shareFile && (
                <SelectField
                  name="month"
                  id="month"
                  label="Month"
                  className="form-group dropdown col-6"
                  value={formdata.month || { value: '', label: '' }}
                  handleChange={(input) =>
                    this.props.updateForm('month', input)
                  }
                  options={preferences.upload_file_month || []}
                />
              )}
              {!this.props.shareFile && (
                <div
                  className={`form-group col-12 ${
                    data.errors.tags ? 'form-error' : ''
                  }`}
                >
                  <label htmlFor="tagslabel">Tags</label>
                  <div className="labelValue customTags mailDrodown">
                    <div className="customTags__input form-control">
                      {/* this.renderTags() */}
                      {formdata.tags && formdata.tags.length
                        ? formdata.tags.map((each) => (
                            <span className="tag tag--fill" key={each.value}>
                              {each.label}
                              <i
                                className="icon-close2 remove"
                                onClick={() =>
                                  this.handleTagChange('remove', each)
                                }
                              />
                            </span>
                          ))
                        : null}
                      <a
                        onClick={this.toggleDropdown}
                        href="javascript:void(0)"
                        id="tagId"
                        className="inputHelper dropdown-toggle noCaret"
                      />
                    </div>
                    <span className="error_label">
                      {data.errors.tags ? data.errors.tags : ''}
                    </span>
                    {this.state.dropdownToggle && !recipientDropdown ? (
                      <div
                        id="tagDropDown"
                        onClick={(e) => e.stopPropagation()}
                        className={`dropdown-menu ${
                          this.state.dropdownToggle ? 'show' : 'hide'
                        }`}
                      >
                        <div className="input-group">
                          <Input
                            autoFocus
                            type="text"
                            name="tagInput"
                            value={this.state.tagInput || ''}
                            onChange={this.filterTags}
                            className="form-control border-0"
                            autoComplete="off"
                          />
                          <span className="input-group-addon border-left-0">
                            <i className="icon-zoom2" />
                          </span>
                        </div>
                        {/* tags */}
                        {preferences.upload_tags.map((each) => (
                          <a
                            key={each.value}
                            onMouseDown={(e) =>
                              this.handleTagChange('select', each, e)
                            }
                            href="javascript:void(0)"
                            className="dropdown-item"
                          >
                            {each.label}
                          </a>
                        ))}
                        <a
                          href="javascript:void(0)"
                          id="noRecords"
                          style={{ display: 'none' }}
                          className="dropdown-item"
                        >
                          No records
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </>
          )}
          {!Utilities.isUserEmployee() && (
            <div className="col-12 text-right">
              <button
                type="button"
                onClick={this.handleToggleIsOpen}
                style={{
                  backgroundColor: '#fff',
                  border: 'none',
                  color: '#00aecc',
                  outline: 'none',
                }}
              >
                {showingToggle ? (
                  'Hide Details'
                ) : (
                  <>
                    <i className="icon-tag" /> Add Details
                  </>
                )}
              </button>
            </div>
          )}
          {showMessageInput && (
            <div className="form-group col-12">
              <label htmlFor="tagslabel">Message</label>
              {hideCheckbox ? null : (
                <div className="mb-2 float-right">
                  <CheckboxField
                    id="dndMessage"
                    name="notifyViaText"
                    label="Do not Send Message"
                    value={
                      Utilities.isUserEmployee() && accountOnly
                        ? true
                        : formdata.checkbox
                    }
                    onChange={this.doNotSendMsg}
                    labelclassName="text-body text-transform-none font-weight-normal"
                  />
                </div>
              )}
              <div
                className={`labelValue ${
                  data.errors.message ? 'form-error' : ''
                }`}
              >
                {Utilities.isUserEmployee() ? (
                  <div className="mb-3">
                    <div
                      className={
                        Utilities.isUserEmployee() ? 'cstmClr msgCustmClr' : ''
                      }
                    >
                      {selectedOption && selectedOption !== null ? (
                        <Input
                          autoComplete="off"
                          type="text"
                          name="subject"
                          id="forSubject"
                          value={this.state.subject}
                          onChange={this.handleChange}
                          placeholder=""
                          disabled={
                            formdata.checkbox !== undefined &&
                            formdata.checkbox === true
                          }
                        />
                      ) : (
                        <Select
                          name="subject"
                          placeholder="Subject"
                          ref={this.selectInputRef}
                          className="select-custom-class slctTemp"
                          value={selectedOption}
                          onChange={this.templateDropdown}
                          options={templateOptions}
                          inputValue={this.state.titleChange}
                          onInputChange={this.templateChange}
                          tabSelectsValue={false}
                          onBlurResetsInput={false}
                          onCloseResetsInput={false}
                          onSelectResetsInput={this.state.titleChange} // false shows
                          noResultsText=""
                          styles={selectStyles}
                          theme={selectTheme}
                          isDisabled={
                            formdata.checkbox !== undefined &&
                            formdata.checkbox === true
                          }
                          classNamePrefix="custom-react-select"
                          components={{
                            Option: SelectTemplateFieldOption,
                            Input: this.inputRenderer,
                          }}
                        />
                      )}
                      {this.state.subject ? (
                        <Button
                          className="btn btn-sm btn-primary"
                          disabled={
                            formdata.checkbox !== undefined &&
                            formdata.checkbox === true
                          }
                          onClick={() => this.templateDropdown(null)}
                        >
                          Clear
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="labelValue customEditor boxResize flWdth">
                  <TinymceEditor
                    initialValue=""
                    value={this.renderValue(accountOnly)}
                    handleChange={this.handleCommentChange}
                    disabled={
                      formdata.checkbox !== undefined &&
                      formdata.checkbox === true
                    }
                  />
                </div>
                <span className="error_label">
                  {data.errors.message ? data.errors.message : ''}
                </span>
              </div>
            </div>
          )}
          {this.props.shareFile || this.props.addToVault ? (
            <div className="col-lg-12 attached-file-list">
              <RenderAttachments
                data={data}
                name={this.props.addToVault || ''}
                openAttachment={
                  this.props.shareFile ? this.openAttachment : null
                }
                showModal={this.props.shareFile ? this.showModal : null}
              />
            </div>
          ) : (
            <div
              className={`form-group col-lg-12 dragWrap${
                data.errors.documents ? ' form-error' : ''
              }`}
            >
              <label htmlFor="uploadfiles">File</label>
              <AttachementPortion
                name="uploadfiles"
                className="DragDrop--mh100"
                handleFileDrop={this.handleFileDrop}
                data={this.state}
                setAttachmentNull={this.setAttachmentNull}
                uploadDocument={this.uploadDocument}
                uploadFiles={this.state.uploadFiles}
                documents={this.state.documents}
                cancelUpload={this.cancelUpload}
                openAttachment={this.openAttachment}
                showModal={this.showModal}
                component={this}
                handleOnedriveFiles={this.handleOnedriveFiles}
                getAuthenticationToken={this.getAuthenticationToken}
                onSuccess={this.onSuccess}
                onCancel={this.onCancel}
                sharedFolder={this.sharedFolder}
                onedriveSharedFileHandle={this.onedriveSharedFileHandle}
                handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
              />
              <span className="error_label">
                {data.errors.documents ? data.errors.documents : ''}
              </span>
            </div>
          )}
          <div className="col-12 justify-content-end formbtn justify-content-end">
            <div className="btn-wrap btn--leftspace">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={this.props.closeModal}
                data-testid="files__modal_cancel_btn"
              >
                Cancel
              </button>
              {this.props.shareFile ? (
                <button
                  type="button"
                  onClick={this.props.handleShare}
                  className="btn btn-primary"
                >
                  Share
                </button>
              ) : (
                <button
                  type="button"
                  onClick={this.props.handleUpload}
                  disabled={this.state.isDocumentUpload}
                  className="btn btn-primary"
                  data-testid="files__modal_upload_btn"
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={this.state.showModal}
          noBtnClick={() => this.setState({ showModal: false })}
          yesBtnClick={() => this.deleteNote('attachment')}
          yesBtnText="Yes"
          noBtnText="No"
          headerText="You are about to delete the attached file."
          messageText="This action cannot be undone. Proceed?"
        />
        {this.state.warningMsgLost && (
          <ConfirmationModal
            isOpen={this.state.warningMsgLost}
            className="customModal customModal--w500 customModal--nopadding"
            messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
            noBtnClick={() => this.setState({ warningMsgLost: false })}
            yesBtnClick={() => this.templateDropdown(selectedTemplate)}
            yesBtnText="Yes"
            noBtnText="No"
          />
        )}
        <Modal
          isOpen={this.state.showAttachmentModal}
          className="customModal customModal--w990 customModal--nopadding modalMxHeight"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={this.attachmentModalClose}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <div
                className={
                  isIE ? 'left-inner-content' : 'sample-letter-preview'
                }
              >
                <div className="letter-logo" />
                <iframe
                  width="100%"
                  height="600"
                  src={this.state.selectedAttachment}
                  title="selected attachment"
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(FilePopupBottom);
