/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import InputField from 'common/InputField';
import { Modal, ModalBody, Button } from 'reactstrap';
import {
  ZOOM_REDIRECT_URL,
  ZOOM_CLIENT_ID,
  SEND_ZOOM_CODE,
  ZOOM_INTEGRATION_STATUS,
  DISCONNECT_ZOOM,
  THIRD_PARTY_APPS,
  PIN_BASED_AUTH_SMART_VAULT,
  OAUTH_ACCESS_TOKENS
} from 'constants/constants';
import { AlertMessage, randomString, createCookie } from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import { EMAIL } from 'utilities/regularexpressions';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { makeThirdPartyAppsMapper } from './ProfileUtilities';
import EachApp from './EachApp/EachApp';
import EmailIntegration from './EmailIntegration';

const client_id = ZOOM_CLIENT_ID;
const redirect_uri = ZOOM_REDIRECT_URL;
const auth_url = 'https://zoom.us/oauth/authorize?response_type=code';
const oauthApps = [ 'googledrive', 'calendly', 'onedrive', 'sharepoint', 'box' ]

const popupwindow = (url, title, w, h) => {
  // eslint-disable-next-line
  const left = screen.width / 2 - w / 2;
  // eslint-disable-next-line
  const top = screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`,
  );
};
const emailsServiceNames = ['googlemail', 'outlookemail', 'office365'];
class ThirdPartyApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDisconnectConfirmation: false,
      thirdPartyApps: [],
      smartVaultEmail: '',
      smartVaultPin: '',
      invalidSmartVaultEmail: false,
      invalidSmartVaultPin: false,
      showSmartVaultModal: false,
      emailServices: []
    };
  }

  componentDidMount() {
    this.getIntegrationStatus();
    this.sendCodeToBackend();
  }

  getIntegrationStatus = async () => {
    const thirdPartyAppsMapper = makeThirdPartyAppsMapper();
    try {
      const thirdPartyAppsResponse = await Axios.get(THIRD_PARTY_APPS)
        .then((response) => response.data)
        .then((responseData) => ({
          ...responseData,
          data: responseData.data.map(thirdPartyAppsMapper),
        }));

      const emailServices = [];
      let isEmailConnected = false;
      const thirdPartyApps = [];
      const thirdPartyEmailApps = [];
      thirdPartyAppsResponse.data.forEach((thirdPartyApp) => {
        if (
          emailsServiceNames.includes(thirdPartyApp.id) &&
          thirdPartyApp.access_token
        ) {
          isEmailConnected = true;
          thirdPartyEmailApps.push(thirdPartyApp);
        } else if (!emailsServiceNames.includes(thirdPartyApp.id)) {
          thirdPartyApps.push(thirdPartyApp);
        }
      });
      if (!isEmailConnected && thirdPartyAppsResponse.is_email_enabled) {
        thirdPartyAppsResponse.data.forEach((thirdPartyApp) => {
          if (emailsServiceNames.includes(thirdPartyApp.id)) {
            emailServices.push(thirdPartyApp);
          }
        });
      }
      const zoomResponse = await Axios.get(ZOOM_INTEGRATION_STATUS).then(
        (response) => response.data.data || {},
      );
      const { email, token } = zoomResponse;
      this.setState({
        loading: false,
        thirdPartyApps: [
          ...thirdPartyEmailApps,
          ...thirdPartyApps,
          {
            id: 'zoom',
            name: 'Zoom',
            email,
            token_exits: token,
            oauth_url: auth_url,
            client_id,
            redirect_url: redirect_uri,
            category: 'meetings'
          },
        ],
        emailServices
      });
    } catch (error) {
      this.setState({ loading: false });
      AlertMessage('error', error.response.data.message, 3000);
    }
  };

  authEndpoint = (app_key) => {
    if (app_key === 'zoom') { return SEND_ZOOM_CODE }
    if (oauthApps.includes(app_key)) { return OAUTH_ACCESS_TOKENS }
    return THIRD_PARTY_APPS
  }

  sendCodeToBackend = async () => {
    const parsedUrl = new URL(window.location.href);
    const app_key = parsedUrl.searchParams.get('app');
    const code = parsedUrl.searchParams.get('code');
    const error = parsedUrl.searchParams.get('error_description');
    if (code && !error) {
      try {
        const response = await Axios.post(
          this.authEndpoint(app_key),
          {
            code,
            app_key,
          }
        ).then((res) => res.data.message);
        AlertMessage('success', response, 3000);
        this.getIntegrationStatus();
      } catch (err) {
        this.setState({ loading: false });
        AlertMessage('error', err.response.data.message, 10000);
      }
    } else if (error) {
      const errorString = 'Connection canceled';
      AlertMessage('error', errorString, 10000);
    }
    setTimeout(() => {
      this.props.navigate('/profile_settings?tab=third_party_apps', {
        replace: true,
      });
    }, 50);
  };

  handleDisconnectConfirmationModal = (thirdPartyApp) => {
    this.setState({
      showDisconnectConfirmation: !this.state.showDisconnectConfirmation,
      deleteAppDetails: thirdPartyApp,
    });
  };

  handleDisconnect = () => {
    const { deleteAppDetails } = this.state;
    this.setState({ loading: true });
    let request;
    if (deleteAppDetails.id === 'zoom') {
      request = Axios.delete(DISCONNECT_ZOOM);
    } else {
      request = Axios.delete(`${THIRD_PARTY_APPS}/${deleteAppDetails.id}`);
    }
    request
      .finally(() => {
        this.setState({
          loading: false,
          showDisconnectConfirmation: false,
        });
        this.getIntegrationStatus();
      })
      .catch(() => {
        AlertMessage('error', null, 3000);
      });
  };

  handleSmartVaultConnect = () => {
    const {
      smartVaultEmail: email,
      smartVaultPin: pin,
      showSmartVaultModal,
    } = this.state;
    const invalidSmartVaultEmail = !EMAIL.test(email);
    const invalidSmartVaultPin = !pin;
    if (
      showSmartVaultModal &&
      (invalidSmartVaultEmail || invalidSmartVaultPin)
    ) {
      return this.setState({
        invalidSmartVaultEmail,
        invalidSmartVaultPin,
      });
    }
    if (!showSmartVaultModal && email && pin) {
      return this.setState({ showSmartVaultModal: true });
    }
    Axios({
      url: PIN_BASED_AUTH_SMART_VAULT,
      method: 'POST',
      data: { email, pin },
    })
      .then((res) => {
        if (res.data.authorization_uri) {
          if (!showSmartVaultModal) this.toggleSmartVaultModal();

          const newPopupWindow = popupwindow(
            res.data.authorization_uri,
            'SmartVault Pin',
            1000,
            600,
          );
          if (
            !newPopupWindow ||
            newPopupWindow.closed ||
            typeof newPopupWindow.closed === 'undefined'
          ) {
            AlertMessage(
              'error',
              new Error(
                'Your browser has blocked popup. Please allow popups to continue',
              ),
              10000,
            );
          }
        } else if (res.data.success) {
          this.setState(
            {
              smartVaultEmail: '',
              smartVaultPin: '',
              invalidSmartVaultEmail: false,
              invalidSmartVaultPin: false,
            },
            () => {
              if (showSmartVaultModal) this.toggleSmartVaultModal();
              this.getIntegrationStatus();
            },
          );
        } else if (!res.data.success) {
          AlertMessage('error', new Error(res.data.message), 10000);
        }
      })
      .catch((error) => {
        AlertMessage('error', error, 10000);
      });
  };

  handleChange = (inputvalue, fieldname) => {
    this.setState((ps) => ({
      [fieldname]: inputvalue,
      invalidSmartVaultEmail:
        fieldname === 'smartVaultEmail'
          ? !EMAIL.test(inputvalue)
          : ps.invalidSmartVaultEmail,
      invalidSmartVaultPin:
        fieldname === 'smartVaultPin'
          ? !inputvalue.length
          : ps.invalidSmartVaultPin,
    }));
  };

  toggleSmartVaultModal = () => {
    this.setState((prevState) => ({
      showSmartVaultModal: !prevState.showSmartVaultModal,
    }));
  };

  handleConnect = (thirdPartyApp) => {
    if (thirdPartyApp.id === 'smartvault') {
      this.handleSmartVaultConnect();
    } else {
      const appDetails = thirdPartyApp;
      const cookieName = randomString(16);
      createCookie(
        cookieName,
        `${window.location.href}&app=${appDetails.id}`,
        30,
      );
      const state = cookieName;
      const oauthUrl = `${appDetails.oauth_url}&client_id=${appDetails.client_id}&redirect_uri=${appDetails.redirect_url}&state=${state}`;
      window.location.href = oauthUrl;
    }
  };

  render() {
    const {
      loading,
      showDisconnectConfirmation,
      thirdPartyApps,
      deleteAppDetails,
      showSmartVaultModal,
      smartVaultEmail,
      invalidSmartVaultEmail,
      invalidSmartVaultPin,
      smartVaultPin,
      emailServices,
    } = this.state;
     const { flags } = this.props;

    const categoryMap = {
      email: {
        name: 'Email',
        description: 'Synchronize, organization and collate emails by both Contact and Client'
      },
      dms: {
        name: 'Document Management Systems',
        description: 'Automate the transferring of files to and from supported DMS providers'
      },
      meetings: {
        name: 'Meetings',
        description: 'Add virtual meeting support and scheduling assistance to requests'
      }
    }

    const groupedApps = thirdPartyApps.reduce((apps, app) => {
      // eslint-disable-next-line no-param-reassign
      if (!apps[app.category]) apps[app.category] = [];
      apps[app.category].push(app);
      return apps;
    }, {});

    return (
      <div id="content">
        {loading && <div id="loading" />}
        <div className="center-wrapper col m-auto px-5">
          <div className='card-section-header'>Email</div>
          <div className='card-section-sub-header'>{categoryMap.email.description}</div>
          <div className="row">
            <EmailIntegration
              thirdPartyApps={emailServices}
              handleConnect={this.handleConnect}
              handleDisconnect={this.handleDisconnectConfirmationModal}
            />
          </div>
          {Object.keys(groupedApps).map((category) => (
            <div>
              <div className='card-section-header'>{categoryMap[category].name}</div>
              <div className='card-section-sub-header'>{categoryMap[category].description}</div>
              <div className="row">
                {groupedApps[category].map((app) => (
                  !(app.id === 'box' && flags.boxTpa) && <EachApp
                    key={app.id}
                    appDetails={app}
                    handleConnect={this.handleConnect}
                    handleDisconnect={this.handleDisconnectConfirmationModal}
                  />
                ))}
              </div>
            </div>
          ))}

          {showDisconnectConfirmation ? (
            <ConfirmationModal
              isOpen
              messageText={
                deleteAppDetails.id === 'zoom'
                  ? 'All Zoom meetings scheduled through Liscio with the connected email will be deleted and the Virtual Meeting Tasks closed. Click Proceed to continue.'
                  : `Disconnect ${deleteAppDetails.name}. Confirm to continue.`
              }
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.handleDisconnect}
              noBtnClick={this.handleDisconnectConfirmationModal}
            />
          ) : null}
          {showSmartVaultModal ? (
            <SmartVaultInputModal
              isOpen={showSmartVaultModal}
              smartVaultEmail={smartVaultEmail}
              invalidSmartVaultEmail={invalidSmartVaultEmail}
              invalidSmartVaultPin={invalidSmartVaultPin}
              smartVaultPin={smartVaultPin}
              headerText="Smart Vault"
              onChange={this.handleChange}
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.handleSmartVaultConnect}
              noBtnClick={this.toggleSmartVaultModal}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(ThirdPartyApps);

const blockStyle = { display: 'block' };
const SmartVaultInputModal = (props) => (
  <Modal
    isOpen={props.isOpen}
    className={
      props.className
        ? props.className
        : 'customModal customModal--center customModal--alert customModal--w500 customModal--nopadding'
    }
  >
    {props.loading && props.loading !== undefined ? (
      <div id="loading" style={blockStyle} />
    ) : null}
    <div className="ModalHeader">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={props.noBtnClick}
      >
        <i className="icon-close2" aria-hidden="true" />
      </button>
    </div>
    <ModalBody className="modalContent">
      <div className="modalContent--inner">
        {props.headerText ? (
          <div className="modalContent--header text-large">
            <h5 className="modalContent--header__heading">
              {props.headerText}
            </h5>
          </div>
        ) : null}
        <br />
        <InputField
          name="smartVaultEmail"
          label="Email"
          type="email"
          className="col-sm-12"
          onChange={props.onChange}
          value={props.smartVaultEmail}
          error={props.invalidSmartVaultEmail}
        />
        <InputField
          name="smartVaultPin"
          label="Pin"
          className="col-sm-12"
          onChange={props.onChange}
          value={props.smartVaultPin}
          error={props.invalidSmartVaultPin}
        />
        <div className="row">
          <div
            className={`col-12 justify-content-end formbtn ${props.btnClassName}`}
          >
            <div className="btn-wrap btn--leftspace">
              {props.noBtnText ? (
                <Button
                  className="btn btn-outline-light"
                  onClick={props.noBtnClick}
                >
                  {props.noBtnText}
                </Button>
              ) : null}
              {props.yesBtnText ? (
                <Button
                  className="btn btn-primary"
                  type="submit"
                  onClick={
                    props.smartVaultEmail && props.smartVaultPin
                      ? props.yesBtnClick
                      : null
                  }
                  disabled={!props.smartVaultEmail || !props.smartVaultPin}
                >
                  {props.yesBtnText}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);
