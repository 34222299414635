/* eslint-disable */
import React from 'react';
import { Modal, ModalBody, ModalHeader, Button, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Pagination from 'common/Pagination';
import ConfirmationModal from '../ConfirmationModal';
import { fetchFilesAndFolders, setAuthorizationHeader, base64ArrayBuffer, downloadFile, queryParams } from './service';
import './widget.scss';
import { DMS_FILE_EXPORT } from 'constants/constants';
import axios from 'axios';
import { AlertMessage } from '../../utilities/utils';
import ExportWidgetBody from '../../ui/SmartVault/ExportWidgetBody';
import { icon, extension } from '../../ui/SmartVault/utils';

export class Widget extends React.PureComponent {
  constructor(props) {
    super(props);
    setAuthorizationHeader(props.token);
    PropTypes.checkPropTypes(Widget.propTypes, props, 'prop', 'Widget');
    this.state = {
      exportMode: props.mode === 'export',
      importMode: props.mode !== 'export',
      selectedRecords: props.selectedRecords,
      selectedRecordIds: [],
      hierarchy: null,
      filesAndFolders: { children: [] },
      selectedFiles: [],
      selectedFolder: null,
      hasSelectedAllFiles: false,
      searchInput: '',
      isLoading: true,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
      itemsPerPage: 25,
      currentPage: 1,
      sortBy: 'name',
      sortDirection: 'asc',
      mouseDown: false,
    };
    this.exportableNodeTypes = ['VaultNodeType', 'FolderNodeType']
    this.selectionBoxRef = React.createRef();
    this.refs = {};
    this.queryParams = { ...queryParams };
    this.signals = [];
  }
  async componentDidMount() {
    document.addEventListener('keydown', this.toggleSelectAll);
    window.scrollTo(0, 0);
    this.updateExplorer('/nodes/pth/');
  }

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        isLoading: true,
        currentPage: 1,
        [name]: value,
        hasSelectedAllFiles: false,
      },
      () => {
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(async () => {
          clearTimeout(this.searchTimerId);
          const { hierarchy } = this.state;
          this.updateExplorer(hierarchy[hierarchy.length - 1].uri);
        }, 1000);
      }
    );
  };

  handlePagination = (currentPage) => {
    this.setState(
      {
        currentPage,
        hasSelectedAllFiles: false,
        isLoading: true,
      },
      () => {
        const { hierarchy } = this.state;
        this.updateExplorer(hierarchy[hierarchy.length - 1].uri);
      }
    );
  };

  handleSorting = (sortBy) => {
    this.setState(
      (ps) => ({
        sortBy,
        sortDirection: ps.sortBy === sortBy ? (ps.sortDirection === 'asc' ? 'desc' : 'asc') : 'asc',
        currentPage: 1,
        hasSelectedAllFiles: false,
        isLoading: true,
      }),
      () => {
        const { hierarchy } = this.state;
        this.updateExplorer(hierarchy[hierarchy.length - 1].uri);
      }
    );
  };

  downloadSingleFile = (e, item) => {
    e.preventDefault();
    this.setState(
      {
        selectedFiles: [item.download_uri],
      },
      () => {
        this.downloadSelectedFiles();
      }
    );
  };

  downloadSelectedFiles = async (e) => {
    try {
      e && e.preventDefault();
      const { filesAndFolders, selectedFiles } = this.state;
      const filesToDownload = filesAndFolders.children.filter((item) => selectedFiles.includes(item.download_uri));
      this.setState({
        isLoading: true,
      });
      this.signals = [];
      const filesToUpload = await Promise.all(
        filesToDownload.map((item) => {
          const signal = axios.CancelToken.source();
          this.signals.push(signal);
          return downloadFile({ ...item, signal }).then((res) => {
            const file = new Blob([res.data], {
              type: res.data.type,
            });
            return new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = (event) => {
                let arrayBuffer = event.target.result;
                const bufferdata = base64ArrayBuffer(arrayBuffer);
                resolve({
                  name: item.name,
                  size: res.data.size,
                  file: bufferdata,
                  type: res.data.type,
                });
              };
              fileReader.onerror = (error) => {
                reject(error);
              };
            });
          });
        })
      );
      this.setState({
        isLoading: false,
      });
      this.props.startUpload(filesToUpload, 'smart_vault');
      this.props.cancelWidget();
    } catch (error) {
      this.handleError(error);
    }
  };

  uploadSelectedFiles = async (e) => {
    const data = {
      app_key: 'smartvault',
      remote_directory: this.state.selectedFolder,
      document_ids: this.state.selectedRecordIds
    }
    const request = axios.post(DMS_FILE_EXPORT, data);
    request
      .then(response => {
        AlertMessage('success', response.data.message, 3000);
        this.props.cancelWidget();
      })
      .catch(error => {
        AlertMessage('error', 'Upload failed!', 3000);
      })
  }

  toggleSelectAll = (e) => {
    if (
      (e.ctrlKey && e.type === 'keydown' && e.which === 65) ||
      (e.type === 'change' && e.target.id === 'select-all')
    ) {
      e.preventDefault();
      let selectedFiles;
      if (!this.state.hasSelectedAllFiles) {
        selectedFiles = this.state.filesAndFolders.children.reduce((acc, item) => {
          if (item.download_uri) acc.push(item.download_uri);
          return acc;
        }, []);
      } else selectedFiles = [];
      this.setState({
        selectedFiles,
        hasSelectedAllFiles: selectedFiles.length === Object.keys(this.refs).length,
      });
    }
  };

  toggleFileSelect = (e, item) => {
    const { selectedFiles } = this.state;
    const selectedFilesSet = new Set(selectedFiles);
    if (selectedFilesSet.has(item.download_uri)) selectedFilesSet.delete(item.download_uri);
    else selectedFilesSet.add(item.download_uri);
    this.setState(
      {
        selectedFiles: [...selectedFilesSet],
        hasSelectedAllFiles: Object.keys(this.refs).length && selectedFilesSet.size === Object.keys(this.refs).length,
      },
      () => {
        this.setIndeterminateCheckboxForSelectAll();
      }
    );
  };

  setIndeterminateCheckboxForSelectAll = () => {
    const selectAllCheckbox = document.querySelector('#select-all');
    if (selectAllCheckbox) {
      const { selectedFiles, hasSelectedAllFiles } = this.state;
      if (selectedFiles.length > 0 && !hasSelectedAllFiles) {
        selectAllCheckbox.indeterminate = true;
      } else selectAllCheckbox.indeterminate = false;
    }
  };

  exploreDirectory = async (e, directory) => {
    e.preventDefault();
    const { name, uri, nodeType, index = null } = directory;
    const hierarchy =
      index || index === 0 ? this.state.hierarchy.slice(0, index + 1) : [...this.state.hierarchy, { name, uri, nodeType }];
    const selectedFiles = [];
    this.setState(
      (ps) => ({
        hierarchy,
        selectedFiles,
        searchInput: hierarchy.length > 1 ? ps.searchInput : '',
        currentPage: 1,
        isLoading: true,
        selectedFolder: this.exportableNodeTypes.includes(directory.nodeType) ? directory.uri : null
      }),
      () => {
        this.updateExplorer(uri);
      }
    );
  };

  handleError = (error) => {
    console.error({ error });
    if (error.message === 'Network Error') {
      this.setState({
        errorMessage:
          "Unable to access SmartVault. Try Disconnecting and Re-connecting the app from 'My Profile Settings > Third Party Apps' page",
        isLoading: false,
      });
    }
  };

  setQueryParams = () => {
    const { searchInput, itemsPerPage, currentPage, sortBy, sortDirection } = this.state;
    this.queryParams.search = searchInput;
    this.queryParams.per_page = itemsPerPage;
    this.queryParams.page = currentPage - 1;
    this.queryParams.sort = sortBy;
    this.queryParams.direction = sortDirection;
  };

  updateExplorer = async (path) => {
    try {
      this.setQueryParams();
      this.signals = [axios.CancelToken.source()];
      const { data } = await fetchFilesAndFolders({
        ...this.queryParams,
        path,
        signal: this.signals[0],
      });
      this.handleResponse(data);
    } catch (error) {
      this.handleError(error);
    }
  };

  handleResponse = (data) => {
    this.refs = {};
    if (data.error.success) {
      const { name, uri, nodeType } = data.message;
      this.setState((ps) => ({
        filesAndFolders: data.message,
        isLoading: false,
        selectedFiles: [],
        hierarchy: ps.hierarchy || [{ name, uri, nodeType }]
      }));
    } else {
      this.setState({
        filesAndFolders: null,
        isLoading: false,
        selectedFiles: [],
      });
    }
  };

  loading = () => (
    <div className="col-sm-12">
      <div id="loading" className="d-block"></div>
    </div>
  )

  renderFilesAndFolders = () => {
    const {
      selectedFiles,
      filesAndFolders,
      isLoading,
      hasSelectedAllFiles,
      sortBy,
      sortDirection,
      hierarchy,
      errorMessage = '',
    } = this.state;
    if (isLoading) return this.loading()
    if (!filesAndFolders || !filesAndFolders.children)
      return <span className="mt-3">{errorMessage || 'No results'}</span>;
    let containFiles = false;
    const filesAndFoldersContent = filesAndFolders.children.map((item) => {
      const isFile = item.nodeType === 'FileNodeType';
      if (!containFiles && isFile) containFiles = true;
      const path = item.uri.split('/').slice(3, -1).join(' > ');
      const size = item.size && formatBytes(item.size);
      if (isFile && !this.refs[`$Ref-${item.uri}`]) {
        this.refs = { ...this.refs, [`$Ref-${item.uri}`]: React.createRef() };
      }
      const isSelected = isFile && selectedFiles.indexOf(item.download_uri) > -1;
      return (
        <div
          ref={(ref) => (isFile ? (this.refs[`$Ref-${item.uri}`] = ref) : null)}
          data-uri={item.download_uri || item.uri}
          className={`${isSelected ? 'selected' : ''} item-grid`}
          key={item.uri}
          onDoubleClick={(e) => {
            if (isFile) {
              this.downloadSingleFile(e, item);
            } else this.exploreDirectory(e, item);
          }}
          onClick={(e) => {
            if (isFile) {
              if (e.ctrlKey || e.shiftKey || e.altKey) {
                this.toggleFileSelect(e, item);
              }
            } else this.exploreDirectory(e, item);
          }}
        >
          {isFile ? (
            <div className="posRel checkbox checkbox-primary check-container mx-2 pl-2">
              <Label htmlFor={item.id}>
                <Input
                  key={item.uri}
                  type="checkbox"
                  id={item.uri}
                  checked={isSelected}
                  className={`chkbx ${item.uri}`}
                  name="isChecked"
                  onChange={(e) => this.toggleFileSelect(e, item)}
                />
                <i className="checkmark" />
              </Label>
            </div>
          ) : (
            ''
          )}

          <div className="mr-2">
            {icon(item)}
          </div>
          <p className="pb-filemng-paragraphs source-name" title={item.name}>
            {item.name}
          </p>
          <p className="pb-filemng-paragraphs source-path" title={`Directory: ${path}`}>
            {path}
          </p>
          <p className="pb-filemng-paragraphs source-size" title={size}>
            {size}
          </p>
          <p className="pb-filemng-paragraphs source-date" title={`Modified on: ${formatDate(item.modifiedOn)}`}>
            {formatDate(item.modifiedOn)}
          </p>
        </div>
      );
    });
    const headerContent = (
      <div className="item-grid" key={`header-key-${hasSelectedAllFiles}`}>
        {containFiles ? (
          <div className="posRel checkbox checkbox-primary check-container mx-2 pl-2">
            <Label htmlFor="select-all">
              <Input
                key="select-all"
                type="checkbox"
                id="select-all"
                checked={hasSelectedAllFiles}
                className="chkbx select-all"
                name="select-all"
                onChange={this.toggleSelectAll}
              />
              <i className="checkmark" />
            </Label>
          </div>
        ) : (
          ''
        )}
        <div
          className={`pb-filemng-paragraphs source-name header ${sortBy === 'name' ? 'active' : ''}`}
          onClick={() => this.handleSorting('name')}
        >
          File name
          {sortBy === 'name' ? <i className={`icon-${sortDirection === 'asc' ? 'down' : 'up'}-arrow`}></i> : ''}
        </div>
        <div className="pb-filemng-paragraphs source-path header">{hierarchy.length > 1 && 'Directory'}</div>
        <div
          className={`pb-filemng-paragraphs source-size header ${sortBy === 'size' ? 'active' : ''}`}
          onClick={() => this.handleSorting('size')}
        >
          {containFiles && (
            <React.Fragment>
              Size{' '}
              {sortBy === 'size' ? <i className={`icon-${sortDirection === 'asc' ? 'down' : 'up'}-arrow`}></i> : ''}
            </React.Fragment>
          )}
        </div>
        <div
          className={`pb-filemng-paragraphs source-date header ${sortBy === 'modified_date' ? 'active' : ''}`}
          onClick={() => this.handleSorting('modified_date')}
        >
          Date Modified
          {sortBy === 'modified_date' ? (
            <i className={`icon-${sortDirection === 'asc' ? 'down' : 'up'}-arrow`}></i>
          ) : (
            ''
          )}
        </div>
      </div>
    );
    return [headerContent, ...filesAndFoldersContent];
  };

  renderBreadCrumbs = (hierarchy) =>
    hierarchy
      ? hierarchy.map((item, index) =>
          index + 1 < hierarchy.length ? (
            <li className="breadcrumb-item" key={item.uri}>
              <a onClick={(e) => this.exploreDirectory(e, { ...item, index })} href="">
                {item.name}
              </a>
            </li>
          ) : (
            <li className="breadcrumb-item active" key={item.uri}>
              {item.name}
            </li>
          )
        )
      : '';

  renderSelectionBox = () => {
    return (
      <div className="selection-border" ref={(ref) => (this.selectionBoxRef = ref)} style={this.state.selectionBox} />
    );
  };

  calculateSelectionBox = (startPoint, endPoint) => {
    if (!this.state.mouseDown || !endPoint || !startPoint) {
      return null;
    }
    const { offsetHeight: bodyHeight, offsetWidth: bodyWidth } = document.querySelector('body');
    const parentNode = document.querySelector('.files-and-folders-uibox');
    const left = Math.min(startPoint.x, endPoint.x) - (bodyWidth - parentNode.offsetWidth) / 2;
    const top = Math.min(startPoint.y, endPoint.y) - (bodyHeight - parentNode.offsetHeight - 59) / 2;
    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);
    return {
      left,
      top,
      width,
      height,
      display: 'block',
    };
  };

  onMouseDown = (e) => {
    if (e.ctrlKey || e.altKey || e.shiftKey) {
      return;
    }
    const nextState = {},
      containerDiv = document.querySelector('.files-and-folders-uibox');
    nextState.mouseDown = true;
    nextState.startPoint = {
      x: e.pageX,
      y: e.pageY + containerDiv.scrollTop,
    };
    this.setState(nextState);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseUp = (e) => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.setState((ps) => ({
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
    }));
  };

  onMouseMove = (e) => {
    e.preventDefault();
    if (this.state.mouseDown) {
      const containerDiv = document.querySelector('.files-and-folders-uibox');
      const { top, right, bottom, left } = containerDiv.getBoundingClientRect();
      if (e.pageY > document.body.offsetHeight) return;
      const endPoint = {
        x: e.pageX,
        y: e.pageY + containerDiv.scrollTop,
      };
      if (e.pageY + 50 > bottom) containerDiv.scrollBy(0, 40);
      if (e.pageY - 50 < top) containerDiv.scrollBy(0, -40);
      const selectedFiles = Object.keys(this.refs).reduce((acc, ref) => {
        const overlapped = isOverlapping(this.refs[ref], this.selectionBoxRef);
        return overlapped ? [...acc, this.refs[ref].dataset.uri] : acc;
      }, []);
      this.setState(
        {
          endPoint,
          selectionBox: this.calculateSelectionBox(this.state.startPoint, endPoint),
          selectedFiles,
          hasSelectedAllFiles: Object.keys(this.refs).length && selectedFiles.length === Object.keys(this.refs).length,
        },
        () => {
          this.setIndeterminateCheckboxForSelectAll();
        }
      );
    }
  };

  componentWillUnmount() {
    this.refs = {};
    clearTimeout(this.searchTimerId);
    this.signals.forEach((signal) => signal && signal.cancel());
    document.removeEventListener('keydown', this.toggleSelectAll);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  render() {
    const {
      importMode,
      exportMode,
      selectedFiles,
      selectedFolder,
      selectedRecords,
      selectedRecordIds,
      hierarchy,
      isLoading,
      searchInput,
      currentPage,
      filesAndFolders,
      itemsPerPage,
      errorMessage = '',
      sortBy,
      sortDirection
    } = this.state;
    const totalRecords = filesAndFolders ? filesAndFolders.total_children : null;
    const totalPages = totalRecords ? Math.ceil(totalRecords / itemsPerPage) : 0;
    const offset = totalPages > 1 ? itemsPerPage * (currentPage - 1) + 1 : totalRecords ? 1 : 0;
    const off = totalRecords ? offset + filesAndFolders.children.length - 1 : 0;
    return (
      <React.Fragment>
        <ConfirmationModal
          isOpen={errorMessage}
          loading={isLoading}
          headerText="Error"
          messageText={errorMessage}
          // noBtnClick={() => this.setState({ switchCpaModal: false, switchToCpa: {} })}
          yesBtnClick={this.props.cancelWidget}
          yesBtnText="Ok"
          // noBtnText="Cancel"
        />
        <Modal
          isOpen={!errorMessage}
          className="customModal customModal--center customModal--alert customModal--nopadding"
        >
          <div className="ModalHeader">
            <button type="button" className="close" aria-label="Close" onClick={this.props.cancelWidget}>
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalHeader className="modalHeader p-2">
            <div className="modalContent--header text-medium pl-4 pb-0">
              <div>{importMode ? 'Select File(s)' : 'Select Folder'}</div>
            </div>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <div className="smart-vault-widget-wrapper">
                <div className="smart-vault-widget-container">
                  <div className="col-12 p-0">
                    <ul className="breadcrumbs">{this.renderBreadCrumbs(hierarchy)}</ul>
                  </div>
                  {importMode && <div className="form-group has-search position-relative mt-3">
                    <span className="icon-zoom2 form-control-feedback"></span>
                    <input
                      type="text"
                      name="searchInput"
                      id="search"
                      value={searchInput}
                      className="form-control"
                      placeholder="Search for the file name"
                      onChange={!errorMessage || (hierarchy && hierarchy.length > 1) ? this.handleSearch : (e) => null}
                      disabled={errorMessage || (hierarchy && hierarchy.length <= 1)}
                      readOnly={errorMessage || (hierarchy && hierarchy.length <= 1)}
                    />
                  </div>}
                  <div className="files-and-folders-uibox" onMouseDown={this.onMouseDown}>
                    {importMode && this.renderFilesAndFolders()}
                    {exportMode && <ExportWidgetBody
                      filesAndFolders={filesAndFolders}
                      formatDate={formatDate}
                      exploreDirectory={this.exploreDirectory}
                      isLoading={isLoading}
                      loading={this.loading}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      handleSorting={this.handleSorting}
                      selectedRecords={selectedRecords}
                      selectedRecordIds={selectedRecordIds}
                    />}
                    {this.renderSelectionBox()}
                  </div>
                  {filesAndFolders && (
                    <div className="pagination-block" id="pagination-block">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalRecords={totalRecords}
                        offset={offset}
                        off={off}
                        handlePageChange={this.handlePagination}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 justify-content-end formbtn mt-0">
                  <div className="btn-wrap btn--leftspace">
                    <Button className="btn btn-outline-light" onClick={this.props.cancelWidget}>
                      Cancel
                    </Button>
                    { this.state.importMode && <Button
                      className="btn btn-primary"
                      onClick={selectedFiles.length && !isLoading ? this.downloadSelectedFiles : null}
                      disabled={isLoading || !selectedFiles.length}
                    >
                      Import Files
                    </Button> }
                    { this.state.exportMode && <Button
                      className="btn btn-primary"
                      onClick={selectedFolder && !isLoading ? this.uploadSelectedFiles : null}
                      disabled={isLoading || !selectedFolder}
                    >
                      Export Files
                    </Button> }
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

Widget.propTypes = {
  token: PropTypes.string,
  cancelWidget: PropTypes.func,
  startUpload: PropTypes.func,
};

const isOverlapping = (e1, e2) => {
  if (!e1 || !e2) return;
  const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
  const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

  let overlap = false;

  if (rect1 && rect2) {
    overlap = !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );
    return overlap;
  }

  console.warn('Please provide valid HTMLElement object');
  return overlap;
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatDate(isoDate) {
  const date = new Date(isoDate);
  return date
    .toLocaleString([], {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(/, /, ' ');
}
