import { GraphQLClient } from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import { GQL_URL } from 'constants/constants';
import { getAuthSession } from 'auth/session';
import { Error } from 'gql/graphql';

export type errorResponse = {
  message: string | undefined;
  code: string | undefined;
};

let graphQLClient: GraphQLClient;

export const initGraphQLClient = () => {
  const authSession = getAuthSession();
  if (authSession) {
    const authToken = authSession.auth_token!;
    graphQLClient = new GraphQLClient(GQL_URL, {
      headers: {
        authorization: authToken,
      },
    });

    // const actingAsUserId = localStorage.getItem(ACT_AS_KEY);

    // if (actingAsUserId) {
    //   axios.defaults.params = { act_as: actingAsUserId };
    // }
  } else {
    graphQLClient = new GraphQLClient(GQL_URL);
  }
};

export function useGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): UseQueryResult<TResult> {
  return useQuery<TResult, Error[], TResult, any[]>(
    [(document.definitions[0] as any).name.value, variables],
    // eslint-disable-next-line require-await
    async ({ queryKey }) => {
      const requestResponse = graphQLClient.request(
        document,
        queryKey[1] ? queryKey[1] : undefined,
      );
      return handleResponse(requestResponse, document);
    },
  );
}

export function useGraphQLWithOptions<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  useQueryOptions?: Omit<
    UseQueryOptions<TResult, Error[], TResult, any>,
    'queryKey' | 'queryFn' | 'initialData'
  > & { initialData?: () => undefined },
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): UseQueryResult<TResult> {
  return useQuery<TResult, Error[], TResult, any[]>(
    [(document.definitions[0] as any).name.value, variables],
    // eslint-disable-next-line require-await
    async ({ queryKey }) => {
      const requestResponse = graphQLClient.request(
        document,
        queryKey[1] ? queryKey[1] : undefined,
      );

      return handleResponse(requestResponse, document);
    },
    useQueryOptions,
  );
}

// type VariablesAndRequestHeaders<T> = T extends Record<string, never> ? [] : [T];

// export function useGraphQL<TResult, TVariables>({
//   document,
//   useQueryOptions,
//   variables,
// }: {
//   document: TypedDocumentNode<TResult, TVariables>;
//   useQueryOptions?: Omit<
//     UseQueryOptions<TResult, unknown, TResult, any>,
//     'queryKey' | 'queryFn' | 'initialData'
//   > & { initialData?: () => undefined };
//   variables:
//     | Array<VariablesAndRequestHeaders<TVariables>>
//     | VariablesAndRequestHeaders<TVariables>;
// }): UseQueryResult<TResult> {
//   return useQuery({
//     queryKey: [(document.definitions[0] as any).name.value, ...variables],
//     // eslint-disable-next-line require-await
//     queryFn: async ({ queryKey }) =>
//       graphQLClient.request(document, queryKey[1] ? queryKey[1] : undefined),
//     ...useQueryOptions,
//   });
// }

export function useMutationGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  options?: Omit<
    UseMutationOptions<TResult, Error[], TVariables, any>,
    'mutationFn'
  >,
) {
  return useMutation<TResult, Error[], TVariables, any[]>({
    ...options,
    // eslint-disable-next-line require-await
    mutationFn: (variables: TVariables) => {
      const requestResponse = graphQLClient.request(
        document,
        variables || undefined,
      );

      return handleResponse(requestResponse, document);
    },
  });
}

export function handleResponse<TResult, TVariables>(
  requestResponse: Promise<TResult>,
  document: TypedDocumentNode<TResult, TVariables>,
): Promise<TResult> {
  return new Promise((resolve, reject) => {
    requestResponse
      ?.then((data) => {
        const name = (document.definitions[0] as any).selectionSet.selections[0]
          .name.value as string;
        if ((data[name as keyof TResult] as any)?.errors?.length) {
          reject((data[name as keyof TResult] as any)?.errors);
        }
        resolve(data);
      })
      .catch((err) => {
        const errors: Array<errorResponse> = [];
        // requestResponse is coming in as a rejected promise, skipping the .then function
        if (err?.response) {
          errors.push({
            message:
              err?.response?.errors?.[0].message || 'Something went wrong',
            code:
              err?.response?.errors?.[0].extensions?.code || 'default:no_code',
          });
        }
        // requestResponse is triggering the .then function returning data.errors
        else {
          errors.push({
            message: err?.message || 'Something went wrong',
            code: err?.code || 'default:no_code',
          });
        }

        reject(errors);
      });
  });
}
