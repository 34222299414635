/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query FetchFirmLicense {\n    fetchFirmLicense {\n      id\n      billingCycle\n      billingStartDay\n      licenseTier\n      seatCount\n      status\n    }\n  }\n": types.FetchFirmLicenseDocument,
    "\n  mutation UpdateFirmLicense(\n    $licenseTier: LicenseTier!\n    $billingCycle: BillingCycle!\n  ) {\n    updateFirmLicense(licenseTier: $licenseTier, billingCycle: $billingCycle) {\n      errors {\n        message\n      }\n      firmLicense {\n        id\n        seatCount\n        billingCycle\n        licenseTier\n      }\n    }\n  }\n": types.UpdateFirmLicenseDocument,
    "\n  query listRequests(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n  ) {\n    listRequests(status: $status, statuses: $statuses) {\n      assignedAccount {\n        name\n      }\n      assignedContact {\n        firstName\n        lastName\n        smsOptIn\n        smsNumber\n      }\n      createdAt\n      dueDate\n      firm {\n        companyName\n      }\n      id\n      lastUpdated\n      owner {\n        id\n      }\n      percentComplete\n      requestType\n      status\n      title\n      updatedAt\n    }\n  }\n": types.ListRequestsDocument,
    "\n  query requestBundle($id: Uuid!) {\n    requestBundle(id: $id) {\n      assignedAccount {\n        name\n      }\n      assignedContact {\n        firstName\n        lastName\n      }\n      createdAt\n      dueDate\n      firm {\n        companyName\n      }\n      id\n      updatedAt\n      owner {\n        id\n      }\n      requests {\n        ...WorkflowRequest\n      }\n      status\n      title\n      updatedAt\n    }\n  }\n": types.RequestBundleDocument,
    "\n  mutation updateRequestBundle(\n    $requestBundleId: Uuid!\n    $legacyAssignedContactId: ID\n    $legacyAssignedAccountId: ID\n    $legacyCpaUserId: ID\n    $assignedContactId: Uuid\n    $assignedAccountId: Uuid\n    $dueDate: ISO8601DateTime\n  ) {\n    updateRequestBundle(\n      legacyAssignedContactId: $legacyAssignedContactId\n      legacyAssignedAccountId: $legacyAssignedAccountId\n      requestBundleId: $requestBundleId\n      assignedContactId: $assignedContactId\n      assignedAccountId: $assignedAccountId\n      dueDate: $dueDate\n      legacyCpaUserId: $legacyCpaUserId\n    ) {\n      errors {\n        message\n      }\n      requestBundle {\n        title\n        id\n        dueDate\n        assignedContact {\n          id\n        }\n      }\n    }\n  }\n": types.UpdateRequestBundleDocument,
    "\n  mutation dispatchRequestBundle($requestBundleId: Uuid!) {\n    dispatchRequestBundle(requestBundleId: $requestBundleId) {\n      errors {\n        message\n      }\n      requestBundle {\n        id\n      }\n    }\n  }\n": types.DispatchRequestBundleDocument,
    "\n  mutation createRequestBundle($workflowIds: [Uuid!]) {\n    createRequestBundle(workflowIds: $workflowIds) {\n      errors {\n        message\n      }\n      requestBundle {\n        id\n      }\n    }\n  }\n": types.CreateRequestBundleDocument,
    "\n  query rpaDocuments(\n    $status: RpaDocumentStatus\n    $page: Int\n    $perPage: Int\n    $sortField: RpaDocumentSortOption\n    $sortDirection: String\n  ) {\n    rpaDocuments(\n      status: $status\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        id\n        title\n        lastUpdated\n      }\n    }\n  }\n": types.RpaDocumentsDocument,
    "\n  query todoFeeds {\n    todoFeeds {\n      id\n      feedItemType\n      createdAt\n      updatedAt\n      isRead\n      item {\n        ... on WorkflowRequest {\n          id\n          dueDate\n          status\n          title\n        }\n        ... on Invoice {\n          itemId: id\n          dueDate: dueBy\n          legacyId\n          subject\n          isRecurring\n        }\n        ... on Task {\n          itemId: id\n          dueDate: dueBy\n          legacyId\n          subject\n        }\n      }\n    }\n  }\n": types.TodoFeedsDocument,
    "\n  mutation deleteWorkflowItem($workflowItemId: Uuid!) {\n    deleteWorkflowItem(workflowItemId: $workflowItemId) {\n      errors {\n        message\n      }\n    }\n  }\n": types.DeleteWorkflowItemDocument,
    "\n  mutation updateWorkflowItem(\n    $prompt: String\n    $type: String\n    $workflowItemId: Uuid!\n  ) {\n    updateWorkflowItem(\n      prompt: $prompt\n      type: $type\n      workflowItemId: $workflowItemId\n    ) {\n      errors {\n        message\n      }\n      workflowItem {\n        ...WorkflowItem\n      }\n    }\n  }\n": types.UpdateWorkflowItemDocument,
    "\n  mutation createWorkflowItem(\n    $workflowSectionId: Uuid\n    $workflowId: Uuid\n    $workflowItemType: String!\n    $prompt: String!\n    $conditionalParentId: Uuid\n  ) {\n    createWorkflowItem(\n      workflowSectionId: $workflowSectionId\n      workflowId: $workflowId\n      workflowItemType: $workflowItemType\n      prompt: $prompt\n      conditionalParentId: $conditionalParentId\n    ) {\n      errors {\n        message\n      }\n      workflowItem {\n        ...WorkflowItem\n      }\n    }\n  }\n": types.CreateWorkflowItemDocument,
    "\n  mutation reorderWorkflowItems(\n    $workflowSectionId: Uuid!\n    $workflowItemIds: [Uuid!]!\n  ) {\n    reorderWorkflowItems(\n      workflowItemIds: $workflowItemIds\n      workflowSectionId: $workflowSectionId\n    ) {\n      errors {\n        message\n      }\n      workflowSection {\n        ...WorkflowSection\n      }\n    }\n  }\n": types.ReorderWorkflowItemsDocument,
    "\n  mutation reorderWorkflowSections(\n    $workflowId: Uuid!\n    $workflowSectionIds: [Uuid!]!\n  ) {\n    reorderWorkflowSections(\n      workflowId: $workflowId\n      workflowSectionIds: $workflowSectionIds\n    ) {\n      errors {\n        message\n      }\n      workflowSections {\n        ...WorkflowSection\n      }\n    }\n  }\n": types.ReorderWorkflowSectionsDocument,
    "\n  mutation createWorkflowItemCondition(\n    $workflowItemId: Uuid!\n    $conditionType: String!\n    $fieldName: String!\n    $matchValue: String\n    $rangeLowerBound: String\n    $rangeUpperBound: String\n  ) {\n    createWorkflowItemCondition(\n      workflowItemId: $workflowItemId\n      conditionType: $conditionType\n      fieldName: $fieldName\n      matchValue: $matchValue\n      rangeLowerBound: $rangeLowerBound\n      rangeUpperBound: $rangeUpperBound\n    ) {\n      errors {\n        message\n      }\n      workflowItemCondition {\n        id\n        conditionType\n        fieldName\n        matchValue\n        rangeLowerBound\n        rangeUpperBound\n      }\n    }\n  }\n": types.CreateWorkflowItemConditionDocument,
    "\n  mutation updateWorkflowItemCondition(\n    $workflowItemConditionId: Uuid!\n    $conditionType: String\n    $fieldName: String\n    $matchValue: String\n    $rangeLowerBound: String\n    $rangeUpperBound: String\n  ) {\n    updateWorkflowItemCondition(\n      workflowItemConditionId: $workflowItemConditionId\n      conditionType: $conditionType\n      fieldName: $fieldName\n      matchValue: $matchValue\n      rangeLowerBound: $rangeLowerBound\n      rangeUpperBound: $rangeUpperBound\n    ) {\n      errors {\n        message\n      }\n      workflowItemCondition {\n        id\n        conditionType\n        fieldName\n        matchValue\n        rangeLowerBound\n        rangeUpperBound\n      }\n    }\n  }\n": types.UpdateWorkflowItemConditionDocument,
    "\n  mutation deleteWorkflowItemCondition($workflowItemConditionId: Uuid!) {\n    deleteWorkflowItemCondition(\n      workflowItemConditionId: $workflowItemConditionId\n    ) {\n      errors {\n        message\n      }\n    }\n  }\n": types.DeleteWorkflowItemConditionDocument,
    "\n  mutation deleteWorkflowRequest($workflowRequestId: Uuid!) {\n    deleteWorkflowRequest(workflowRequestId: $workflowRequestId) {\n      errors {\n        message\n      }\n    }\n  }\n": types.DeleteWorkflowRequestDocument,
    "\n  fragment RpaDocument on RpaDocument {\n    id\n    fileUrl\n    legacyId\n    title\n  }\n": types.RpaDocumentFragmentDoc,
    "\n  query rpaDocumentProcessingCount($status: RpaDocumentStatus) {\n    rpaDocuments(status: $status) {\n      totalCount\n    }\n  }\n": types.RpaDocumentProcessingCountDocument,
    "\n  fragment WorkflowRequestSection on WorkflowRequestSection {\n    id\n    title\n    requestItems {\n      ...WorkflowRequestItem\n    }\n  }\n": types.WorkflowRequestSectionFragmentDoc,
    "\n  fragment WorkflowRequest on WorkflowRequest {\n    id\n    title\n    assignedContacts {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      smsNumber\n      smsOptIn\n      cpaUser {\n        id\n      }\n    }\n    assignedEmployees {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n    }\n    owner {\n      contact {\n        firstName\n        lastName\n      }\n      employee {\n        firstName\n        lastName\n      }\n    }\n    updatedAt\n    dueDate\n    status\n    percentComplete\n    updatedAt\n    assignedAccount {\n      id\n      legacyId\n      name\n    }\n    workflow {\n      title\n    }\n    requestSections {\n      ...WorkflowRequestSection\n    }\n    rpaDocuments {\n      ...RpaDocument\n    }\n    workflow {\n      ...WorkflowWithSections\n    }\n  }\n": types.WorkflowRequestFragmentDoc,
    "\n  fragment WorkflowRequestsWorkflowRequest on WorkflowRequest {\n    id\n    title\n    assignedAccount {\n      id\n      name\n    }\n    assignedContacts {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      smsNumber\n      smsOptIn\n      cpaUser {\n        id\n      }\n    }\n    assignedEmployees {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      cpaUser {\n        id\n      }\n    }\n    owner {\n      employee {\n        firstName\n        lastName\n      }\n      contact {\n        firstName\n        lastName\n      }\n    }\n    updatedAt\n    dueDate\n    status\n    percentComplete\n    updatedAt\n    workflow {\n      title\n      type\n    }\n  }\n": types.WorkflowRequestsWorkflowRequestFragmentDoc,
    "\n  fragment WorkflowRequestItem on WorkflowRequestItem {\n    responseItem {\n      ...WorkflowResponseItem\n    }\n    prompt\n    dependency {\n      ... on Invoice {\n        legacyId\n      }\n      ... on Document {\n        id\n        uuid\n        legacyId\n        awsUrlFileName\n      }\n      ... on Payable {\n        payableId: id\n        document {\n          id\n          uuid\n          legacyId\n        }\n        url\n      }\n    }\n    id\n  }\n": types.WorkflowRequestItemFragmentDoc,
    "\n  query workflowRequestQuery($id: Uuid!) {\n    workflowRequest(id: $id) {\n      ...WorkflowRequest\n    }\n  }\n": types.WorkflowRequestQueryDocument,
    "\n  query workflowRequestsQuery(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n    $filter: String\n    $filterData: WfRequestsFilterInput\n    $page: Int\n    $perPage: Int\n    $sortField: WfRequestSortOption\n    $sortDirection: String\n  ) {\n    workflowRequests(\n      status: $status\n      statuses: $statuses\n      filter: $filter\n      filterData: $filterData\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        ...WorkflowRequestsWorkflowRequest\n      }\n    }\n  }\n": types.WorkflowRequestsQueryDocument,
    "\n  query workflowRequestsCount(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n    $filterData: WfRequestsFilterInput\n  ) {\n    workflowRequests(\n      status: $status\n      statuses: $statuses\n      filterData: $filterData\n    ) {\n      totalCount\n    }\n  }\n": types.WorkflowRequestsCountDocument,
    "\n  mutation createWorkflowRequest($workflowId: Uuid!, $title: String) {\n    createWorkflowRequest(workflowId: $workflowId, title: $title) {\n      errors {\n        message\n      }\n      workflowRequest {\n        title\n        id\n      }\n    }\n  }\n": types.CreateWorkflowRequestDocument,
    "\n  mutation updateWorkflowRequest(\n    $workflowRequestId: Uuid!\n    $legacyAssignedAccountId: ID\n    $assignedContactIds: [Uuid!]\n    $assignedAccountId: Uuid\n    $dueDate: ISO8601DateTime\n    $title: String\n    $status: WorkflowRequestStatus\n  ) {\n    updateWorkflowRequest(\n      legacyAssignedAccountId: $legacyAssignedAccountId\n      workflowRequestId: $workflowRequestId\n      assignedContactIds: $assignedContactIds\n      assignedAccountId: $assignedAccountId\n      dueDate: $dueDate\n      title: $title\n      status: $status\n    ) {\n      errors {\n        message\n      }\n      workflowRequest {\n        title\n        id\n        dueDate\n        assignedContacts {\n          id\n        }\n      }\n    }\n  }\n": types.UpdateWorkflowRequestDocument,
    "\n    mutation assignEmployeeWorkflowRequest(\n      $workflowRequestId: Uuid!\n      $assignedEmployeeIds: [ID!]\n    ) {\n      assignEmployeeWorkflowRequest(\n        workflowRequestId: $workflowRequestId\n        assignedEmployeeIds: $assignedEmployeeIds\n      ) {\n        errors {\n          message\n        }\n        workflowRequest {\n          title\n          id\n          dueDate\n          assignedContacts {\n            id\n          }\n        }\n      }\n    }\n  ": types.AssignEmployeeWorkflowRequestDocument,
    "\n  mutation dispatchWorkflowRequest($workflowRequestId: Uuid!) {\n    dispatchWorkflowRequest(workflowRequestId: $workflowRequestId) {\n      errors {\n        message\n      }\n      workflowRequest {\n        id\n      }\n    }\n  }\n": types.DispatchWorkflowRequestDocument,
    "\n  mutation bulkSendRequests(\n    $workflowId: Uuid!\n    $recipientList: [WfRequestRecipientInput!]!\n    $title: String\n    $dueDate: ISO8601DateTime\n  ) {\n    bulkSendRequests(\n      workflowId: $workflowId\n      recipientList: $recipientList\n      title: $title\n      dueDate: $dueDate\n    ) {\n      errors {\n        message\n      }\n      workflowRequests {\n        id\n      }\n    }\n  }\n": types.BulkSendRequestsDocument,
    "\n  fragment WorkflowSection on WorkflowSection {\n    createdAt\n    id\n    position\n    items {\n      ...WorkflowItem\n      ...WorkflowSignableDocumentItem\n    }\n    title\n    updatedAt\n  }\n": types.WorkflowSectionFragmentDoc,
    "\n  fragment WorkflowWithoutSections on Workflow {\n    id\n    title\n    description\n    createdAt\n    status\n    type\n  }\n": types.WorkflowWithoutSectionsFragmentDoc,
    "\n  fragment WorkflowWithSections on Workflow {\n    ...WorkflowWithoutSections\n    sections {\n      ...WorkflowSection\n    }\n  }\n": types.WorkflowWithSectionsFragmentDoc,
    "\n  query workflows(\n    $status: WorkflowStatus\n    $statuses: [WorkflowStatus!]\n    $page: Int\n    $perPage: Int\n    $sortField: WorkflowSortOption\n    $sortDirection: String\n  ) {\n    workflows(\n      status: $status\n      statuses: $statuses\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        ...WorkflowWithoutSections\n      }\n    }\n  }\n": types.WorkflowsDocument,
    "\n  query workflowsCount(\n    $status: WorkflowStatus\n    $statuses: [WorkflowStatus!]\n    $page: Int\n    $perPage: Int\n  ) {\n    workflows(\n      status: $status\n      statuses: $statuses\n      page: $page\n      perPage: $perPage\n    ) {\n      totalCount\n    }\n  }\n": types.WorkflowsCountDocument,
    "\n  query workflowQuery($id: Uuid!) {\n    workflow(id: $id) {\n      ...WorkflowWithSections\n    }\n  }\n": types.WorkflowQueryDocument,
    "\n  mutation createWorkflow($title: String!, $description: String) {\n    createWorkflow(title: $title, description: $description) {\n      errors {\n        message\n      }\n      workflow {\n        id\n        title\n        description\n      }\n    }\n  }\n": types.CreateWorkflowDocument,
    "\n  mutation updateWorkflow($status: String!, $workflowId: Uuid!) {\n    updateWorkflow(status: $status, workflowId: $workflowId) {\n      errors {\n        message\n      }\n      workflow {\n        id\n        status\n      }\n    }\n  }\n": types.UpdateWorkflowDocument,
    "\n  mutation createWorkflowSection($workflowId: Uuid!, $title: String!) {\n    createWorkflowSection(workflowId: $workflowId, title: $title) {\n      errors {\n        message\n      }\n      workflowSection {\n        id\n      }\n    }\n  }\n": types.CreateWorkflowSectionDocument,
    "\n  mutation deleteWorkflowSection($workflowSectionId: Uuid!) {\n    deleteWorkflowSection(workflowSectionId: $workflowSectionId) {\n      errors {\n        message\n      }\n    }\n  }\n": types.DeleteWorkflowSectionDocument,
    "\n  mutation updateWorkflowSection($title: String!, $workflowSectionId: Uuid!) {\n    updateWorkflowSection(\n      title: $title\n      workflowSectionId: $workflowSectionId\n    ) {\n      errors {\n        message\n      }\n      workflowSection {\n        id\n      }\n    }\n  }\n": types.UpdateWorkflowSectionDocument,
    "\n  fragment WorkflowItemCondition on WorkflowItemCondition {\n    id\n    conditionType\n    fieldName\n    matchValue\n    rangeLowerBound\n    rangeUpperBound\n  }\n": types.WorkflowItemConditionFragmentDoc,
    "\n  fragment WorkflowItemInterface on WorkflowItemInterface {\n    id\n    prompt\n    type\n    conditionalParentId\n    updatedAt\n    position\n    section {\n      id\n    }\n    conditions {\n      ...WorkflowItemCondition\n    }\n  }\n": types.WorkflowItemInterfaceFragmentDoc,
    "\n  fragment WorkflowItem on WorkflowItemInterface {\n    ...DefaultWorkflowItem\n    ...WorkflowSignableDocumentItem\n  }\n": types.WorkflowItemFragmentDoc,
    "\n  fragment DefaultWorkflowItem on WorkflowItem {\n    ... on WorkflowItem {\n      ...WorkflowItemInterface\n    }\n  }\n": types.DefaultWorkflowItemFragmentDoc,
    "\n  fragment WorkflowSignableDocumentItem on WorkflowSignableDocumentItem {\n    ... on WorkflowSignableDocumentItem {\n      ...WorkflowItemInterface\n      esignAgreement {\n        documentToSign {\n          id\n          uuid\n          legacyId\n          awsUrlFileName\n        }\n        signatories {\n          ...Signers\n        }\n        signingAvailableUrl\n        startSigningUrl\n      }\n    }\n  }\n": types.WorkflowSignableDocumentItemFragmentDoc,
    "\n  fragment Signers on AgreementSignatory {\n    id\n    label\n    contact {\n      id\n    }\n    kbaRequired\n    signOnSameDevice\n    esignSignatureBlocks {\n      pageNumber\n      top\n      left\n      width\n      height\n    }\n  }\n": types.SignersFragmentDoc,
    "\n  query workflowTemplates($statuses: [WorkflowTemplateStatus!]) {\n    workflowTemplates(statuses: $statuses) {\n      ...WorkflowTemplate\n    }\n  }\n": types.WorkflowTemplatesDocument,
    "\n    mutation createWorkflowTemplateFromWorkflow($id: Uuid!) {\n      createWorkflowTemplateFromWorkflow(workflowId: $id) {\n        errors {\n          message\n        }\n        workflowTemplate {\n          ...WorkflowTemplate\n        }\n      }\n    }\n  ": types.CreateWorkflowTemplateFromWorkflowDocument,
    "\n    mutation createWorkflowsFromWorkflowTemplateDocument($id: Uuid!) {\n      createWorkflowsFromWorkflowTemplate(workflowTemplateId: $id) {\n        errors {\n          message\n        }\n        workflows {\n          id\n        }\n      }\n    }\n  ": types.CreateWorkflowsFromWorkflowTemplateDocumentDocument,
    "\n    mutation updateWorkflowTemplateFromWorkflow(\n      $workflowId: Uuid!\n      $workflowTemplateId: Uuid!\n    ) {\n      updateWorkflowTemplateFromWorkflow(\n        workflowId: $workflowId\n        workflowTemplateId: $workflowTemplateId\n      ) {\n        errors {\n          message\n        }\n        workflowTemplate {\n          ...WorkflowTemplate\n        }\n      }\n    }\n  ": types.UpdateWorkflowTemplateFromWorkflowDocument,
    "\n  mutation deleteWorkflowTemplate($workflowTemplateId: Uuid!) {\n    deleteWorkflowTemplate(workflowTemplateId: $workflowTemplateId) {\n      errors {\n        message\n      }\n      workflowTemplate {\n        ...WorkflowTemplate\n      }\n    }\n  }\n": types.DeleteWorkflowTemplateDocument,
    "\n  fragment WorkflowTemplate on WorkflowTemplate {\n    id\n    createdAt\n    updatedAt\n    displayName\n    status\n    firm {\n      id\n    }\n  }\n": types.WorkflowTemplateFragmentDoc,
    "\n  mutation saveWorkflow($id: Uuid!, $title: String, $description: String) {\n    saveWorkflow(id: $id, title: $title, description: $description) {\n      errors {\n        message\n      }\n      workflow {\n        id\n      }\n    }\n  }\n": types.SaveWorkflowDocument,
    "\n  mutation readyToSend($workflowId: Uuid!) {\n    readyToSend(workflowId: $workflowId) {\n      errors {\n        message\n      }\n      workflowRequest {\n        id\n      }\n    }\n  }\n": types.ReadyToSendDocument,
    "\n  fragment WorkflowResponseItem on WorkflowResponseItem {\n    id\n    type\n    updatedAt\n    notApplicable\n    value {\n      ...responseDocumentCollectionValue\n      ...responseTextValue\n      ...responseBooleanValue\n    }\n    workflowItem {\n      ...WorkflowItem\n    }\n  }\n": types.WorkflowResponseItemFragmentDoc,
    "\n  mutation updateResponseItemBool(\n    $notApplicable: Boolean\n    $booleanValue: Boolean\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemBool(\n      notApplicable: $notApplicable\n      booleanValue: $booleanValue\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        id\n      }\n    }\n  }\n": types.UpdateResponseItemBoolDocument,
    "\n  fragment responseBooleanValue on BooleanValue {\n    booleanValue: value\n  }\n": types.ResponseBooleanValueFragmentDoc,
    "\n  mutation updateResponseItemText(\n    $notApplicable: Boolean\n    $textValue: String\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemText(\n      notApplicable: $notApplicable\n      textValue: $textValue\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        ...WorkflowResponseItem\n      }\n    }\n  }\n": types.UpdateResponseItemTextDocument,
    "\n  fragment responseTextValue on StringValue {\n    value\n  }\n": types.ResponseTextValueFragmentDoc,
    "\n  mutation updateResponseItemDocUpload(\n    $notApplicable: Boolean\n    $liscioObjectData: [LiscioObjectInput!]\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemDocUpload(\n      notApplicable: $notApplicable\n      liscioObjectData: $liscioObjectData\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        ...WorkflowResponseItem\n      }\n    }\n  }\n": types.UpdateResponseItemDocUploadDocument,
    "\n  fragment responseDocumentCollectionValue on DocumentCollectionValue {\n    documentItems: value {\n      legacyId\n    }\n  }\n": types.ResponseDocumentCollectionValueFragmentDoc,
    "\n  mutation CreateSmsBrandRegistration($params: SmsBrandRegistrationInput!) {\n    createSmsBrandRegistration(params: $params) {\n      errors {\n        message\n      }\n      smsBrandRegistration {\n        id\n      }\n    }\n  }\n": types.CreateSmsBrandRegistrationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FetchFirmLicense {\n    fetchFirmLicense {\n      id\n      billingCycle\n      billingStartDay\n      licenseTier\n      seatCount\n      status\n    }\n  }\n"): (typeof documents)["\n  query FetchFirmLicense {\n    fetchFirmLicense {\n      id\n      billingCycle\n      billingStartDay\n      licenseTier\n      seatCount\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateFirmLicense(\n    $licenseTier: LicenseTier!\n    $billingCycle: BillingCycle!\n  ) {\n    updateFirmLicense(licenseTier: $licenseTier, billingCycle: $billingCycle) {\n      errors {\n        message\n      }\n      firmLicense {\n        id\n        seatCount\n        billingCycle\n        licenseTier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateFirmLicense(\n    $licenseTier: LicenseTier!\n    $billingCycle: BillingCycle!\n  ) {\n    updateFirmLicense(licenseTier: $licenseTier, billingCycle: $billingCycle) {\n      errors {\n        message\n      }\n      firmLicense {\n        id\n        seatCount\n        billingCycle\n        licenseTier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listRequests(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n  ) {\n    listRequests(status: $status, statuses: $statuses) {\n      assignedAccount {\n        name\n      }\n      assignedContact {\n        firstName\n        lastName\n        smsOptIn\n        smsNumber\n      }\n      createdAt\n      dueDate\n      firm {\n        companyName\n      }\n      id\n      lastUpdated\n      owner {\n        id\n      }\n      percentComplete\n      requestType\n      status\n      title\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query listRequests(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n  ) {\n    listRequests(status: $status, statuses: $statuses) {\n      assignedAccount {\n        name\n      }\n      assignedContact {\n        firstName\n        lastName\n        smsOptIn\n        smsNumber\n      }\n      createdAt\n      dueDate\n      firm {\n        companyName\n      }\n      id\n      lastUpdated\n      owner {\n        id\n      }\n      percentComplete\n      requestType\n      status\n      title\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query requestBundle($id: Uuid!) {\n    requestBundle(id: $id) {\n      assignedAccount {\n        name\n      }\n      assignedContact {\n        firstName\n        lastName\n      }\n      createdAt\n      dueDate\n      firm {\n        companyName\n      }\n      id\n      updatedAt\n      owner {\n        id\n      }\n      requests {\n        ...WorkflowRequest\n      }\n      status\n      title\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query requestBundle($id: Uuid!) {\n    requestBundle(id: $id) {\n      assignedAccount {\n        name\n      }\n      assignedContact {\n        firstName\n        lastName\n      }\n      createdAt\n      dueDate\n      firm {\n        companyName\n      }\n      id\n      updatedAt\n      owner {\n        id\n      }\n      requests {\n        ...WorkflowRequest\n      }\n      status\n      title\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateRequestBundle(\n    $requestBundleId: Uuid!\n    $legacyAssignedContactId: ID\n    $legacyAssignedAccountId: ID\n    $legacyCpaUserId: ID\n    $assignedContactId: Uuid\n    $assignedAccountId: Uuid\n    $dueDate: ISO8601DateTime\n  ) {\n    updateRequestBundle(\n      legacyAssignedContactId: $legacyAssignedContactId\n      legacyAssignedAccountId: $legacyAssignedAccountId\n      requestBundleId: $requestBundleId\n      assignedContactId: $assignedContactId\n      assignedAccountId: $assignedAccountId\n      dueDate: $dueDate\n      legacyCpaUserId: $legacyCpaUserId\n    ) {\n      errors {\n        message\n      }\n      requestBundle {\n        title\n        id\n        dueDate\n        assignedContact {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateRequestBundle(\n    $requestBundleId: Uuid!\n    $legacyAssignedContactId: ID\n    $legacyAssignedAccountId: ID\n    $legacyCpaUserId: ID\n    $assignedContactId: Uuid\n    $assignedAccountId: Uuid\n    $dueDate: ISO8601DateTime\n  ) {\n    updateRequestBundle(\n      legacyAssignedContactId: $legacyAssignedContactId\n      legacyAssignedAccountId: $legacyAssignedAccountId\n      requestBundleId: $requestBundleId\n      assignedContactId: $assignedContactId\n      assignedAccountId: $assignedAccountId\n      dueDate: $dueDate\n      legacyCpaUserId: $legacyCpaUserId\n    ) {\n      errors {\n        message\n      }\n      requestBundle {\n        title\n        id\n        dueDate\n        assignedContact {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation dispatchRequestBundle($requestBundleId: Uuid!) {\n    dispatchRequestBundle(requestBundleId: $requestBundleId) {\n      errors {\n        message\n      }\n      requestBundle {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation dispatchRequestBundle($requestBundleId: Uuid!) {\n    dispatchRequestBundle(requestBundleId: $requestBundleId) {\n      errors {\n        message\n      }\n      requestBundle {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createRequestBundle($workflowIds: [Uuid!]) {\n    createRequestBundle(workflowIds: $workflowIds) {\n      errors {\n        message\n      }\n      requestBundle {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createRequestBundle($workflowIds: [Uuid!]) {\n    createRequestBundle(workflowIds: $workflowIds) {\n      errors {\n        message\n      }\n      requestBundle {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query rpaDocuments(\n    $status: RpaDocumentStatus\n    $page: Int\n    $perPage: Int\n    $sortField: RpaDocumentSortOption\n    $sortDirection: String\n  ) {\n    rpaDocuments(\n      status: $status\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        id\n        title\n        lastUpdated\n      }\n    }\n  }\n"): (typeof documents)["\n  query rpaDocuments(\n    $status: RpaDocumentStatus\n    $page: Int\n    $perPage: Int\n    $sortField: RpaDocumentSortOption\n    $sortDirection: String\n  ) {\n    rpaDocuments(\n      status: $status\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        id\n        title\n        lastUpdated\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query todoFeeds {\n    todoFeeds {\n      id\n      feedItemType\n      createdAt\n      updatedAt\n      isRead\n      item {\n        ... on WorkflowRequest {\n          id\n          dueDate\n          status\n          title\n        }\n        ... on Invoice {\n          itemId: id\n          dueDate: dueBy\n          legacyId\n          subject\n          isRecurring\n        }\n        ... on Task {\n          itemId: id\n          dueDate: dueBy\n          legacyId\n          subject\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query todoFeeds {\n    todoFeeds {\n      id\n      feedItemType\n      createdAt\n      updatedAt\n      isRead\n      item {\n        ... on WorkflowRequest {\n          id\n          dueDate\n          status\n          title\n        }\n        ... on Invoice {\n          itemId: id\n          dueDate: dueBy\n          legacyId\n          subject\n          isRecurring\n        }\n        ... on Task {\n          itemId: id\n          dueDate: dueBy\n          legacyId\n          subject\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteWorkflowItem($workflowItemId: Uuid!) {\n    deleteWorkflowItem(workflowItemId: $workflowItemId) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteWorkflowItem($workflowItemId: Uuid!) {\n    deleteWorkflowItem(workflowItemId: $workflowItemId) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateWorkflowItem(\n    $prompt: String\n    $type: String\n    $workflowItemId: Uuid!\n  ) {\n    updateWorkflowItem(\n      prompt: $prompt\n      type: $type\n      workflowItemId: $workflowItemId\n    ) {\n      errors {\n        message\n      }\n      workflowItem {\n        ...WorkflowItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateWorkflowItem(\n    $prompt: String\n    $type: String\n    $workflowItemId: Uuid!\n  ) {\n    updateWorkflowItem(\n      prompt: $prompt\n      type: $type\n      workflowItemId: $workflowItemId\n    ) {\n      errors {\n        message\n      }\n      workflowItem {\n        ...WorkflowItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWorkflowItem(\n    $workflowSectionId: Uuid\n    $workflowId: Uuid\n    $workflowItemType: String!\n    $prompt: String!\n    $conditionalParentId: Uuid\n  ) {\n    createWorkflowItem(\n      workflowSectionId: $workflowSectionId\n      workflowId: $workflowId\n      workflowItemType: $workflowItemType\n      prompt: $prompt\n      conditionalParentId: $conditionalParentId\n    ) {\n      errors {\n        message\n      }\n      workflowItem {\n        ...WorkflowItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createWorkflowItem(\n    $workflowSectionId: Uuid\n    $workflowId: Uuid\n    $workflowItemType: String!\n    $prompt: String!\n    $conditionalParentId: Uuid\n  ) {\n    createWorkflowItem(\n      workflowSectionId: $workflowSectionId\n      workflowId: $workflowId\n      workflowItemType: $workflowItemType\n      prompt: $prompt\n      conditionalParentId: $conditionalParentId\n    ) {\n      errors {\n        message\n      }\n      workflowItem {\n        ...WorkflowItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation reorderWorkflowItems(\n    $workflowSectionId: Uuid!\n    $workflowItemIds: [Uuid!]!\n  ) {\n    reorderWorkflowItems(\n      workflowItemIds: $workflowItemIds\n      workflowSectionId: $workflowSectionId\n    ) {\n      errors {\n        message\n      }\n      workflowSection {\n        ...WorkflowSection\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation reorderWorkflowItems(\n    $workflowSectionId: Uuid!\n    $workflowItemIds: [Uuid!]!\n  ) {\n    reorderWorkflowItems(\n      workflowItemIds: $workflowItemIds\n      workflowSectionId: $workflowSectionId\n    ) {\n      errors {\n        message\n      }\n      workflowSection {\n        ...WorkflowSection\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation reorderWorkflowSections(\n    $workflowId: Uuid!\n    $workflowSectionIds: [Uuid!]!\n  ) {\n    reorderWorkflowSections(\n      workflowId: $workflowId\n      workflowSectionIds: $workflowSectionIds\n    ) {\n      errors {\n        message\n      }\n      workflowSections {\n        ...WorkflowSection\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation reorderWorkflowSections(\n    $workflowId: Uuid!\n    $workflowSectionIds: [Uuid!]!\n  ) {\n    reorderWorkflowSections(\n      workflowId: $workflowId\n      workflowSectionIds: $workflowSectionIds\n    ) {\n      errors {\n        message\n      }\n      workflowSections {\n        ...WorkflowSection\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWorkflowItemCondition(\n    $workflowItemId: Uuid!\n    $conditionType: String!\n    $fieldName: String!\n    $matchValue: String\n    $rangeLowerBound: String\n    $rangeUpperBound: String\n  ) {\n    createWorkflowItemCondition(\n      workflowItemId: $workflowItemId\n      conditionType: $conditionType\n      fieldName: $fieldName\n      matchValue: $matchValue\n      rangeLowerBound: $rangeLowerBound\n      rangeUpperBound: $rangeUpperBound\n    ) {\n      errors {\n        message\n      }\n      workflowItemCondition {\n        id\n        conditionType\n        fieldName\n        matchValue\n        rangeLowerBound\n        rangeUpperBound\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createWorkflowItemCondition(\n    $workflowItemId: Uuid!\n    $conditionType: String!\n    $fieldName: String!\n    $matchValue: String\n    $rangeLowerBound: String\n    $rangeUpperBound: String\n  ) {\n    createWorkflowItemCondition(\n      workflowItemId: $workflowItemId\n      conditionType: $conditionType\n      fieldName: $fieldName\n      matchValue: $matchValue\n      rangeLowerBound: $rangeLowerBound\n      rangeUpperBound: $rangeUpperBound\n    ) {\n      errors {\n        message\n      }\n      workflowItemCondition {\n        id\n        conditionType\n        fieldName\n        matchValue\n        rangeLowerBound\n        rangeUpperBound\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateWorkflowItemCondition(\n    $workflowItemConditionId: Uuid!\n    $conditionType: String\n    $fieldName: String\n    $matchValue: String\n    $rangeLowerBound: String\n    $rangeUpperBound: String\n  ) {\n    updateWorkflowItemCondition(\n      workflowItemConditionId: $workflowItemConditionId\n      conditionType: $conditionType\n      fieldName: $fieldName\n      matchValue: $matchValue\n      rangeLowerBound: $rangeLowerBound\n      rangeUpperBound: $rangeUpperBound\n    ) {\n      errors {\n        message\n      }\n      workflowItemCondition {\n        id\n        conditionType\n        fieldName\n        matchValue\n        rangeLowerBound\n        rangeUpperBound\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateWorkflowItemCondition(\n    $workflowItemConditionId: Uuid!\n    $conditionType: String\n    $fieldName: String\n    $matchValue: String\n    $rangeLowerBound: String\n    $rangeUpperBound: String\n  ) {\n    updateWorkflowItemCondition(\n      workflowItemConditionId: $workflowItemConditionId\n      conditionType: $conditionType\n      fieldName: $fieldName\n      matchValue: $matchValue\n      rangeLowerBound: $rangeLowerBound\n      rangeUpperBound: $rangeUpperBound\n    ) {\n      errors {\n        message\n      }\n      workflowItemCondition {\n        id\n        conditionType\n        fieldName\n        matchValue\n        rangeLowerBound\n        rangeUpperBound\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteWorkflowItemCondition($workflowItemConditionId: Uuid!) {\n    deleteWorkflowItemCondition(\n      workflowItemConditionId: $workflowItemConditionId\n    ) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteWorkflowItemCondition($workflowItemConditionId: Uuid!) {\n    deleteWorkflowItemCondition(\n      workflowItemConditionId: $workflowItemConditionId\n    ) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteWorkflowRequest($workflowRequestId: Uuid!) {\n    deleteWorkflowRequest(workflowRequestId: $workflowRequestId) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteWorkflowRequest($workflowRequestId: Uuid!) {\n    deleteWorkflowRequest(workflowRequestId: $workflowRequestId) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment RpaDocument on RpaDocument {\n    id\n    fileUrl\n    legacyId\n    title\n  }\n"): (typeof documents)["\n  fragment RpaDocument on RpaDocument {\n    id\n    fileUrl\n    legacyId\n    title\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query rpaDocumentProcessingCount($status: RpaDocumentStatus) {\n    rpaDocuments(status: $status) {\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query rpaDocumentProcessingCount($status: RpaDocumentStatus) {\n    rpaDocuments(status: $status) {\n      totalCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowRequestSection on WorkflowRequestSection {\n    id\n    title\n    requestItems {\n      ...WorkflowRequestItem\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowRequestSection on WorkflowRequestSection {\n    id\n    title\n    requestItems {\n      ...WorkflowRequestItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowRequest on WorkflowRequest {\n    id\n    title\n    assignedContacts {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      smsNumber\n      smsOptIn\n      cpaUser {\n        id\n      }\n    }\n    assignedEmployees {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n    }\n    owner {\n      contact {\n        firstName\n        lastName\n      }\n      employee {\n        firstName\n        lastName\n      }\n    }\n    updatedAt\n    dueDate\n    status\n    percentComplete\n    updatedAt\n    assignedAccount {\n      id\n      legacyId\n      name\n    }\n    workflow {\n      title\n    }\n    requestSections {\n      ...WorkflowRequestSection\n    }\n    rpaDocuments {\n      ...RpaDocument\n    }\n    workflow {\n      ...WorkflowWithSections\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowRequest on WorkflowRequest {\n    id\n    title\n    assignedContacts {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      smsNumber\n      smsOptIn\n      cpaUser {\n        id\n      }\n    }\n    assignedEmployees {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n    }\n    owner {\n      contact {\n        firstName\n        lastName\n      }\n      employee {\n        firstName\n        lastName\n      }\n    }\n    updatedAt\n    dueDate\n    status\n    percentComplete\n    updatedAt\n    assignedAccount {\n      id\n      legacyId\n      name\n    }\n    workflow {\n      title\n    }\n    requestSections {\n      ...WorkflowRequestSection\n    }\n    rpaDocuments {\n      ...RpaDocument\n    }\n    workflow {\n      ...WorkflowWithSections\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowRequestsWorkflowRequest on WorkflowRequest {\n    id\n    title\n    assignedAccount {\n      id\n      name\n    }\n    assignedContacts {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      smsNumber\n      smsOptIn\n      cpaUser {\n        id\n      }\n    }\n    assignedEmployees {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      cpaUser {\n        id\n      }\n    }\n    owner {\n      employee {\n        firstName\n        lastName\n      }\n      contact {\n        firstName\n        lastName\n      }\n    }\n    updatedAt\n    dueDate\n    status\n    percentComplete\n    updatedAt\n    workflow {\n      title\n      type\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowRequestsWorkflowRequest on WorkflowRequest {\n    id\n    title\n    assignedAccount {\n      id\n      name\n    }\n    assignedContacts {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      smsNumber\n      smsOptIn\n      cpaUser {\n        id\n      }\n    }\n    assignedEmployees {\n      id\n      firstName\n      middleName\n      lastName\n      legacyId\n      cpaUser {\n        id\n      }\n    }\n    owner {\n      employee {\n        firstName\n        lastName\n      }\n      contact {\n        firstName\n        lastName\n      }\n    }\n    updatedAt\n    dueDate\n    status\n    percentComplete\n    updatedAt\n    workflow {\n      title\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowRequestItem on WorkflowRequestItem {\n    responseItem {\n      ...WorkflowResponseItem\n    }\n    prompt\n    dependency {\n      ... on Invoice {\n        legacyId\n      }\n      ... on Document {\n        id\n        uuid\n        legacyId\n        awsUrlFileName\n      }\n      ... on Payable {\n        payableId: id\n        document {\n          id\n          uuid\n          legacyId\n        }\n        url\n      }\n    }\n    id\n  }\n"): (typeof documents)["\n  fragment WorkflowRequestItem on WorkflowRequestItem {\n    responseItem {\n      ...WorkflowResponseItem\n    }\n    prompt\n    dependency {\n      ... on Invoice {\n        legacyId\n      }\n      ... on Document {\n        id\n        uuid\n        legacyId\n        awsUrlFileName\n      }\n      ... on Payable {\n        payableId: id\n        document {\n          id\n          uuid\n          legacyId\n        }\n        url\n      }\n    }\n    id\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflowRequestQuery($id: Uuid!) {\n    workflowRequest(id: $id) {\n      ...WorkflowRequest\n    }\n  }\n"): (typeof documents)["\n  query workflowRequestQuery($id: Uuid!) {\n    workflowRequest(id: $id) {\n      ...WorkflowRequest\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflowRequestsQuery(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n    $filter: String\n    $filterData: WfRequestsFilterInput\n    $page: Int\n    $perPage: Int\n    $sortField: WfRequestSortOption\n    $sortDirection: String\n  ) {\n    workflowRequests(\n      status: $status\n      statuses: $statuses\n      filter: $filter\n      filterData: $filterData\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        ...WorkflowRequestsWorkflowRequest\n      }\n    }\n  }\n"): (typeof documents)["\n  query workflowRequestsQuery(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n    $filter: String\n    $filterData: WfRequestsFilterInput\n    $page: Int\n    $perPage: Int\n    $sortField: WfRequestSortOption\n    $sortDirection: String\n  ) {\n    workflowRequests(\n      status: $status\n      statuses: $statuses\n      filter: $filter\n      filterData: $filterData\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        ...WorkflowRequestsWorkflowRequest\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflowRequestsCount(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n    $filterData: WfRequestsFilterInput\n  ) {\n    workflowRequests(\n      status: $status\n      statuses: $statuses\n      filterData: $filterData\n    ) {\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query workflowRequestsCount(\n    $status: WorkflowRequestStatus\n    $statuses: [WorkflowRequestStatus!]\n    $filterData: WfRequestsFilterInput\n  ) {\n    workflowRequests(\n      status: $status\n      statuses: $statuses\n      filterData: $filterData\n    ) {\n      totalCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWorkflowRequest($workflowId: Uuid!, $title: String) {\n    createWorkflowRequest(workflowId: $workflowId, title: $title) {\n      errors {\n        message\n      }\n      workflowRequest {\n        title\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createWorkflowRequest($workflowId: Uuid!, $title: String) {\n    createWorkflowRequest(workflowId: $workflowId, title: $title) {\n      errors {\n        message\n      }\n      workflowRequest {\n        title\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateWorkflowRequest(\n    $workflowRequestId: Uuid!\n    $legacyAssignedAccountId: ID\n    $assignedContactIds: [Uuid!]\n    $assignedAccountId: Uuid\n    $dueDate: ISO8601DateTime\n    $title: String\n    $status: WorkflowRequestStatus\n  ) {\n    updateWorkflowRequest(\n      legacyAssignedAccountId: $legacyAssignedAccountId\n      workflowRequestId: $workflowRequestId\n      assignedContactIds: $assignedContactIds\n      assignedAccountId: $assignedAccountId\n      dueDate: $dueDate\n      title: $title\n      status: $status\n    ) {\n      errors {\n        message\n      }\n      workflowRequest {\n        title\n        id\n        dueDate\n        assignedContacts {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateWorkflowRequest(\n    $workflowRequestId: Uuid!\n    $legacyAssignedAccountId: ID\n    $assignedContactIds: [Uuid!]\n    $assignedAccountId: Uuid\n    $dueDate: ISO8601DateTime\n    $title: String\n    $status: WorkflowRequestStatus\n  ) {\n    updateWorkflowRequest(\n      legacyAssignedAccountId: $legacyAssignedAccountId\n      workflowRequestId: $workflowRequestId\n      assignedContactIds: $assignedContactIds\n      assignedAccountId: $assignedAccountId\n      dueDate: $dueDate\n      title: $title\n      status: $status\n    ) {\n      errors {\n        message\n      }\n      workflowRequest {\n        title\n        id\n        dueDate\n        assignedContacts {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation assignEmployeeWorkflowRequest(\n      $workflowRequestId: Uuid!\n      $assignedEmployeeIds: [ID!]\n    ) {\n      assignEmployeeWorkflowRequest(\n        workflowRequestId: $workflowRequestId\n        assignedEmployeeIds: $assignedEmployeeIds\n      ) {\n        errors {\n          message\n        }\n        workflowRequest {\n          title\n          id\n          dueDate\n          assignedContacts {\n            id\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation assignEmployeeWorkflowRequest(\n      $workflowRequestId: Uuid!\n      $assignedEmployeeIds: [ID!]\n    ) {\n      assignEmployeeWorkflowRequest(\n        workflowRequestId: $workflowRequestId\n        assignedEmployeeIds: $assignedEmployeeIds\n      ) {\n        errors {\n          message\n        }\n        workflowRequest {\n          title\n          id\n          dueDate\n          assignedContacts {\n            id\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation dispatchWorkflowRequest($workflowRequestId: Uuid!) {\n    dispatchWorkflowRequest(workflowRequestId: $workflowRequestId) {\n      errors {\n        message\n      }\n      workflowRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation dispatchWorkflowRequest($workflowRequestId: Uuid!) {\n    dispatchWorkflowRequest(workflowRequestId: $workflowRequestId) {\n      errors {\n        message\n      }\n      workflowRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation bulkSendRequests(\n    $workflowId: Uuid!\n    $recipientList: [WfRequestRecipientInput!]!\n    $title: String\n    $dueDate: ISO8601DateTime\n  ) {\n    bulkSendRequests(\n      workflowId: $workflowId\n      recipientList: $recipientList\n      title: $title\n      dueDate: $dueDate\n    ) {\n      errors {\n        message\n      }\n      workflowRequests {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation bulkSendRequests(\n    $workflowId: Uuid!\n    $recipientList: [WfRequestRecipientInput!]!\n    $title: String\n    $dueDate: ISO8601DateTime\n  ) {\n    bulkSendRequests(\n      workflowId: $workflowId\n      recipientList: $recipientList\n      title: $title\n      dueDate: $dueDate\n    ) {\n      errors {\n        message\n      }\n      workflowRequests {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowSection on WorkflowSection {\n    createdAt\n    id\n    position\n    items {\n      ...WorkflowItem\n      ...WorkflowSignableDocumentItem\n    }\n    title\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment WorkflowSection on WorkflowSection {\n    createdAt\n    id\n    position\n    items {\n      ...WorkflowItem\n      ...WorkflowSignableDocumentItem\n    }\n    title\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowWithoutSections on Workflow {\n    id\n    title\n    description\n    createdAt\n    status\n    type\n  }\n"): (typeof documents)["\n  fragment WorkflowWithoutSections on Workflow {\n    id\n    title\n    description\n    createdAt\n    status\n    type\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowWithSections on Workflow {\n    ...WorkflowWithoutSections\n    sections {\n      ...WorkflowSection\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowWithSections on Workflow {\n    ...WorkflowWithoutSections\n    sections {\n      ...WorkflowSection\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflows(\n    $status: WorkflowStatus\n    $statuses: [WorkflowStatus!]\n    $page: Int\n    $perPage: Int\n    $sortField: WorkflowSortOption\n    $sortDirection: String\n  ) {\n    workflows(\n      status: $status\n      statuses: $statuses\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        ...WorkflowWithoutSections\n      }\n    }\n  }\n"): (typeof documents)["\n  query workflows(\n    $status: WorkflowStatus\n    $statuses: [WorkflowStatus!]\n    $page: Int\n    $perPage: Int\n    $sortField: WorkflowSortOption\n    $sortDirection: String\n  ) {\n    workflows(\n      status: $status\n      statuses: $statuses\n      page: $page\n      perPage: $perPage\n      sortField: $sortField\n      sortDirection: $sortDirection\n    ) {\n      totalCount\n      collection {\n        ...WorkflowWithoutSections\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflowsCount(\n    $status: WorkflowStatus\n    $statuses: [WorkflowStatus!]\n    $page: Int\n    $perPage: Int\n  ) {\n    workflows(\n      status: $status\n      statuses: $statuses\n      page: $page\n      perPage: $perPage\n    ) {\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query workflowsCount(\n    $status: WorkflowStatus\n    $statuses: [WorkflowStatus!]\n    $page: Int\n    $perPage: Int\n  ) {\n    workflows(\n      status: $status\n      statuses: $statuses\n      page: $page\n      perPage: $perPage\n    ) {\n      totalCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflowQuery($id: Uuid!) {\n    workflow(id: $id) {\n      ...WorkflowWithSections\n    }\n  }\n"): (typeof documents)["\n  query workflowQuery($id: Uuid!) {\n    workflow(id: $id) {\n      ...WorkflowWithSections\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWorkflow($title: String!, $description: String) {\n    createWorkflow(title: $title, description: $description) {\n      errors {\n        message\n      }\n      workflow {\n        id\n        title\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createWorkflow($title: String!, $description: String) {\n    createWorkflow(title: $title, description: $description) {\n      errors {\n        message\n      }\n      workflow {\n        id\n        title\n        description\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateWorkflow($status: String!, $workflowId: Uuid!) {\n    updateWorkflow(status: $status, workflowId: $workflowId) {\n      errors {\n        message\n      }\n      workflow {\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateWorkflow($status: String!, $workflowId: Uuid!) {\n    updateWorkflow(status: $status, workflowId: $workflowId) {\n      errors {\n        message\n      }\n      workflow {\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWorkflowSection($workflowId: Uuid!, $title: String!) {\n    createWorkflowSection(workflowId: $workflowId, title: $title) {\n      errors {\n        message\n      }\n      workflowSection {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createWorkflowSection($workflowId: Uuid!, $title: String!) {\n    createWorkflowSection(workflowId: $workflowId, title: $title) {\n      errors {\n        message\n      }\n      workflowSection {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteWorkflowSection($workflowSectionId: Uuid!) {\n    deleteWorkflowSection(workflowSectionId: $workflowSectionId) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteWorkflowSection($workflowSectionId: Uuid!) {\n    deleteWorkflowSection(workflowSectionId: $workflowSectionId) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateWorkflowSection($title: String!, $workflowSectionId: Uuid!) {\n    updateWorkflowSection(\n      title: $title\n      workflowSectionId: $workflowSectionId\n    ) {\n      errors {\n        message\n      }\n      workflowSection {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateWorkflowSection($title: String!, $workflowSectionId: Uuid!) {\n    updateWorkflowSection(\n      title: $title\n      workflowSectionId: $workflowSectionId\n    ) {\n      errors {\n        message\n      }\n      workflowSection {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowItemCondition on WorkflowItemCondition {\n    id\n    conditionType\n    fieldName\n    matchValue\n    rangeLowerBound\n    rangeUpperBound\n  }\n"): (typeof documents)["\n  fragment WorkflowItemCondition on WorkflowItemCondition {\n    id\n    conditionType\n    fieldName\n    matchValue\n    rangeLowerBound\n    rangeUpperBound\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowItemInterface on WorkflowItemInterface {\n    id\n    prompt\n    type\n    conditionalParentId\n    updatedAt\n    position\n    section {\n      id\n    }\n    conditions {\n      ...WorkflowItemCondition\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowItemInterface on WorkflowItemInterface {\n    id\n    prompt\n    type\n    conditionalParentId\n    updatedAt\n    position\n    section {\n      id\n    }\n    conditions {\n      ...WorkflowItemCondition\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowItem on WorkflowItemInterface {\n    ...DefaultWorkflowItem\n    ...WorkflowSignableDocumentItem\n  }\n"): (typeof documents)["\n  fragment WorkflowItem on WorkflowItemInterface {\n    ...DefaultWorkflowItem\n    ...WorkflowSignableDocumentItem\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DefaultWorkflowItem on WorkflowItem {\n    ... on WorkflowItem {\n      ...WorkflowItemInterface\n    }\n  }\n"): (typeof documents)["\n  fragment DefaultWorkflowItem on WorkflowItem {\n    ... on WorkflowItem {\n      ...WorkflowItemInterface\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowSignableDocumentItem on WorkflowSignableDocumentItem {\n    ... on WorkflowSignableDocumentItem {\n      ...WorkflowItemInterface\n      esignAgreement {\n        documentToSign {\n          id\n          uuid\n          legacyId\n          awsUrlFileName\n        }\n        signatories {\n          ...Signers\n        }\n        signingAvailableUrl\n        startSigningUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowSignableDocumentItem on WorkflowSignableDocumentItem {\n    ... on WorkflowSignableDocumentItem {\n      ...WorkflowItemInterface\n      esignAgreement {\n        documentToSign {\n          id\n          uuid\n          legacyId\n          awsUrlFileName\n        }\n        signatories {\n          ...Signers\n        }\n        signingAvailableUrl\n        startSigningUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Signers on AgreementSignatory {\n    id\n    label\n    contact {\n      id\n    }\n    kbaRequired\n    signOnSameDevice\n    esignSignatureBlocks {\n      pageNumber\n      top\n      left\n      width\n      height\n    }\n  }\n"): (typeof documents)["\n  fragment Signers on AgreementSignatory {\n    id\n    label\n    contact {\n      id\n    }\n    kbaRequired\n    signOnSameDevice\n    esignSignatureBlocks {\n      pageNumber\n      top\n      left\n      width\n      height\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query workflowTemplates($statuses: [WorkflowTemplateStatus!]) {\n    workflowTemplates(statuses: $statuses) {\n      ...WorkflowTemplate\n    }\n  }\n"): (typeof documents)["\n  query workflowTemplates($statuses: [WorkflowTemplateStatus!]) {\n    workflowTemplates(statuses: $statuses) {\n      ...WorkflowTemplate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation createWorkflowTemplateFromWorkflow($id: Uuid!) {\n      createWorkflowTemplateFromWorkflow(workflowId: $id) {\n        errors {\n          message\n        }\n        workflowTemplate {\n          ...WorkflowTemplate\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation createWorkflowTemplateFromWorkflow($id: Uuid!) {\n      createWorkflowTemplateFromWorkflow(workflowId: $id) {\n        errors {\n          message\n        }\n        workflowTemplate {\n          ...WorkflowTemplate\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation createWorkflowsFromWorkflowTemplateDocument($id: Uuid!) {\n      createWorkflowsFromWorkflowTemplate(workflowTemplateId: $id) {\n        errors {\n          message\n        }\n        workflows {\n          id\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation createWorkflowsFromWorkflowTemplateDocument($id: Uuid!) {\n      createWorkflowsFromWorkflowTemplate(workflowTemplateId: $id) {\n        errors {\n          message\n        }\n        workflows {\n          id\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateWorkflowTemplateFromWorkflow(\n      $workflowId: Uuid!\n      $workflowTemplateId: Uuid!\n    ) {\n      updateWorkflowTemplateFromWorkflow(\n        workflowId: $workflowId\n        workflowTemplateId: $workflowTemplateId\n      ) {\n        errors {\n          message\n        }\n        workflowTemplate {\n          ...WorkflowTemplate\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation updateWorkflowTemplateFromWorkflow(\n      $workflowId: Uuid!\n      $workflowTemplateId: Uuid!\n    ) {\n      updateWorkflowTemplateFromWorkflow(\n        workflowId: $workflowId\n        workflowTemplateId: $workflowTemplateId\n      ) {\n        errors {\n          message\n        }\n        workflowTemplate {\n          ...WorkflowTemplate\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteWorkflowTemplate($workflowTemplateId: Uuid!) {\n    deleteWorkflowTemplate(workflowTemplateId: $workflowTemplateId) {\n      errors {\n        message\n      }\n      workflowTemplate {\n        ...WorkflowTemplate\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteWorkflowTemplate($workflowTemplateId: Uuid!) {\n    deleteWorkflowTemplate(workflowTemplateId: $workflowTemplateId) {\n      errors {\n        message\n      }\n      workflowTemplate {\n        ...WorkflowTemplate\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowTemplate on WorkflowTemplate {\n    id\n    createdAt\n    updatedAt\n    displayName\n    status\n    firm {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowTemplate on WorkflowTemplate {\n    id\n    createdAt\n    updatedAt\n    displayName\n    status\n    firm {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveWorkflow($id: Uuid!, $title: String, $description: String) {\n    saveWorkflow(id: $id, title: $title, description: $description) {\n      errors {\n        message\n      }\n      workflow {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation saveWorkflow($id: Uuid!, $title: String, $description: String) {\n    saveWorkflow(id: $id, title: $title, description: $description) {\n      errors {\n        message\n      }\n      workflow {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation readyToSend($workflowId: Uuid!) {\n    readyToSend(workflowId: $workflowId) {\n      errors {\n        message\n      }\n      workflowRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation readyToSend($workflowId: Uuid!) {\n    readyToSend(workflowId: $workflowId) {\n      errors {\n        message\n      }\n      workflowRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkflowResponseItem on WorkflowResponseItem {\n    id\n    type\n    updatedAt\n    notApplicable\n    value {\n      ...responseDocumentCollectionValue\n      ...responseTextValue\n      ...responseBooleanValue\n    }\n    workflowItem {\n      ...WorkflowItem\n    }\n  }\n"): (typeof documents)["\n  fragment WorkflowResponseItem on WorkflowResponseItem {\n    id\n    type\n    updatedAt\n    notApplicable\n    value {\n      ...responseDocumentCollectionValue\n      ...responseTextValue\n      ...responseBooleanValue\n    }\n    workflowItem {\n      ...WorkflowItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateResponseItemBool(\n    $notApplicable: Boolean\n    $booleanValue: Boolean\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemBool(\n      notApplicable: $notApplicable\n      booleanValue: $booleanValue\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateResponseItemBool(\n    $notApplicable: Boolean\n    $booleanValue: Boolean\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemBool(\n      notApplicable: $notApplicable\n      booleanValue: $booleanValue\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment responseBooleanValue on BooleanValue {\n    booleanValue: value\n  }\n"): (typeof documents)["\n  fragment responseBooleanValue on BooleanValue {\n    booleanValue: value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateResponseItemText(\n    $notApplicable: Boolean\n    $textValue: String\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemText(\n      notApplicable: $notApplicable\n      textValue: $textValue\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        ...WorkflowResponseItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateResponseItemText(\n    $notApplicable: Boolean\n    $textValue: String\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemText(\n      notApplicable: $notApplicable\n      textValue: $textValue\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        ...WorkflowResponseItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment responseTextValue on StringValue {\n    value\n  }\n"): (typeof documents)["\n  fragment responseTextValue on StringValue {\n    value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateResponseItemDocUpload(\n    $notApplicable: Boolean\n    $liscioObjectData: [LiscioObjectInput!]\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemDocUpload(\n      notApplicable: $notApplicable\n      liscioObjectData: $liscioObjectData\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        ...WorkflowResponseItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateResponseItemDocUpload(\n    $notApplicable: Boolean\n    $liscioObjectData: [LiscioObjectInput!]\n    $workflowResponseItemId: Uuid!\n  ) {\n    updateResponseItemDocUpload(\n      notApplicable: $notApplicable\n      liscioObjectData: $liscioObjectData\n      workflowResponseItemId: $workflowResponseItemId\n    ) {\n      errors {\n        message\n      }\n      workflowResponseItem {\n        ...WorkflowResponseItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment responseDocumentCollectionValue on DocumentCollectionValue {\n    documentItems: value {\n      legacyId\n    }\n  }\n"): (typeof documents)["\n  fragment responseDocumentCollectionValue on DocumentCollectionValue {\n    documentItems: value {\n      legacyId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSmsBrandRegistration($params: SmsBrandRegistrationInput!) {\n    createSmsBrandRegistration(params: $params) {\n      errors {\n        message\n      }\n      smsBrandRegistration {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSmsBrandRegistration($params: SmsBrandRegistrationInput!) {\n    createSmsBrandRegistration(params: $params) {\n      errors {\n        message\n      }\n      smsBrandRegistration {\n        id\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;