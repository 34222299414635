import axios from 'axios';

import {
  SIGN_IN_SSO,
  SIGN_IN,
  CPA_AUTHORIZATION,
  MAGIC_LINKS,
  RESET_PASSWORD,
  CHANGE_PASSWORD_V5,
} from 'constants/constants';

export interface SignInCpaData {
  cpa_uuid: string;
  cpa_name: string;
  cpa_user_id: number;
  cpa_user_status: string;
  cpa_user_role: string;
  cpa_logo_url: string;
  company_url: string;
}

export interface SignInResponse {
  id: number;
  email: string;
  full_name: string;
  auth_token: string;
  avatar: string;
  cpa_data: Array<SignInCpaData>;
}

export interface SignInWithMagicLinkResponse extends SignInResponse {}

export interface CpaSelectorResponse {
  id: number;
  email: string;
  full_name: string;
  auth_token: string;
  avatar: string;
  cpa_data: SignInCpaData;
}

export interface GenerateMagicLinkResponse {
  message: string;
}

export interface GenerateResetPasswordTokenLink {
  message: string;
}

export interface ResetPasswordResponse {
  message: string;
}

export type ChangePasswordRequest = {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
  two_factor_code?: string;
};

export async function signIn({
  email,
  password,
  two_factor_code,
  company_url,
}: {
  email: string;
  password: string;
  two_factor_code?: string;
  company_url?: string;
}): Promise<SignInResponse> {
  const response = await axios.post(SIGN_IN, {
    email,
    password,
    two_factor_code,
    company_url,
  });
  return response?.data;
}

export async function signInSSO({
  redirect_uri,
  login_type,
  token,
  code,
  company_url,
}: {
  redirect_uri: string;
  login_type: string;
  token?: string;
  code?: string;
  company_url?: string;
}): Promise<SignInResponse> {
  const response = await axios.post(SIGN_IN_SSO, {
    redirect_uri,
    login_type,
    token,
    code,
    company_url,
  });
  return response?.data;
}

export async function cpaSwitcher({
  cpa_uuid,
  auth_token,
  login_type,
}: {
  cpa_uuid: string;
  auth_token?: string;
  login_type?: string;
}): Promise<CpaSelectorResponse> {
  const config = auth_token
    ? {
        headers: { Authorization: auth_token },
      }
    : undefined;
  const response = await axios.post(
    CPA_AUTHORIZATION,
    {
      cpa_uuid,
      login_type,
    },
    config,
  );
  return response?.data;
}

export async function generateMagicLink({
  email,
  appType,
}: {
  email: string;
  appType: string;
}): Promise<GenerateMagicLinkResponse> {
  const response = await axios.post(MAGIC_LINKS, {
    email,
    app_type: appType,
  });
  return response?.data;
}

export async function signInWithMagicLink({
  token,
  twoFactorCode,
}: {
  token: string;
  twoFactorCode?: string;
}): Promise<SignInWithMagicLinkResponse> {
  const response = await axios.put(`${MAGIC_LINKS}/${token}`, {
    two_factor_code: twoFactorCode,
  });
  return response?.data;
}

export async function generateResetPasswordLink({
  email,
  appType,
}: {
  email: string;
  appType: string;
}): Promise<GenerateResetPasswordTokenLink> {
  const response = await axios.post(RESET_PASSWORD, {
    email,
    app_type: appType,
  });
  return response?.data;
}

export async function resetPassword({
  token,
  twoFactorCode,
  password,
  passwordConfirmation,
}: {
  token: string;
  twoFactorCode?: string;
  password: string;
  passwordConfirmation: string;
}): Promise<ResetPasswordResponse> {
  const response = await axios.put(`${RESET_PASSWORD}/${token}`, {
    two_factor_code: twoFactorCode,
    password,
    password_confirmation: passwordConfirmation,
  });
  return response?.data;
}

/**
 * Change password request.
 * Requires Bearer token auth header
 */
export const changePassword = async (data: ChangePasswordRequest) => {
  const result = await axios.put(CHANGE_PASSWORD_V5, data);
  return result.data;
};
