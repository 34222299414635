/* eslint-disable jsx-a11y/anchor-is-valid */
import { styled } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';
import { LoginFormInterface } from 'components/Login/LoginV5/LoginV5Form';
import { useMemo } from 'react';

const ParagraphStyled = styled('p')({
  paddingTop: '16px',
});

export interface NextStepsMessageProps {
  backToLogin: () => void;
  rawMessage?: string;
}

export const NextStepsMessage = ({
  backToLogin,
  rawMessage = '',
}: NextStepsMessageProps) => {
  const { getValues } = useFormContext<LoginFormInterface>();

  const message = useMemo(() => {
    const { email } = getValues();
    return rawMessage.replace('#{email}', email);
  }, [getValues, rawMessage]);

  return (
    <>
      <ParagraphStyled>{message}</ParagraphStyled>
      <div className="crPage--bott text-center mt-3">
        <span className="txtInfo">Back to </span>
        <a
          href="#"
          onClick={backToLogin}
          className="btn tptBtn"
          data-testid="back_to_login"
        >
          <b>Login</b>
        </a>
      </div>
    </>
  );
};

export default NextStepsMessage;
