import { getStorageData } from 'utilities/utils';

declare let rudderanalytics: RudderStack;

export const swapIdsWithStaticString = (url: string) => {
  if (!url) return '';

  let isLookBehindSupported = false;

  try {
    // eslint-disable-next-line prefer-regex-literals, no-new
    new RegExp('(?<=)');
    isLookBehindSupported = true;
  } catch (err) {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    // If the agent doesn't support look behinds, the attempted
    // creation of a RegExp object using that syntax throws and
    // isLookBehindSupported remains false.
  }

  const DIGITS_REGEX = /[0-9]+/g;
  const digitsReplaced = url.replace(DIGITS_REGEX, '<digits>');

  if (isLookBehindSupported) {
    // Not using a RegEx literal here because its very presence in the bundle throws a dramatic error in Safari.
    // eslint-disable-next-line prefer-regex-literals, no-useless-escape
    const ID_REGEX = new RegExp(
      '(?<=(sms|inbox|sent|draft|archive|all_messages|pinned|cp|i)/)\\S+(?<![new])',
      'g',
    );

    return digitsReplaced.replace(ID_REGEX, '<hash-id>');
  }

  return digitsReplaced;
};

const userDataKeysToExclude = [
  'default_recipient',
  'has_agreed_to_term',
  'pref_type_options',
  'privacy_content',
  'userpilot_data',
  'multiple_cpa_data',
  'term_content',
  'unread_badge',
];

export const getRelevantRudderDetails = () => {
  // TODO: __HOMEDATA-AUDIT
  const user = getStorageData();

  userDataKeysToExclude.forEach((key) => delete user[key]);

  return {
    ...user,
    sanitizedPath: swapIdsWithStaticString(window.location.pathname),
  };
};

export const rudderTrack: TrackFunction = (type, properties = {}) => {
  rudderanalytics.track(type, {
    ...getRelevantRudderDetails(),
    ...properties,
  });
};

export const rudderPageView = (location: RudderStackLocation) => {
  const [firstWordInTitle] = document.title.split(' ');
  const { previousLocation, pathname } = location;

  if (previousLocation !== pathname) {
    rudderanalytics.page(
      firstWordInTitle,
      swapIdsWithStaticString(pathname || ''),
      {
        ...location,
        ...getRelevantRudderDetails(),
        previousLocation: swapIdsWithStaticString(previousLocation),
      },
    );
  }
};

export function rudderTrackGenericClick(e: MouseEvent) {
  const eventTarget = e.target as HTMLElement;

  if (eventTarget) {
    const clickedOn =
      eventTarget.outerText || `Element With No Text: ${eventTarget.outerHTML}`;

    rudderTrack(`Clicked`, {
      ...getRelevantRudderDetails(),
      clickedOn: clickedOn.slice(0, 100),
    });
  }
}

export function rudderTrackGenericSubmit(e: SubmitEvent) {
  if (e.target) {
    const formDataAsObject: HashMap = {};
    const formData = new FormData(e.target as HTMLFormElement);
    // eslint-disable-next-line no-return-assign
    formData.forEach((value, key) => (formDataAsObject[key] = value));

    rudderTrack(`Form Submit`, formDataAsObject);
  }
}
