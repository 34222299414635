import {
  useGetZipPackage,
  useWorkflowRequestQuery,
} from 'hooks/workflow-request-hooks';
import { useGeneratePdfUrl } from 'components/Requests/hooks/useGeneratePdfUrl';
import { AlertMessage } from 'utilities/utils';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import FileDisplayFromUrl from 'common/FileDisplayFromUrl/FileDisplayFromUrl';
import { useState } from 'react';

type Props = {
  title?: string;
  workflowRequestId?: string;
};

/**
 * This hook is used for the transition of the action buttons
 * in the requests to be moved to the header ellipse. This way
 * the logic is managed in a single location and then only the
 * necessary parts are exposed in the other components based on
 * "requestDetailsActions" feature flag.
 */
export const useHeaderMenuHook = ({
  workflowRequestId = '',
  title = '',
}: Props) => {
  const [displayPdf, setDisplayPdf] = useState(false);
  const { mutate: exportMutation } = useGetZipPackage(workflowRequestId);
  const { data: requestData } = useWorkflowRequestQuery(workflowRequestId);
  const { pdfURL } = useGeneratePdfUrl(workflowRequestId);

  const toggleDisplayPdf = () => setDisplayPdf((state) => !state);

  const handleExportClicked = () => {
    exportMutation(undefined, {
      onSuccess: () => {
        AlertMessage(
          TOAST_SUCCESS,
          `ZIP export successfully initiated. Depending on the size of this request and any uploaded files, it may take some time to process. Once complete it will become available for download from the Liscio Vault.`,
        );
      },
      onError: () => {
        AlertMessage(
          TOAST_FAILURE,
          `Liscio encountered an error when attempting to create a ZIP file for the following request: ${
            requestData?.workflowRequest?.title ||
            requestData?.workflowRequest?.workflow.title
          }. Please try again or contact us if this error persists. We apologize for any inconvenience.`,
        );
      },
    });
  };

  const PrintPDF = () => (
    <FileDisplayFromUrl
      url={pdfURL as string}
      headerTitle={title as string}
      open={displayPdf}
      onClose={() => toggleDisplayPdf()}
    />
  );

  return {
    handleExportClicked,
    PrintPDF,
    toggleDisplayPdf,
  };
};
