/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  createSMSMessage,
  getThreadDetail,
  getThreadDetailPreview,
  getThreads,
  getHome,
  createPhoneLine as getOrderPhoneNumberMutation,
  getSMSPhoneLineList,
  addSmsDocumentToVault,
  getFirmUserSMSStatus,
  archiveSMSThread,
  getSMSProvisionedStatus,
  createSmsBrandRegistrationV5,
} from 'api/sms';
import checkIsNewThread from 'components/SMS/utils/checkIsNewThread';
import { createSmsBrandRegistration } from 'components/SMS/components/SMSSettingsForm/gql-queries';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useUnReadSMSCount } from 'components/SMS/hooks/useUnReadSMSCount';
import { useLocation } from 'react-router-dom-latest';
import { useMutationGraphQL } from './useGraphQL';

const INITIAL_INTERVAL = 10_000;

export function useGetThreads() {
  const errorCount = useRef(0);
  const { data: homeData } = useGetHome();

  const enabled = Boolean(homeData?.smsPhoneLine?.phone_number);
  return useQuery({
    queryKey: ['threads'],
    queryFn: async () => {
      try {
        const result = await getThreads();
        errorCount.current = 0;

        // Filter out threads without participants as it causes FE issues
        const invalidSmsThreads = result.threads.filter(
          (thread) => !thread.participants || thread.participants.length === 0,
        );
        if (invalidSmsThreads.length) {
          console.warn(
            'Invalid SMS Threads without any participants:',
            invalidSmsThreads,
          );
        }
        result.threads = result.threads.filter(
          (thread) => thread.participants && thread.participants.length > 0,
        );

        return result;
      } catch (e) {
        errorCount.current += 1;

        return { threads: [] };
      }
    },
    refetchInterval: () =>
      INITIAL_INTERVAL + errorCount.current * INITIAL_INTERVAL,
    cacheTime: 5000,
    retry: false,
    enabled,
  });
}

export function useGetThreadDetail(
  id: number | string,
  refetch: boolean,
  onError?: () => void,
) {
  return useQuery({
    queryKey: ['thread-detail', id],
    refetchInterval: refetch ? 3000 : undefined,
    cacheTime: 5000,
    queryFn: getThreadDetail,
    enabled: !checkIsNewThread(id),
    onError,
  });
}

/**
 * Used by the Timeline Slideout to render the SMS
 * messages for a given Timeline Activity.
 *
 * This method only works when a valid id is provided;
 * an empty string for the id prevents the query from
 * executing.
 */
export function useGetThreadDetailOnce(id: number | string) {
  return useQuery({
    queryKey: ['thread-detail', id],
    queryFn: getThreadDetailPreview,
    enabled: id !== '',
  });
}

export function usePostMessageMutation() {
  return useMutation({ mutationFn: createSMSMessage });
}

export function useGetHome(enabled: boolean = true) {
  return useQuery({
    queryKey: ['home'],
    queryFn: getHome,
    enabled,
  });
}

export function useFirmUserSMSStatus() {
  return useQuery({
    queryKey: ['firm-user-sms-status'],
    queryFn: getFirmUserSMSStatus,
  });
}

export function useSMSProvisionedStatus() {
  return useQuery({
    queryKey: ['provision-sms-status'],
    queryFn: getSMSProvisionedStatus,
  });
}

export function useOrderPhoneNumberMutation() {
  return useMutation({
    mutationFn: getOrderPhoneNumberMutation,
    onError: (error: AxiosError) => error,
  });
}

export function useSMSPhoneLineList(page = 1, search = '') {
  const result = useQuery({
    queryKey: ['smsPhoneLineList', page, search],
    queryFn: () => getSMSPhoneLineList(page, search),
  });

  return { data: result?.data?.data };
}

export function useCreateSmsBrandRegistration() {
  return useMutationGraphQL(createSmsBrandRegistration);
}

export function useCreateSmsBrandRegistrationV5() {
  return useMutation({
    mutationFn: createSmsBrandRegistrationV5,
  });
}

export function useAddSmsDocumentToVault() {
  return useMutation({
    mutationFn: addSmsDocumentToVault,
  });
}

export function useArchiveSMSThread() {
  return useMutation({
    mutationFn: archiveSMSThread,
  });
}

/**
 * All purpose hook for managing the SMSNotificationBar
 */
export const useSMSNotificationBar = () => {
  const location = useLocation();
  const SMS_NOTIFICATION_BAR = 'smsNotificationBar';
  const storedDismissalKey = localStorage.getItem(SMS_NOTIFICATION_BAR);
  const [lastDismissalKey, setLastDismissalKey] = useState(storedDismissalKey);
  const { unreadSMSCount, firstId, lastUpdatedAt, firstContactName } =
    useUnReadSMSCount();
  const currentUnreadSmsKey = `${unreadSMSCount}:${firstId}:${lastUpdatedAt}`;

  const dismissNotification = useCallback(() => {
    setLastDismissalKey(currentUnreadSmsKey);
    localStorage.setItem(SMS_NOTIFICATION_BAR, currentUnreadSmsKey);
  }, [currentUnreadSmsKey, setLastDismissalKey]);

  const isOpen = useMemo(
    () =>
      unreadSMSCount > 0 &&
      currentUnreadSmsKey !== lastDismissalKey &&
      !location.pathname.includes('sms'),
    [unreadSMSCount, currentUnreadSmsKey, lastDismissalKey, location.pathname],
  );

  const message = useMemo(
    () =>
      unreadSMSCount === 1
        ? `You have a new text from ${firstContactName}`
        : `You have new text messages`,
    [unreadSMSCount, firstContactName],
  );
  const url = useMemo(
    () => (unreadSMSCount === 1 ? `/sms/${firstId}` : '/sms'),
    [unreadSMSCount, firstId],
  );

  return {
    isOpen,
    dismissNotification,
    message,
    url,
  };
};
