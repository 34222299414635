import { lazy } from 'react';
import { Route } from 'react-router-dom-latest';
import { BuilderRequestView } from './views/BuilderRequestView/BuilderRequestView';
import { DispatchView } from './views/DispatchView/DispatchView';
import { DraftRequestsView } from './views/DraftRequestsView/DraftRequestsView';
import { OpenRequestsView } from './views/OpenRequestsView/OpenRequestsView';
import { ResponseView } from './views/ResponseView/ResponseView';
import { ArchivedRequestsView } from './views/ArchivedRequestsView/ArchivedRequestsView';
import { PendingRequestsView } from './views/PendingRequestsView/PendingRequestsView';
import { REQUESTS_PAGE_ROUTES } from './requests.constants';
import { BundleCompleteView } from './views/ResponseView/BundleCompleteView';
import { DraftWorkflowsView } from './views/DraftRequestsView/DraftWorkflowsView';
import { RpaDocumentsProcessingView } from './views/RpaDocumentsProcessingView/RpaDocumentsProcessingView';

const RequestsPage = lazy(() => import('./RequestsPage'));

export const RequestsRoutes = (
  <Route path={REQUESTS_PAGE_ROUTES.requests} element={<RequestsPage />}>
    <Route index element={<OpenRequestsView />} />
    <Route
      path={REQUESTS_PAGE_ROUTES.requestsDrafts}
      element={<DraftRequestsView />}
    />
    <Route
      path={REQUESTS_PAGE_ROUTES.workflowsDrafts}
      element={<DraftWorkflowsView />}
    />
    <Route
      path={REQUESTS_PAGE_ROUTES.processing}
      element={<RpaDocumentsProcessingView />}
    />
    <Route
      path={REQUESTS_PAGE_ROUTES.archived}
      element={<ArchivedRequestsView />}
    />
    <Route
      path={REQUESTS_PAGE_ROUTES.pending}
      element={<PendingRequestsView />}
    />
    <Route
      path={REQUESTS_PAGE_ROUTES.builder}
      element={<BuilderRequestView />}
    />
    <Route
      path={`${REQUESTS_PAGE_ROUTES.requests}/:id`}
      element={<ResponseView />}
    />
    <Route
      path={`${REQUESTS_PAGE_ROUTES.requests}/:workflow_request_id/complete`}
      element={<BundleCompleteView />}
    />
    <Route
      path={`${REQUESTS_PAGE_ROUTES.dispatch}/:id`}
      element={<DispatchView />}
    />
    <Route
      path={`${REQUESTS_PAGE_ROUTES.builder}/:workflow_id`}
      element={<BuilderRequestView />}
    />
  </Route>
);
