import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';
import { useGetWorkflowById } from 'hooks/workflow-hooks';
import {
  useCreateWorkflowItemCondition,
  useDeleteWorkflowItem,
  useCreateWorkflowItem,
} from 'hooks/workflow-item-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { WorkflowItemFragment } from 'gql/graphql';
import { AlertMessage, getFeatureGates } from 'utilities/utils';
import { ConditionalQuestionEditor } from 'components/Requests/components/RequestsBuilder/components/ConditionalQuestionEditor/ConditionalQuestionEditor';
import { AddCircle } from '@mui/icons-material';
import { TOAST_FAILURE } from 'constants/constants';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { getWorkflowItemWithIdFromCache } from 'components/Requests/components/RequestsBuilder/utils/item-utils';
import { deleteWorkflowItemFromCache } from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';

export interface ConditionsEditorProps {
  activeUUID: string;
  number: number;
}

export function ConditionsEditor({
  number,
  activeUUID,
}: ConditionsEditorProps) {
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { workflowId, isErrored, isSubmitting } = useRequestBuilderContext();

  const { mutate: createWorkflowItemMutation, isLoading } =
    useCreateWorkflowItem();
  const { mutate: deleteWorkflowItemMutation } = useDeleteWorkflowItem();
  const { mutate: createCondition } = useCreateWorkflowItemCondition();
  const [conditionalItems, setConditionalItems] = useState<
    WorkflowItemFragment[]
  >([]);
  const [parentItem, setParentItem] = useState<WorkflowItemFragment | null>(
    null,
  );
  const { data: workflowData } = useGetWorkflowById(workflowId, {
    staleTime: Infinity,
  });
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  // the condition is stored on the conditional item
  const [enabled, setEnabled] = useState(false);

  const [matchValue, setMatchValue] = useState<string>('false');

  useEffect(() => {
    const [prntItem, condItems] = getWorkflowItemWithIdFromCache(
      queryClient,
      activeUUID,
      workflowId || '',
    );
    setParentItem(prntItem);

    // on first load, if there are no conditional items, enable the editor
    if (initialLoad && condItems.length > 0) {
      setEnabled(true);
      setInitialLoad(false);
    }

    setConditionalItems(condItems);
    if (matchValue !== condItems[0]?.conditions?.[0]?.matchValue) {
      setMatchValue(
        condItems[0]?.conditions?.[0]?.matchValue !== 'false'
          ? 'true'
          : 'false',
      );
    }
  }, [
    queryClient,
    workflowId,
    workflowData,
    matchValue,
    activeUUID,
    initialLoad,
  ]);

  const createWorkflowItem = useCallback(() => {
    if (!parentItem) {
      AlertMessage(TOAST_FAILURE, 'Failed to add condition');
      return;
    }

    createWorkflowItemMutation(
      {
        workflowSectionId: parentItem?.section.id,
        workflowId,
        prompt: '',
        workflowItemType: WorkflowItemType.Boolean,
        conditionalParentId: parentItem?.id,
      },
      {
        onSuccess: (response) => {
          const responseItem = response?.createWorkflowItem
            ?.workflowItem as WorkflowItemFragment;
          if (!responseItem) {
            return;
          }

          function addCondition() {
            createCondition(
              {
                workflowItemId: responseItem?.id,
                fieldName: parentItem?.id,
                matchValue,
                conditionType: 'equals',
              },
              {
                onSettled: (conditionResponse) => {
                  const worklowItemCondition =
                    conditionResponse?.createWorkflowItemCondition
                      ?.workflowItemCondition;
                  if (!worklowItemCondition) {
                    // we don't want a nonfunctional conditional item hanging around
                    // deleteWorkflowItem(responseItem?.id);
                  }
                },
              },
            );
          }
          // await item added to react query cache
          setTimeout(addCondition, 0);
        },
      },
    );
  }, [
    createCondition,
    createWorkflowItemMutation,
    matchValue,
    parentItem,
    workflowId,
  ]);

  const deleteWorkflowItem = useCallback(
    (workflowItemId: string, keepParent: boolean = false) => {
      // Remove from the cache
      deleteWorkflowItemFromCache(queryClient, { workflowItemId }, workflowId);

      deleteWorkflowItemMutation(
        {
          workflowItemId,
        },
        {
          onSuccess: (response) => {
            if (response?.deleteWorkflowItem?.errors?.length) {
              AlertMessage(TOAST_FAILURE, 'Failed to delete conditions');
              return;
            }
            if (!keepParent) setParentItem(null);
          },
        },
      );
    },
    [deleteWorkflowItemMutation, queryClient, workflowId],
  );

  useEffect(() => {
    if (enabled && parentItem && conditionalItems.length === 0) {
      createWorkflowItem();
    }
  }, [
    parentItem,
    conditionalItems.length,
    createWorkflowItem,
    workflowId,
    enabled,
    deleteWorkflowItem,
  ]);

  const handleEnabledChange = () => {
    if (enabled === true && conditionalItems.length > 0) {
      conditionalItems.forEach((item) => {
        // remove from the BE and keep the parent
        deleteWorkflowItem(item.id, true);
      });

      setConditionalItems([]);
    }

    setEnabled((cEnabled) => !cEnabled);
  };

  const featureGate = getFeatureGates();
  const hasConditionalQuestionsFeature = featureGate?.conditional_questions;
  const conditionalFeatureTooltip =
    !hasConditionalQuestionsFeature &&
    'Upgrade to Pro Tier to create conditional questions.';

  return (
    <Stack gap="20px" paddingTop="20px">
      <Box>
        <TooltipStyled title={conditionalFeatureTooltip}>
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={handleEnabledChange}
                disabled={
                  !hasConditionalQuestionsFeature || !parentItem || isErrored
                }
              />
            }
            label="Conditions"
          />
        </TooltipStyled>
        <Typography color="text.secondary">
          Add conditions depending on the client answer.
        </Typography>
      </Box>
      {enabled && (
        <Stack bgcolor="common.white" gap="inherit" padding="20px">
          <Stack direction="row">
            <Stack
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.palette.success.main}
              borderRadius="4px 0px 0px 4px"
              sx={{ padding: '6px 12px' }}
            >
              <Typography
                component="label"
                htmlFor="matchValue"
                fontSize=".875rem"
                color={theme.palette.success.contrastText}
                fontWeight={700}
              >
                If
              </Typography>
            </Stack>
            <Select
              fullWidth
              value={matchValue}
              defaultValue="true"
              onChange={({ target: { value } }) => {
                setMatchValue(value);
              }}
              variant="standard"
              inputProps={{
                id: 'matchValue',
                sx: {
                  border: `2px solid ${theme.palette.success.main}`,
                  borderLeft: 'none',
                  borderRadius: '0px 4px 4px 0px',
                },
              }}
              disabled={isSubmitting || isErrored}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </Stack>
          {matchValue &&
            conditionalItems.map((ifItem, idx) => (
              <ConditionalQuestionEditor
                key={ifItem.id}
                label={`${number}.${idx + 1}`}
                item={ifItem}
                matchValue={matchValue}
              />
            ))}
          <Stack flexDirection="row" alignItems="center">
            <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
            <IconButton
              onClick={createWorkflowItem}
              disabled={
                isLoading ||
                isSubmitting ||
                isErrored ||
                !hasConditionalQuestionsFeature
              }
              color="primary"
              aria-label="Add conditional question"
            >
              <AddCircle />
            </IconButton>
            <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
