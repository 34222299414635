import { Edit, Save, FileDownload } from '@mui/icons-material';
import { Button, ButtonGroup, FormControlLabel, Switch } from '@mui/material';
import { PrintIcon } from 'common/Icons';
import { WorkflowRequestFragment } from 'gql/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useContext } from 'react';
import { isClient } from 'utilities/utils';
import { useChangeRequestDueDate } from 'components/Requests/hooks/change-request-due-date/change-request-due-date';
import { useHeaderMenuHook } from 'components/Requests/hooks/header-menu-hook';
import { WorkflowViewInfoBar } from 'components/Requests/components/WorkflowView/components/WorkflowViewInfoBar/WorkflowViewInfoBar';
import { WorkflowViewTitlePresentation } from 'components/Requests/components/WorkflowView/components/WorkflowViewTitleWrapper/WorkflowViewTitleWrapper';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';

type WorkflowViewTitleProps = {
  workflowRequest?: WorkflowRequestFragment;
  allowResponseEdit: boolean;
  onEditResponseClick?: () => void;
  onEditWorkflowClick?: () => void;
  isDispatch?: boolean;
  disableInputState?: boolean;
  onShowUnansweredSwitchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const WorkflowViewTitle = ({
  workflowRequest,
  allowResponseEdit,
  onEditResponseClick,
  onEditWorkflowClick,
  isDispatch,
  disableInputState,
  onShowUnansweredSwitchChange: onShowSwitchChange,
}: WorkflowViewTitleProps) => {
  const workflow = workflowRequest?.workflow;

  const { PrintPDF, handleExportClicked, toggleDisplayPdf } = useHeaderMenuHook(
    {
      title: workflowRequest?.title || '',
      workflowRequestId: workflowRequest?.id,
    },
  );

  const { requestDetailsActions } = useFlags();
  const status = workflowRequest?.status || workflow?.status;
  const isUserClient = isClient();
  const { ChangeDueDateButton, ChangeDueDateDialog } = useChangeRequestDueDate(
    workflowRequest as WorkflowRequestFragment,
  );
  const { isDirty } = useContext(WorkflowViewContext);

  return (
    <>
      <WorkflowViewTitlePresentation
        title={workflowRequest?.title || ''}
        description={workflow?.description || ''}
        sourceDocuments={workflowRequest?.rpaDocuments || []}
        slots={{
          infoBar: <WorkflowViewInfoBar isDirty={isDirty} status={status} />,
          controls: (
            <>
              {(isUserClient || allowResponseEdit) && (
                <FormControlLabel
                  className="hide-form-control-label"
                  sx={{ marginBottom: '0px', alignSelf: 'end' }}
                  control={<Switch onChange={onShowSwitchChange} />}
                  label="Show unanswered items only"
                />
              )}
              {onEditWorkflowClick && (
                <Button
                  startIcon={<Edit />}
                  variant="contained"
                  onClick={onEditWorkflowClick}
                  data-print-id="editWorkflowButton"
                >
                  Edit Request
                </Button>
              )}
              {!requestDetailsActions ? (
                <ButtonGroup variant="contained">
                  <ChangeDueDateButton />
                  {allowResponseEdit && (
                    <Button
                      startIcon={disableInputState ? <Edit /> : <Save />}
                      variant="contained"
                      onClick={onEditResponseClick}
                      data-print-id="editWorkflowButton"
                    >
                      {disableInputState ? 'Edit Responses' : 'Save'}
                    </Button>
                  )}
                  {!isUserClient && !isDispatch && (
                    <Button
                      startIcon={<PrintIcon />}
                      variant="contained"
                      onClick={() => toggleDisplayPdf()}
                      data-print-id="exportWorkflowButton"
                    >
                      Print PDF
                    </Button>
                  )}
                  {allowResponseEdit && (
                    <Button
                      startIcon={<FileDownload />}
                      variant="contained"
                      onClick={handleExportClicked}
                      data-print-id="exportWorkflowButton"
                    >
                      Export Zip
                    </Button>
                  )}
                </ButtonGroup>
              ) : null}
            </>
          ),
        }}
      />
      <ChangeDueDateDialog />
      <PrintPDF />
    </>
  );
};
