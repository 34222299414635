import * as yup from 'yup';

export const formSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  password_confirmation: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  two_factor_code: yup
    .string()
    .when('$processState', (contextArray, schema) => {
      const [processState] = contextArray;
      if (processState === 'mfaCode') {
        return schema.required('Code is required');
      }
      return schema;
    }),
});
