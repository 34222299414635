import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { AlertMessage } from 'utilities/utils';
import { ACT_AS_KEY, SWITCH_CPA } from 'constants/constants';
import MultipleLevelMenu from 'components/Sidebar/elements/MultipleLevelMenu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useWebsocket } from 'websocket';
import { setAuthSession } from 'auth/session';
import { MenuItemIcon } from 'components/Sidebar/elements/MenuItemIcon';

const MultipleCpa = ({ isMultipleCpa, multipleCpaData }) => {
  const websocket = useWebsocket();
  function switchCpa(e, item) {
    if (!item.is_current_cpa) {
      const parsedUrl = new URL(item.custom_liscio_url);
      const data = { cpa_id: item.id };
      const request = axios.post(SWITCH_CPA, data);
      // this.setState({ loading: false });
      request.then((res) => {
        /**
         * TODO: How can we optimize this with the removed legacy auth cookies?
         * Can we just reload the page after setting the new auth cookie?
         * That may be the easiest?
         * We should no longer need `setLegacyRootDomainAuthSession`
         * NOTE: DO we need to udpate the user in local storage?
         */
        if (res.data.status === 200) {
          websocket.consumer.disconnect();
          localStorage.clear();
          const date = new Date();
          date.setTime(date.getTime() + 30 * 60 * 1000);
          // Update auth session and redirect
          setAuthSession({ auth_token: res.data.auth_token });
          window.location.replace(parsedUrl);
        } else {
          AlertMessage('error', 'Something went wrong', 3000);
        }
      });
    }
  }
  const isActingAsAnEmployee = localStorage.getItem(ACT_AS_KEY);

  let otherCpas = [];
  let currentCpa = {};
  if (
    isMultipleCpa &&
    isMultipleCpa !== undefined &&
    multipleCpaData &&
    multipleCpaData.length
  ) {
    otherCpas = multipleCpaData.filter((x) => !x.is_current_cpa);
    currentCpa = multipleCpaData.filter((x) => x.is_current_cpa)[0];
  }
  const items = {
    label: currentCpa.name,
    id: currentCpa.id,
    children: otherCpas.map((e) => {
      e.label = e.name;
      e.image = e.logo;
      return e;
    }),
  };

  if (
    isMultipleCpa &&
    isMultipleCpa !== undefined &&
    multipleCpaData &&
    multipleCpaData.length > 0 &&
    !isActingAsAnEmployee
  ) {
    return (
      <MultipleLevelMenu
        onSelect={switchCpa}
        component={
          <ListItemButton key={items.id}>
            <ListItemIcon>
              <MenuItemIcon iconClass="icon-switch-account" />
            </ListItemIcon>
            <ListItemText primary={items.label.toUpperCase()} />
          </ListItemButton>
        }
        menuItems={items}
      />
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  homedata: state.homeReducer.data,
  isMultipleCpa: state.notificationReducer.isMultipleCpa,
  multipleCpaData: state.notificationReducer.multipleCpaData,
});

export default connect(mapStateToProps)(MultipleCpa);
