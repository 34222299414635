import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import FileDisplayFromUrl from 'common/FileDisplayFromUrl/FileDisplayFromUrl';
import { Maybe, RpaDocumentFragment } from 'gql/graphql';
import { useState } from 'react';

type MainProps = {
  rpaDocuments: RpaDocumentFragment[];
};

type SinglePreviewProps = {
  fileUrl?: Maybe<string> | undefined;
  title?: Maybe<string> | undefined;
};

function SinglePreview({ fileUrl, title }: SinglePreviewProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <Box>
      <Stack direction="row">
        <Button
          variant="text"
          startIcon={<i className="icon-open-eye" />}
          onClick={handleOpen}
          sx={{ color: theme.palette.secondary.dark }}
        >
          <Typography variant="inherit" fontWeight={700}>
            Source file:
          </Typography>
        </Button>
        <Typography
          display="flex"
          alignItems="center"
          sx={{ userSelect: 'none' }}
        >
          <i
            className="icon-filetype-pdf"
            style={{
              color: theme.palette.success.main,
              fontSize: '1.5rem',
              paddingRight: '0.3rem',
            }}
          />
          {title}
        </Typography>
      </Stack>
      <FileDisplayFromUrl
        url={fileUrl as string}
        headerTitle={title as string}
        open={isOpen}
        onClose={handleClose}
      />
    </Box>
  );
}

export function RPAPreview({ rpaDocuments }: MainProps) {
  return rpaDocuments?.length > 0 ? (
    <Stack>
      {rpaDocuments.map((rpaDoc) => (
        <SinglePreview key={rpaDoc.id} {...rpaDoc} />
      ))}
    </Stack>
  ) : null;
}
