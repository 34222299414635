import {
  WorkflowRequestFragment,
  WorkflowWithSectionsFragment,
} from 'gql/graphql';
import { ChangeEvent, useEffect, useState } from 'react';

import { WorkflowSections } from 'components/Requests/components/WorkflowView/components/WorkflowSections/WorkflowSections';
import { WorkflowViewTitle } from './components/WorkflowViewTitle/WorkflowViewTitle';

type WorkflowViewerProps = {
  workflow?: WorkflowWithSectionsFragment;
  workflowRequest?: WorkflowRequestFragment;
  onEditResponseClick?: () => void;
  disableInputState?: boolean;
  onEditWorkflowClick?: () => void;
  isDispatch?: boolean;
  allowResponseEdit?: boolean;
};

export const WorkflowViewer = ({
  workflow,
  workflowRequest,
  onEditResponseClick,
  onEditWorkflowClick,
  disableInputState,
  isDispatch,
  allowResponseEdit = false,
}: WorkflowViewerProps) => {
  const [showAllQuestions, setShowAllQuestions] = useState(true);
  const title = workflowRequest?.title || workflow?.title;

  useEffect(() => {
    document.title = title || 'Request';
  }, [title]);

  const handleShowSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setShowAllQuestions(!event.target.checked);
  };

  return (
    <>
      <WorkflowViewTitle
        workflowRequest={workflowRequest}
        allowResponseEdit={allowResponseEdit}
        onEditResponseClick={onEditResponseClick}
        onEditWorkflowClick={onEditWorkflowClick}
        isDispatch={isDispatch}
        disableInputState={disableInputState}
        onShowUnansweredSwitchChange={handleShowSwitch}
      />
      <WorkflowSections
        disableEdit={disableInputState}
        showAllQuestions={showAllQuestions}
        workflowRequestData={workflowRequest}
        alwaysShowConditionals={isDispatch}
      />
    </>
  );
};
