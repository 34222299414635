import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { graphql } from 'gql';
import {
  ResponseDocumentCollectionValueFragment,
  WorkflowItemFragment,
  WorkflowResponseItemFragment,
} from 'gql/graphql';
import { useContext, useEffect, useState } from 'react';
import TaskFileUpload from 'components/Files/TaskFileUpload';
import { useMutationGraphQL } from 'hooks/useGraphQL';
import { useDocumentDetails } from 'hooks/document-hooks';
import FileDisplayFromUrl from 'common/FileDisplayFromUrl/FileDisplayFromUrl';
import { invalidateWorkflowRequestsQuery } from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { useQueryClient } from '@tanstack/react-query';
import { AlertMessage } from 'utilities/utils';
import useEffectOnce from 'hooks/useEffectOnce';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';
import { TOAST_FAILURE } from 'constants/constants';

export const updateResponseItemDocUploadDocument = graphql(/* GraphQL */ `
  mutation updateResponseItemDocUpload(
    $notApplicable: Boolean
    $liscioObjectData: [LiscioObjectInput!]
    $workflowResponseItemId: Uuid!
  ) {
    updateResponseItemDocUpload(
      notApplicable: $notApplicable
      liscioObjectData: $liscioObjectData
      workflowResponseItemId: $workflowResponseItemId
    ) {
      errors {
        message
      }
      workflowResponseItem {
        ...WorkflowResponseItem
      }
    }
  }
`);

export const responseDocumentCollectionValueFragment = graphql(/* GraphQL */ `
  fragment responseDocumentCollectionValue on DocumentCollectionValue {
    documentItems: value {
      legacyId
    }
  }
`);

interface UploadItemProps {
  disabledEdit: boolean;
  workflowItem: WorkflowItemFragment;
  workflowResponseItem?: WorkflowResponseItemFragment | null;
}

export function UploadItem({
  disabledEdit,
  workflowItem,
  workflowResponseItem,
}: UploadItemProps) {
  const { mutate: uploadMutation, isLoading: isMutating } = useMutationGraphQL(
    updateResponseItemDocUploadDocument,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [notApplicable, setNotApplicable] = useState(
    workflowResponseItem?.notApplicable || false,
  );

  const { checkValid, setDirtyField } = useContext(WorkflowViewContext);

  const collectionValue =
    workflowResponseItem?.value as ResponseDocumentCollectionValueFragment;

  useEffectOnce(() => {
    checkValid(collectionValue);
  });

  const [documentLegacyIds, setDocumentLegacyIds] = useState<string[] | null>(
    null,
  );
  const [documents, setDocuments] = useState<any[] | null>(null);
  const [documentPreviewUrl, setDocumentPreviewUrl] = useState<string | null>(
    null,
  );

  // Extract the legacyIds for the useDocumentInfo query
  useEffect(() => {
    const ids = collectionValue?.documentItems?.reduce((acc, item) => {
      if (item.legacyId) {
        acc.push(item.legacyId);
      }
      return acc;
    }, [] as string[]);
    setDocumentLegacyIds(ids);
  }, [collectionValue?.documentItems]);

  const {
    data: docData,
    isSuccess: docDataIsSuccess,
    isLoading: docDataIsLoading,
    isError: docDataIsError,
    fetchStatus: docDataFetchStatus,
  } = useDocumentDetails(documentLegacyIds);

  const [document, setDocument] = useState<File | null>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (docDataIsSuccess) {
      setDocuments(docData.documents);
    }
    // do not watch docData for changes unless you like infinite loops
  }, [docData, docDataIsSuccess]);

  const handleViewDocumentClick = (doc: any, open: boolean) => {
    setModalOpen(open);
    setDocumentPreviewUrl(doc.aws_url_original);
  };

  const handleDocumentsChange = (docs: any[]) => {
    handleFormUpdate('task', docs);
  };

  const handleFormUpdate = (type: string, docs: any[]) => {
    if (!workflowResponseItem) {
      setDocument(null);
      return;
    }
    setDocuments(docs);
    // set an empty array if there are no documents (deletes all documents)
    // otherwise, map the document uuids to the liscioObjectData format
    const liscioObjectData =
      docs.length > 0
        ? docs
            .filter((doc) => doc.uuid)
            .map((doc) => ({
              liscioObjectType: 'Document',
              id: `${doc.uuid}`,
            }))
        : [];
    setDirtyField(workflowResponseItem.id, true);

    uploadMutation(
      {
        workflowResponseItemId: `${workflowResponseItem.id}`,
        liscioObjectData,
      },
      {
        onSettled: (data) => {
          // onError doesn't get triggered if there are errors in the response
          if (data?.updateResponseItemDocUpload?.errors?.length) {
            // remove the document from the form so the UI matches the backend
            setDocument(null);
            // Alert the user that there was an error with the same Alert component as the TaskFileUpload component
            AlertMessage(
              'error',
              'There was an error uploading the document.',
              10000,
            );
          }
          //#region
          // BEGIN HACK
          // If src/components/Files/FilePopupBottom.jsx#L148-L149 has any documentIds while it unmounts, it will delete the files
          // causing the request response item to point to a file that doesn't exist.
          // This is a hack to prevent that from happening by letting the modal unmount first before setting the updated document list in it's props.
          // I am putting this setTimeout right here vs in the ClientFilePopup or FilePopupBottom because
          // I don't know what downstream efects will happen if I change the those files (remember kids, don't use legacy code)
          const timeout = setTimeout(() => {
            setDocumentLegacyIds(docs.map((doc) => doc?.id));
          }, 500);
          clearTimeout(timeout);
          // END HACK
          //#endregion

          checkValid(true);
          setDirtyField(workflowResponseItem.id, false);

          if (!workflowResponseItem?.id) {
            return;
          }
          invalidateWorkflowRequestsQuery(
            workflowResponseItem?.id,
            queryClient,
          );
        },
      },
    );
  };

  const handleNotApplicable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotApplicable(e.target.checked);

    uploadMutation(
      {
        workflowResponseItemId: workflowResponseItem?.id,
        notApplicable: e.target.checked,
      },
      {
        onSuccess: () => {
          checkValid(true);
          invalidateWorkflowRequestsQuery(
            workflowResponseItem?.id,
            queryClient,
          );
        },
        onError: () => {
          AlertMessage(TOAST_FAILURE, 'Failed to update response item');
          setNotApplicable(!e.target.checked);
        },
      },
    );
  };

  const { prompt } = workflowItem;

  const documentsAreLoading = docDataIsLoading && docDataFetchStatus !== 'idle';

  const disabled = disabledEdit || isMutating || notApplicable;

  return (
    <Box width="100%">
      <Stack
        width="100%"
        direction="column"
        sx={{
          '& .dragBrowse .DragDrop': {
            backgroundColor: 'white',
          },
        }}
      >
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="h4">{prompt}</Typography>
          </Box>
        </Stack>
        <Stack
          width="100%"
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          {/* non-editable */}
          {/* documents query is active  */}
          {disabled && documentsAreLoading && (
            <Skeleton width="100%" sx={{ minHeight: '30px' }} />
          )}
          {/* document(s) exist  */}
          {disabled &&
            documents &&
            documents?.length > 0 &&
            documents.map((doc) => (
              <Button
                key={doc.uuid}
                onClick={() => handleViewDocumentClick(doc, true)}
                startIcon={
                  doc.success === false ? (
                    <i className="icon-expired" />
                  ) : (
                    <i className="icon-open-eye" />
                  )
                }
                sx={{
                  minWidth: '150px',
                  paddingTop: '0px',
                  justifyContent: 'flex-start',
                }}
                disabled={documentsAreLoading || doc.success === false}
              >
                {doc.doc_name || 'Error loading document'}
              </Button>
            ))}
          {/* NO documents and NO Errors and NOT loading */}
          {disabled &&
            !documentsAreLoading &&
            !documents?.length &&
            !docDataIsError && (
              <Typography variant="body2" p={2}>
                No documents uploaded
              </Typography>
            )}
        </Stack>
        {/* editable */}
        <Box
          className={document ? 'hideDrag' : 'showDrag'}
          width="100%"
          padding="10px 0px"
          aria-hidden={!!disabled}
          visibility={disabled ? 'hidden' : 'visible'}
        >
          {documentsAreLoading ? (
            <Skeleton width="100%" sx={{ minHeight: '60px' }} />
          ) : (
            <Box width="100%" sx={{ minHeight: '60px' }}>
              <TaskFileUpload
                updateDocumentState={handleDocumentsChange}
                updateForm={handleFormUpdate}
                data={{ documents: documents || [] }}
                uploadType="task"
                type="task"
              />
            </Box>
          )}
        </Box>
      </Stack>
      <FileDisplayFromUrl
        open={modalOpen}
        headerTitle="File Upload"
        url={documentPreviewUrl || ''}
        onClose={() => setModalOpen(false)}
      />
      <FormGroup>
        <FormControlLabel
          label="This item does not apply to me"
          control={
            <Switch
              disabled={disabledEdit || isMutating}
              onChange={handleNotApplicable}
              checked={notApplicable}
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
