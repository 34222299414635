/* eslint-disable global-require */
import { useCallback, useRef, useState } from 'react';

import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthenticatedRedirect } from 'components/AuthenticatedRedirect/AuthenticatedRedirect';
import {
  useGenerateMagicLink,
  useGenerateResetPasswordLink,
  useSignIn,
} from 'hooks/login-v5-hooks';

import { DEFAULT_ERROR_MESSAGE } from 'constants/constants';
import {
  CpaSelector,
  MFAForm,
  LoginForm,
  ForgotPasswordForm,
  NextStepsMessage,
  LoginPageLayout,
} from './components';
import { formSchema } from './helpers/formValidationSchema';
import { INVALID_MFA_ERROR_TYPE } from './constants';

export type FormUseContext = 'login' | 'magicLink' | 'passwordReset';

export type ProcessState =
  | 'loginForm'
  | 'mfaCode'
  | 'cpaSelector'
  | 'forgotPasswordForm'
  | 'magicLinkSent'
  | 'resetPasswordLinkSent';

export interface LoginFormInterface {
  email: string;
  password: string;
  two_factor_code?: string;
  company_url?: string;
}

export const LoginV5Form = () => {
  const [processState, setProcessState] = useState<ProcessState>('loginForm');
  const formUseContextRef = useRef<FormUseContext>();

  /**
   * Sign In
   */
  const {
    mutateAsync: signIn,
    data: signInResponse,
    error,
    isLoading: isSigningIn,
    reset: resetMutation,
  } = useSignIn({
    onSuccess: () => {
      setProcessState('cpaSelector');
    },
  });

  /**
   * Magic Link
   */
  const {
    mutateAsync: sendMagicLink,
    isLoading: isSendingMagicLink,
    data: magicLinkResponse,
  } = useGenerateMagicLink({
    onSuccess: () => {
      setProcessState('magicLinkSent');
    },
  });

  /**
   * Reset Password
   */
  const {
    mutateAsync: sendResetPasswordLink,
    isLoading: isSendingResetPasswordLink,
    data: resetPasswordResponse,
  } = useGenerateResetPasswordLink({
    onSuccess: () => {
      setProcessState('resetPasswordLinkSent');
    },
  });

  const formCtx = useForm<LoginFormInterface>({
    resolver: yupResolver(formSchema),
    context: { processState },
  });
  const { handleSubmit, setError, reset: resetForm, getValues } = formCtx;

  const onSubmit: SubmitHandler<LoginFormInterface> = useCallback(
    (data) => {
      if (formUseContextRef.current === 'magicLink') {
        sendMagicLink({ email: data.email, appType: 'firm' });
      } else if (formUseContextRef.current === 'passwordReset') {
        sendResetPasswordLink({ email: data.email, appType: 'firm' });
      } else {
        signIn(data);
      }
    },
    [sendMagicLink, sendResetPasswordLink, signIn],
  );

  const switchFormUseContext = useCallback((newContext: FormUseContext) => {
    formUseContextRef.current = newContext;
  }, []);

  /**
   * Error handling
   */
  if (error) {
    const { two_factor_code } = getValues();

    const statusCode = error?.response?.status;
    const message =
      error?.response?.data?.errors?.[0]?.message || DEFAULT_ERROR_MESSAGE;
    const errorType = error?.response?.data?.errors?.[0]?.type;

    // We need to establish a better contract with the backend :/
    if (errorType === INVALID_MFA_ERROR_TYPE && !two_factor_code) {
      setProcessState('mfaCode');
    } else {
      setError('root.serverError', {
        type: String(statusCode),
        message,
      });
    }
    resetMutation(); // clear error
  }

  const backToLogin = useCallback(() => {
    resetForm();
    resetMutation();
    setProcessState('loginForm');
    formUseContextRef.current = 'login';
  }, [resetForm, resetMutation]);

  const goToForgotPassword = useCallback(() => {
    setProcessState('forgotPasswordForm');
  }, []);

  const isLoading =
    isSigningIn || isSendingMagicLink || isSendingResetPasswordLink;

  return (
    <AuthenticatedRedirect>
      <FormProvider {...formCtx}>
        <div id="page-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading && <div id="loading" />}
            <LoginPageLayout>
              <>
                {processState === 'loginForm' && (
                  <LoginForm goToForgotPassword={goToForgotPassword} />
                )}
                {processState === 'cpaSelector' && signInResponse && (
                  <CpaSelector
                    temporaryAuthToken={signInResponse?.auth_token}
                    availableCpas={signInResponse?.cpa_data}
                    backToLogin={backToLogin}
                    loginType="password"
                  />
                )}
                {processState === 'mfaCode' && (
                  <MFAForm backToLogin={backToLogin} />
                )}
                {processState === 'forgotPasswordForm' && (
                  <ForgotPasswordForm
                    backToLogin={backToLogin}
                    switchFormUseContext={switchFormUseContext}
                  />
                )}
                {processState === 'magicLinkSent' && (
                  <NextStepsMessage
                    backToLogin={backToLogin}
                    rawMessage={magicLinkResponse?.message}
                  />
                )}
                {processState === 'resetPasswordLinkSent' && (
                  <NextStepsMessage
                    backToLogin={backToLogin}
                    rawMessage={resetPasswordResponse?.message}
                  />
                )}
              </>
            </LoginPageLayout>
          </form>
        </div>
      </FormProvider>
    </AuthenticatedRedirect>
  );
};

export default LoginV5Form;
