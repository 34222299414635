import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-latest';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Modal, ModalHeader } from 'reactstrap';
import DocumentShow from 'components/UploadedFiles/DocumentShow';
import NoteDetail from 'components/Notes/NoteDetail';
import EmailThreadDetails from 'components/Emails/EmailThreadDetails';
import DisplaySnippet from 'common/DisplaySnippet';
import {
  getAvatar,
  getBadgeIcon,
  getDueTime,
  getTimeOfActivity,
  getHeaderIcon,
  getInfocusInvoceNumber,
} from 'components/Infocus/inFocusUtils';
import { ACTIVITY_TIMELINE_EXPRIMENT } from 'constants/constants';
import { rudderTrack } from 'analytics-utils/tracking';
import { LinearProgress } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import SMSSlideout from './SMSSlideout';

/* TODO: Remove this Modal. We are using modal from material ui and you have to style it accordingly */

const BorderDiv = styled.div`
  .lefCol {
    border-left: 2px solid transparent;
    transition: all ease-in-out 350ms;
    &:hover {
      border-left-color: #00aecc;
    }
  }
`;

const ActivityCard = ({
  id,
  user,
  taskType,
  activityType,
  status,
  avatar,
  avatarName,
  subject,
  title,
  percentComplete,
  dueBy,
  documents,
  lastActivityAt,
  description,
  invoiceAmount,
  invoiceNumber,
  isRecurring,
  refreshData,
  contactSmsPhoneNumber = null,
  contactSmsEnabled = null,
  constactSmsThreadId,
  uuid,
}) => {
  const { requestsPageFeature } = useFlags();
  const [showModal, setShowModal] = useState(false);
  const [showSmsSlideout, setShowSmsSlideout] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const smsReminderInfo = {
    contact_sms_phone_number: contactSmsPhoneNumber,
    contact_sms_enabled: contactSmsEnabled,
    contact_sms_thread_id: constactSmsThreadId,
  };

  const handleClick = () => {
    let domain;

    if (location?.pathname?.includes('accountdetails'))
      domain = 'Account Timeline';
    if (location?.pathname?.includes('contactdetails'))
      domain = 'Contact Timeline';

    switch (activityType) {
      case 'message':
        rudderTrack(ACTIVITY_TIMELINE_EXPRIMENT, {
          userJourney: `${domain} => Message Thread Detail`,
        });
        navigate(`/inbox/${encodeURIComponent(id)}`, {
          state: { from: 'account' },
        });
        break;
      case 'task':
        navigate(`/task/detail/${id}`, {
          state: { accountsDetail: id, from: location.pathname },
        });
        break;
      case 'invoice':
        navigate(`/billing/recurring/${id}`, {
          state: {
            source: 'inFocus',
            currentTab: 'inFocus',
          },
        });
        break;
      case 'wf_request':
        navigate(`/requests/${uuid}`);
        break;
      case 'document':
      case 'note':
      case 'email':
        rudderTrack(ACTIVITY_TIMELINE_EXPRIMENT, {
          userJourney: `${domain} => Email Modal`,
        });
        setShowModal(true);
        break;
      case 'text_message':
        setShowModal(false);
        setShowSmsSlideout(true);
        break;
      default:
        setShowModal(false);
    }
  };

  const closeModal = () => {
    /* we will refresh infocus view when file is archive
    TO DO : right we are refreshing view everytime file preview is closed.
    We need to refresh only when we archive file */
    if (activityType === 'file') {
      refreshData();
    }
    setShowModal(false);
  };

  if (!requestsPageFeature && activityType === 'wf_request') return null;

  return (
    <Box className="InFocusTasks">
      <BorderDiv style={{ cursor: 'pointer' }} onClick={handleClick}>
        <Grid
          container
          direction="column"
          spacing={3}
          sx={{ my: 5, padding: '0 0 0 1rem' }}
          className="lefCol"
        >
          <Grid container>
            <Grid item direction="row" xs={8}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* this applies to only type activity */}
                {getHeaderIcon(activityType)}
                {/* this applies for task and invoice activity */}
                {taskType && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: 1, ml: 1 }}
                  />
                )}
                {taskType &&
                  getBadgeIcon(taskType === 'Bill' ? status : taskType)}
              </Box>
            </Grid>
            {/* for showing time */}
            <Grid item xs={4}>
              {getTimeOfActivity(lastActivityAt, 'last_activity')}
            </Grid>
          </Grid>
          {subject && (
            <div>
              <DisplaySnippet snippet={subject} type="primary" />
            </div>
          )}
          {/* for showing user name */}
          {user &&
            getAvatar({
              avatar,
              avatarName,
              name: user,
              activityType,
              smsReminderInfo,
              activityUUID: uuid,
              taskId: id,
            })}
          {invoiceNumber && getInfocusInvoceNumber(isRecurring, invoiceNumber)}
          {title && (
            <div>
              <DisplaySnippet snippet={title} type="primary" />
            </div>
          )}
          {description && activityType !== 'document' && (
            <Box sx={{ mb: 1 }}>
              <DisplaySnippet snippet={description} />
            </Box>
          )}
          <Box
            component="div"
            sx={{ display: 'flex', alignItems: 'center' }}
            className="text-dark duedateDoc"
          >
            <Stack
              direction="row"
              sx={{ alignItems: 'center', width: '100%' }}
              spacing={1}
            >
              {/* this is applies for invoice activity */}
              {invoiceAmount && taskType === 'Bill' && (
                <>
                  <Typography
                    className="text-body"
                    variant="subtitle1"
                    sx={{ display: 'inline-block', fontWeight: 600 }}
                  >
                    ${invoiceAmount}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: 1, ml: 1 }}
                  />
                </>
              )}
              {dueBy && getDueTime(dueBy, 'due_by')}
              {dueBy && documents && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mr: 1, ml: 1 }}
                />
              )}
              {documents}
            </Stack>
            {activityType === 'wf_request' && (
              <Box width="12rem" justifySelf="flex-end">
                <TooltipStyled title={`${percentComplete}%`} arrow>
                  <LinearProgress
                    value={percentComplete}
                    variant="determinate"
                  />
                </TooltipStyled>
              </Box>
            )}
          </Box>
        </Grid>
      </BorderDiv>
      {showModal && activityType === 'document' && (
        <Modal isOpen={showModal} className="Md-full-width modal-FullH">
          <DocumentShow selectedItem={{ id }} onButtonClose={closeModal} />
        </Modal>
      )}
      {showModal && activityType === 'note' && (
        <NoteDetail
          notesof="activity"
          id={id}
          closeNoteDetailsModal={closeModal}
        />
      )}
      {showModal && activityType === 'email' && (
        <Modal
          isOpen={showModal}
          style={{ height: '80vh' }}
          className="customModal customModal--center customModal--w1150 customModal--nopadding emailModal"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={closeModal}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <EmailThreadDetails
            from="linkedEmailAccConTask"
            selectedEmailId={id}
          />
        </Modal>
      )}
      {showSmsSlideout && activityType === 'text_message' && (
        <SMSSlideout
          threadId={id}
          showSlideout={showSmsSlideout}
          setShowSlideout={setShowSmsSlideout}
        />
      )}
    </Box>
  );
};
export default ActivityCard;
