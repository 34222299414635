/* eslint-disable*/
import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';

const GoogleAuthenticationModal = (props) => {
  let doneVerification = false;
  let recoveryModal = false;
  let verifyModal = false;
  if (props.className === 'DoneVerification') {
    doneVerification = true;
  } else if (props.className === 'RecoveryModal') {
    recoveryModal = true;
  } else if (props.className === 'VerifyModal') {
    verifyModal = true;
  }

  const RecoveryCodes = props.data?.recoveryCodes?.length
    ? props.data.recoveryCodes.map((item, index) => (
      <div key={index}>{item.value}</div>
    ))
    : [];

  return (
    <Modal isOpen={props.isOpen} className="customModal customModal--alert customModal--w700 customModal--nopadding">
      <div className="ModalHeader">
        <button type="button" className="close" aria-label="Close" onClick={props.noBtnClick}>
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody className="modalContent">
        <div className="modalContent--inner">
          <div className="modalContent--header text-large">
            <h5 className="modalContent--header text-medium mb-2">{props.headerText}</h5>
          </div>
          {doneVerification && !recoveryModal && !verifyModal ? (
            <div className="d-block">
              <div className="formGroup mb-3">
                <label className="text-body text-small mb-0">Status</label>
                <div className="lblValue">
                  {props.data.mfaEnabled ? (
                    <div>
                      <span className="fontSize">Enabled</span>
                      <a onClick={props.mfaHandleDisable} className="fontSize font-weight-bold ml-2">
                        Disable
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="formGroup mb-3">
                <label className="text-body text-small mb-0">Access</label>
                <div className="lblValue">
                  <a className="fontSize font-weight-bold link px-0" onClick={props.generateRecoveryCodes}>
                    Generate Recovery Codes…
                    {' '}
                    <i className="iconCode">?</i>
                  </a>
                </div>
              </div>
            </div>
          ) : recoveryModal && !doneVerification && !verifyModal ? (
            <div className="row">
              <div className="col-12 pb-3">
                <label className="text-body font-weight-normal mb-0 mt-3">
                  Make sure to
                  {' '}
                  <a onClick={props.copyCodes} className="text-primary">
                    save your codes
                  </a>
                  {' '}
                  in a safe place.
                </label>
              </div>
              <div className="col-12">
                <div className="row" id="templateData">
                  <div className="labelValue col-12">
                    <div className="form-control">
                      <Scrollbars
                        style={{ width: '100%' }}
                        autoHide
                        autoHideTimeout={1000}
                        // renderTrackHorizontal={props => <div {...props} className="thumb-vertical" />}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={300}
                      >
                        <div id="copyCodes">{RecoveryCodes}</div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
              {/*
                <SelectField
                  name="savecode"
                  className="form-group dropdown col-12"
                  labelClass="d-none"
                  error={props.data.errors.recoveryCode}
                  options={props.data.recoveryCodes}
                  handleChange={props.handleRecoveryCodeChange}
                  value={
                    props.data.recoveryCodeId ?
                    { value: props.data.recoveryCodeId, label: props.data.recoveryCodeName }
                    :
                      undefined
                    }
                  clearable
                /> */}
            </div>
          ) : verifyModal && !recoveryModal && !doneVerification ? (
            <div>
              <p>To verify your device:</p>
              <p>Open GOOGLE AUTHENTICATOR app.</p>
              <p>
                Use your phone camera to scan the BARCODE BOX (QR code) below, OR use the SECRET KEY below the barcode
                box.
              </p>
              <div className="d-block text-center">
                <img src={props.data.qrCode} alt="barcode" />
              </div>
              <div className="form-group mb-4">
                <input type="text" className="form-control" value={props.data.barCodeSecretKey} readOnly />
              </div>
              <p className="mb-1">GOOGLE AUTHENTICATOR app will generate a VERIFICATION CODE.</p>
              <p>Enter the VERIFICATION CODE below.</p>
              <div className="form-group">
                <label htmlFor="codeverify">VERIFICATION CODE</label>
                <div className={props.data.errors.verificationCode ? 'lblValue form-error' : 'lblValue'}>
                  <input
                    className={props.data.errors.verificationCode ? 'form-control error' : 'form-control'}
                    type="text"
                    value={props.data.verificationCode}
                    onChange={props.handleVerificationCode}
                  />
                  {props.data.errors.verificationCode ? (
                    <span className="error_label">{props.data.errors.verificationCode}</span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-dark mb-1">
                Enabling multi-factor authentication is a critical step in improving your cybersecurity.
              </p>
              <p className="text-dark mb-0">
                Liscio is happy to announce that we’re currently supporting
                {' '}
                <a
                  target="_blank"
                  href="https://support.google.com/accounts/answer/1066447?hl=en"
                  className="link"
                  rel="noreferrer"
                >
                  Google Authenticator.
                </a>
              </p>
            </div>
          )}
          <div className="row">
            <div className="col-12 justify-content-end formbtn justify-content-end">
              <div className="btn-wrap btn--leftspace">
                {props.noBtnText ? (
                  <Button className="btn btn-outline-light" onClick={props.noBtnClick}>
                    {props.noBtnText}
                  </Button>
                ) : null}
                {props.yesBtnText ? (
                  <Button className="btn btn-primary" onClick={props.yesBtnClick}>
                    {props.yesBtnText}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GoogleAuthenticationModal;
