import { Stack } from '@mui/material';
import { RequestItemDependency } from 'components/Requests/components/TaxDelivery/components/RequestItemDependency/RequestItemDependency';
import { WorkflowItemTitle } from 'components/Requests/components/WorkflowView/components/WorkflowItemTitle/WorkflowItemTitle';
import {
  WorkflowRequestFragment,
  WorkflowRequestItemFragment,
  WorkflowSignableDocumentItemFragment,
} from 'gql/graphql';
import { WorkflowSignableDocument } from 'components/Requests/components/TaxDelivery/components/WorkflowSignableDocument/WorkflowSignableDocument';

type TaxDeliveryItemProps = {
  workflowItem?: WorkflowSignableDocumentItemFragment | null;
  workflowRequestItem?: WorkflowRequestItemFragment | null;
  assignees?: WorkflowRequestFragment['assignedContacts'];
  disableEdit?: boolean;
};

export const TaxDeliveryItem = ({
  workflowItem,
  workflowRequestItem,
  assignees,
  disableEdit,
}: TaxDeliveryItemProps) => {
  const document =
    workflowRequestItem?.dependency ||
    workflowItem?.esignAgreement?.documentToSign;

  const renderSignableDocument = () => {
    if (workflowItem && workflowItem.esignAgreement) {
      return (
        <WorkflowSignableDocument
          signers={workflowItem.esignAgreement.signatories}
          assignees={assignees}
          disableEdit={disableEdit || false}
        />
      );
    }

    return null;
  };

  return (
    <Stack>
      <WorkflowItemTitle prompt={workflowRequestItem?.prompt || ''} />
      <Stack>
        <RequestItemDependency value={document} />
        {renderSignableDocument()}
      </Stack>
    </Stack>
  );
};
