/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import HeaderTabs from 'common/HeaderTabs';
import TemplatesList from './TemplatesList';

const TemplatesTabs = [
  {
    index: 0,
    label: 'Message Templates',
    value: 'message_template',
  },
  {
    index: 1,
    label: 'Task Templates',
    value: 'task_template',
  },
  {
    index: 2,
    label: 'eDoc Templates',
    value: 'edoc_template',
  },
  {
    index: 3,
    label: 'Invoice Templates',
    value: 'invoice_template',
  },
];

class Templates extends Component {
  constructor(props) {
    super(props);
    const parsedUrl = new URL(window.location.href);
    const tab = parsedUrl.searchParams.get('tab');
    let tabIndex = 0;
    if (tab && tab !== 'create_message_template') {
      tabIndex = TemplatesTabs.findIndex((each) => each.value === tab);
    }
    this.state = {
      activeTabIndex: tabIndex === undefined ? 0 : tabIndex,
      activatedTabs: { [TemplatesTabs[tabIndex].value]: true },
    };
  }

  componentDidMount() {
    const { activeTabIndex, createTemplate } = this.state;
    const { navigate } = this.props;
    if (activeTabIndex && TemplatesTabs[activeTabIndex]) {
      navigate(`/templates?tab=${TemplatesTabs[activeTabIndex].value}`);
    } else if (activeTabIndex && createTemplate) {
      navigate('/templates?tab=create_message_template');
    }
    document.title = TemplatesTabs[activeTabIndex].label;
  }

  changeActiveTab = (key) => {
    this.setState({
      activeTabIndex: key,
      activatedTabs: {
        ...this.state.activatedTabs,
        [TemplatesTabs[key].value]: true,
      },
      createTemplate: false,
    });
    const { navigate } = this.props;
    document.title = TemplatesTabs[key].label;
    navigate(`/templates?tab=${TemplatesTabs[key].value}`);
  };

  render() {
    const { activeTabIndex, activatedTabs } = this.state;
    const tabs = TemplatesTabs;
    return (
      <div id="content">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={tabs}
            activeTab={activeTabIndex}
            handleClick={this.changeActiveTab}
          />
        </header>
        <div>
          {activatedTabs.message_template ? (
            <div className={activeTabIndex === 0 ? '' : 'hidden'}>
              <TemplatesList type={TemplatesTabs[0].value} />
            </div>
          ) : null}
          {activatedTabs.task_template ? (
            <div className={activeTabIndex === 1 ? '' : 'hidden'}>
              <TemplatesList type={TemplatesTabs[1].value} />
            </div>
          ) : null}
          {activatedTabs.edoc_template ? (
            <div className={activeTabIndex === 2 ? '' : 'hidden'}>
              <TemplatesList type={TemplatesTabs[2].value} />
            </div>
          ) : null}

          {activatedTabs.invoice_template ? (
            <div className={activeTabIndex === 3 ? '' : 'hidden'}>
              <TemplatesList type={TemplatesTabs[3].value} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Templates;
