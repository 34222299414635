import { Box } from '@mui/material';
import { ItemListItem } from 'components/Requests/components/WorkflowView/components/ItemListItem/ItemListItem';
import { WorkflowPreviewListItem } from 'components/Requests/components/WorkflowView/components/WorkflowSectionItem/WorkflowSectionItem';
import { WorkflowViewContext } from 'components/Requests/components/WorkflowView/context/WorkflowViewContext';
import {
  getConditionalQuestions,
  isConditionalQuestion,
  showConditionalQuestion,
} from 'components/Requests/utils/conditionalUtils';
import { WorkflowRequestFragment } from 'gql/graphql';
import { useContext } from 'react';
import WorkflowSectionWrapper from 'components/Requests/components/WorkflowView/components/WorkflowSectionWrapper/WorkflowSectionWrapper';

type WorkflowSectionsProps = {
  disableEdit?: boolean;
  showAllQuestions?: boolean;
  workflowRequestData?: WorkflowRequestFragment;
  alwaysShowConditionals?: boolean;
};

export const WorkflowSections = ({
  disableEdit,
  showAllQuestions,
  workflowRequestData,
  alwaysShowConditionals,
}: WorkflowSectionsProps) => {
  const { conditionalQuestionsMap } = useContext(WorkflowViewContext);

  let itemCount = 0;
  let subItemCount = 0;
  return (
    <Box width="100%">
      <Box
        padding="0px 20px 20px 20px"
        mb="80px"
        sx={{ width: '100%', maxWidth: '700px' }}
      >
        {workflowRequestData?.workflow?.sections?.map((section, idx) => (
          <WorkflowSectionWrapper title={section.title} key={section.id}>
            {section?.items?.map((parentItem, jdx) => {
              if (parentItem?.conditions?.length) {
                return null;
              }

              const conditionalQuestions = getConditionalQuestions(
                parentItem.id,
                conditionalQuestionsMap,
              );

              const parentRequestItem =
                workflowRequestData?.requestSections?.[idx]?.requestItems?.[
                  jdx
                ];
              const requestItems = [parentRequestItem, ...conditionalQuestions];

              return requestItems.map((requestItem) => {
                const item = requestItem?.responseItem?.workflowItem;

                if (!item) {
                  return null;
                }

                let showQuestion;

                let responseItem =
                  workflowRequestData?.requestSections?.[idx]?.requestItems?.[
                    jdx
                  ]?.responseItem;

                if (isConditionalQuestion(item)) {
                  // we need to get the correct response item for conditionals
                  // to send the the BE (instead of setting the parents)
                  responseItem = conditionalQuestions.reduce(
                    (acc: any[], next) => {
                      if (next.responseItem?.workflowItem?.id === item.id) {
                        acc.push(next.responseItem);
                      }
                      return acc;
                    },
                    [],
                  )[0];

                  const showConditionalQ =
                    alwaysShowConditionals ||
                    showConditionalQuestion(conditionalQuestionsMap, item);

                  showQuestion = showAllQuestions
                    ? showConditionalQ
                    : showConditionalQ &&
                      responseItem?.value === null &&
                      !responseItem?.notApplicable;

                  itemCount += 0;
                  subItemCount += 1;
                } else {
                  showQuestion = showAllQuestions
                    ? true
                    : responseItem?.value === null &&
                      !responseItem?.notApplicable;
                  itemCount += 1;
                  subItemCount = 0;
                }

                return workflowRequestData?.requestSections ? (
                  <ItemListItem
                    disableEdit={disableEdit}
                    workflowItem={item}
                    workflowResponseItem={responseItem}
                    workflowRequestItem={requestItem}
                    workflowRequest={workflowRequestData}
                    position={itemCount}
                    subPosition={subItemCount}
                    key={`${item.id} ${responseItem?.updatedAt}`}
                    visible={showQuestion}
                  />
                ) : (
                  <WorkflowPreviewListItem
                    key={item.id}
                    workflowItem={item}
                    workflowRequestItem={requestItem}
                    position={itemCount}
                    subPosition={subItemCount}
                    workflowRequest={workflowRequestData}
                  />
                );
              });
            })}
          </WorkflowSectionWrapper>
        ))}
      </Box>
    </Box>
  );
};
