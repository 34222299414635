import {
  InputBase,
  FormHelperText,
  InputBaseProps,
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
} from '@mui/material';
import { randomString } from 'utilities/utils';
import DatePicker from 'react-datepicker';
import isValid from 'date-fns/isValid';
import { FormControlStyled } from './FormControlStyled';
import { InputLabelStyled } from './InputLabelStyled';

export type DateFieldStyledProps = {
  InputBaseProps?: InputBaseProps;
  error?: boolean;
  InputLabelProps?: InputLabelProps;
  label?: string;
  helperText?: string;
  FormHelperTextProps?: FormHelperTextProps;
  sx?: FormControlProps['sx'];
  onChange?: (date: string | null) => void;
  fullWidth?: boolean;
  value?: Date | null;
} & FormControlProps;

export const DateFieldStyled = ({
  error,
  InputBaseProps: InputProps,
  InputLabelProps: LabelProps,
  label,
  helperText,
  FormHelperTextProps: HelperTextProps,
  sx,
  fullWidth,
  onChange,
  value,
}: DateFieldStyledProps) => {
  const inputLabelId = InputProps?.id ? InputProps.id : randomString(5);
  return (
    <FormControlStyled error={error} sx={sx}>
      {label && (
        <InputLabelStyled htmlFor={inputLabelId} {...LabelProps}>
          {label}
        </InputLabelStyled>
      )}
      <DatePicker
        onChange={(date) => {
          onChange?.(date?.toISOString() || null);
        }}
        id={inputLabelId}
        selected={isValid(value) ? value : null}
        customInput={
          <InputBase error={error} fullWidth={fullWidth} {...InputProps} />
        }
      />
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControlStyled>
  );
};
