import { useQueryClient } from '@tanstack/react-query';
import {
  createWorkflowItemMutationDocument,
  deleteWorkflowItemDocument,
  updateWorkflowItemDocument,
  createWorkflowItemConditionDocument,
  reorderWorkflowItemsMutationDocument,
  updateWorkflowItemConditionDocument,
  deleteWorkflowItemConditionDocument,
} from 'api/workflow-items';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { alertGqlError } from 'components/Requests/components/RequestsBuilder/utils/get-error-message';
import {
  addWorkflowItemToCache,
  deleteWorkflowItemFromCache,
  addWorkflowConditionToCache,
  addWorkflowSectionToCache,
  updateWorkflowConditionInCache,
} from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { getWorkflowItemWithIdFromCache } from 'components/Requests/components/RequestsBuilder/utils/item-utils';
import { useMutationGraphQL } from './useGraphQL';

export function useCreateWorkflowItem() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(createWorkflowItemMutationDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: (response) => {
      if (!response?.createWorkflowItem?.workflowItem) {
        //shouldn't happen
        setErrored('NEW', 'Failed to add question');
        setSubmitting(false);
        return;
      }

      addWorkflowItemToCache(
        queryClient,
        response?.createWorkflowItem?.workflowItem,
        workflowId,
      );
      setSubmitted();
    },
    onError: (errors) => {
      alertGqlError(errors, 'Failed to add question');

      setErrored('NEW', 'Failed to add question');
      setSubmitting(false);
    },
  });
}

export function useDeleteWorkflowItem() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(deleteWorkflowItemDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSettled: (response, _, { workflowItemId }) => {
      const [, conditionalList] = getWorkflowItemWithIdFromCache(
        queryClient,
        workflowItemId,
        workflowId,
      );

      if (conditionalList?.length > 0) {
        conditionalList?.forEach((item) => {
          deleteWorkflowItemFromCache(
            queryClient,
            { workflowItemId: item.id },
            workflowId,
          );
        });
      }

      // Remove from the cache
      deleteWorkflowItemFromCache(queryClient, { workflowItemId }, workflowId);

      setSubmitted();
    },
    onError: (errors, { workflowItemId }) => {
      alertGqlError(errors, 'Failed to delete question');

      setErrored(workflowItemId, 'Failed to delete question');
      setSubmitting(false);
    },
  });
}

export function useUpdateWorkflowItem() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(updateWorkflowItemDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: (response) => {
      if (!response?.updateWorkflowItem?.workflowItem) {
        setSubmitting(false);
        return;
      }

      addWorkflowItemToCache(
        queryClient,
        response?.updateWorkflowItem?.workflowItem,
        workflowId,
        true,
      );
      setSubmitted();
    },
    onError: (errors, { workflowItemId, prompt, type }) => {
      alertGqlError(errors, 'Failed to update question');

      if (prompt) {
        setErrored(workflowItemId, 'Failed to update question');
      }
      if (type) {
        setErrored(`${workflowItemId}.type`, 'Failed to update question type');
      }

      setSubmitting(false);
    },
  });
}

export function useCreateWorkflowItemCondition() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(createWorkflowItemConditionDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSettled: (response, _, { workflowItemId }) => {
      if (!response?.createWorkflowItemCondition?.workflowItemCondition) {
        setSubmitting(false);
        return;
      }

      addWorkflowConditionToCache(
        queryClient,
        workflowItemId,
        response?.createWorkflowItemCondition?.workflowItemCondition,
        workflowId,
      );
      setSubmitted();
    },
    onError: (errors, { workflowItemId }) => {
      alertGqlError(errors, 'Failed to add condition');

      setErrored(workflowItemId, 'Failed to add condition');
      setSubmitting(false);
    },
  });
}

export function useReorderWorkflowItems() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted } =
    useRequestBuilderContext();
  return useMutationGraphQL(reorderWorkflowItemsMutationDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: (response) => {
      const rSection = response?.reorderWorkflowItems?.workflowSection;
      if (!rSection) {
        setSubmitting(false);
        return;
      }

      addWorkflowSectionToCache(queryClient, rSection, workflowId || '', true);
      setSubmitted();
    },
    onError: (errors) => {
      alertGqlError(errors, 'Failed to reorder questions');

      setSubmitting(false);
    },
  });
}

export function useUpdateWorkflowItemCondition() {
  const queryClient = useQueryClient();
  const { workflowId, setSubmitting, setSubmitted, setErrored } =
    useRequestBuilderContext();
  return useMutationGraphQL(updateWorkflowItemConditionDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSettled: (conditionResponse) => {
      const workflowItemCondition =
        conditionResponse?.updateWorkflowItemCondition?.workflowItemCondition;
      if (!workflowItemCondition) {
        setSubmitting(false);
        return;
      }

      updateWorkflowConditionInCache(
        queryClient,
        workflowItemCondition,
        workflowId,
      );
      setSubmitted();
    },
    onError: (errors, { fieldName }) => {
      alertGqlError(errors, 'Failed to update conditional');

      setErrored(`${fieldName}.match`, 'Failed to update conditional');

      setSubmitting(false);
    },
  });
}

export function useDeleteWorkflowItemCondition() {
  const { setSubmitting, setSubmitted } = useRequestBuilderContext();
  return useMutationGraphQL(deleteWorkflowItemConditionDocument, {
    onMutate: () => {
      setSubmitting(true);
    },
    onSuccess: (response) => {
      if (!response?.deleteWorkflowItemCondition) {
        setSubmitting(false);
        return;
      }

      setSubmitted();
    },
    onError: (errors) => {
      alertGqlError(errors, 'Failed to delete condition');

      setSubmitting(false);
    },
  });
}
