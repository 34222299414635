import * as yup from 'yup';

export const formSchema = yup.object().shape({
  two_factor_code: yup
    .string()
    .when('$processState', (contextArray, schema) => {
      const [processState] = contextArray;
      if (processState === 'mfaCode') {
        return schema.required('Code is required');
      }
      return schema;
    }),
});
