/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
// TODO: microsoft file browser
import React, { Component } from 'react';
import { FileDrop } from 'react-file-drop';
import { Modal, ModalHeader, ModalBody, Label, Input } from 'reactstrap';
import moment from 'moment-timezone';
import Axios from 'axios';
// import { GraphFileBrowser } from '@microsoft/file-browser';
import {
  getCpaPreferences,
  fileType,
  AlertMessage,
  getStorageData,
  isUserEmployee,
} from 'utilities/utils';
import { OAUTH_ACCESS_TOKENS, THIRD_PARTY_APPS } from 'constants/constants';
import { NUMBER } from 'utilities/regularexpressions';

import HeaderTabs from 'common/HeaderTabs_updated';
import PageNotFound from 'common/PageNotFound';
import ConfirmationModal from 'common/ConfirmationModal';
import GooglePicker from 'common/GooglePicker';
import SmartVaultWidget from 'common/SmartVault';
import { RenderAttachmentsInProgress } from 'common/CustomFields';
import CustomImage from 'common/CustomImage';
import * as TaskApi from 'api/TaskApi';
import UploadFiles from 'api/UploadFiles';
import ClientLiscioVault from 'components/UploadedFiles/ClientLiscioVault_updated';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import liscioLogo from 'images/liscio_logo.png';
import LiscioVaultList from './LiscioVaultList_updated';
import ClientFilePopup from './ClientFilePopup';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
// eslint-disable-next-line no-unused-vars
let accessToken = '';
const onedriveListHeaders = [
  {
    value: 'name',
    label: 'Name',
    className: 'col-3 thCol',
  },
  {
    value: 'shared_by',
    label: 'Shared By',
    className: 'col-2 thCol',
  },
  {
    value: 'modified_by',
    label: 'Modified By',
    className: 'col-2 thCol',
  },
  {
    value: 'date_modified',
    label: 'Date Modified',
    className: 'col-2 thCol',
  },
  {
    value: 'size',
    label: 'Size',
    className: 'col-1 thCol',
  },
  {
    value: 'sharing',
    label: 'Sharing',
    className: 'col thCol',
  },
];
const oneDriveModalHeaders = [
  {
    label: 'My Files',
    value: 'my_files',
  },
  {
    label: 'Shared with me',
    value: 'shared_with_me',
  },
];
class TaskFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openModal: false,
      preferences: {},
      documents: [],
      liscioVaultList: [],
      uploadFiles: [],
      // previewScreen: false,
      // previewDocument: null,
      deleteConfirm: false,
      fileDropped: null,
      fileuploadFields: {},
      showAttachmentModal: false,
      selectedAttachment: '',
      openOnedrive: false,
      thirdPartyApps: [],
      accessToken: '',
      sharedFilesList: [],
      folderSequence: [],
      onedriveFilesActiveTab: 0,
      noSharedFiles: false,
      googleDriveModal: false,
      showSmartVaultWidget: false,
    };
  }

  UNSAFE_componentWillMount() {
    const preferences = getCpaPreferences();
    this.setState({
      preferences,
      // TODO: __HOMEDATA-AUDIT
      isEmployee: getStorageData && getStorageData().is_employee,
    });
    this.updateDocumentsArray();
  }

  // componentWillReceiveProps(nextProps) {
  //   this.updateDocumentsArray();
  // }
  componentDidMount() {
    const dropDownFrame = document.getElementById('AttachmentDropDownFrame');
    this.setState({
      dropDownFrame,
    });
    const request = Axios.get(THIRD_PARTY_APPS);
    request
      .then((response) => {
        if (response.data.success === 200) {
          this.setState({
            thirdPartyApps: response.data.data.length ? response.data.data : [],
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log('err', err);
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const { data } = prevProps;
    const {
      data: { documents },
    } = this.props;
    if (
      data.documents &&
      documents &&
      (data.documents.length !== documents.length ||
        (data.documents.length === documents.length &&
          data.documents.length > 0 &&
          data.documents[0].id !== documents[0].id))
    ) {
      this.updateDocumentsArray();
    }
  }

  // onSuccess = (keys) => {
  //   const selectedRecords = keys.map((selectedItem) => {
  //     const item = selectedItem.driveItem_203[2];
  //     const head = {
  //       headers: {
  //         Authorization: 'Bearer ' + accessToken,
  //       },
  //     };
  //     Axios.get(
  //       'https://graph.microsoft.com/v1.0/me/drive/items/' + item,
  //       head,
  //     ).then((res) => {
  //       const selectedfile = [];
  //       selectedfile.push(res.data);
  //       this.startUpload(selectedfile, 'onedrive');
  //     });
  //     return selectedItem;
  //   });
  //   this.handleOnedriveFiles('close');
  // };

  // onCancel = (err) => {
  //   this.handleOnedriveFiles('close');
  // };

  onedriveSharedFileHandle = (item, itemType = '') => {
    const head = {
      headers: {
        Authorization: `Bearer ${this.state.accessToken}`,
      },
    };
    if (
      ((item.folder && item.folder !== undefined) ||
        (!item.folder && item.folder === undefined)) &&
      !item.file
    ) {
      this.setState({ loading: true });
      if (item.id === 'shared') {
        this.sharedFolder(1);
      } else {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}/children?expand=thumbnails`,
          head,
        ).then((response) => {
          if (response.status === 200) {
            let { folderSequence: sequence } = this.state;
            const index = sequence.findIndex((x) => x.active === true);
            if (index > -1) {
              sequence[index].active = false;
              this.setState({ folderSequence: sequence });
            }
            if (itemType === 'folder') {
              const indx = sequence.findIndex((x) => x.id === item.id);
              if (indx > -1) {
                sequence[indx].active = true;
                sequence = sequence.slice(0, indx + 1);
                this.setState({ folderSequence: sequence });
              }
            } else {
              const seq = {
                name: item.name,
                id: item.id,
                parentReference: { driveId: item.parentReference.driveId },
                active: true,
                folder: true,
              };
              sequence.push(seq);
            }
            this.setState({
              sharedFilesList: response.data.value,
              folderSequence: sequence,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        });
      }
    } else {
      const checkboxState = this.state.sharedFilesList;
      const index = checkboxState.findIndex((x) => x.id === item.id);
      if (index > -1) {
        checkboxState[index].checked = !checkboxState[index].checked;
        this.setState({ sharedFilesList: checkboxState }, () => {
          // console.log('pali', this.state.sharedFilesList);
        });
      }
    }
  };

  setAttachmentNull = () => {
    const uploadFile = document.getElementById('upload_doc');
    uploadFile.value = null;
  };

  // getAuthenticationToken = () => Promise.resolve(accessToken);

  handleOnedriveSelectedFiles = () => {
    const head = {
      headers: {
        Authorization: `Bearer ${this.state.accessToken}`,
      },
    };
    const checkboxState = this.state.sharedFilesList;
    checkboxState.map((item) => {
      if (item.checked && item.checked !== undefined) {
        Axios.get(
          `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}`,
          head,
        ).then((res) => {
          const selectedfile = [];
          selectedfile.push(res.data);
          this.startUpload(selectedfile, 'onedrive');
        });
      }
      this.handleOnedriveFiles('close');
      return null;
    });
  };

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open') {
      const request = Axios.get(`${OAUTH_ACCESS_TOKENS}/${item.key}`);

      request
        .then((response) => {
          if (response.status === 200) {
            accessToken = response.data.access_token;
            const { openOnedrive } = this.state;

            this.setState({
              openOnedrive: !openOnedrive,
              accessToken
            });
          }
          else {
            AlertMessage(
              'error',
              `Please set up ${item.value} to use the cloud store.`,
              3000,
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (toggle === 'close') {
      const { openOnedrive } = this.state;
      this.setState({
        openOnedrive: !openOnedrive,
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
      });
    }
  };

  updateDocumentsArray = () => {
    const { data, uploadType } = this.props;
    if (
      uploadType === 'task' ||
      uploadType === 'note' ||
      uploadType === 'message' ||
      uploadType === 'message_template' ||
      uploadType === 'task_template' ||
      uploadType === 'edoc_template' ||
      uploadType === 'edoc'
    ) {
      this.setState({
        documents: data.documents,
      });
    }
  };

  manageState = (data, vaultListData) => {
    const { documents } = this.state;
    const docs = [];
    const doc_ids = [];
    data.documents.map((e) => {
      let isNewFile = true;
      documents.map((existing_doc) => {
        if (existing_doc.id === e.id) {
          isNewFile = false;
        }
        return existing_doc;
      });
      if (isNewFile) {
        docs.push(e);
        doc_ids.push(e.id);
      }
      return e;
    });
    this.setState(
      {
        documents: documents.concat(docs),
        showVault: false,
        vaultListData,
      },
      () => {
        this.props.updateDocumentState(documents.concat(docs));
      },
    );
  };

  handleModalChange = (value) => {
    //  if value set it else toggle it
    if (value) {
      this.setState({
        openModal: value,
      });
    } else {
      const { openModal } = this.state;
      this.setState({
        fileDropped: null,
        openModal: !openModal,
      });
    }
    if (document.getElementById('draftInput')) {
      document.getElementById('draftInput').focus(); // to focus out from editor
    }
  };

  handleFileDrop = (files, e) => {
    if (e && this.props.uploadType === 'edoc') {
      const uploadFile = files;
      this.startUpload(uploadFile);
    } else if (e) {
      const uploadFile = files;
      this.setState({
        openModal: true,
        fileDropped: uploadFile,
      });
    }
  };

  previewDoc = (doc) => {
    // this.setState({
    //   previewScreen: true,
    //   previewDocument: doc,
    // });
    //  use above code only if you want to show preview screen and comment below code
    UploadFiles.showOpenAttachment(doc, this);
  };

  attachmentModalClose = () => {
    this.setState({
      showAttachmentModal: false,
      selectedAttachment: '',
    });
  };

  // previewCloseAttachment = (data) => {
  //   if (data.editedFileName) {
  //     if (this.state.documents.length) {
  //       let x;
  //       for (x = 0; x < this.state.documents.length; x += 1) {
  //         if (this.state.documents[x].id === data.selectedItem.id) {
  //           const doc = this.state.documents;
  //           const filename = data.editedFileName;
  //           doc[x].doc_name = filename;
  //           this.setState({ documents: doc }, () => {
  //             this.props.updateDocumentState(doc);
  //           });
  //         }
  //       }
  //     }
  //   }
  //   this.setState({
  //     previewScreen: false,
  //     previewDocument: null,
  //   });
  // }
  delAttachmentModal = (doc, confirmed) => {
    const {
      documents,
      isFromLiscioVault,
      deleteDocId,
      isQbInvoicePdf,
      isAttachmentFromEmailOrMessage,
    } = this.state;
    const { uploadType, isEdit, data } = this.props;
    if (confirmed) {
      if (isFromLiscioVault) {
        const documnts = [];
        documents.map((e) => {
          if (e.id !== deleteDocId) {
            documnts.push(e);
          }
          return e;
        });
        this.setState(
          {
            documents: documnts,
          },
          () => this.props.updateDocumentState(documnts),
        );
      }
      // delete attachment if it uploaded directly
      // will delete all kind of attachments for messages
      let addedUrl = '';
      if (isQbInvoicePdf) {
        this.props.deleteQbInvoicePdf(deleteDocId, true);
      } else if (uploadType === 'note') {
        if (isEdit) {
          addedUrl = `?doc_type=note&note_id=${data.editNoteId}`;
        }
        TaskApi.deleteTaskAttachment(
          this.state.deleteDocId,
          this,
          '',
          '',
          addedUrl,
        );
      } else if (uploadType === 'task') {
        if (isEdit) {
          addedUrl = `?doc_type=task&task_id=${data.editTaskId}`;
        }
        TaskApi.deleteTaskAttachment(
          this.state.deleteDocId,
          this,
          '',
          '',
          addedUrl,
        );
      } else if (uploadType === 'message') {
        addedUrl = `?doc_type=message&message_id=${encodeURIComponent(
          data.messageId,
        )}`;
        TaskApi.deleteTaskAttachment(
          this.state.deleteDocId,
          this,
          '',
          '',
          addedUrl,
        );
      } else if (uploadType === 'edoc') {
        if (!isFromLiscioVault) {
          TaskApi.deleteTaskAttachment(
            this.state.deleteDocId,
            this,
            '',
            '',
            addedUrl,
          );
        }
      } else if (
        uploadType === 'task_template' ||
        uploadType === 'message_template' ||
        uploadType === 'edoc_template'
      ) {
        addedUrl = `?doc_type=${uploadType}&template_id=${data.templateId}`;
        TaskApi.deleteTaskAttachment(
          this.state.deleteDocId,
          this,
          '',
          '',
          addedUrl,
        );
      }
      if (isAttachmentFromEmailOrMessage) {
        this.props.deleteEmailAttachment(deleteDocId);
      }
      this.setState({
        deleteConfirm: false,
        previewScreen: false,
        deleteDocId: null,
        isQbInvoicePdf: false,
        isAttachmentFromEmailOrMessage: false,
      });
    } else {
      this.setState({
        deleteDocId: doc.id,
        deleteConfirm: true,
        isFromLiscioVault: doc.fromlisciovault,
        isQbInvoicePdf: doc.is_qb_invoice_pdf,
        isAttachmentFromEmailOrMessage: doc.isAttachmentFromEmailOrMessage,
      });
    }
  };

  openVault = (input, vaultListData) => {
    this.setState({
      showVault: input,
      // eslint-disable-next-line react/no-access-state-in-setstate
      vaultListData: vaultListData && this.state.vaultListData,
    });
    if (document.getElementById('draftInput')) {
      document.getElementById('draftInput').focus(); // to focus out from editor
    }
  };

  // handleFileDrop = (files) => {
  //   const uploadFile = files;
  //   this.startUpload(uploadFile);
  // }
  uploadDocument = () => {
    const uploadFile = document.getElementById('upload_doc').files;
    this.startUpload(uploadFile);
  };

  startUpload = (input, fro = '') => {
    console.log({ input });
    const uploadFile = [];
    for (let j = 0; j < input.length; j += 1) {
      // console.log('input[j].type', input[j].type);
      // console.log('input[j].type', input[j]);
      const type = fro === 'onedrive' ? input[j].file.mimeType : input[j].type;
      if (
        type &&
        (type === 'application/pdf' ||
          type === 'application/doc' ||
          type === 'application/msword' ||
          type === 'application/vnd.google-apps.document' ||
          type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      ) {
        uploadFile.push(input[j]);
      } else {
        AlertMessage(
          'error',
          'Only PDF and Word Documents are accepted for eDoc Request',
          6000,
        );
      }
      if (type === undefined || type === null || type.length === 0) {
        if (
          ['docx', 'doc', 'pdf'].indexOf(input[j].name.split('.').pop()) > -1
        ) {
          uploadFile.push(input[j]);
        }
      }
    }
    const component = this;
    const { uploadType } = this.props;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      getCpaPreferences() &&
      getCpaPreferences().attachment &&
      getCpaPreferences().attachment[0].label &&
      NUMBER.test(getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes = getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    if (uploadFile.length) {
      for (let i = 0; i < uploadFile.length; i += 1) {
        const data = new FormData();
        // const data = {};
        if (fro === 'onedrive' || fro === 'sharepoint') {
          // this.googleDriveLoader('false');
          data.append('aws_url_file_name', uploadFile[i].name);
          data.append('aws_url', uploadFile[i]['@microsoft.graph.downloadUrl']);
        } else if (fro === 'google_drive' || fro === 'smart_vault') {
          // this.googleDriveLoader('false');
          // data.aws_url = uploadFile[i].file;
          // data.file_name = uploadFile[i].name;
          // console.log('QSQ', uploadFile[i].type);
          data.append('aws_url_file_name', uploadFile[i].name);
          // // const blob = new Blob([uploadFile[i]], { type: 'image/jpg' });
          data.append('aws_url', uploadFile[i].file);
        } else {
          data.append('aws_url', uploadFile[i], uploadFile[i].name);
        }
        // data.append('isDraft', true);
        if (uploadType === 'message') {
          data.append('doc_type', 'Message');
        }
        if (uploadType === 'message' || uploadType === 'task') {
          data.append('is_draft', true);
        }
        if (
          uploadType === 'message_template' ||
          uploadType === 'task_template' ||
          uploadType === 'edoc_template'
        ) {
          data.append('doc_type', uploadType);
        }
        // if (uploadType === 'edoc') {
        //   data.append('is_draft', true);
        // }
        if (uploadFile[i].size > maxsizeInBytes) {
          alert(`File exceeds limit of ${maxsizeInMb}MB`);
          return false;
        }
        const { CancelToken } = Axios;
        const source = CancelToken.source();
        const key = `${uploadFile[i].name}sourceToken`;
        const fileSize = uploadFile[i].size;
        const fileSizeKey = `fileSize${uploadFile[i].name}`;
        const { uploadFiles } = this.state;
        this.setState({
          [key]: source,
          [fileSizeKey]: fileSize,
          uploadFiles: [...uploadFiles, ...[uploadFile[i].name]],
        });
        const config = {
          headers: { 'content-type': 'multipart/form-data' },
          cancelToken: source.token,
          onUploadProgress: onUploadProgress(uploadFile[i].name),
        };
        UploadFiles.addUploadDocument(
          data,
          component,
          '',
          config,
          uploadFile[i].name,
          uploadType,
          fro,
        );
        if (component.state.errors && component.state.errors.documents) {
          const err = component.state.errors;
          err.documents = '';
          component.setState({ errors: err });
        }
      }
    }
    return true;
  };

  cancelUpload = (filename) => {
    this.state[`${filename}sourceToken`].cancel();
  };

  sharedFolder = (key) => {
    if (key === 0) {
      this.setState({
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
      });
    } else if (key === 1) {
      const head = {
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`,
        },
      };
      this.setState({ loading: true, sharedFilesList: [], folderSequence: [] });
      Axios.get(
        'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe',
        head,
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.value.length === 0) {
            this.setState({ loading: false, noSharedFiles: true });
            // AlertMessage('error', 'No items found in shared list', 1000);
          } else {
            const { folderSequence: seq, sharedFilesList } = this.state;
            seq.push({
              name: 'Shared',
              id: 'shared',
              parentReference: { driveId: 'shared' },
              active: true,
              folder: true,
            });
            this.setState({ folderSequence: seq });
            response.data.value.map((item) => {
              const data = item.remoteItem;
              const sharedFiles = sharedFilesList;
              sharedFiles.push(data);
              this.setState({
                sharedFilesList: sharedFiles,
                onedriveFilesActiveTab: 1,
              });
              return null;
            });
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }
      });
    }
  };

  googleDrive = (stat = '') => {
    if (stat === 'false') {
      this.setState({ googleDriveModal: false });
    } else {
      this.setState((prevState) => ({
        googleDriveModal: !prevState.googleDriveModal,
      }));
    }
  };

  googleDriveLoader = (stat = '') => {
    if (stat === 'false') {
      this.setState({ loading: false });
    } else if (stat === 'true') {
      this.setState({ loading: true });
    } else {
      this.setState((prevState) => ({
        loading: !prevState.loading,
      }));
    }
  };

  smartVaultAccessToken = () => {
    const { access_token = null } =
      this.state.thirdPartyApps.find(
        (thirdPartyApp) => thirdPartyApp.id === 'smartvault',
      ) || {};
    return access_token;
  };

  toggleSmartVaultWidget = () => {
    this.setState((ps) => ({
      showSmartVaultWidget: !ps.showSmartVaultWidget,
    }));
  };

  renderAttachments() {
    let files = '';
    let { documents } = this.state;
    const { invoicePdfs } = this.props.data;
    const { emailAttachments } = this.props;
    if (invoicePdfs) {
      documents = [...documents, ...invoicePdfs];
    }
    if (emailAttachments) {
      documents = [...documents, ...emailAttachments];
    }
    const { uploadType, isEdit } = this.props;
    if (this.props.propsData && this.props.propsData.gettingEmailAttachments) {
      files = (
        <div className="file-drop-upload">
          <div className="file-drop-type">fetching attachments...</div>
        </div>
      );
    }
    if (documents.length) {
      files = documents.map((doc) => {
        const logo = fileType(doc);
        const getSize = doc.file_size / 1024;
        const trimSize = getSize.toFixed(2);
        return (
          <div key={`para${doc.id}`} className="file-drop-upload">
            <div className="file-drop-type">
              <i className={logo} aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <h6 href="#" className="fileName" key={`document${doc.id}`}>
                  {doc.is_active === undefined || doc.is_active === true ? (
                    <a
                      className="fileName pr-0"
                      key={`doc${doc.id}`}
                      href="javascript:void(0)"
                      onClick={() => this.previewDoc(doc)}
                    >
                      {doc.doc_name}
                    </a>
                  ) : (
                    <span
                      className="text-dark"
                      onClick={() => this.previewDoc(doc)}
                    >
                      {doc.doc_name}
                    </span>
                  )}
                </h6>
              </div>
              {doc.is_active === undefined || doc.is_active === true ? (
                <div>
                  <a
                    className="previewFile file-action"
                    key={`doc${doc.id}`}
                    href="javascript:void(0)"
                    onClick={() => this.previewDoc(doc)}
                  >
                    <i className="icon-open-eye" /> Preview
                  </a>
                  {doc.file_size && (
                    <span className="file-action">{trimSize} KB</span>
                  )}
                </div>
              ) : null}
              {doc.is_active === undefined || doc.is_active === true ? (
                <div>
                  {uploadType === 'message' ||
                  (uploadType === 'task' && !isEdit) ||
                  uploadType === 'note' ||
                  uploadType === 'edoc' ||
                  uploadType === 'message_template' ||
                  uploadType === 'task_template' ||
                  uploadType === 'edoc_template' ? (
                    <a
                      className="file-action"
                      key={`delete${doc.id}`}
                      onClick={() => this.delAttachmentModal(doc, false)}
                      href="javascript:void(0)"
                    >
                      <i className="icon-close2" />
                    </a>
                  ) : null}
                </div>
              ) : (
                <span className="text-dark">Deleted</span>
              )}
            </div>
          </div>
        );
      });
    }
    return files;
  }

  render() {
    const { isEdit, uploadType, type } = this.props;

    const {
      documents,
      isEmployee,
      uploadFiles,
      googleDriveModal,
      dropDownFrame,
      showSmartVaultWidget,
    } = this.state;
    // merged data to show it in preview screen
    const {
      folderSequence,
      thirdPartyApps,
      openOnedrive,
      sharedFilesList,
      onedriveFilesActiveTab,
      noSharedFiles,
    } = this.state;
    const apps = [];
    if (
      thirdPartyApps &&
      thirdPartyApps.length &&
      getCpaPreferences() &&
      getCpaPreferences().cloud_store &&
      getCpaPreferences().cloud_store.length
    ) {
      getCpaPreferences().cloud_store.map((each) => {
        thirdPartyApps.map((item) => {
          if (item.id === each.key && item.token_exits) {
            apps.push(each);
          }
          return null;
        });
        return null;
      });
    }
    if (
      !apps.length &&
      getCpaPreferences() &&
      getCpaPreferences().cloud_store &&
      getCpaPreferences().cloud_store.length
    ) {
      apps.push(getCpaPreferences().cloud_store[0]);
    }
    // const arrow = <i className="icon-rightarrow" />;
    const onedriveFolderHeader =
      folderSequence && folderSequence.length
        ? folderSequence.map((each, index) => (
            <span
              key={folderSequence.indexOf(each)}
              onClick={() => this.onedriveSharedFileHandle(each, 'folder')}
            >
              <span className="move-handle pr-1">
                {index === 0 ? each.name : ` > ${each.name}`}
              </span>
            </span>
          ))
        : null;
    const tabs = oneDriveModalHeaders;

    return (
      <>
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        {uploadType === 'message' ||
        (uploadType === 'task' && !isEdit) ||
        uploadType === 'note' ||
        uploadType === 'edoc' ||
        uploadType === 'message_template' ||
        uploadType === 'task_template' ||
        uploadType === 'edoc_template' ? (
          <FileDrop
            frame={dropDownFrame}
            onDrop={this.handleFileDrop}
            className="dragBrowse"
            id="Attachments"
          >
            <div
              className="DragDrop d-flex justify-content-center align-items-center"
              id="AttachmentDropDownFrame"
            >
              <div className="dropFile icon-upload" />
              <div className="dropFile pr-0">Drop files to attach</div>
              <div className="dropFile pl-0 pr-0">
                <div className="col">
                  <span>, </span>
                  <label
                    htmlFor="upload_doc"
                    className="fileContainer text-capitalize"
                  >
                    <a
                      href="javascript:void(0);"
                      onMouseDown={() => this.openVault(true)}
                    >
                      <img
                        className="pr-1"
                        style={{ width: '20px' }}
                        src={liscioLogo}
                        alt="logo"
                      />
                      Liscio Vault
                    </a>
                  </label>
                </div>
              </div>
              {this.props.handleTemplatesBtn && (
                <div className="dropFile pl-0 pr-0">
                  <div className="col">
                    <span> or </span>
                    <label
                      htmlFor="upload_doc"
                      className="fileContainer text-capitalize"
                    >
                      <a
                        href="javascript:void(0);"
                        onMouseDown={() => this.props.handleTemplatesBtn(true)}
                      >
                        Templates
                      </a>
                    </label>
                  </div>
                </div>
              )}
              {type === 'edoc' ? (
                <div className="dropFile pl-0">
                  <div className="col">
                    <span>or&nbsp;</span>
                    <label
                      htmlFor="upload_doc"
                      className="fileContainer text-capitalize"
                    >
                      Browse
                      <input
                        type="file"
                        id="upload_doc"
                        name="aws_url"
                        onClick={() => this.setAttachmentNull()}
                        onChange={() => this.uploadDocument()}
                        className="hide"
                        accept=".doc,.docx,.pdf"
                        multiple
                      />
                    </label>
                  </div>
                </div>
              ) : (
                <div className="dropFile pl-0">
                  <div className="col">
                    <span>or </span>
                    <label
                      htmlFor="upload_doc"
                      className="fileContainer text-capitalize"
                    >
                      <a
                        href="javascript:void(0);"
                        onMouseDown={() => this.handleModalChange(true)}
                      >
                        Browse
                      </a>
                    </label>
                    <span>.</span>
                  </div>
                </div>
              )}
              {type === 'edoc' ? (
                apps.length && isUserEmployee() ? (
                  <div className="dropdown dropdown--small">
                    <span className="text-dark p-1"> or </span>
                    <div className="dropdown-menu dropdown-menu-right">
                      {apps.map((each) =>
                        each.key === 'onedrive' ? (
                          <span
                            className="fileContainer text-capitalize dropdown-item"
                            onClick={() =>
                              this.handleOnedriveFiles('open', each)
                            }
                          >
                            <CustomImage
                              className="mr-2"
                              src="onedrive.png"
                              style={{ height: '15px' }}
                            />
                            {each.label}
                          </span>
                        ) : each.key === 'googledrive' ? (
                          <span
                            className="fileContainer text-capitalize dropdown-item"
                            onClick={() => this.googleDrive()}
                          >
                            <CustomImage
                              className="mr-2"
                              src="googledrive.svg"
                              style={{ height: '15px' }}
                            />
                            {each.label}
                          </span>
                        ) : each.key === 'smartvault' ? (
                          <span
                            className="fileContainer text-capitalize dropdown-item"
                            onClick={this.toggleSmartVaultWidget}
                          >
                            <CustomImage
                              className="mr-2"
                              src="smartvault.png"
                              style={{ height: '15px' }}
                            />
                            {each.label}
                          </span>
                        ) : (
                          <span className="fileContainer text-capitalize dropdown-item">
                            {each.label}
                          </span>
                        ),
                      )}
                    </div>
                  </div>
                ) : null
              ) : null}
            </div>
          </FileDrop>
        ) : null}
        {this.state.openModal && (
          <ClientFilePopup
            openModal={this.state.openModal}
            preferences={this.state.preferences}
            handleModalChange={this.handleModalChange}
            parentState={this.props.data}
            fileDropped={this.state.fileDropped}
            fileuploadFields={this.state.fileuploadFields}
            manageState={this.manageState}
            uploadType={this.props.uploadType}
            messageComp={this.props.messageComp || ''}
          />
        )}

        <div className="attached-file-list">
          {this.renderAttachments()}
          <RenderAttachmentsInProgress
            uploadFiles={uploadFiles}
            data={this.state}
            cancelUpload={this.cancelUpload}
          />
        </div>
        {openOnedrive ? (
          <Modal
            isOpen
            className="customModal customModal--center customModal--alert customModal--w950 customModal--nopadding"
          >
            <div className="ModalHeader">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => this.handleOnedriveFiles('close')}
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className=" odHeader p-0">
                <HeaderTabs
                  data={tabs}
                  activeTab={onedriveFilesActiveTab}
                  handleClick={this.sharedFolder}
                />
              </div>
              {sharedFilesList &&
              sharedFilesList.length > 0 &&
              onedriveFilesActiveTab === 1 &&
              !noSharedFiles ? (
                <div
                  className="center-wrapper"
                  style={{ maxHeight: '500px', overflowY: 'auto' }}
                >
                  <div className="tableWrap">
                    <div className="tableHead">
                      <div className="tableFilter d-flex justify-content-end">
                        <span className="align-self-center mr-2" />
                        <div className="select-custom-class" />
                      </div>
                    </div>
                    <div className="d-flex pb-3">{onedriveFolderHeader}</div>
                    <div className="tRow tRow--head">
                      <div className="row">
                        <div
                          className="col-auto thCol pr-0 flex-grow-0"
                          style={{ width: '55px' }}
                        >
                          <div className="d-flex">
                            <span
                              className="ml-auto"
                              style={{ paddingRight: '6px' }}
                            >
                              <i className="icon-Files" aria-hidden="true" />
                            </span>
                          </div>
                        </div>
                        {onedriveListHeaders.map((item) => (
                          <div key={item.value} className={item.className}>
                            <span className="posRel">{item.label}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="tRow tRow--body">
                      {sharedFilesList.map((item) => {
                        let commentTime = item ? item.lastModifiedDateTime : '';
                        const parsedTime = item ? item.createdDateTime : '';
                        if (commentTime && commentTime.indexOf('ago') === -1) {
                          const zonedTime = moment.tz(parsedTime, timeZone);
                          commentTime = `${zonedTime.format(
                            'MMM DD, YYYY',
                          )} at ${zonedTime.format('hh:mm a')}`;
                        }
                        const getSize = item.size ? item.size / 1024 : '';
                        const trimSize = getSize ? getSize.toFixed(2) : '';
                        return (
                          <div className="row" key={item.id}>
                            <div className="col-auto tdCol pr-0 pl-1 flex-grow-0">
                              <div className="d-flex ml-auto">
                                <div style={{ width: '32px' }}>
                                  {((item.folder &&
                                    item.folder !== undefined) ||
                                    (!item.folder &&
                                      item.folder === undefined)) &&
                                  !item.file ? null : (
                                    <div className="posRel checkbox checkbox-primary check-container pl-0 pr-3">
                                      <Label htmlFor={item.id}>
                                        <Input
                                          key={item.id}
                                          type="checkbox"
                                          id={item.id}
                                          checked={
                                            item.checked ? item.checked : false
                                          }
                                          className={`chkbx ${item.id}`}
                                          name="isChecked"
                                          onChange={() =>
                                            this.onedriveSharedFileHandle(item)
                                          }
                                        />
                                        <i className="checkmark" />
                                      </Label>
                                    </div>
                                  )}
                                </div>
                                <span
                                  onClick={() =>
                                    this.onedriveSharedFileHandle(item)
                                  }
                                >
                                  <i
                                    className={
                                      !item.folder &&
                                      item.folder === undefined &&
                                      item.file
                                        ? fileType({
                                            content_type: item.file.mimeType,
                                          })
                                        : 'icon-File'
                                    }
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </div>
                            <div
                              className="col-3 tdCol"
                              onClick={() =>
                                this.onedriveSharedFileHandle(item)
                              }
                            >
                              <span>{item.name ? item.name : '-'}</span>
                            </div>
                            <div
                              className="col-2 tdCol"
                              onClick={() =>
                                this.onedriveSharedFileHandle(item)
                              }
                            >
                              <span>
                                {item.shared
                                  ? item.shared.owner
                                    ? item.shared.owner.user
                                      ? item.shared.owner.user.displayName
                                        ? item.shared.owner.user.displayName
                                        : '-'
                                      : '-'
                                    : '-'
                                  : '-'}
                              </span>
                            </div>
                            <div
                              className="col-2 tdCol"
                              onClick={() =>
                                this.onedriveSharedFileHandle(item)
                              }
                            >
                              <span>
                                {item.lastModifiedBy
                                  ? item.lastModifiedBy.user
                                    ? item.lastModifiedBy.user.displayName
                                    : '-'
                                  : '-'}
                              </span>
                            </div>
                            <div
                              className="col-2 tdCol"
                              onClick={() =>
                                this.onedriveSharedFileHandle(item)
                              }
                            >
                              <span>{commentTime || '-'}</span>
                            </div>
                            <div
                              className="col-1 tdCol"
                              onClick={() =>
                                this.onedriveSharedFileHandle(item)
                              }
                            >
                              <span>{trimSize ? `${trimSize}KB` : '-'}</span>
                            </div>
                            <div
                              className="col tdCol"
                              onClick={() =>
                                this.onedriveSharedFileHandle(item)
                              }
                            >
                              <span>{item.shared ? 'Sharing' : 'Private'}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      type="button"
                      onClick={this.handleOnedriveSelectedFiles}
                      className="btn btn-primary"
                    >
                      Select
                    </button>
                  </div>
                </div>
              ) : noSharedFiles ? (
                <div id="content">
                  <div className="center-wrapper">
                    <PageNotFound
                      name="message"
                      message="There are no records to show you right now."
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="modalContent--inner"
                  style={{ maxHeight: '500px', overflowY: 'auto' }}
                >
                  {/* <GraphFileBrowser
                    getAuthenticationToken={this.getAuthenticationToken}
                    onSuccess={this.onSuccess}
                    onCancel={this.onCancel}
                    itemMode="files"
                  /> */}
                </div>
              )}
            </ModalBody>
          </Modal>
        ) : null}
        {googleDriveModal ? (
          <GooglePicker
            googleDrive={this.googleDrive}
            startUpload={this.startUpload}
            googleDriveLoader={this.googleDriveLoader}
          />
        ) : null}
        {showSmartVaultWidget ? (
          <SmartVaultWidget
            token={this.smartVaultAccessToken()}
            startUpload={this.startUpload}
            cancelWidget={this.toggleSmartVaultWidget}
          />
        ) : null}
        <Modal
          isOpen={this.state.showAttachmentModal}
          className="customModal customModal--w990 customModal--nopadding"
        >
          <ModalHeader className="ModalHeader">
            <button
              type="button"
              onClick={this.attachmentModalClose}
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </ModalHeader>
          <ModalBody className="modalContent">
            <div className="modalContent--inner">
              <div
                className={
                  isIE ? 'left-inner-content' : 'sample-letter-preview'
                }
              >
                <div className="letter-logo" />
                {isIE ? (
                  <PdfViewer file={this.state.selectedAttachment} />
                ) : (
                  <iframe
                    width="100%"
                    height="600"
                    name="myfilename"
                    src={this.state.selectedAttachment}
                    title="selected attachment"
                  />
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ConfirmationModal
          isOpen={this.state.deleteConfirm}
          headerText="You are about to delete the attached file."
          messageText="This action cannot be undone. Proceed?"
          noBtnClick={() =>
            this.setState({ deleteConfirm: false, deleteDocId: null })
          }
          yesBtnClick={() => this.delAttachmentModal('', true)}
          yesBtnText="Yes"
          noBtnText="No"
        />
        <Modal
          isOpen={this.state.showVault}
          className="customModal customModal--nopadding"
        >
          <div className="ModalHeader">
            <button
              onClick={() => this.openVault(false)}
              type="button"
              className="close"
              aria-label="Close"
            >
              <i className="icon-close2" aria-hidden="true" />
            </button>
          </div>
          <ModalBody>
            {isEmployee ? (
              <LiscioVaultList
                updateDocuments={this.manageState}
                openVault={this.openVault}
                selecteddocuments={documents}
                isVisible={this.state.showVault}
                getOnlyPrivateFilesinLiscioVault={
                  this.props.getOnlyPrivateFilesinLiscioVault
                }
                page={uploadType}
              />
            ) : (
              <ClientLiscioVault
                updateDocuments={this.manageState}
                openVault={this.openVault}
                selecteddocuments={documents}
                isVisible={this.state.showVault}
              />
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default TaskFileUpload;
